import React from "react";

const YesNoToggle = ({ titleText, value, onChange }) => {
  return (
    <div className="mt-5">
      <div className="text-[14px] text-primary-600 mb-3">
        {titleText}
      </div>
      <label className={`flex items-center justify-between w-12 h-6 ${value && value == "true" ? "bg-mediumseagreen-200" : 'bg-slate-200'} rounded-full p-1`}>
        <input
          type="checkbox"
          className="hidden"
          checked={value === "true"}
          onChange={() => onChange(value === "true" ? "false" : "true")}
        />
        <span
          className={`${
            value && value=="true" ? "translate-x-6" : "translate-x-0"
          } inline-block w-5 h-5 bg-white rounded-full transform transition duration-300 ease-in-out`}
        ></span>
      </label>
    </div>
  );
};

export default YesNoToggle;