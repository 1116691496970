import { useState, useEffect } from "react";
import React from "react";
import "whatwg-fetch";
import parse from "html-react-parser";
import { usePayment } from "../PaymentContext";
import { useUserData } from "../UserDataContext";
import { useJobs } from "../jobsContext";
import sanitizeHtml from "sanitize-html";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { db, increment, storage } from "../firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { useUsermaven } from "../UserMaven";
import AppModal from "./AppModal";
import AccountCreateModal from "./AccountCreateModal";
import { AnimatePresence, motion, layout } from "framer-motion";
import {
  ref,
  uploadBytesResumable,
  deleteObject,
  listAll,
} from "firebase/storage";
import WrangleTooltip from "./WrangleTooltip";
const JobPanel = () => {
  window.hj =
    window.hj ||
    function () {
      (hj.q = hj.q || []).push(arguments);
    };
  const navigate = useNavigate();
  const [isQualificationOpen, setIsQualificationOpen] = useState(false);
  const [isExperienceOpen, setIsExperienceOpen] = useState(false);
  const [isAppModalOpen, setIsAppModalOpen] = useState(false);
  const [fullDescription, setFullDescription] = useState(false);
  const [activeThreadIndex, setActiveThreadIndex] = useState(null);
  const { isAuthenticated, userData, setUserData, user } = useUserData();
  const [matchScore, setMatchScore] = useState(100);
  const [applied, setApplied] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    setUpgradeFeatureModal,
    appsUsed,
    currentPlan,
    bonusLeft,
    pendingApps,
    paymentsEnabled,
  } = usePayment();
  const { selectedJob, setBannerMessage, setBannerType } = useJobs();
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    e.stopPropagation(); // Stop event from propagating to parent elements
    navigator.clipboard.writeText(selectedJob.applyNowLink);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000); // Reset the tooltip after 1 second
  };

  const hashCode = (s) =>
    s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
  const title = selectedJob.title || "";
  const [isDisliked, setIsDisliked] = useState(
    userData.dislikes && userData.dislikes.includes(selectedJob.guid)
  );
  const [isLiked, setIsLiked] = useState(
    userData.likes && userData.likes.includes(selectedJob.guid)
  );
  const [savedSelectedJob, setSavedSelectedJob] = useState(selectedJob);
  const [threads, setThreads] = useState([
    {
      title: "What should I keep in mind when submitting a job application?",
      messages: [
        {
          text: "How to improve public speaking skills?",
          sender: "user",
        },
      ],
      isSuggestion: true,
    },
    {
      title:
        "How do I stand out to recruiters after submitting an application?",
      messages: [
        {
          text: "How to manage work stress effectively?",
          sender: "user",
        },
      ],
      isSuggestion: true,
    },
    {
      title: "What's the best way to frame myself on application questions?",
      messages: [
        {
          text: "How to negotiate a higher salary?",
          sender: "user",
        },
      ],
      isSuggestion: true,
    },
  ]);

  let usermavenOpts = {
    key: "UMWZUKUs0g",
    tracking_host: "https://events.usermaven.com",
  };
  const { usermaven } = useUsermaven(usermavenOpts);

  useEffect(() => {
    if (!isAppModalOpen) {
      setSavedSelectedJob(selectedJob);
    }

    const categories = selectedJob.categories || [];
    const skills = selectedJob.skills || [];
    const type = selectedJob.type || "";
    const location = selectedJob.location || "";
    const title = selectedJob.title || "";

    const userPreferences = Array.isArray(userData.preferences)
      ? userData.preferences
      : [];

    setMatchScore(calculateMatchScore(userData, selectedJob));
  }, [selectedJob, isAppModalOpen, userData.preferences, matchScore]);

  useEffect(() => {
    setIsLiked(userData.likes && userData.likes.includes(selectedJob.guid));
    setIsDisliked(
      userData.dislikes && userData.dislikes.includes(selectedJob.guid)
    );
  }, [userData.likes, userData.dislikes, selectedJob]);

  const toggleQualification = () =>
    setIsQualificationOpen(!isQualificationOpen);
  const toggleExperience = () => setIsExperienceOpen(!isExperienceOpen);

  const variants = {
    open: { opacity: 1, height: "auto" },
    collapsed: { opacity: 0, height: 0 },
  };

  const variants1 = {
    hidden: { opacity: 0, y: 0, height: 0 },
    visible: { opacity: 1, y: 0, height: "auto" },
    exit: { opacity: 0, y: 0, height: 0 },
  };

  const calculateMatchScore = (userData, job) => {
    const userPreferences = userData.preferences || {};
    let matchingAttributes = 0;
    let mismatchingAttributes = 0;

    // Count matching and mismatching attributes
    if (job.categories) {
      job.categories.forEach((category) => {
        if (userPreferences[category] === 1) matchingAttributes++;
        else if (userPreferences[category] === -1) mismatchingAttributes++;
      });
    }
    if (userPreferences[job.type] === 1) matchingAttributes++;
    else if (userPreferences[job.type] === -1) mismatchingAttributes++;

    if (job.skills) {
      job.skills.forEach((skill) => {
        if (userPreferences[skill] === 1) matchingAttributes++;
        else if (userPreferences[skill] === -1) mismatchingAttributes++;
      });
    }
    if (userPreferences[job.seniority] === 1) matchingAttributes++;
    else if (userPreferences[job.seniority] === -1) mismatchingAttributes++;

    if (userPreferences[job.title] === 1) matchingAttributes++;
    else if (userPreferences[job.title] === -1) mismatchingAttributes++;

    // Adjust match score based on matching and mismatching attributes
    let score = 50 + 10 * matchingAttributes - 20 * mismatchingAttributes;

    // Ensure score is within 0-100 range
    score = Math.max(0, Math.min(100, score));

    return score;
  };

  const checkSubscribe = async () => {
    let nameregex = new RegExp("^[a-zA-Z]+[-' ]+[a-zA-Z]+([-' ]+[a-zA-Z]+)?$");
    let emailregex = new RegExp(
      "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])"
    );
    let phoneregex = new RegExp("^(?=([+()\\d\\s-]*\\d){10,})[+\\d()\\s-]*$");

    if (userData.name == undefined || !nameregex.test(userData.name)) {
      setBannerMessage("Error: Invalid name.");
      setBannerType("errorRegex");
      return;
    } else if (
      userData.phone == undefined ||
      !phoneregex.test(userData.phone)
    ) {
      setBannerMessage("Error: Invalid phone.");
      setBannerType("errorRegex");
      return;
    } else if (
      userData.address == undefined ||
      !(userData.address.length > 1)
    ) {
      setBannerMessage("Error: Invalid address.");
      setBannerType("errorRegex");
      return;
    } else if (
      userData.email == undefined ||
      !emailregex.test(userData.email)
    ) {
      setBannerMessage("Error: Invalid email.");
      setBannerType("errorRegex");
      return;
    } else if (
      userData.filename == undefined ||
      userData.filename == null ||
      !userData.filename
    ) {
      setBannerMessage("Error: Please upload a resume.");
      setBannerType("errorRegex");
      return;
    } else {
      try {
        const dbRef = ref(storage, String(user.uid));
        const res = await listAll(dbRef);
        if (res.items.length === 0) {
          setBannerMessage("Error: Please reupload your resume.");
          setBannerType("errorRegex");
          return; // This will exit the function, so subsequent code will not run
        }
      } catch (error) {
        return;
        // Handle the error as needed
      }
    }
    if (!isAuthenticated) {
      setIsAppModalOpen(false);
      return;
    }
    if (paymentsEnabled) {
      let pending = pendingApps > 0 ? pendingApps : 0;
      if (
        ((currentPlan == 1 && 15 - appsUsed - pending <= 0) ||
          (currentPlan == 2 && 50 - appsUsed - pending <= 0) ||
          (currentPlan == 3 && 250 - appsUsed - pending <= 0)) &&
        bonusLeft - pending < 1 &&
        currentPlan != 4
      ) {
        setIsAppModalOpen(false);
        setUpgradeFeatureModal(true);
        return;
      }
    }

    usermaven.track("Applied to Job", {
      uid: user?.uid,
      name: userData?.name,
      email: userData?.email,
      address: userData?.address,
      appsUsed: appsUsed,
      salary: userData?.keywords?.salary,
      seniority: userData?.keywords?.seniority,
      title: userData?.keywords?.title,
      jobId: selectedJob.guid,
    });
    setIsAppModalOpen(true);
    const jobId = hashCode(selectedJob.guid).toString();
    const currentUrl = window.location.href;
    const separator = currentUrl.includes("?") ? "&" : "?";
    sendApplicationCopy();
    window.history.pushState({}, "", `${currentUrl}${separator}apply=${jobId}`);
  };
  const sendApplicationCopy = async () => {
    const emailDate = {
      username: userData.name,
      jobTitle: selectedJob.title,
      jobLocation: selectedJob.companyName,
      jobType: selectedJob.type,
      skill: selectedJob.skills[0],
      companyName: selectedJob.companyName,
    };
    try {
      const res = await fetch(
        `https://app.wranglejobs.com/api/send-email-application-copy`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            //'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({
            data: emailDate,
            email: userData.email,
          }),
        }
      );

      if (res.ok) {
        console.log("COMPLETE");
        const responseData = await res.json();
        console.log(responseData);
      } else {
        console.log("Request failed");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const timeSince = (date) => {
    const now = new Date();
    const publishedDate = new Date(date);
    const seconds = Math.floor((now - publishedDate) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const isTitleLine = (element) => {
    return element.type === "h3";
  };

  const truncateDescription = (desc, maxLines = 84, guid) => {
    // Define allowed tags
    const allowedTags = sanitizeHtml.defaults.allowedTags.filter(
      (tag) => tag !== "a"
    ); // Removing 'a' tags

    // Sanitize HTML and remove unwanted tags
    const sanitizedHtml = sanitizeHtml(desc, {
      allowedTags: allowedTags,
    });

    // Parse sanitized HTML
    const parsedDescription = parse(sanitizedHtml);
    let truncatedLines = [];
    let totalLines = 0;

    for (
      let i = 0;
      i < parsedDescription.length && totalLines < maxLines;
      i++
    ) {
      const element = parsedDescription[i];

      // Skip any nodes containing the unwanted string
      if (
        element.props &&
        element.props.children === "Originally posted on Himalayas"
      ) {
        continue; // Skip the current iteration and go to the next one
      }

      if (
        element.type === "p" ||
        element.type === "ul" ||
        isTitleLine(element)
      ) {
        totalLines += 1;
      }

      if (totalLines <= maxLines) {
        truncatedLines.push(<div key={i}>{element}</div>);
      }
    }

    // Append the "originally posted on Himalayas" link if guid contains "Himalayas"
    if (guid.toLowerCase().includes("himalayas")) {
      truncatedLines.push(
        <a
          href={guid}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-400"
        >
          originally posted on Himalayas
        </a>
      );
    }

    return truncatedLines;
  };

  const tagColors = {
    engineering: { bgColor: "bg-green-50", textColor: "text-green-600" },
    sales: { bgColor: "bg-blue-50", textColor: "text-blue-600" },
    marketing: { bgColor: "bg-purple-50", textColor: "text-purple-600" },
    product: { bgColor: "bg-pink-50", textColor: "text-pink-600" },
    design: { bgColor: "bg-teal-50", textColor: "text-teal-600" },
    support: { bgColor: "bg-red-50", textColor: "text-red-600" },
    operations: { bgColor: "bg-orange-50", textColor: "text-orange-600" },
    data: { bgColor: "bg-indigo-50", textColor: "text-indigo-600" },
    finance: { bgColor: "bg-yellow-50", textColor: "text-yellow-600" },
    management: { bgColor: "bg-gray-100", textColor: "text-gray-600" },
  };

  const renderTag = (tag) => {
    const normalizedTag = tag.toLowerCase();
    const color = tagColors[normalizedTag] || {
      bgColor: "bg-gray-100",
      textColor: "text-gray-600",
    };
  };

  const updatePreferences = (jobAttributes, increment) => {
    const updatedPreferences = { ...userData.preferences };
    jobAttributes.forEach((attribute) => {
      if (Array.isArray(attribute)) {
        attribute.forEach((item) => {
          updatedPreferences[item] =
            (updatedPreferences[item] || 0) + increment;
        });
      } else {
        updatedPreferences[attribute] =
          (updatedPreferences[attribute] || 0) + increment;
      }
    });
    return updatedPreferences;
  };

  const handleLikeJob = () => {
    if (!isLiked) {
      setIsLiked(true);

      // Add to likes and update preferences
      setUserData((prevUserData) => ({
        ...prevUserData,
        likes: [...(prevUserData.likes || []), selectedJob.guid],
        preferences: updatePreferences(
          [selectedJob.categories, selectedJob.skills, selectedJob.seniority],
          1
        ),
      }));
    } else {
      setIsLiked(false);

      // Remove from likes and update preferences
      setUserData((prevUserData) => ({
        ...prevUserData,
        likes: prevUserData.likes.filter((like) => like !== selectedJob.guid),
        preferences: updatePreferences(
          [selectedJob.categories, selectedJob.skills, selectedJob.seniority],
          -1
        ),
      }));
    }
  };

  const handleDislikeJob = () => {
    if (!isDisliked) {
      setIsDisliked(true);

      // Add to dislikes and update preferences
      setUserData((prevUserData) => ({
        ...prevUserData,
        dislikes: [...(prevUserData.dislikes || []), selectedJob.guid],
        preferences: updatePreferences(
          [selectedJob.categories, selectedJob.skills, selectedJob.seniority],
          -1
        ),
      }));
    } else {
      setIsDisliked(false);

      // Remove from dislikes and update preferences
      setUserData((prevUserData) => ({
        ...prevUserData,
        dislikes: prevUserData.dislikes.filter(
          (dislike) => dislike !== selectedJob.guid
        ),
        preferences: updatePreferences(
          [selectedJob.categories, selectedJob.skills, selectedJob.seniority],
          1
        ),
      }));
    }
  };

  return (
    <div className="bg-white border-neutral-700 border-[1px] border-solid rounded-lg overflow-hidden flex flex-col self-stretch">
      <div className="space-y-6 px-6 py-6">
        <div className="flex justify-between items-start">
          <div className="text-8xl font-semibold max-w-[50%]">
            {selectedJob.title}
          </div>
          <div className="flex space-x-2 start">
            <div
              onClick={() => {
                if (
                  [
                    "ashbyhq.com",
                    "workable.com",
                    "lever.co",
                    "greenhouse.io",
                  ].some(
                    (domain) =>
                      selectedJob.applyNowLink &&
                      selectedJob.applyNowLink.includes(domain)
                  )
                ) {
                  hj("event", "easy_apply");
                  isAuthenticated ? checkSubscribe() : setOpen(true);
                } else {
                  //console.log(selectedJob);
                  hj("event", "offsite_apply");
                  window.open(selectedJob.applyNowLink, "_blank");
                }
              }}
              className="bg-mediumseagreen-200 hover:bg-green-400 transition-all duration-300 cursor-pointer text-white text-sm font-semibold rounded-beast py-2.5 px-5 items-center justify-center"
            >
              {[
                "ashbyhq.com",
                "workable.com",
                "lever.co",
                "greenhouse.io",
              ].some(
                (domain) =>
                  selectedJob.applyNowLink &&
                  selectedJob.applyNowLink.includes(domain)
              )
                ? "Easy Apply"
                : "Apply"}
            </div>
            {isAuthenticated ? (
              <>
                <WrangleTooltip title={!isLiked ? "Like Job" : "Unlike Job"}>
                  <div
                    onClick={handleLikeJob}
                    className="flex justify-center items-center border-neutral-700 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] border-solid border-[1px] rounded-beast p-2 hover:bg-neutral-50"
                  >
                    <motion.svg
                      className={`outline-none relative w-[18px] h-[18px] shrink-0 ${
                        isLiked ? "text-mediumseagreen-200" : "text-gray-200"
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      whileTap={{ scale: 1.3 }}
                    >
                      <path
                        d="M4 17.9808V9.70753C4 6.07416 4 4.25748 5.17157 3.12874C6.34315 2 8.22876 2 12 2C15.7712 2 17.6569 2 18.8284 3.12874C20 4.25748 20 6.07416 20 9.70753V17.9808C20 20.2867 20 21.4396 19.2272 21.8523C17.7305 22.6514 14.9232 19.9852 13.59 19.1824C12.8168 18.7168 12.4302 18.484 12 18.484C11.5698 18.484 11.1832 18.7168 10.41 19.1824C9.0768 19.9852 6.26947 22.6514 4.77285 21.8523C4 21.4396 4 20.2867 4 17.9808Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path d="M4 7H20" strokeWidth="1.5" />
                    </motion.svg>
                  </div>
                </WrangleTooltip>
                <WrangleTooltip title={!isDisliked ? "Hide Job" : "Unhide Job"}>
                  <div
                    onClick={handleDislikeJob}
                    className="flex justify-center items-center shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] border-neutral-700 border-solid border-[1px] rounded-beast p-2 hover:bg-neutral-50"
                  >
                    <motion.svg
                      className={`outline-none relative w-[18px] h-[18px] shrink-0 ${
                        isDisliked ? "text-red-300" : "text-gray-200"
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      whileTap={{ scale: 1.3 }}
                    >
                      <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V2ZM12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84286 16.1566 4 14.1217 4 12C3.9978 10.2233 4.59302 8.49755 5.69 7.1L16.9 18.31C15.5025 19.407 13.7767 20.0022 12 20V20ZM18.31 16.9L7.1 5.69C8.49755 4.59302 10.2233 3.99779 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1572 7.84344 20 9.87827 20 12C20.0022 13.7767 19.407 15.5025 18.31 16.9Z" />
                    </motion.svg>
                  </div>
                </WrangleTooltip>
              </>
            ) : (
              <div
                onClick={() => {
                  window.open(selectedJob.applyNowLink, "_blank");
                }}
                className="border-neutral-700 border-solid border-[1px] hover:bg-neutral-50 transition-all shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] duration-300 cursor-pointer text-gray-500 text-sm font-medium rounded-beast py-2.5 px-5 items-center justify-center"
              >
                Apply
              </div>
            )}
          </div>
        </div>
        <AnimatePresence mode="wait">
          {!fullDescription && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={variants1}
              transition={{ duration: 0.4, ease: [0.25, 0.1, 0.25, 1] }}
              style={{ position: "relative" }}
            >
              <div className="mb-2">
                <div className="text-sm text-light-100 font-medium mb-3">
                  <span className="font-semibold">
                    {selectedJob.companyName}
                  </span>
                  {selectedJob.locationRestrictions &&
                    ` • ${
                      Array.isArray(selectedJob.locationRestrictions)
                        ? selectedJob.locationRestrictions[0]
                        : selectedJob.locationRestrictions
                    }`}
                  {` • ${timeSince(formatDate(selectedJob.pubDate))}`}
                </div>
                <div className="flex text-sm font-medium text-light-100 mb-3 items-center">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-3 flex-shrink-0"
                  >
                    <path
                      d="M2.57956 8.62505C2.50886 8.03528 2.47351 7.74039 2.52323 7.499C2.6651 6.81015 3.27111 6.25159 4.07871 6.06529C4.36172 6 4.717 6 5.42757 6H18.5724C19.283 6 19.6383 6 19.9213 6.06529C20.7289 6.25159 21.3349 6.81015 21.4768 7.499C21.5265 7.74039 21.4911 8.03528 21.4204 8.62505C21.2584 9.97669 20.4991 10.716 19.0512 11.1423L14.88 12.3703C13.4541 12.7901 12.7411 13 12 13C11.2589 13 10.5459 12.7901 9.11996 12.3703L4.94882 11.1423C3.50094 10.7161 2.7416 9.97669 2.57956 8.62505Z"
                      stroke="#7C7C7C"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M3.46283 10.5L3.26658 12.7757C2.91481 16.855 2.73892 18.8947 3.86734 20.1974C4.99576 21.5 6.93851 21.5 10.824 21.5H13.176C17.0615 21.5 19.0042 21.5 20.1327 20.1974C21.2611 18.8947 21.0852 16.855 20.7334 12.7757L20.5372 10.5"
                      stroke="#7C7C7C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.5 5.5L15.4227 5.23509C15.0377 3.91505 14.8452 3.25503 14.3869 2.87752C13.9286 2.5 13.3199 2.5 12.1023 2.5H11.8977C10.6801 2.5 10.0714 2.5 9.61309 2.87752C9.15478 3.25503 8.96228 3.91505 8.57727 5.23509L8.5 5.5"
                      stroke="#7C7C7C"
                      strokeWidth="1.5"
                    />
                  </svg>

                  <span>
                    {selectedJob.type}
                    {selectedJob.seniority &&
                      ` • ${
                        Array.isArray(selectedJob.seniority)
                          ? selectedJob.seniority[0]
                          : selectedJob.seniority
                      }`}
                    {selectedJob.maxSalary &&
                      ` • $${selectedJob.maxSalary.toLocaleString()}`}
                    {selectedJob.source === "indeed" &&
                      ` • ${"Posted on Indeed"}`}
                  </span>
                </div>
                <div className="flex text-sm text-light-100 mb-4 font-medium items-center">
                  {selectedJob.source == "indeed" ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-3 flex-shrink-0"
                    >
                      <path
                        d="M20.9427 16.8354C20.2864 12.8866 18.2432 9.94613 16.467 8.219C15.9501 7.71642 15.6917 7.46513 15.1208 7.23257C14.5499 7 14.0592 7 13.0778 7H10.9222C9.94081 7 9.4501 7 8.87922 7.23257C8.30834 7.46513 8.04991 7.71642 7.53304 8.219C5.75682 9.94613 3.71361 12.8866 3.05727 16.8354C2.56893 19.7734 5.27927 22 8.30832 22H15.6917C18.7207 22 21.4311 19.7734 20.9427 16.8354Z"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.25662 4.44287C7.05031 4.14258 6.75128 3.73499 7.36899 3.64205C8.00392 3.54651 8.66321 3.98114 9.30855 3.97221C9.89237 3.96413 10.1898 3.70519 10.5089 3.33548C10.8449 2.94617 11.3652 2 12 2C12.6348 2 13.1551 2.94617 13.4911 3.33548C13.8102 3.70519 14.1076 3.96413 14.6914 3.97221C15.3368 3.98114 15.9961 3.54651 16.631 3.64205C17.2487 3.73499 16.9497 4.14258 16.7434 4.44287L15.8105 5.80064C15.4115 6.38146 15.212 6.67187 14.7944 6.83594C14.3769 7 13.8373 7 12.7582 7H11.2418C10.1627 7 9.6231 7 9.20556 6.83594C8.78802 6.67187 8.5885 6.38146 8.18945 5.80064L7.25662 4.44287Z"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.6267 12.9186C13.4105 12.1205 12.3101 11.4003 10.9892 11.9391C9.66829 12.4778 9.45847 14.2113 11.4565 14.3955C12.3595 14.4787 12.9483 14.2989 13.4873 14.8076C14.0264 15.3162 14.1265 16.7308 12.7485 17.112C11.3705 17.4932 10.006 16.8976 9.85742 16.0517M11.8417 10.9927V11.7531M11.8417 17.2293V17.9927"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-3 flex-shrink-0"
                    >
                      <path
                        d="M11 6H21"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 12H21"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 18H21"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 7.39286L4.5 9L8 4"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M3 18.3929L4.5 20L8 15"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                      />
                    </svg>
                  )}
                  {selectedJob.source === "indeed" ? (
                    <span>
                      {selectedJob.salaryRange || "No salary rate posted"}
                    </span>
                  ) : (
                    <span>
                      {selectedJob.skills?.length
                        ? selectedJob.skills.slice(0, 3).join(", ")
                        : ""}
                      {selectedJob.skills?.length
                        ? selectedJob.skills.length > 3 &&
                          `, +${selectedJob.skills.length - 3} more`
                        : ""}
                    </span>
                  )}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {!fullDescription && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={variants1}
              transition={{ duration: 0.4, ease: [0.25, 0.1, 0.25, 1] }}
              style={{ position: "relative" }}
            >
              <div className="border-neutral-700 border-solid border-[1px] rounded-lg overflow-hidden">
                <div
                  className={`border-neutral-700 border-solid border-b-[1px] text-sm font-semibold px-4 py-3.5 ${
                    !userData.preferences || userData.preferences.length === 0
                      ? "bg-purple-50 text-purple-600"
                      : matchScore >= 75
                      ? "bg-green-50 text-green-600"
                      : matchScore >= 50
                      ? "bg-yellow-50 text-yellow-600"
                      : "bg-orange-50 text-orange-400"
                  }`}
                >
                  {!userData.preferences || userData.preferences.length === 0
                    ? isAuthenticated
                      ? "Like some jobs to see how well this matches!"
                      : "Create an account and like some jobs to see how well this matches!"
                    : matchScore >= 75
                    ? "This role is an excellent match for your preferences"
                    : matchScore >= 50
                    ? "This role is a fair match for your preferences"
                    : "This role doesn't fit your preferences well"}
                </div>
                <div className="px-4 py-3">
                  {selectedJob.source == "indeed" ? (
                    <div
                      className="mb-2 text-light-100 "
                      onClick={toggleQualification}
                    >
                      <div className="flex justify-between items-center text-sm text-light-100 font-medium cursor-pointer pb-2">
                        <div className="flex items-center">
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-3"
                          >
                            <path
                              d="M12.5 22H6.59087C5.04549 22 3.81631 21.248 2.71266 20.1966C0.453366 18.0441 4.1628 16.324 5.57757 15.4816C7.827 14.1422 10.4865 13.7109 13 14.1878"
                              stroke="#7C7C7C"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.5 6.5C15.5 8.98528 13.4853 11 11 11C8.51472 11 6.5 8.98528 6.5 6.5C6.5 4.01472 8.51472 2 11 2C13.4853 2 15.5 4.01472 15.5 6.5Z"
                              stroke="#7C7C7C"
                              strokeWidth="1.5"
                            />
                            <path
                              d="M18.6911 14.5777L19.395 15.9972C19.491 16.1947 19.7469 16.3843 19.9629 16.4206L21.2388 16.6343C22.0547 16.7714 22.2467 17.3682 21.6587 17.957L20.6668 18.9571C20.4989 19.1265 20.4069 19.4531 20.4589 19.687L20.7428 20.925C20.9668 21.9049 20.4509 22.284 19.591 21.7718L18.3951 21.0581C18.1791 20.929 17.8232 20.929 17.6032 21.0581L16.4073 21.7718C15.5514 22.284 15.0315 21.9009 15.2554 20.925L15.5394 19.687C15.5914 19.4531 15.4994 19.1265 15.3314 18.9571L14.3395 17.957C13.7556 17.3682 13.9436 16.7714 14.7595 16.6343L16.0353 16.4206C16.2473 16.3843 16.5033 16.1947 16.5993 15.9972L17.3032 14.5777C17.6872 13.8074 18.3111 13.8074 18.6911 14.5777Z"
                              stroke="#7C7C7C"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          {selectedJob.source == "indeed"
                            ? "Job posting information"
                            : "Ideal candidate profile"}
                        </div>
                        <motion.svg
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          animate={{ rotate: isQualificationOpen ? -180 : 0 }}
                          transition={{ duration: 0.3, ease: "easeInOut" }}
                        >
                          <path
                            d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                            stroke="#7C7C7C"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </motion.svg>
                      </div>
                      <motion.div
                        initial="collapsed"
                        animate={isQualificationOpen ? "open" : "collapsed"}
                        variants={variants}
                        transition={{
                          duration: 0.3,
                          ease: [0.04, 0.62, 0.23, 0.98],
                        }}
                      >
                        <AnimatePresence>
                          {isQualificationOpen && (
                            <motion.div className="text-light-100 text-sm mt-2 pb-2">
                              {
                                "You'll need an Indeed account to apply to this job, create one on their website."
                              }
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </motion.div>
                    </div>
                  ) : (
                    <div
                      className="mb-2 text-light-100 "
                      onClick={toggleQualification}
                    >
                      <div className="flex justify-between items-center text-sm text-light-100 font-medium cursor-pointer pb-2">
                        <div className="flex items-center">
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-3"
                          >
                            <path
                              d="M12.5 22H6.59087C5.04549 22 3.81631 21.248 2.71266 20.1966C0.453366 18.0441 4.1628 16.324 5.57757 15.4816C7.827 14.1422 10.4865 13.7109 13 14.1878"
                              stroke="#7C7C7C"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.5 6.5C15.5 8.98528 13.4853 11 11 11C8.51472 11 6.5 8.98528 6.5 6.5C6.5 4.01472 8.51472 2 11 2C13.4853 2 15.5 4.01472 15.5 6.5Z"
                              stroke="#7C7C7C"
                              strokeWidth="1.5"
                            />
                            <path
                              d="M18.6911 14.5777L19.395 15.9972C19.491 16.1947 19.7469 16.3843 19.9629 16.4206L21.2388 16.6343C22.0547 16.7714 22.2467 17.3682 21.6587 17.957L20.6668 18.9571C20.4989 19.1265 20.4069 19.4531 20.4589 19.687L20.7428 20.925C20.9668 21.9049 20.4509 22.284 19.591 21.7718L18.3951 21.0581C18.1791 20.929 17.8232 20.929 17.6032 21.0581L16.4073 21.7718C15.5514 22.284 15.0315 21.9009 15.2554 20.925L15.5394 19.687C15.5914 19.4531 15.4994 19.1265 15.3314 18.9571L14.3395 17.957C13.7556 17.3682 13.9436 16.7714 14.7595 16.6343L16.0353 16.4206C16.2473 16.3843 16.5033 16.1947 16.5993 15.9972L17.3032 14.5777C17.6872 13.8074 18.3111 13.8074 18.6911 14.5777Z"
                              stroke="#7C7C7C"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          {"Ideal candidate profile"}
                        </div>
                        <motion.svg
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          animate={{ rotate: isQualificationOpen ? -180 : 0 }}
                          transition={{ duration: 0.3, ease: "easeInOut" }}
                        >
                          <path
                            d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                            stroke="#7C7C7C"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </motion.svg>
                      </div>
                      <motion.div
                        initial="collapsed"
                        animate={isQualificationOpen ? "open" : "collapsed"}
                        variants={variants}
                        transition={{
                          duration: 0.3,
                          ease: [0.04, 0.62, 0.23, 0.98],
                        }}
                      >
                        <AnimatePresence>
                          {isQualificationOpen && (
                            <motion.div className="text-light-100 text-sm mt-2 pb-2">
                              {"The ideal candidate is: " +
                                selectedJob.seniority +
                                (selectedJob.locationRestrictions
                                  ? ", and based in: " +
                                    selectedJob.locationRestrictions
                                  : "") +
                                ", with a focus in " +
                                selectedJob.skills[
                                  selectedJob.skills.length - 1
                                ] +
                                " and " +
                                selectedJob.skills[
                                  selectedJob.skills.length - 2
                                ]}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </motion.div>
                    </div>
                  )}
                  <div className="text-light-100" onClick={toggleExperience}>
                    <div className="flex justify-between items-center text-sm text-light-100 font-medium cursor-pointer">
                      <div className="flex items-center">
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-3"
                        >
                          <path
                            d="M3.5 10C3.5 6.22876 3.5 4.34315 4.7448 3.17157C5.98959 2 7.99306 2 12 2H12.7727C16.0339 2 17.6645 2 18.7969 2.79784C19.1214 3.02643 19.4094 3.29752 19.6523 3.60289C20.5 4.66867 20.5 6.20336 20.5 9.27273V11.8182C20.5 14.7814 20.5 16.2629 20.0311 17.4462C19.2772 19.3486 17.6829 20.8491 15.6616 21.5586C14.4044 22 12.8302 22 9.68182 22C7.88275 22 6.98322 22 6.26478 21.7478C5.10979 21.3424 4.19875 20.4849 3.76796 19.3979C3.5 18.7217 3.5 17.8751 3.5 16.1818V10Z"
                            stroke="#7C7C7C"
                            strokeWidth="1.5"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.5 12C20.5 13.8409 19.0076 15.3333 17.1667 15.3333C16.5009 15.3333 15.716 15.2167 15.0686 15.3901C14.4935 15.5442 14.0442 15.9935 13.8901 16.5686C13.7167 17.216 13.8333 18.0009 13.8333 18.6667C13.8333 20.5076 12.3409 22 10.5 22"
                            stroke="#7C7C7C"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 7H15"
                            stroke="#7C7C7C"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 11H11"
                            stroke="#7C7C7C"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {"Manual application link"}
                      </div>
                      <motion.svg
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        animate={{ rotate: isExperienceOpen ? -180 : 0 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                      >
                        <path
                          d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                          stroke="#7C7C7C"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </motion.svg>
                    </div>
                    <motion.div
                      initial="collapsed"
                      animate={isExperienceOpen ? "open" : "collapsed"}
                      variants={variants}
                      transition={{
                        duration: 0.3,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      <AnimatePresence>
                        {isExperienceOpen && (
                          <motion.div className="text-light-100 text-sm pt-4">
                            {selectedJob.source == "indeed" ? (
                              <span>
                                You can still track this job on Wrangle after
                                applying at
                                <a
                                  href={selectedJob.applyNowLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-inherit no-underline px-1 bg-gray-200 rounded mt-1 inline-block"
                                >
                                  {selectedJob.applyNowLink}
                                </a>
                              </span>
                            ) : (
                              <span>
                                You can apply with our AI on Wrangle or apply
                                manually at
                                <WrangleTooltip
                                  title={isCopied ? "Copied!" : "Click to copy"}
                                  onMouseLeave={() => setIsCopied(false)} // Reset on tooltip unhover
                                >
                                  <a
                                    href="#"
                                    onClick={copyToClipboard}
                                    className="text-inherit no-underline py-1 px-2 bg-gray-100 hover:bg-gray-200 rounded mt-1 inline-block duration-300 transition-all"
                                  >
                                    {selectedJob.applyNowLink}
                                  </a>
                                </WrangleTooltip>
                              </span>
                            )}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </motion.div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence mode="wait">
        {!fullDescription && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={variants1}
            transition={{ duration: 0.4, ease: [0.25, 0.1, 0.25, 1] }}
            style={{ position: "relative" }}
          >
            <div className="pl-6 pb-6">
              <AnimatePresence>
                <motion.div className="flex">
                  <div
                    className={`justify-center items-center h-[46px] min-w-[46px] bg-copilot rounded-md box-border shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] mr-4`}
                    style={{
                      backgroundImage: "url('/copilot.png')",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <motion.div
                    className="flex gap-2 overflow-x-auto max-h-[200px] scrollbar-hide"
                    initial={{ opacity: 0, translateX: -10 }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: -10 }}
                    transition={{ duration: 0.2 }}
                  >
                    {threads
                      .slice()
                      .reverse()
                      .map((thread, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            const threadTitle = encodeURIComponent(
                              thread.title
                            );
                            navigate(`/copilot?thread=${threadTitle}`);
                          }}
                          className={`font-text-l-regular cursor-pointer transform-gpu transition-all duration-300 text-sm bg-base-white rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-2 px-4 items-center justify-start border-[1px] border-solid hover:bg-neutral-50 ${
                            threads.length - 1 - index == activeThreadIndex
                              ? "border-mediumseagreen-200"
                              : "border-neutral-700 hover:border-neutral-300"
                          }
                        ${
                          threads.length - 1 - index == activeThreadIndex
                            ? "text-black"
                            : "text-light-100"
                        }
                        mr-2`}
                        >
                          {thread.title}
                        </div>
                      ))}
                  </motion.div>
                </motion.div>
              </AnimatePresence>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="w-full h-[1px] bg-neutral-700" />
      <motion.div
        className="px-6 overflow-y-auto"
        onMouseEnter={() => setFullDescription(true)}
        onMouseLeave={() => setFullDescription(false)}
      >
        <h2 className="text-lg font-semibold mb-2">About the job:</h2>
        <p className="text-sm text-light-100">
          {truncateDescription(selectedJob.description, 84, selectedJob.guid)}
        </p>
      </motion.div>
      <AnimatePresence mode="wait">
        <AppModal
          isOpen={isAppModalOpen}
          onCloseApp={(buttonName) => {
            setIsAppModalOpen(false);
          }}
          inpLink={savedSelectedJob ? savedSelectedJob.applyNowLink : null}
          company={savedSelectedJob ? savedSelectedJob.company : null}
          guid={savedSelectedJob ? savedSelectedJob.guid : null}
          applied={applied}
          applyNowLink={savedSelectedJob ? savedSelectedJob.applyNowLink : null}
          setApplied={setApplied}
          companyName={
            savedSelectedJob
              ? savedSelectedJob.companyName
              : selectedJob.companyName
          }
          title={savedSelectedJob ? savedSelectedJob.title : selectedJob.title}
          htmlId={false}
        />
      </AnimatePresence>
      <AccountCreateModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default JobPanel;
