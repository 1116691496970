import React from "react";
import FileUpload from "./FileUpload";
import YesNoToggle from "./YesNoToggle";
import CheckboxGroup from "./CheckBoxGroup";
import RadioInputCard from "./RadioInputCard";
import DropdownInput from "./DropdownInput";
import FormInputCard from "./FormInputCard";
import { useEffect, useState, useMemo, useRef } from "react";
import { motion } from "framer-motion";
import { useUserData } from "../UserDataContext";
import { useJobs } from "../jobsContext";
import Rive from "@rive-app/react-canvas";
import { usePayment } from "../PaymentContext";
import Confetti from "react-confetti";
import { db, increment, auth } from "../firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import InputSelect from "./InputSelect";
import { useUsermaven } from "../UserMaven";

const ApplicationModal = ({
  isOpen,
  onCloseApp,
  inpLink,
  company,
  guid,
  companyName,
  title,
  htmlId,
}) => {
  let appLink = inpLink;
  const ignoredSelectors = {};
  const [formInfo, setFormInfo] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const { user, userData, setUserData, currentApi, isAuthenticated } =
    useUserData();
  const { selectedJob, setBannerMessage, setBannerType } = useJobs();
  const [fetched, setFetched] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const [newApp, setNewApp] = useState(false);
  const [errored, setErrored] = useState(false);
  const [jsonSuccess, setJsonSuccess] = useState(true);
  const modalRef = useRef();
  const currentJobOutRef = useRef();
  const hashCode = (s) =>
    s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
  const {
    setUpgradeFeatureModal,
    appsUsed,
    currentPlan,
    bonusLeft,
    pendingApps,
    paymentsEnabled,
  } = usePayment();

  let usermavenOpts = {
    key: "UMWZUKUs0g",
    tracking_host: "https://events.usermaven.com",
  };
  const { usermaven } = useUsermaven(usermavenOpts);
  const closeModal = (param) => {
    if (!param) {
      usermaven.track("Application Modal Closed", {
        uid: user?.uid,
        name: userData?.name,
        email: userData?.email,
        address: userData?.address,
        appsUsed: appsUsed,
        salary: userData?.keywords?.salary,
        seniority: userData?.keywords?.seniority,
        title: userData?.keywords?.title,
        jobId: guid,
      });
    }
    onCloseApp();
    resetModalState();
  };
  const resetModalState = () => {
    setFormInfo([]);
    setInputValues({});
    setFetched(false);
    setShowButton(true);
    setShowSuccess(false);
    setIsFormReady(false);
    setNewApp(false);
    setErrored(false);
  };
  const getFormHtml = async () => {
    //console.log(guid);
    //console.log(appLink);
    //console.log(htmlId)
    let idToken;
    if (auth.currentUser) {
      idToken = await auth.currentUser.getIdToken(true);
    } else {
      return;
    }
    const response = await fetch(`${currentApi}/get-form-workday`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ id: guid }),
    });
    if (!response.ok) {
      setErrored(true);
      throw new Error(`Error response from server: ${response.status}`);
    }

    const { formInfo, link } = await response.json();
    appLink = link;
    return formInfo;
  };
  const getFormNormal = async () => {
    let idToken;
    if (auth.currentUser) {
      idToken = await auth.currentUser.getIdToken(true);
    } else {
      return;
    }
    const response = await fetch(`${currentApi}/get-form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        appLink: appLink,
        guid: hashCode(guid).toString(),
      }),
    });
    if (!response.ok) {
      setErrored(true);
      throw new Error(`Error response from server: ${response.status}`);
    }

    const { formInfo } = await response.json();
    return formInfo;
  };
  const fetchFormInfo = async () => {
    //console.log(appLink);
    setErrored(false);
    try {
      /*let idToken;
      if(auth.currentUser){
         idToken = await auth.currentUser.getIdToken(true);
      }
      else{
        return;
      }
      /*let formInfo;
      if(htmlId){
        const response = await fetch(`${currentApi}/get-workday-form`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}`},
          body: JSON.stringify({ id: guid}),
        });
        if (!response.ok) {
          setErrored(true);
          throw new Error(`Error response from server: ${response.status}`);
        }
    
        const { form } = await response.json();
        formInfo = form;
      }
      else{*/
      /*const response = await fetch(`${currentApi}/get-form`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}`},
          body: JSON.stringify({ appLink: appLink,
          guid: hashCode(guid).toString() }),
        });
        if (!response.ok) {
          setErrored(true);
          throw new Error(`Error response from server: ${response.status}`);
        }
    
        const { formInfo } = await response.json();
      /*}*/
      let formInfo;
      if (htmlId) {
        formInfo = await getFormHtml();
      } else {
        formInfo = await getFormNormal();
      }
      console.log(formInfo);
      if (!formInfo || formInfo.length === 0) {
        console.error("Form information is empty.");
        setErrored(true);
        return;
      }
      // const prompt = formInfo
      //   .filter(
      //     ({ type, label }) =>
      //       type !== 'file' && !new Set().has(label)
      //   )
      //   .map(
      //     ({ selector, options, ...rest }) =>
      //       `${rest.label}|${rest.type || rest.tag}|${
      //         rest.required ? 'Required' : 'Not required'
      //       }${options ? '|' + options.map((opt) => opt.label).join(',') : ''}`
      //   )
      //   .join('\n');
      const prompt = formInfo
        .filter(({ type, label }) => type !== "file" && type !== "Divider")
        .map(({ selector, options, label, type, tag, required }) => {
          const typeOrTag = type || tag || "Unknown";
          const requiredText = required ? "Required" : "Not required";
          // Check if options is an array and map over it, else return an empty string
          const optionsText = Array.isArray(options)
            ? "|" + options.map((opt) => opt.label).join(",")
            : "";

          return `${label}|${typeOrTag}|${requiredText}${optionsText}`;
        })
        .join("\n");

      try {
        let { parsedAnswers, jobIdentifier } = await openAIWhisper(prompt);
        if (jobIdentifier == currentJobOutRef.current) {
          for (let i = 0; i < formInfo.length; i++) {
            // check for toggles
            if (
              !formInfo[i].options &&
              formInfo[i].type == "checkbox" &&
              (!parsedAnswers[formInfo[i].label] ||
                parsedAnswers[formInfo[i].label] != "true" ||
                parsedAnswers[formInfo[i].label] != "false")
            ) {
              if (
                formInfo[i].selector ==
                  '[name="cards[1511a958-cba8-4266-8468-abdd3e0d8d9b][field7]"]' ||
                formInfo[i].selector ==
                  `[name="cards[498867a3-ab6c-4c28-b022-50da44067fa7][field6"]` ||
                formInfo[i].selector ==
                  `[name="cards[498867a3-ab6c-4c28-b022-50da44067fa7][field10]"]` ||
                formInfo[i].selector == `[set="23662402002"]` ||
                formInfo[i].label.includes("acknowledge") ||
                formInfo[i].label.includes("Acknowledge")
              ) {
                parsedAnswers[formInfo[i].label] = "true";
              } else {
                parsedAnswers[formInfo[i].label] = "false";
                formInfo[i].type = "toggle";
                formInfo[i].tag = "toggle";
              }
            }
          }
          //console.log(parsedAnswers)
          setInputValues(parsedAnswers);
          setFormInfo(formInfo);
          if (formInfo.some((info) => info.jsonOptions !== undefined)) {
            setJsonSuccess(false);
          }
          console.log(formInfo);
          setFetched(true);
          setNewApp(true);
        }
      } catch (error) {
        console.error("Error while calling OpenAI Whisper API:", error);
        setErrored(true);
      }
    } catch (error) {
      console.error("Error while fetching form information:", error);
      setErrored(true);
    }
  };
  useEffect(() => {
    if (isOpen) {
      resetModalState();
      fetchFormInfo();
    } else {
      resetModalState();
    }
  }, [isOpen, appLink]);

  const Loader = () => (
    <Rive
      src="/wrangleloading.riv"
      className="w-[400px] h-[400px] min-w-[400px] min-h-[400px]"
    ></Rive>
  );

  const Success = () => (
    <Rive
      src="/success.riv"
      className="min-w-[250px] min-h-[250px] w-[250px] h-[250px]"
    ></Rive>
  );

  const openAIWhisper = (questions) => {
    return new Promise(async (resolve, reject) => {
      if (questions.length === 0) {
        //console.log('Form info is empty. Not calling OpenAI Whisper API');
        resolve([]);
        return;
      }

      try {
        currentJobOutRef.current = hashCode(guid).toString();
        let idToken;
        if (auth.currentUser) {
          idToken = await auth.currentUser.getIdToken(true);
        } else {
          return;
        }
        const response = await fetch(`${currentApi}/ai-request`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            questions: questions,
            userData: {
              Address: userData.address,
              Portfolio: userData.portfolio,
              Phone: userData.phone,
              Name: userData.name,
              Keyinfo: userData.keyInfo,
              Filename: userData.filename,
              Email: userData.email,
              linkedin: userData.linkedin
                ? "https://linkedin.com/in/" + userData.linkedin
                : "",
              figma: userData.figma
                ? "https://figma.com/@" + userData.figma
                : "",
              dribbble: userData.dribbble
                ? "https://dribbble.com/" + userData.dribbble
                : "",
              github: userData.github
                ? "https://github.com/" + userData.github
                : "",
              twitter: userData.twitter
                ? "https://twitter.com/" + userData.twitter
                : "",
              stackoverflow: userData.stackoverflow
                ? "https://stackoverflow.com/users/" + userData.stackoverflow
                : "",
            },
            selectedJob: {
              companyName: companyName,
              title: title,
            },
            jobIdentifier: hashCode(guid).toString(),
          }),
        });

        if (!response.ok) {
          console.error("Error while fetching from OpenAI Whisper API");
          setErrored(true);
          resolve([]);
        }

        const { parsedAnswers, jobIdentifier } = await response.json();
        resolve({ parsedAnswers, jobIdentifier });
      } catch (error) {
        console.error("Error while calling OpenAI Whisper API:", error);
        setErrored(true);
        resolve([]);
      }
    });
  };
  useEffect(() => {
    const requiredFields = formInfo.filter(
      (info) => info.required && info.type !== "file"
    );

    const allRequiredFieldsFilled = requiredFields.every((field) => {
      if (field.options) {
        const validOptions = field.options.map((option) => option.value);
        if (Array.isArray(inputValues[field.label])) {
          return (
            inputValues[field.label].length > 0 &&
            inputValues[field.label].every((value) =>
              validOptions.includes(value)
            )
          );
        } else {
          return (
            validOptions.includes(inputValues[field.label]) &&
            !["Please Select", "Please select", "---", "--", ""].includes(
              inputValues[field.label]
            )
          );
        }
      }
      if (field.type == "checkbox") {
        return true;
      }
      return !!inputValues[field.label];
    });

    const unansweredRequiredFields = requiredFields.filter((field) => {
      if (field.options) {
        const validOptions = field.options.map((option) => option.value);
        if (Array.isArray(inputValues[field.label])) {
          return !(
            inputValues[field.label].length > 0 &&
            inputValues[field.label].every((value) =>
              validOptions.includes(value)
            )
          );
        } else {
          return (
            !validOptions.includes(inputValues[field.label]) ||
            ["Please Select", "Please select", "---", "--", ""].includes(
              inputValues[field.label]
            )
          );
        }
      }
      return !inputValues[field.label];
    });

    setIsFormReady(allRequiredFieldsFilled);
  }, [inputValues]);
  const listOptionsJSON = (jsonInp) => {
    if (jsonInp && jsonInp.items && Array.isArray(jsonInp.items)) {
      const optionsArray = jsonInp.items.map((item) => ({
        id: item.id,
        text: item.text,
      }));
      return optionsArray;
    }
    return null;
  };
  const renderInput = useMemo(() => (input, inputIdx) => {
    if (input.label == "Divider") return;
    console.log(input);
    if (
      (input.selector ==
        '[name="cards[1511a958-cba8-4266-8468-abdd3e0d8d9b][field7]"]' ||
        input.selector ==
          `[name="cards[498867a3-ab6c-4c28-b022-50da44067fa7][field6"]` ||
        input.selector ==
          `[name="cards[498867a3-ab6c-4c28-b022-50da44067fa7][field10]"]` ||
        input.selector == `[set="23662402002"]` ||
        input.label.includes("acknowledge") ||
        input.label.includes("Acknowledge")) &&
      input.type == "checkbox" &&
      !input.options
    ) {
      return;
    }
    if (
      input.label == "Demographics (Select all that Apply)" ||
      //input.label.includes("pronouns") ||
      //input.label.includes("Pronouns") ||
      input.label == "Custom"
    )
      return;
    const options =
      input.options?.map((option) => ({
        label: option.label || option.value,
        value: option.value,
      })) || [];
    const parsedOptions = input.jsonOptions
      ? listOptionsJSON(input.jsonOptions)
      : null;
    let inputValue = inputValues?.[input.label] || "";

    if (Array.isArray(inputValue)) {
      inputValue.forEach((val, idx) => {
        const inputOptionValue = options?.find(
          ({ label }) => label === val || label.includes(val)
        )?.value;
        if (inputOptionValue) {
          inputValue[idx] = inputOptionValue;
        }
      });
    } else if (options && options.length) {
      const val = options.find(
        ({ label }) =>
          label && (label === inputValue || label.includes(inputValue))
      )?.value;
      inputValue = val || inputValue;
    } else if (
      input.type !== "text" &&
      input.type !== "textarea" &&
      input.type !== "number" &&
      input.tag !== "input" &&
      input.tag !== "textarea" &&
      input.tag !== "text" &&
      typeof inputValue === "string" &&
      inputValue.includes(",")
    ) {
      inputValue = inputValue.split(",").map((item) => item.trim());
    }

    inputValues[input.label] = inputValue;

    const titleText = input.required ? `${input.label} *` : input.label;
    if (!input.label) {
      return (
        <CheckboxGroup
          key={inputIdx}
          label={"Unknown label."}
          options={options}
          required={input.required}
          onChange={() => {}}
        />
      );
    }

    if (input.label.toLowerCase().includes("cover letter")) {
      const handleCoverLetterChange = (value) => {
        setInputValues((values) => ({
          ...values,
          coverLetter: value,
          [input.label]: value,
        }));
      };

      return (
        <FormInputCard
          key={inputIdx}
          titleText={titleText}
          inputText={input.placeholder}
          value={
            inputValues["coverLetter"] && inputValues["coverLetter"] !== ""
              ? inputValues["coverLetter"]
              : inputValues[input.label]
          }
          onChange={handleCoverLetterChange}
        />
      );
    }

    switch (input.type || input.tag) {
      case "inputSelect": {
        return (
          <InputSelect
            key={inputIdx}
            titleText={titleText}
            options={parsedOptions}
            value={inputValue}
            onChange={(value) => {
              /*if (!options[value]) {
            console.error("The selected option does not exist");
            return;
          }*/
              setJsonSuccess(true);
              setInputValues({ ...inputValues, [input.label]: value });
            }}
          />
        );
      }
      case "number": {
        return (
          <FormInputCard
            key={inputIdx}
            titleText={titleText}
            inputText={input.placeholder}
            value={/^\d*$/.test(inputValue) ? inputValue : ""}
            onChange={(value) =>
              setInputValues({ ...inputValues, [input.label]: value })
            }
            isNumber={true}
          />
        );
      }
      case "toggle": {
        return (
          <YesNoToggle
            key={inputIdx}
            titleText={titleText}
            required={input.required}
            onChange={(checked) => {
              setInputValues({ ...inputValues, [input.label]: checked });
            }}
            value={inputValue}
          />
        );
      }
      case "checkbox": {
        if (!Array.isArray(inputValue)) {
          inputValue = [inputValue];
        }
        //console.log("inputvals", inputValues[input.label]);
        //console.log("inputval", inputValue);
        return (
          <CheckboxGroup
            key={inputIdx}
            label={titleText}
            options={options}
            required={input.required}
            initialChecked={inputValue}
            onChange={(name, checked) => {
              //console.log("inputvals", inputValues[input.label]);
              //console.log("inputval", inputValue);
              const newInputValue = inputValues[input.label] || [];
              if (checked && !newInputValue.includes(name)) {
                newInputValue.push(name);
              } else if (!checked && newInputValue.includes(name)) {
                newInputValue.splice(newInputValue.indexOf(name), 1);
              }
              setInputValues({ ...inputValues, [input.label]: newInputValue });
            }}
          />
        );
      }
      case "group" && !input.required:
        return;
      case "file":
        return (
          <FileUpload
            key={inputIdx}
            titleText={titleText}
            file={inputValue}
            setFile={() => {}}
          />
        );
      case "radio": {
        return (
          <RadioInputCard
            key={inputIdx}
            titleText={titleText}
            options={options}
            value={inputValue}
            onChange={(checked) =>
              setInputValues({ ...inputValues, [input.label]: checked })
            }
          />
        );
      }
      case "select": {
        if (!input) {
          console.error("input is undefined");
          return null;
        }
        if (!options) {
          console.error(
            "options are undefined or the selected option does not exist"
          );
          //console.log(input, options, options[inputValue])
          return null;
        }
        if (!options[inputValue]) {
          return (
            <DropdownInput
              key={inputIdx}
              titleText={titleText}
              options={options}
              value={inputValue}
              onChange={(value) => {
                if (!options[value]) {
                  console.error("The selected option does not exist");
                  return;
                }

                setInputValues({
                  ...inputValues,
                  [input.label]: options[value].label,
                });
              }}
            />
          );
        }

        return (
          <DropdownInput
            key={inputIdx}
            titleText={titleText}
            options={options}
            value={inputValue}
            onChange={(value) => {
              if (!options[value]) {
                console.error("The selected option does not exist");
                return;
              }

              setInputValues({
                ...inputValues,
                [input.label]: options[value].label,
              });
            }}
          />
        );
      }
      case "button": {
        if (options) {
          return (
            <DropdownInput
              key={inputIdx}
              titleText={titleText}
              options={options}
              value={inputValue}
              onChange={(value) => {
                if (!options[value]) {
                  console.error("The selected option does not exist");
                  return;
                }
                setInputValues({
                  ...inputValues,
                  [input.label]: options[value].label,
                });
              }}
            />
          );
        }
        return;
      }
      default:
        //console.log(input);
        return (
          <FormInputCard
            key={inputIdx}
            titleText={titleText}
            inputText={input.placeholder}
            value={inputValue}
            onChange={(value) =>
              setInputValues({ ...inputValues, [input.label]: value })
            }
          />
        );
    }
  });

  const handleSubmit = async () => {
    if (paymentsEnabled) {
      let pending = pendingApps > 0 ? pendingApps : 0;
      if (
        ((currentPlan == 1 && 15 - appsUsed - pending <= 0) ||
          (currentPlan == 2 && 50 - appsUsed - pending <= 0) ||
          (currentPlan == 3 && 250 - appsUsed - pending <= 0)) &&
        bonusLeft - pending < 1 &&
        currentPlan != 4
      ) {
        closeModal(true);
        setUpgradeFeatureModal(true);
      }
    }
    usermaven.track("Application Submitted", {
      uid: user?.uid,
      name: userData?.name,
      email: userData?.email,
      address: userData?.address,
      appsUsed: appsUsed,
      salary: userData?.keywords?.salary,
      seniority: userData?.keywords?.seniority,
      title: userData?.keywords?.title,
      jobId: guid,
    });
    const jobRef = htmlId ? guid : hashCode(guid).toString();
    const userId = user.uid;

    setShowSuccess(true);
    setShowButton(false);

    setTimeout(async () => {
      closeModal();
      setNewApp(false);
      setUserData((prevUserData) => ({
        ...prevUserData,
        applied: [...(prevUserData.applied || []), guid],
      }));

      const updatedFormInfo = formInfo.map((input) => {
        const newValue = inputValues[input.label];
        const { required, options, ...inputWithoutRequiredOrOptions } = input;
        return {
          ...inputWithoutRequiredOrOptions,
          value: newValue || input.value,
        };
      });

      try {
        /*console.log({
          url: appLink,
          inputs: updatedFormInfo,
          profileId: userId,
          jobId: jobRef,
          coverLetter: ""
        });*/
        if (!htmlId) {
          let idToken;
          if (auth.currentUser) {
            idToken = await auth.currentUser.getIdToken(true);
          } else {
            return;
          }
          const response = await fetch(`${currentApi}/apply`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              applications: [
                {
                  address: userData.address,
                  url: appLink,
                  inputs: updatedFormInfo,
                  profileId: userId,
                  filename: userData.filename,
                  jobId: jobRef,
                  coverLetter: inputValues["coverLetter"] || "",
                },
              ],
            }),
          });

          if (response.ok) {
            if (currentPlan == 5) {
              setBannerMessage("Update: Your application has arrived!");
              setBannerType("successSubmit");
            } else {
              setBannerMessage("Update: Request sent to server.");
              setBannerType("update");
            }
          } else {
            setBannerMessage("Error: Error with submission.");
            setBannerType("error");
            console.error("Error while submitting the job application");
          }
        } else {
          const response = await fetch(`${currentApi}/api-apply-workday`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer wrangle-test`,
            },
            body: JSON.stringify({
              application: {
                inputs: updatedFormInfo,
                profileId: "LURq7VVIz9ZqhJKcOMyn",
                jobId: jobRef,
              },
              userData: userData,
            }),
          });

          if (response.ok) {
            if (currentPlan == 5) {
              setBannerMessage("Update: Your application has arrived!");
              setBannerType("successSubmit");
            } else {
              setBannerMessage("Update: Request sent to server.");
              setBannerType("update");
            }
          } else {
            setBannerMessage("Error: Error with submission.");
            setBannerType("error");
            console.error("Error while submitting the job application");
          }
        }
      } catch (error) {
        setBannerMessage("Error: Error with submission.");
        setBannerType("error");
        console.error("Error:", error);
      }
    }, 3000);
  };

  return isOpen ? (
    <>
      <motion.div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
        {showSuccess && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={200}
          />
        )}
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => {
            closeModal();
          }}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <motion.div
          ref={modalRef}
          className={`z-20 inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full ${
            !fetched || showSuccess ? "h-400px w-400px" : "max-h-[90%]"
          }`}
          initial={{
            width: "initialWidth",
            height: "initialHeight",
            opacity: 0,
          }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <div className="bg-white p-6 flex flex-col">
            <div className="mt-3 text-center sm:text-left">
              <div
                className={`overflow-y-auto no-scrollbar ${
                  errored ? "w-full" : "w-full"
                } ${
                  !fetched || showSuccess ? "" : "max-h-[75vh] min-h-[50vh]"
                } `}
              >
                {errored ? (
                  <div className="relative bg-base-white shadow-[0px_20px_24px_-4px_rgba(16,_24,_40,_0.08),_0px_8px_8px_-4px_rgba(16,_24,_40,_0.03)] w-full overflow-hidden flex flex-col items-start justify-start text-left text-lg text-gray-900 font-text-sm-semibold">
                    <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[48px]">
                      <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
                        <div className="self-stretch flex flex-col items-start justify-start gap-[2px]">
                          <div className="self-stretch relative leading-[28px] font-semibold">
                            Whoops! Something went wrong.
                          </div>
                          <div className="self-stretch relative text-base leading-[24px] text-gray-600">
                            It looks like this job is either expired, or the
                            application exceeded the models context length.
                          </div>
                        </div>
                        <div className="self-stretch py-12 flex flex-col items-center justify-center">
                          <img
                            className="relative w-[390px] h-[258px] object-cover"
                            alt=""
                            src="/error.png"
                          />
                        </div>
                      </div>
                      <div className="self-stretch flex flex-row items-start justify-center gap-[12px] text-sm text-gray-700">
                        <div
                          className="flex-1 rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row py-2.5 px-4 items-center justify-center border-[1px] border-solid border-gray-300 cursor-pointer"
                          onClick={() => {
                            closeModal();
                            setErrored(false);
                          }}
                        >
                          <div className="relative leading-[20px] font-semibold">
                            Go Back
                          </div>
                        </div>
                        <div
                          className="flex-1 rounded-lg bg-mediumseagreen-100 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row py-2.5 px-4 items-center justify-center text-base-white border-[1px] border-solid border-mediumseagreen-100 cursor-pointer"
                          onClick={() => {
                            closeModal();
                            setErrored(false);
                            setUserData((prevUserData) => ({
                              ...prevUserData,
                              applied: [...(prevUserData.applied || []), guid],
                            }));
                            resetModalState();
                          }}
                        >
                          <div className="relative leading-[20px] font-semibold">
                            Hide Job
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : showSuccess ? (
                  <div className="flex items-center justify-center h-[400px] w-[400px] min-h-[400px] min-w-[100%]">
                    <Success />
                  </div>
                ) : (
                  <>
                    <div className="mb-10">
                      {fetched ? (
                        <div className="input-container flex flex-col items-start gap-6">
                          {formInfo
                            .map((input, inputIdx) =>
                              renderInput(input, inputIdx)
                            )
                            .filter((input) => input !== null)}
                        </div>
                      ) : (
                        <div className="flex items-center justify-center h-[400px] w-[400px] min-h-[400px] min-w-[100%]">
                          <Loader />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              {newApp && !errored && showButton ? (
                <div className="relative mb-2">
                  <div className="flex justify-center mt-4 gap-12">
                    <button
                      onClick={() => {
                        closeModal();
                      }}
                      className="cursor-pointer py-3 px-6 text-base-white bg-transparent rounded-md flex items-center justify-center gap-[6px] focus:outline-none hover:bg-neutral-200 rounded-3xs border-[1px] border-solid border-neutral-600"
                    >
                      <div className="relative text-mini leading-[22px] text-gray-700 font-semibold font-text-l-regular">
                        Cancel
                      </div>
                    </button>
                    <button
                      className={`cursor-pointer py-3 px-6 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] flex items-center justify-center gap-[6px] focus:outline-none transition-colors duration-300 ${
                        isFormReady && jsonSuccess && !htmlId
                          ? "bg-mediumseagreen-200 text-base-white hover:bg-green-300"
                          : "bg-gray-200 text-gray-500 cursor-not-allowed"
                      }`}
                      onClick={handleSubmit}
                      disabled={(!isFormReady || !jsonSuccess) && !htmlId}
                    >
                      <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular">
                        Submit Application
                      </div>
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </>
  ) : null;
};

export default ApplicationModal;
