import { useEffect, useState } from "react";
import ApplicationQuestion from "./ApplicationQuestion";
export const ApplicationStepSection = ({
  sectionData,
  onValueChange,
  value,
  inputValues,
  setInputValues,
  setJsonSuccess
}) => {
  const [sectionValue, setSectionValue] = useState({});
  useEffect(() => {
    if (value) setSectionValue(value);
  }, [value]);
  return <div className="mb-4">
      {(sectionData.title || sectionData.subTitle) && <div>
          {sectionData.title && <div className="text-base font-semibold text-primary-600 mb-2">
              {sectionData.title}
            </div>}
          {sectionData.subTitle && <div className="text-note text-primary-600 mb-2">
              {sectionData.subTitle}
            </div>}
        </div>}
      {sectionData.questions.map(question => <ApplicationQuestion setJsonSuccess={setJsonSuccess} questionData={question} inp={inputValues[question.index]} onValueChange={value => {
      setInputValues({
        ...inputValues,
        [question.index]: value
      });
      //console.log({...inputValues, [question.index]: value});
      //console.log({...inputValues, [question.index]: value})
      //onValueChange({ ...sectionValue, [question.id]: value });
      //setSectionValue({ ...sectionValue, [question.id]: value });
    }} key={`section-${sectionData.id}-question-${question.index}`} value={sectionValue[question.id]} />)}
    </div>;
};
export default ApplicationStepSection;