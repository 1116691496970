export const SKILL_OPTIONS = [
  {
    label: "Novice",
    value: "Novice",
    color: "#fe7d8b",
  },
  {
    label: "Beginner",
    value: "Beginner",
    color: "#f68559",
  },
  {
    label: "Skillful",
    value: "Skillful",
    color: "#ec930c",
  },
  {
    label: "Experienced",
    value: "Experienced",
    color: "#48ba75",
  },
  {
    label: "Expert",
    value: "Expert",
    color: "#9ba1fb",
  },
];

export const PERSONAL_DETAILS = [
  {
    name: "job_title",
    type: "input",
    label: "Job Title",
    placeholder: "e.g. Teacher",
  },
  {
    name: "first_name",
    type: "input",
    label: "First Name",
    span: 2,
  },
  {
    name: "last_name",
    type: "input",
    label: "Last Name",
    span: 2,
  },
  {
    name: "email",
    type: "input",
    label: "Email",
    span: 2,
  },
  {
    name: "phone",
    type: "input",
    label: "Phone",
    span: 2,
    inputType: "number",
  },
  {
    name: "country",
    type: "input",
    label: "Country",
    span: 2,
  },
  {
    name: "city",
    type: "input",
    label: "City",
    span: 2,
  },
];

export const SUMMARY = [
  {
    name: "summary",
    type: "editor",
    min: 150,
    max: 500,
  },
];

export const EDUCATION_ITEM = [
  {
    name: "degree",
    type: "input",
    label: "Degree",
    span: 2,
  },
  {
    name: "school",
    type: "input",
    label: "School/University",
    span: 2,
  },
  {
    name: "start_date",
    type: "input",
    label: "Start Date",
    inputType: "monthPicker",
    span: 2,
  },
  {
    name: "end_date",
    type: "input",
    label: "End Date",
    inputType: "monthPicker",
    span: 2,
  },
];

export const CERTIFICATE_ITEM = [
  {
    name: "title",
    type: "input",
    label: "Title",
    span: 2,
  },
  {
    name: "date",
    type: "input",
    label: "Date",
    inputType: "monthPicker",
    span: 2,
  },
];

export const EMPLOYMENT_ITEM = [
  {
    name: "job_title",
    type: "input",
    label: "Job Title",
    span: 2,
  },
  {
    name: "employer",
    type: "input",
    label: "Employer",
    span: 2,
  },
  {
    name: "start_date",
    type: "input",
    label: "Start Date",
    inputType: "monthPicker",
    span: 2,
  },
  {
    name: "end_date",
    type: "input",
    label: "End Date",
    inputType: "monthPicker",
    span: 2,
  },
  {
    name: "description",
    type: "editor",
    label: "Description",
    min: 200,
  },
];

export const SKILL_ITEM = [
  {
    name: "skill",
    type: "input",
    label: "Skill",
    span: 2,
  },
  {
    name: "level",
    type: "slidePicker",
    label: "Level",
    span: 2,
    defaultValue: "Expert",
    options: SKILL_OPTIONS,
  },
];
