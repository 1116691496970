import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { useJobs } from "../jobsContext";
import React from "react";
import DemoContainer from "../components/DemoContainer";
import JobPanel from "../components/JobPanel";
import SkeletonJobPanel from "../components/SkeletonPanel";
import { useIdentifyUser } from "../hooks/useIdentifyUser";
import { useUserData } from "../UserDataContext";
const Demo_1 = _compiledBlock(_props => <div className="relative bg-neutral-50 w-full h-screen flex flex-row items-start justify-start text-left text-base text-gray-50 font-text-l-regular">
      <div className="m-auto items-center flex flex-col p-10 pt-4 rounded-2xl bg-base-white border border-solid border-neutral-600">
        <h1 className="w-full mb-7">404: Page not Found</h1>
        <button onClick={_props.v0} className="cursor-pointer [border:none] py-2.5 px-[18px] bg-mediumseagreen-200 self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center gap-[6px] hover:bg-green-300 transition-colors duration-300">
          <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular text-base-white text-left">
            Back to Wrangle
          </div>
        </button>
      </div>
    </div>, {
  name: "Demo_1"
});
const Demo_2 = _compiledBlock(_props2 => <div className="relative bg-neutral-50 w-full h-screen flex flex-row items-start justify-start text-left text-base text-gray-50 font-text-l-regular">
      <div className="self-stretch flex-1 overflow-hidden flex flex-row p-8 items-start justify-start gap-[32px] text-9xl text-gray-700">
        {_props2.v0}
        {_props2.v1}
      </div>
    </div>, {
  name: "Demo_2",
  portals: ["v0", "v1"]
});
const Demo = () => {
  useIdentifyUser();
  const {
    selectedJob
  } = useJobs();
  const {
    user
  } = useUserData();
  const formatDate = timestamp => {
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  if (user.uid != "5ZbUc6Gv2COwZOjvHpU1i9tpNxm2" && user.uid != "BTGsPigEK7cIKrHxdCuGCnJ4HSl2") {
    return /*@million jsx-skip*/<Demo_1 v0={() => {
      navigate("/jobs");
    }} />;
  }
  return /*@million jsx-skip*/<Demo_2 v0={<DemoContainer formatDate={formatDate} />} v1={selectedJob ? <JobPanel /> : <SkeletonJobPanel />} />;
};
export default React.memo(Demo);