import { useEffect } from "react";
import { useUserData } from "../UserDataContext";

export const useIdentifyUser = () => {
  const { user } = useUserData();

  useEffect(() => {
    if (user) {
      window.heap.identify(user.uid);
    }
  }, [user]);
};
