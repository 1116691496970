import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { useEffect } from "react";
import { textUtil } from "../../utils";
import "../fonts/IBMPlexSans/stylesheet.css";
import "../fonts/IBMPlexSans/IBMPlexSans";
import "../fonts/IBMPlexSans/IBMPlexSans-Medium";
import "../fonts/IBMPlexSans/IBMPlexSans-Bold";
const SectionGrop_1 = _compiledBlock(_props => <div style={_props.v0}>
      <span style={_props.v1}>
        {_props.v2}<br />
        {_props.v3}
      </span>
      <div style={_props.v4}>
        {_props.v5}
      </div>
    </div>, {
  name: "SectionGrop_1",
  portals: ["v2", "v3", "v5"]
});
const SectionGrop = ({
  title,
  subTitle,
  children,
  contentStyle = {},
  showIf = false
}) => {
  if (!showIf) return null;
  return /*@million jsx-skip*/<SectionGrop_1 v0={{
    display: "flex",
    gap: "16px",
    alignItems: "flex-start",
    width: "100%"
  }} v1={{
    width: "150px",
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "1em"
  }} v2={title} v3={subTitle && subTitle} v4={{
    ...{
      display: "flex",
      flex: 1,
      flexDirection: "column ",
      marginTop: "2px"
    },
    ...contentStyle
  }} v5={children} />;
};
const Template1 = ({
  doc,
  data
}) => {
  const contacts = [[data.country, data.city].filter(i => i).join(", "), data.email, textUtil.getUSNumber(data.phone) || data.phone].filter(item => item);
  useEffect(() => {
    // Adding the fonts
    doc?.setFont("IBMPlexSans", "normal");
    doc?.setFont("IBMPlexSans-Medium", "normal");
    doc?.setFont("IBMPlexSans-Bold", "normal");
  }, []);
  return <div style={{
    fontFamily: "IBMPlexSans",
    display: "flex",
    flexDirection: "column",
    padding: "50px",
    gap: "40px",
    paddingBottom: "80px",
    WebkitFontSmoothing: "antialiased",
    color: "#212121",
    fontSize: "16px",
    width: "100%",
    boxSizing: "border-box"
  }}>
      <SectionGrop title="Summary" contentStyle={{
      gap: "10px"
    }} showIf={data.first_name || data.last_name || data.job_title}>
        <span style={{
        fontFamily: "IBMPlexSans-Bold",
        fontSize: "1.5em",
        lineHeight: 1.25
      }}>
          {data.first_name} {data.last_name}
        </span>
        <span style={{
        fontSize: "0.75em",
        marginTop: "-8px",
        color: "#928585"
      }}>
          {data.job_title}
        </span>
      </SectionGrop>
      <SectionGrop title="Contacts" showIf={contacts.length}>
        <ul style={{
        paddingLeft: "16px",
        margin: 0,
        // listStyleType: "square",
        gap: "8px",
        display: "flex",
        flexDirection: "column"
      }}>
          {contacts.map((item, n) => <li key={item + n} style={{
          fontSize: "0.85em",
          lineHeight: 1.5,
          color: "#928585"
        }}>
              {item}
            </li>)}
        </ul>
      </SectionGrop>
      <SectionGrop title="Profile" contentStyle={{
      gap: "10px"
    }} showIf={data.summary}>
        <span style={{
        fontSize: "0.85em",
        lineHeight: 1.62,
        color: "#928585",
        marginTop: "-10px"
      }} dangerouslySetInnerHTML={{
        __html: data.summary
      }} />
      </SectionGrop>
      <SectionGrop title="Employment" contentStyle={{
      gap: "20px"
    }} showIf={data.employments?.length}>
        {data.employments?.map((item, n) => <div key={item + n} style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px"
      }}>
            <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}>
              <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            fontFamily: "IBMPlexSans-Medium"
          }}>
                {`${item.job_title || ""}${item.employer ? " at " + item.employer : ""}`}
              </span>
              <span style={{
            fontSize: "0.75em",
            color: "#a9a1a1"
          }}>
                {`${item.start_date || ""}${item.end_date ? " - " + item.end_date : ""}`}
              </span>
            </div>
            <span style={{
          fontSize: "0.85em",
          lineHeight: 1.62,
          color: "#928585"
        }} dangerouslySetInnerHTML={{
          __html: item.description
        }} />
          </div>)}
      </SectionGrop>
      <SectionGrop title="Education" contentStyle={{
      gap: "20px"
    }} showIf={data.educations?.length}>
        {data.educations?.map((item, n) => <div key={item + n} style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px"
      }}>
            <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}>
              <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            fontFamily: "IBMPlexSans-Medium"
          }}>
                {item.school}
              </span>
              <span style={{
            fontSize: "0.75em",
            color: "#a9a1a1"
          }} className="text-xs opacity-50">
                {`${item.start_date || ""}${item.end_date ? " - " + item.end_date : ""}`}
              </span>
            </div>
            <span style={{
          fontSize: "0.85em",
          lineHeight: 1.62,
          color: "#928585"
        }}>
              {item.degree}
            </span>
          </div>)}
      </SectionGrop>
      <SectionGrop title="Certifications" subTitle="and awards" contentStyle={{
      display: "grid",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      columnGap: "100px",
      rowGap: "10px",
      marginTop: "2px"
    }} showIf={data.certificates?.length}>
        {data.certificates?.map((item, n) => <div key={item + n} style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "12px"
      }}>
            <span style={{
          fontSize: "0.85em",
          lineHeight: 1.62,
          fontFamily: "IBMPlexSans-Medium"
        }}>
              {item.title}
            </span>
            <span style={{
          fontSize: "0.85em",
          lineHeight: 1.62,
          color: "#928585"
        }}>
              {item.date}
            </span>
          </div>)}
      </SectionGrop>
      <SectionGrop title="Skills" contentStyle={{
      display: "grid",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      columnGap: "50px",
      rowGap: "10px"
    }} showIf={data.skills?.length}>
        {data.skills?.map((item, n) => <div key={item + n} style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "12px"
      }}>
            <span style={{
          fontSize: "0.85em",
          lineHeight: 1.62,
          fontFamily: "IBMPlexSans-Medium"
        }}>
              {item.skill}
            </span>
            <span style={{
          fontSize: "0.85em",
          lineHeight: 1.62,
          color: "#928585"
        }}>
              {item.level}
            </span>
          </div>)}
      </SectionGrop>
    </div>;
};
export default Template1;