import React, { useEffect, useRef } from 'react';

const FormInputCard = ({ titleText, inputText, value, onChange, applied, isNumber }) => {
  const textareaRef = useRef(null);
  const handleOnChange = (e) => {
    const val = e.target.value;

    // If the value is either empty or is entirely made of digits, update the value
    if (/^\d*$/.test(val)) {
      onChange(val);
    }
  };
  useEffect(() => {
      if (textareaRef.current) {
        if(applied == undefined){
          textareaRef.current.style.height = 'auto';
          textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
        }
        else{
          textareaRef.current.style.height = 'auto';

          // Calculate the number of rows needed based on the string's length
          let rowsNeeded = Math.ceil(inputText.length / textareaRef.current.cols / 2);
          // Set the number of rows in the textarea
          textareaRef.current.rows = rowsNeeded;
        }
      }
      
  }, [value]);
  return ((applied && (inputText == undefined || inputText == null || inputText == "")) ? null : (
    <div className="mt-5">
      <div className="text-[14px] text-primary-600 mb-3 no-scrollbar">
        {titleText}
      </div>
      <textarea
        ref={textareaRef}
        className="font-text-l-regular focus:outline-none w-full focus:ring focus:ring-green-50 no-scrollbar focus:border-mediumseagreen-200 text-mini bg-base-white rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border py-3 px-3 justify-start border-[1px] border-solid border-neutral-700 resize-none"
        placeholder={applied ? inputText : ""}
        required
        value={value}
        onChange={isNumber ? handleOnChange : (e) => onChange(e.target.value)}
        rows={1}
        readOnly={applied}
      />
    </div>
  ));
};

export default FormInputCard;