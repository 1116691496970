import React, { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useDebounce, useThisResume } from "../../../hooks";
import { templateUtil } from "../../../utils";
import { useUserData } from "../../../UserDataContext";
import { db, storage } from "../../../firebase";
import { ref as storageRef, uploadBytesResumable, deleteObject, listAll } from "firebase/storage";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
const Preview = forwardRef((props, ref) => {
  const thisResume = useThisResume(state => state);
  const {
    userData,
    setUserData,
    user
  } = useUserData();
  const docRef = doc(db, "Profiles", user.uid);
  const template = templateUtil.getActiveTemplate(thisResume.template);
  const newDoc = useRef(new jsPDF({
    unit: "px",
    putOnlyUsedFonts: true
  })).current;
  const pagesWrapper = useRef(null);
  const pageContainer = useRef(null);
  const scaleContainer = useRef(null);
  const docTemplate = useRef(null);
  const saveResumeDebaounced = useDebounce(() => {
    handleSaveResume();
  }, 1000);
  useEffect(() => {
    function handleResize() {
      const paperWidth = pageContainer.current.offsetWidth;
      const paperRatio = pageContainer.current.offsetHeight / paperWidth;
      const realWidth = Math.min(paperWidth, window.innerWidth / 2.2);
      const scale = realWidth / paperWidth;
      pagesWrapper.current.style.width = `${realWidth}px`;
      pagesWrapper.current.style.height = `${paperRatio * realWidth}px`;
      scaleContainer.current.style.transform = `scale(${scale})`;
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleDownloadPNG = () => {
    html2canvas(pageContainer.current, {}).then(function (canvas) {
      canvas.style.display = "none";
      document.body.appendChild(canvas);
      var url = canvas.toDataURL("image/png");
      var link = document.createElement("a");
      link.download = "resume.png";
      link.href = url;
      link.click();
    });
  };
  const handleGeneratePdf = () => {
    newDoc.html(docTemplate.current, {
      async callback(newDoc) {
        newDoc.save("Portfolio");
      },
      windowWidth: pageContainer.current.offsetWidth,
      width: newDoc.internal.pageSize.getWidth()
    });
  };
  const handleSaveResume = async () => {
    const resumeData = userData.resumeData ? [...userData.resumeData] : [];
    const idx = resumeData.findIndex(r => r.id === thisResume.id);
    const resumeTitle = `${thisResume.data.first_name || ""} ${thisResume.data.last_name} ${thisResume.data.job_title || ""}`;
    const formattedJobTitle = (thisResume.data.job_title || "").replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "");
    const now = new Date();
    const formattedDate = [String(now.getDate()).padStart(2, "0"), now.toLocaleString("en-us", {
      month: "short"
    }), now.getFullYear(), String(now.getHours()).padStart(2, "0"),
    // Hours
    String(now.getMinutes()).padStart(2, "0") // Minutes
    ].join("_");
    const resumeFileName = `${thisResume.data.first_name || ""}_${thisResume.data.last_name || ""}_${formattedJobTitle || "[job_title]"}_${formattedDate}_${thisResume.id}`;
    if (idx >= 0) {
      resumeData[idx].data = thisResume.data;
      resumeData[idx].template = thisResume.template;
      resumeData[idx].title = resumeTitle;
      resumeData[idx].filename = resumeFileName;
    } else {
      const {
        id,
        pdfUrl,
        score,
        template,
        title,
        updatedAt,
        createdAt,
        data
      } = thisResume;
      resumeData.push({
        id,
        pdfUrl,
        score,
        template,
        title: resumeTitle,
        createdAt,
        updatedAt,
        data,
        filename: resumeFileName // Adding the filename to the new resume object
      });
    }
    // if(!thisResume.data.job_title) return;
    // else{
    // await updateDoc(
    //   docRef,
    //   { allResumes: arrayUnion(`${resumeFileName}.pdf`) },
    //   { merge: true }
    // );
    // const updatedResumes = userData.allResumes
    //   ? [
    //       ...new Set([
    //         ...userData.allResumes.filter((resumeId) => !resumeId.includes(resumeFileName.split("_").pop())),
    //         `${resumeFileName}.pdf`
    //       ])
    //     ]
    //   : [`${resumeFileName}.pdf`];
    // setUserData({
    //   ...userData,
    //   allResumes: updatedResumes,
    // });
    // const dbRef = ref(storage, String(user.uid));
    // const res = await listAll(dbRef);
    // try {
    //   for (let item of res.items) {
    //     if (item.name.split("_").pop().split(".")[0] === thisResume.id) {
    //       await deleteObject(item);
    //     }
    //   }
    // } catch (error) {
    //   return;
    //   // Handle the error as needed
    // }
    newDoc.html(docTemplate.current, {
      async callback(newDoc) {
        newDoc.setProperties({
          title: resumeFileName
        });
        setUserData({
          ...userData,
          resumeData
        });
      },
      windowWidth: pageContainer.current.offsetWidth,
      width: newDoc.internal.pageSize.getWidth()
    });
    //}
  };
  useImperativeHandle(ref, () => ({
    fullSave: async () => {
      console.log("Fully Saving...");
      const resumeData = userData.resumeData ? [...userData.resumeData] : [];
      const idx = resumeData.findIndex(r => r.id === thisResume.id);
      const resumeTitle = `${thisResume.data.first_name || ""} ${thisResume.data.last_name} ${thisResume.data.job_title || ""}`;
      const formattedJobTitle = (thisResume.data.job_title || "").replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "");
      const now = new Date();
      const formattedDate = [String(now.getDate()).padStart(2, "0"), now.toLocaleString("en-us", {
        month: "short"
      }), now.getFullYear(), String(now.getHours()).padStart(2, "0"),
      // Hours
      String(now.getMinutes()).padStart(2, "0") // Minutes
      ].join("_");
      const resumeFileName = `${thisResume.data.first_name || ""}_${thisResume.data.last_name || ""}_${formattedJobTitle || "[job_title]"}_${formattedDate}_${thisResume.id}`;
      if (idx >= 0) {
        resumeData[idx].data = thisResume.data;
        resumeData[idx].template = thisResume.template;
        resumeData[idx].title = resumeTitle;
        resumeData[idx].filename = resumeFileName;
      } else {
        const {
          id,
          pdfUrl,
          score,
          template,
          title,
          updatedAt,
          createdAt,
          data
        } = thisResume;
        resumeData.push({
          id,
          pdfUrl,
          score,
          template,
          title: resumeTitle,
          createdAt,
          updatedAt,
          data,
          filename: resumeFileName // Adding the filename to the new resume object
        });
      }
      if (!thisResume.data.job_title) return;else {
        console.log(resumeFileName);
        console.log(userData.allResumes);
        await updateDoc(docRef, {
          allResumes: arrayUnion(`${resumeFileName}.pdf`)
        }, {
          merge: true
        });
        const updatedResumes = userData.allResumes ? [...new Set([...userData.allResumes.filter(resumeId => !resumeId.includes(resumeFileName.split("_").pop())), `${resumeFileName}.pdf`])] : [`${resumeFileName}.pdf`];
        setUserData({
          ...userData,
          allResumes: updatedResumes
        });
        const dbRef = storageRef(storage, user.uid);
        const res = await listAll(dbRef);
        try {
          for (let item of res.items) {
            if (item.name.split("_").pop().split(".")[0] === thisResume.id) {
              await deleteObject(item);
            }
          }
        } catch (error) {
          return;
          // Handle the error as needed
        }
        newDoc.html(docTemplate.current, {
          async callback(newDoc) {
            newDoc.setProperties({
              title: resumeFileName
            });
            const pdfBlob = newDoc.output("blob");
            const pdfFilePath = `${String(user.uid)}/${resumeFileName}.pdf`;
            const storageRef2 = storageRef(storage, pdfFilePath);
            const uploadTask = uploadBytesResumable(storageRef2, pdfBlob);
            uploadTask.on("state_changed", snapshot => {}, error => {
              console.error("Upload failed", error);
            }, async () => {
              setUserData({
                ...userData,
                resumeData
              });
            });
          },
          windowWidth: pageContainer.current.offsetWidth,
          width: newDoc.internal.pageSize.getWidth()
        });
      }
    }
  }));
  useEffect(() => {
    saveResumeDebaounced();
  }, [thisResume.data, thisResume.template]);
  return <div className="flex flex-[1.5] bg-gray justify-center resume-builder-preview grow scroll-smooth sticky overflow-auto h-screen px-12 box-border top-0 py-16">
      <div ref={pagesWrapper} className="flex flex-col box-border relative">
        <div className="flex gap-2 absolute -top-3 -translate-y-full right-0">
          <button onClick={handleDownloadPNG} className="px-5 py-2 text-base font-medium text-white bg-mediumseagreen-200 rounded-md cursor-pointer font-text-l-regular">
            Download PNG
          </button>
          <button onClick={handleGeneratePdf} className="px-5 py-2 text-base font-medium text-white bg-mediumseagreen-200 rounded-md cursor-pointer font-text-l-regular">
            Download PDF
          </button>
        </div>
        <div ref={scaleContainer} className="origin-top-left ">
          <div ref={pageContainer} className="inline-flex">
            <div className="w-[210mm] min-h-[297mm] bg-white sticky top-0 text-[12pt]" width="210mm" height="297mm" format="A4">
              <div ref={docTemplate}>
                <template.template doc={newDoc} data={thisResume.data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
});
export { Preview };