import React, { useState, useRef, useEffect } from 'react';

const InputSelect = ({ titleText, options, value, onChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [prevValue, setPrevValue] = useState(null);
  const [matchedOptions, setMatchedOptions] = useState(options);
  const [interimValue, setInterimValue] = useState(value || "");
  /*const [selectedValue, setSelectedValue] = useState(() => {
    if (options && options.length) {
      const option = options.find(({value: optVal}) => optVal.includes(value) || optVal === value);
      return value && option?.value || null;
    }
    return null;
  });*/
  const [selectedValue, setSelectedValue] = useState(() => {
    if (options && options.length) {
      const option = options.find(({ value: optVal }) => optVal === value);
      return option?.value || null;
    }
    return null;
});
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    const option = options.find(({value: optVal}) => optVal === value);
    if (value && value !== selectedValue && options && option) {
      setSelectedValue(value);

      if (value !== prevValue) {
        onChange(option.text);
        setPrevValue(value);
      }
    }
  }, [value, options, prevValue]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!titleText || !options || options.length === 0) {
    return null;
  }

  return (
    <div className="mt-5">
      <div className="text-[14px] text-primary-600 mb-3">
        {titleText}
      </div>
      <div ref={dropdownRef} className="relative w-full">
      <input
      className="font-text-l-regular text-mini w-full bg-base-white self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-between border-[1px] border-solid border-neutral-700 focus:outline-none cursor-pointer"
      onChange={e => {
        const input = e.target.value;
        setInterimValue(input.toLowerCase()); // Convert it to lowercase

        const newMatchedOptions = options.filter(opt => 
            opt.text && opt.text.toLowerCase().includes(input.toLowerCase())
        );
        if (newMatchedOptions.length === 0) {
          // If no options match, set matchedOptions to an array with an object having "Other"
          setMatchedOptions([{ text: "Other", value: "Other" }]);
      } else {
          // If there are matching options, set them to matchedOptions
          newMatchedOptions.push("test");
          setMatchedOptions(newMatchedOptions);
      }

        if(newMatchedOptions.length > 0) {
          setIsDropdownOpen(true);
        }
      }}
      value={interimValue}
    >
    </input>
    {isDropdownOpen && (
        <div className="absolute left-0 w-full top-full bg-white border-[1px] border-solid border-neutral-700 rounded-md z-10">
        {matchedOptions.slice(0, 4).map((option, index) => (
            <div
                className="px-4 py-2 cursor-pointer w-full hover:bg-gray-100 text-[14px] text-primary-600"
                key={index}
                onClick={() => {
                    setIsDropdownOpen(false);
                    onChange(option.text);  // passing index to onChange
                    setInterimValue(option.text)
                }}
            >
                {option.text}
            </div>
        ))}
    </div>
    )}
      </div>
    </div>
  );
};

export default InputSelect;