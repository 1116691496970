import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { useEffect } from "react";
import { templateUtil } from "../../../utils";
import { useThisResume } from "../../../hooks";
import { useNavigate } from "react-router-dom";
const ProgressBar_1 = _compiledBlock(_props => <div className="flex px-8 flex-col sticky top-0 z-20 pt-4 pb-5 bg-base-white border-b-[1px] border-neutral-700 border-solid">
      <div className="flex py-2 gap-2 items-center">
        <span className="bg-green-100 border-solid border-[1px] box-border border:bg-mediumseagreen-200 rounded-md px-1.5 leading-none py-1 text-xs text-mediumseagreen-200 font-semibold">
          {_props.v0}%
        </span>
        <span className="text-sm text-gray">Resume score</span>
      </div>
      <div className="relative h-1 bg-slate-200 rounded-sm overflow-hidden">
        <span className="w-full h-full top-0 duration-300 right-full bg-primary absolute rounded-sm" style={_props.v1} />
      </div>
    </div>, {
  name: "ProgressBar_1",
  portals: ["v0"]
});
const ProgressBar = () => {
  const {
    data,
    changeScore,
    template: templateKey
  } = useThisResume(state => ({
    data: state.data,
    template: state.template,
    changeScore: state.changeScore
  }));
  const progress = templateUtil.getProgress(templateKey, data);
  const navigate = useNavigate();
  useEffect(() => {
    changeScore(progress);
  }, [progress]);
  return /*@million jsx-skip*/<ProgressBar_1 v0={progress} v1={{
    transform: `translateX(${progress}%)`
  }} />;
};
export { ProgressBar };