import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
const AppSummaryTable_1 = _compiledBlock(_props => <div className="rounded-md border border-solid border-neutral-600">
      {_props.v0}
    </div>, {
  name: "AppSummaryTable_1",
  portals: ["v0"]
});
export const AppSummaryTable = ({
  rows,
  setCurrentStep
}) => {
  const renderRow = (row, idx) => <div key={`app-summary-row-${idx}`} className={`flex flex-row items-start p-3 ${idx !== 0 ? "border-t border-solid border-neutral-600" : ""}`}>
      <div className="text-[14px] text-primary-600 w-[25%]">{row.caption}</div>
      <div className="w-[60%]">
        {row.values.map((rowValue, idx) => <div key={`app-summary-row-value-${idx}`} className={`text-[14px] overflow-hidden whitespace-nowrap text-ellipsis ${idx !== 0 ? "mt-3" : ""}`}>
            {rowValue}
          </div>)}
      </div>
      <div className="w-[15%] flex items-start justify-end">
        <a className="text-mediumseagreen-200 text-[14px] cursor-pointer" onClick={() => {
        setCurrentStep(idx + 1);
      }}>Edit</a>
      </div>
    </div>;
  return /*@million jsx-skip*/<AppSummaryTable_1 v0={rows.map((row, idx) => renderRow(row, idx))} />;
};
export default AppSummaryTable;