import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { app, db, auth } from "../firebase";
import { useUserData } from "../UserDataContext";
import { AnimatePresence, motion, useMotionValue, useTransform } from "framer-motion";
function Login() {
  const [emailEntered, setEmailEntered] = useState(false);
  const [isSignUp, setIsSignUp] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const firebaseAuth = getAuth(app);
  const navigate = useNavigate();
  const {
    setUserData,
    clearUserData
  } = useUserData();
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const prod = true;
  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === "Enter" && emailEntered) {
        if (document.activeElement === emailInputRef.current) {
          passwordInputRef.current.focus();
        } else if (document.activeElement === passwordInputRef.current || email && password) {
          handleFormSubmit();
        }
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [email, password, emailEntered]);
  useEffect(() => {
    if (localStorage.getItem("rememberMe") === "true" && new Date().getTime() < localStorage.getItem("expiry")) {
      setEmail(localStorage.getItem("email"));
      setPassword(localStorage.getItem("password"));
      setRememberMe(true);
    } else {
      clearLocalStorage();
    }
  }, []);
  const clearLocalStorage = () => {
    localStorage.clear();
  };
  const loginUser = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
      const user = userCredential.user;
      window.heap.identify(user.uid);
      console.log(user.uid);
      if (rememberMe) {
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        localStorage.setItem("rememberMe", "true");
        localStorage.setItem("expiry", new Date().getTime() + 30 * 24 * 60 * 60 * 1000);
      } else {
        clearLocalStorage();
      }
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      setErrorMessage("Failed to log in.");
      if (error.code === "auth/user-not-found") {
        setEmailError("No account found with this email.");
      } else if (error.code === "auth/wrong-password") {
        setPasswordError("Incorrect password. Please try again.");
      } else if (error.code === "auth/invalid-email") {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
        setPasswordError("");
      }
    }
  };
  const signupUser = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
      const user = userCredential.user;
      try {
        let idToken;
        if (auth.currentUser) {
          idToken = await auth.currentUser.getIdToken(true);
        }
        const res = fetch(`https://app.wranglejobs.com/api/send-email-consumer`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`
          },
          body: JSON.stringify({
            template: "welcome",
            email: email
          })
        });
      } catch {
        console.log("error with email send");
      }
      window.heap.identify(user.uid);
      const userId = user.uid;
      const userReference = doc(db, "Profiles", userId);
      console.log(userReference);
      const userDoc = await getDoc(userReference);
      console.log(userDoc);
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search);
      let referral = params.get("referral");
      if (userDoc.exists() && userDoc.data() && Object.keys(userDoc.data()).length > 0) {
        console.log("User already exists in Profiles collection.");
        console.log(userId);
        setUserData(userDoc.data());
      } else {
        console.log("Creating new user document.");
        console.log(userId);
        const initialData = {
          email: user.email,
          applied: [],
          favorites: [],
          AcctCreationDate: new Date(),
          Referrer: referral
        };
        await setDoc(userReference, initialData, {
          merge: true
        });
        setUserData(initialData);
      }

      // Call the conversion tracking function
      gtag_report_conversion("/dashboard");
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      setErrorMessage("Failed to sign up.");
      if (error.code === "auth/email-already-in-use") {
        setEmailError("Email already in use.");
      } else if (error.code === "auth/weak-password") {
        setPasswordError("Password is too weak.");
      } else if (error.code === "auth/invalid-email") {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
        setPasswordError("");
      }
    }
  };
  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(firebaseAuth, email);
      setErrorMessage("Check your email for a link to reset your password");
    } catch (error) {
      console.error(error);
      setErrorMessage("Failed to send password reset email.");
      if (error.code === "auth/user-not-found") {
        setEmailError("User not found.");
      } else {
        setEmailError("");
      }
    }
  };
  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(firebaseAuth, provider);
      const user = result.user;
      window.heap.identify(user.uid);
      const userId = user.uid;
      console.log(userId);
      const userReference = doc(db, "Profiles", userId);
      const userDoc = await getDoc(userReference);
      console.log(userDoc.data());
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search);
      let referral = params.get("referral");
      if (userDoc.exists() && userDoc.data() && Object.keys(userDoc.data()).length > 0) {
        console.log("User already exists in Profiles collection.");
        console.log(userId);
        setUserData(userDoc.data());
      } else {
        console.log("Creating new user document.");
        console.log(userId);
        const initialData = {
          email: user.email,
          applied: [],
          favorites: [],
          AcctCreationDate: new Date(),
          Referrer: referral
        };
        await setDoc(userReference, initialData, {
          merge: true
        });
        setUserData(initialData);
      }
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      setErrorMessage("Failed to sign in with Google.");
    }
  };
  const handleFormSwitch = () => {
    setIsSignUp(!isSignUp);
    setEmailEntered(false);
    setErrorMessage("");
    setEmailError("");
    setPasswordError("");
    clearLocalStorage();
  };
  const handleFormSubmit = () => {
    if (isSignUp) {
      signupUser();
    } else {
      loginUser();
    }
  };
  const Checkbox = () => {
    const [checked, setChecked] = React.useState(false);
    const pathLength = useMotionValue(0);
    const opacity = useTransform(pathLength, [0, 0.5], [0, 1]);
    const duration = 0.4;
    const boxVariants = {
      checked: {
        backgroundColor: "#5cd193"
      },
      unchecked: {
        backgroundColor: "#fafbfc"
      }
    };
    const checkVariants = {
      checked: {
        pathLength: 1
      },
      unchecked: {
        pathLength: 0
      }
    };
    return <div className="relative flex items-center">
        <motion.div className={`relative h-5 w-5 cursor-pointer rounded-md border-[1px] border-solid transition-all duration-500 ${checked ? "border-mediumseagreen-200 bg-mediumseagreen-200" : "border-gray-neutral-200 bg-fafbfc"}`} variants={boxVariants} initial={false} animate={checked ? "checked" : "unchecked"} onClick={() => setChecked(!checked)}>
          <motion.svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3.5" stroke="white" className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-3.5 w-3.5" initial={false} animate={checked ? "checked" : "unchecked"}>
            <motion.path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" variants={checkVariants} style={{
            pathLength,
            opacity
          }} transition={{
            duration
          }} />
          </motion.svg>
        </motion.div>
      </div>;
  };
  const variants = {
    hidden: {
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0
    }
  };
  return <div className="relative bg-base-white w-screen h-screen flex flex-row items-start justify-start text-left text-sm text-gray-900 font-text-l-regular" style={{
    boxSizing: "border-box"
  }}>
      <div className="self-stretch flex-1 flex flex-row p-8 items-center justify-end gap-[48px]">
        <div className="self-stretch flex-1 flex flex-row px-2 items-center justify-center">
          <div className="w-[80%] flex flex-col items-center justify-start gap-[32px]">
            <motion.div layout className="self-stretch flex flex-col items-center justify-start gap-[24px] text-center text-11xl" initial={{
            opacity: 0,
            translateY: 10
          }} animate={{
            opacity: 1,
            translateY: 0
          }} exit={{
            opacity: 0,
            translateY: 10
          }} transition={{
            duration: 0.5,
            delay: 0,
            ease: "easeOut"
          }}>
              <img className="relative w-[58px] h-[58px]" alt="" src="/logo.svg" />
              <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                <div className="self-stretch relative leading-[38px] font-semibold">
                  Let's get you hired.
                </div>
                <div className="self-stretch relative text-base leading-[24px] text-gray-600">
                  {isSignUp ? "Welcome! Enter your details to get started." : "Welcome back! Log in with your account."}
                </div>
              </div>
            </motion.div>
            <div className="self-stretch rounded-xl flex flex-col items-center justify-start gap-[24px] text-gray-700">
              <motion.div layout initial={{
              opacity: 0,
              translateY: 10
            }} animate={{
              opacity: 1,
              translateY: 0
            }} exit={{
              opacity: 0,
              translateY: 10
            }} transition={{
              duration: 0.5,
              delay: 0,
              ease: "easeOut"
            }} className="w-full">
                <motion.div className="self-stretch rounded-lg bg-white duration-300 transition-all shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row py-2.5 px-[18px] items-center justify-center border-[1px] border-solid border-gray-300 cursor-pointer hover:bg-neutral-50 hover:border-neutral-300" onClick={handleGoogleSignIn}>
                  <div className="relative flex items-center leading-[24px] font-semibold text-base-black cursor-pointer justify-center">
                    <img className="relative w-5 h-5 overflow-hidden shrink-0 pr-4" alt="Google Logo" src="/Google.png" />
                    {isSignUp ? "Sign up with Google" : "Log in with Google"}
                  </div>
                </motion.div>
              </motion.div>

              <motion.div layout className="self-stretch flex flex-row items-center justify-center">
                <div className="w-full h-[1px] bg-neutral-700" />
                <span className="flex-shrink mx-4 text-neutral-400 font-medium">
                  or
                </span>
                <div className="w-full h-[1px] bg-neutral-700" />
              </motion.div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                <motion.div layout className="self-stretch flex flex-col items-start justify-start gap-[6px]" initial={{
                opacity: 0,
                translateY: 10
              }} animate={{
                opacity: 1,
                translateY: 0
              }} exit={{
                opacity: 0,
                translateY: 10
              }} transition={{
                duration: 0.5,
                delay: 0,
                ease: "easeOut"
              }}>
                  <input className="font-text-l-regular text-mini focus:outline-none focus:ring focus:ring-green-50 hover:border-neutral-300 transition-all duration-300 focus:border-mediumseagreen-200 bg-base-white self-stretch hover:bg-neutral-50 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700" type="text" placeholder={"Enter email..."} required value={email} ref={emailInputRef} onChange={e => {
                  setEmail(e.target.value);
                  setEmailError("");
                }} />
                </motion.div>
                {emailError && <motion.div layout className="self-stretch relative leading-[20px] text-red-600" initial={{
                opacity: 0
              }} animate={{
                opacity: 1
              }} exit={{
                opacity: 0
              }} transition={{
                duration: 0.2
              }}>
                    {emailError}
                  </motion.div>}
              </div>
              {(emailEntered || !isSignUp) && <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                  <motion.div layout className="self-stretch flex flex-col items-start justify-start gap-[6px]" initial={{
                opacity: 0,
                translateY: 10
              }} animate={{
                opacity: 1,
                translateY: 0
              }} exit={{
                opacity: 0
              }} transition={{
                duration: 0.5,
                delay: 0,
                ease: "easeOut"
              }}>
                    <input className="font-text-l-regular text-mini focus:outline-none focus:ring focus:ring-green-50 hover:border-neutral-300 transition-all duration-300 focus:border-mediumseagreen-200 bg-base-white self-stretch hover:bg-neutral-50 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700" type="password" placeholder={"Enter password..."} required value={password} ref={passwordInputRef} onChange={e => {
                  setPassword(e.target.value);
                  setPasswordError("");
                }} />
                  </motion.div>
                  {passwordError && <motion.div layout className="self-stretch relative leading-[20px] text-red-600" initial={{
                opacity: 0
              }} animate={{
                opacity: 1
              }} exit={{
                opacity: 0
              }} transition={{
                duration: 0.2
              }}>
                      {passwordError}
                    </motion.div>}
                </div>}
              {!isSignUp ? <motion.div initial={{
              opacity: 0
            }} animate={{
              opacity: 1
            }} exit={{
              opacity: 0
            }} transition={{
              duration: 0.2
            }} layout className="self-stretch flex flex-row items-center justify-start">
                  <motion.div className="flex-1 flex flex-row items-start justify-start gap-[8px]">
                    <Checkbox checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                    <div className="flex-1 flex flex-col items-start justify-start">
                      <div className="self-stretch relative leading-[20px] font-medium">
                        Remember for 30 days
                      </div>
                    </div>
                  </motion.div>

                  <motion.div className="self-stretch cursor-pointer overflow-hidden flex flex-row items-center justify-center gap-[8px] text-mediumseagreen-200" onClick={handleForgotPassword}>
                    <div className="relative leading-[20px] font-semibold cursor-pointer">
                      Forgot password
                    </div>
                  </motion.div>
                </motion.div> : null}

              <motion.div layout className="self-stretch flex flex-col items-start justify-start gap-[16px] text-base text-base-white" initial={{
              opacity: 0,
              translateY: 10
            }} animate={{
              opacity: 1,
              translateY: 0
            }} exit={{
              opacity: 0,
              translateY: 10
            }} transition={{
              duration: 0.5,
              delay: 0,
              ease: "easeOut"
            }}>
                <motion.div className={`self-stretch rounded-lg ${isSignUp ? email.length > 0 ? "bg-mediumseagreen-200 hover:bg-green-300 text-base-white" : "bg-neutral-100 text-neutral-300" : email.length > 0 && password.length > 0 ? "bg-mediumseagreen-200 hover:bg-green-300 text-base-white" : "bg-neutral-100 text-neutral-300"} shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row py-2.5 px-[18px] items-center justify-center cursor-pointer transition-all duration-300`} onClick={!emailEntered ? !isSignUp ? email.length > 0 ? handleFormSubmit : () => {} : email.length > 0 ? () => setEmailEntered(true) : () => {} : handleFormSubmit}>
                  <div className="relative leading-[24px] font-semibold cursor-pointer">
                    {!emailEntered ? isSignUp ? "Continue with email" : "Log in with email" : "Create account"}
                  </div>
                </motion.div>
              </motion.div>

              <motion.div layout className="self-stretch flex flex-row items-baseline justify-center gap-[4px] text-gray-600" initial="hidden" animate="visible" variants={variants} transition={{
              duration: 0.5,
              delay: 0,
              ease: "easeOut"
            }}>
                <div className="relative leading-[20px]">
                  {isSignUp ? "Already have an account?" : "Don’t have an account?"}
                </div>
                <div className="overflow-hidden flex flex-row items-center justify-center gap-[8px] text-mediumseagreen-200">
                  <img className="relative w-5 h-5 overflow-hidden shrink-0 hidden" alt="" src="/placeholder2.svg" />
                  <div className="relative leading-[20px] font-semibold cursor-pointer" onClick={handleFormSwitch}>
                    {isSignUp ? "Log in" : "Sign up"}
                  </div>
                </div>
              </motion.div>
              <div className="self-stretch flex flex-row items-baseline justify-center gap-[4px] text-gray-600"></div>
            </div>
          </div>
        </div>
        <div className="self-stretch rounded-8xs [background:linear-gradient(180deg,_#A2F4C1,_#e5ffee_97.92%)] w-3/5 overflow-hidden shrink-0 flex items-center justify-center">
          <motion.img className="relative max-w-full overflow-hidden max-h-full object-cover" alt="" src="/sign.png" initial="beast" animate="visible" variants={variants} transition={{
          duration: 0.7,
          delay: 0,
          ease: "easeOut"
        }} />
        </div>
      </div>
    </div>;
}
export default Login;