import algoliasearch from "algoliasearch";
import { motion, AnimatePresence } from "framer-motion";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { useUserData } from "../UserDataContext";
import { useLocation } from "react-router-dom";
import SearchDropdown from "./SearchDropdown";
import { useJobs } from "../jobsContext";
import AWS from "aws-sdk";
import { DOMParser } from "xmldom";
import stringSimilarity from "string-similarity";

const Search = (props) => {
  const [inputValue, setInputValue] = useState("");
  const { userData, user } = useUserData();
  const location = useLocation();
  const inputRef = useRef(null);
  const { setSelectedJob } = useJobs();
  const [isHovered, setHovered] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);
  const [areFiltersSet, setAreFiltersSet] = useState(false);
  const [filterLever, setFilterLever] = useState(true);
  const [fetchedJobs, setFetchedJobs] = useState([]);

  const searchClient = algoliasearch(
    "1AD8YCD11H",
    "72b766934c217c2c483592d02639cd46"
  );

  const index = searchClient.initIndex("JOBS");

  /*
  const fetchJobs = async (country) => {
    const findTags = (category, tagMap, threshold = 0.6) => {
      const tagScores = Object.keys(tagMap).map((tag) => {
        const keywords = tagMap[tag];
        const wordScores = keywords.map(
          ([keyword, weight]) =>
            stringSimilarity.compareTwoStrings(keyword, category) * weight
        );
        const tagScore = Math.max(...wordScores);
        return { tag, score: tagScore };
      });

      const sortedTagScores = tagScores.sort((a, b) => b.score - a.score);
      const bestTags = sortedTagScores
        .filter((tagScore) => tagScore.score >= threshold)
        .map((tagScore) => tagScore.tag);

      return bestTags.slice(0, 4);
    };

    const tagMap = {
      engineering: [
        ["engineer", 2],
        ["developer", 1],
        ["programmer", 1],
        ["devops", 1],
        ["engineering", 2],
      ],
      sales: [
        ["sales", 2],
        ["business development", 1],
        ["account management", 1],
      ],
      marketing: [
        ["marketing", 2],
        ["branding", 1],
        ["promotion", 1],
      ],
      product: [
        ["product management", 1],
        ["product design", 1],
        ["ux design", 1],
        ["product", 1],
      ],
      design: [
        ["graphic design", 1],
        ["web design", 1],
        ["visual design", 1],
        ["design", 2],
        ["ui/ux", 1],
      ],
      support: [
        ["customer support", 1],
        ["technical support", 1],
        ["customer success", 1],
        ["clerk", 1],
      ],
      operations: [
        ["operations", 1],
        ["logistics", 1],
        ["supply chain", 1],
      ],
      data: [
        ["data analysis", 1],
        ["data science", 1],
        ["business intelligence", 1],
        ["writing", 2],
      ],
      finance: [
        ["finance", 1],
        ["accounting", 1],
        ["tax", 1],
        ["treasury", 1],
      ],
      management: [
        ["management", 1],
        ["leadership", 1],
        ["strategy", 1],
        ["manager", 2],
      ],
    };
    function convertToUnixTimestamp(dateString) {
      const parts = dateString.split("/");
      const year = parts[2];
      const month = parts[1].padStart(2, "0");
      const day = parts[0].padStart(2, "0");
      const date = new Date(`${year}-${month}-${day}`);
      return date.getTime() ? Math.floor(date.getTime() / 1000) : null;
    }

    try {
      const url = `https://app.wranglejobs.com/api/fetch-xml?country=${country}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Failed to fetch jobs");
      }

      const jobsData = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(jobsData, "text/xml");
      const jobs = xmlDoc.getElementsByTagName("job");

      const newFetchedJobs = [];
      for (let i = 0; i < Math.min(jobs.length, 100); i++) {
        const job = jobs[i];
        const title = job.getElementsByTagName("title")[0].textContent;
        const city = job.getElementsByTagName("city")[0].textContent;
        const state = job.getElementsByTagName("state")[0].textContent;
        const countryEl = job.getElementsByTagName("country")[0].textContent;
        const postalCode =
          job.getElementsByTagName("postalCode")[0].textContent || null;
        const jobId = job.getElementsByTagName("jobId")[0].textContent;
        const description =
          job.getElementsByTagName("description")[0].textContent;
        const company = job.getElementsByTagName("company")[0].textContent;
        const companyLogo = job.getElementsByTagName("companyLogo")[0]
          ? job.getElementsByTagName("companyLogo")[0].textContent
          : null;
        const category = job.getElementsByTagName("category")[0].textContent;
        const jobUrl = job.getElementsByTagName("jobUrl")[0].textContent;
        const impressionUrl =
          job.getElementsByTagName("impressionUrl")[0].textContent;
        const cpc = job.getElementsByTagName("cpc")[0]
          ? job.getElementsByTagName("cpc")[0].textContent
          : null;
        const salary = job.getElementsByTagName("salary")[0]
          ? job.getElementsByTagName("salary")[0].textContent
          : null;
        const jobType =
          job.getElementsByTagName("jobType")[0].textContent || null;
        const postedAt = job.getElementsByTagName("postedAt")[0].textContent;
        const isoDate = convertToUnixTimestamp(postedAt);

        // Process the category to assign tags
        const tags = findTags(category.toLowerCase(), tagMap);

        newFetchedJobs.push({
          title: title,
          companyName: company,
          locationRestrictions: [city, state, countryEl]
            .filter(Boolean)
            .join(", "),
          pubDate: isoDate,
          guid: jobId,
          salaryRange: salary, // Remove non-numeric characters
          categories: [category], // Assign the processed tags
          tags: tags,
          type: jobType, // Assuming jobType maps directly to your type field
          description: description,
          companyLogo: companyLogo,
          jobUrl: jobUrl,
          applyNowLink: jobUrl,
          impressionUrl: impressionUrl,
          cpc: cpc,
          postalCode: postalCode,
          source: "indeed",
          // Not all fields are available in the Indeed data, so some are left out
        });
      }

      // Use a callback function to ensure state is updated correctly
      setFetchedJobs((prevJobs) => {
        const updatedJobs = [...prevJobs, ...newFetchedJobs];
        props.setResults((prevResults) => [...prevResults, ...newFetchedJobs]);
        return updatedJobs;
      });

      console.log(newFetchedJobs);
    } catch (error) {
      console.error(`Error fetching jobs for ${country}:`, error);
    }
  };
  useEffect(() => {
    console.log("fetching");
    const fetchInterval = setInterval(() => {
      fetchJobs("CA");
    }, 10000); // 60000 milliseconds = 1 minute

    // Clean up the interval when the component unmounts
    return () => clearInterval(fetchInterval);
  }, []);
  */

  const dropdownOptions = [
    [
      { value: "All Levels", label: "All Levels" },
      { value: "Entry-level", label: "Entry-level" },
      { value: "Mid-level", label: "Mid-level" },
      { value: "Senior", label: "Senior" },
      { value: "Manager", label: "Manager" },
      { value: "Director", label: "Director" },
      { value: "Executive", label: "Executive" },
    ],
    [
      { value: "All Salaries", label: "All Salaries" },
      { value: "$25,000+", label: "$25,000+" },
      { value: "$50,000+", label: "$50,000+" },
      { value: "$75,000+", label: "$75,000+" },
      { value: "$100,000+", label: "$100,000+" },
      { value: "$200,000+", label: "$200,000+" },
    ],
    [
      { value: "All Locations", label: "All Locations" },
      { value: "USA Only", label: "USA Only" },
      { value: "International Only", label: "International Only" },
    ],
  ];

  const sortOptions = [
    { value: "pubDate_desc", label: "Recency" },
    { value: "maxSalary_desc", label: "Salary" },
    { value: "favorites", label: "Favorites" },
  ];

  const [selectedValues, setSelectedValues] = useState(
    dropdownOptions.map((option) => [option[0].value])
  );

  const [selectedSortValue, setSelectedSortValue] = useState(
    sortOptions[0].value
  );

  useEffect(() => {
    const params = new URLSearchParams();

    const hasParams = [...params.keys()].length > 0;

    if (inputValue) {
      params.set("search", inputValue);
    }

    selectedValues.forEach((value, index) => {
      if (value && value.length) {
        params.set(`filter${index}`, JSON.stringify(value));
      }
    });

    if (selectedSortValue) {
      params.set("sort", selectedSortValue);
    }
  }, [inputValue, selectedValues, selectedSortValue]);

  const performSearch = async (value, filters, selectedOption) => {
    console.log("Searching with value:", value);
    //console.log("Using filters:", filters);
    //console.log("Index Name:", selectedOption);
    props.setResults([]);

    //console.log("searching", (selectedOption));
    /// The indexName will depend on the selected option
    let indexName = "JOBS_pubDate_desc";
    if (selectedOption === "pubDate_desc") {
      //console.log("desc");
      indexName = "JOBS_pubDate_desc";
    } else if (selectedOption === "maxSalary_desc") {
      indexName = "JOBS_maxSalary_desc";
    } else if (selectedOption === "relevance_desc") {
      indexName = "JOBS";
    } else if (selectedOption === "favorites") {
      indexName = "JOBS_pubDate_desc";
    }

    const index = searchClient.initIndex(indexName);

    const results = await index.search(value, {
      filters: filters,
      hitsPerPage: 250,
    });

    //console.log("Received Results:", results);

    const filteredHits = results["hits"]
      .filter((hit) => {
        if (hit.applyNowLink && typeof hit.applyNowLink === "string") {
          return hit.applyNowLink;
        }
        return true;
      })
      .filter((job) => job.type);

    const sortedHits = sortResults(userData, filteredHits);

    props.setResults(filteredHits);
    setSelectedJob(filteredHits.find((job) => job.type));
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      performSearch(inputValue, getFilters(selectedValues), selectedSortValue);
    }
  };

  {
    useEffect(() => {
      console.log("triggered1");
      const searchParams = new URLSearchParams(location.search);
      const mode = searchParams.get("mode");

      if (inputValue !== "" && mode !== "matches") {
        performSearch(
          inputValue,
          getFilters(selectedValues),
          selectedSortValue
        );
      } else if (mode !== "matches") {
        performSearch("", getFilters(selectedValues), selectedSortValue);
      }
    }, [selectedValues, selectedSortValue]);
  }

  const handleDropdownChange = (dropdownIndex, selectedValuesForDropdown) => {
    setSelectedValues((prevValues) => {
      const newValues = [...prevValues];

      // If the option is already selected, remove it; otherwise, add it.
      if (dropdownIndex === 0) {
        if (selectedValuesForDropdown.includes("All Levels")) {
          // If "All Levels" is selected, clear all other selections
          newValues[0] = ["All Levels"];
        } else if (newValues[0].includes("All Levels")) {
          // If any other value is selected while "All Levels" was previously selected
          // Unselect "All Levels" and update the other selections
          newValues[0] = selectedValuesForDropdown.filter(
            (val) => val !== "All Levels"
          );
        } else {
          // Any other scenario, just update the selected values
          newValues[0] = selectedValuesForDropdown;
        }
      } else {
        newValues[dropdownIndex] = selectedValuesForDropdown;
      }

      return newValues;
    });
  };

  const sortResults = (userData, jobs) => {
    const userPreferences = userData.preferences || {};

    // Calculate match score for each job
    const scoredJobs = jobs.map((job) => {
      let matchScore = 0;

      // Increase score for liked attributes, with null checks
      if (job.categories) {
        job.categories.forEach((category) => {
          matchScore += userPreferences[category] || 0;
        });
      }
      matchScore += userPreferences[job.type] || 0;
      if (job.skills) {
        job.skills.forEach((skill) => {
          matchScore += userPreferences[skill] || 0;
        });
      }
      matchScore += userPreferences[job.seniority] || 0;
      matchScore += userPreferences[job.title] || 0;

      // Add recency bias
      const pubDate = new Date(job.pubDate);
      const recencyBias =
        Math.max(0, 30 - (new Date() - pubDate) / (1000 * 60 * 60 * 24)) / 30;
      matchScore += recencyBias * 5; // Adjust the bias factor as needed

      return { ...job, matchScore };
    });

    // Sort jobs by match score, highest to lowest
    const sortedJobs = scoredJobs.sort((a, b) => b.matchScore - a.matchScore);

    return sortedJobs;
  };

  const getFilters = (selectedValuesArray) => {
    const filters = [];
    const hashCode = (s) =>
      s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

    // Handling Seniority Dropdown
    if (selectedValuesArray[0] && selectedValuesArray[0].length) {
      const seniorityFilters = selectedValuesArray[0]
        .filter((val) => val !== "All Levels")
        .map((val) => `seniority:"${val}"`);

      if (seniorityFilters.length) {
        filters.push(`(${seniorityFilters.join(" OR ")})`);
      }
    }

    // Handling Salary Dropdown
    if (selectedValuesArray[1] && selectedValuesArray[1].length) {
      const salaryFilters = selectedValuesArray[1]
        .filter((val) => val !== "All Salaries")
        .map((val) => {
          const minSalary = val.replace(/[^\d.-]/g, "");
          return `maxSalary >= ${minSalary}`;
        });
      filters.push(...salaryFilters);
    }

    // Handling Location Dropdown
    if (selectedValuesArray[2] && selectedValuesArray[2].length) {
      const locationFilters = selectedValuesArray[2]
        .map((location) => {
          switch (location) {
            case "USA Only":
              return `locationRestrictions:"United States"`;
            case "International Only":
              return `NOT locationRestrictions:"United States"`;
            case "All Locations":
              return "";
            default:
              return `locationRestrictions:"${location}"`;
          }
        })
        .filter(Boolean);
      filters.push(...locationFilters);
    }

    if (selectedSortValue === "favorites") {
      const favoritesFilter = userData.favorites
        .map((favorite) => `id:${hashCode(favorite)}`)
        .join(" OR ");
      filters.push(`(${favoritesFilter})`);
    }

    if (userData && userData.applied && userData.applied.length > 0) {
      const appliedFilters = userData.applied.map(
        (url) => `NOT id:${hashCode(url)}`
      );
      filters.push(...appliedFilters);
    }

    filters.push(`NOT linkedin:true`);
    filters.push(`NOT image:none`);

    //filters.push(`locationRestrictions:"United States"`);

    const filtersString = filters.join(" AND ");
    //console.log("Generated Filters:", filtersString);

    return filters.join(" AND ");
  };

  useEffect(() => {
    const filtersAreSet = selectedValues.some(
      (value, index) => value !== dropdownOptions[index][0].value
    );
    setAreFiltersSet(filtersAreSet);
  }, [selectedValues, dropdownOptions]);

  return (
    <>
      <div
        className="self-stretch flex flex-col items-start justify-start gap-[16px] text-9xl "
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className="self-stretch flex flex-row items-start justify-start">
          <motion.div
            layout="position"
            onClick={() => {
              inputRef.current && inputRef.current.focus();
            }}
            className={`cursor-pointer flex-1 ${
              isInputFocused
                ? "border-mediumseagreen-200"
                : "border-neutral-700"
            } rounded-8xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] pr-3 overflow-hidden flex flex-row transform-gpu transition-all duration-300 ${
              !isInputFocused ? "hover:border-neutral-300" : ""
            } items-center gap-2 text-sm text-success-600 border-[1px] border-solid`}
          >
            <AnimatePresence mode="wait">
              <motion.div
                onClick={() => handleKeyDown({ key: "Enter" })}
                className={`cursor-pointer ${
                  isInputFocused
                    ? "px-4 hover:bg-neutral-50 bg-neutral-100 border-neutral-700"
                    : "pl-3 pr-1 border-white"
                } h-full border-r-[1px] border-solid py-3 flex text-black items-center justify-center transition-all transform-gpu duration-300`}
              >
                <img
                  className="relative w-4 h-4 shrink-0 overflow-hidden"
                  alt=""
                  src="/search.svg"
                />
              </motion.div>
            </AnimatePresence>

            <motion.div
              layout="position"
              className="flex-grow flex items-center justify-between"
            >
              <input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                className="outline-none bg-transparent w-full py-3"
                placeholder="Search for keywords..."
              />

              <AnimatePresence mode="wait">
                {!isInputFocused && (
                  <motion.div
                    className="text-neutral-300 pl-3 whitespace-nowrap"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {props.results.length} results found
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </motion.div>
        </div>

        <AnimatePresence>
          {(isInputFocused || inputValue || isHovered || areFiltersSet) && (
            <motion.div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
              {dropdownOptions.map((_, index) => (
                <SearchDropdown
                  key={index}
                  titleText={`Dropdown ${index + 1}`}
                  options={dropdownOptions[index]}
                  value={selectedValues[index]}
                  onChange={(value) => handleDropdownChange(index, value)}
                  selectedSortValue={selectedSortValue}
                  sort={false}
                />
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default Search;
