import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  setPersistence,
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import { db, auth } from "./firebase";
import {
  doc,
  getDocs,
  onSnapshot,
  collection,
  getDoc,
  addDoc,
} from "firebase/firestore";
import React from "react";

import { app } from "./firebase";
import Dashboard from "./pages/Dashboard";
import Jobs from "./pages/Jobs";
import Demo from "./pages/Demo";
import Copilot from "./pages/Copilot";
import Tracker from "./pages/Tracker";
import Login from "./pages/Login";
import OnboardingModal from "./components/OnboardingModal"; // import the Onboarding component
import { JobsProvider, useJobs } from "./jobsContext";
import { UserDataProvider, useUserData } from "./UserDataContext";
import { PaymentProvider } from "./PaymentContext";
import SideMenuGeneral from "./components/SideMenuGeneral";
import UpgradeModal from "./components/UpgradeModal";
import UpgradeFeatureModal from "./components/UpgradeFeatureModal";
import Rive from "rive-react";
import Banner from "./components/Banner";
import MoreInfoPage from "./pages/MoreInfoPage";
import Settings from "./pages/Settings";
import Resumes from "./pages/Resumes";
import ResumeBuilder from "./pages/ResumeBuilder";
import ProcessingModal from "./components/ProcessingModal";
import { AnimatePresence, motion } from "framer-motion";
import { useResumeStore, useTemplateStore } from "./hooks";
import SideMenuDemo from "./demoThings/SideMenuDemo";
import { usePageView, useUsermaven } from "./UserMaven";
import Analytics from "./pages/Analytics";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  let usermavenOpts = {
    key: "UMWZUKUs0g",
    tracking_host: "https://events.usermaven.com",
  };
  const { usermaven } = useUsermaven(usermavenOpts);
  usePageView(usermaven);

  const auth = getAuth(app);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            uid: currentUser.uid,
            email: currentUser.email,
          })
        );
        setUser(currentUser);
      } else {
        localStorage.removeItem("user");
        setUser(null);
      }
      setLoading(false);
    });

    const savedUser = localStorage.getItem("user");
    if (savedUser) {
      setUser(JSON.parse(savedUser));
      setLoading(false);
    }
    return unsubscribe;
  }, []);

  useEffect(() => {
    // Heap Analytics initialization code
    window.heap = window.heap || [];
    heap.load = function (e, t) {
      window.heap.appid = e;
      window.heap.config = t = t || {};
      var r = document.createElement("script");
      r.type = "text/javascript";
      r.async = !0;
      r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";
      var a = document.getElementsByTagName("script")[0];
      a.parentNode.insertBefore(r, a);
      for (
        var n = function (e) {
            return function () {
              heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          },
          p = [
            "addEventProperties",
            "addUserProperties",
            "clearEventProperties",
            "identify",
            "resetIdentity",
            "removeEventProperty",
            "setEventProperties",
            "track",
            "unsetEventProperty",
          ],
          o = 0;
        o < p.length;
        o++
      )
        heap[p[o]] = n(p[o]);
    };
    heap.load("2764096392");
  }, []);

  return (
    <UserDataProvider user={user} loading={loading}>
      <PaymentProvider>
        <JobsProvider>
          <AppContent />
        </JobsProvider>
      </PaymentProvider>
    </UserDataProvider>
  );
}

function motionElement(component) {
  return (
    <motion.div
      key={location.pathname}
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 10 }}
      transition={{ type: "ease", damping: 30, stiffness: 80, duration: 0.3 }}
      className="w-full h-full"
    >
      {component}
    </motion.div>
  );
}
function AppContent() {
  const location = useLocation();
  const { userData, user, isLoading, isAuthenticated } = useUserData();
  const { bannerMessage, setBannerMessage, bannerType, setBannerType } =
    useJobs();
  const [startExitAnimation, setStartExitAnimation] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const { fillResumes, changeResume } = useResumeStore((state) => ({
    fillResumes: state.fillResumes,
    changeResume: state.changeResume,
  }));
  const { fillUnactiveTemplates } = useTemplateStore((state) => ({
    fillUnactiveTemplates: state.fillUnactiveTemplates,
  }));

  const isMobileDevice = () => {
    const ua = window.navigator.userAgent;
    return /Android|Mobi/i.test(ua);
  };

  if (isMobileDevice()) {
    return (
      <div className="relative bg-neutral-50 w-full h-screen flex flex-row items-center justify-center text-center text-gray-50 font-text-l-regular">
        <div className="flex flex-col items-center justify-center gap-[12px]">
          <img src="/error.png" alt="Error" className="w-[300px] h-[200px]" />
          <h1 className="text-4xl text-gray-700 font-semibold mb-2">Whoops!</h1>
          <p className="text-base tracking-[-0.1px] leading-[24px] text-gray-50 mb-4 px-24">
            We don't support mobile yet, but we're currently working on a mobile
            app. For now, please visit us on desktop!
          </p>
        </div>
      </div>
    );
  }

  const displayBanner = () => {
    //console.log("should be displaying banner");
    setShowBanner(true);
    setStartExitAnimation(false);

    setTimeout(() => {
      setStartExitAnimation(true);

      setTimeout(() => {
        setShowBanner(false);
        setBannerMessage("");
        setBannerType("");
      }, 700);
    }, 2000);
  };

  useEffect(() => {
    const localResumes = JSON.parse(localStorage.getItem("@resumes") || "[]");
    if (localResumes.length) {
      fillResumes(localResumes);
    }
    const activeResume = localStorage.getItem("@activeResume");
    if (activeResume) {
      const parsedActiveResume = JSON.parse(activeResume);
      changeResume(parsedActiveResume.id, parsedActiveResume);
      localStorage.removeItem("@activeResume");
    }
    const unsubResume = useResumeStore.subscribe(
      (state) => state.resumes,
      (resumes) => {
        localStorage.setItem("@resumes", JSON.stringify(resumes));
      }
    );
    return () => unsubResume();
  }, []);

  useEffect(() => {
    const localUnactiveTemplates = JSON.parse(
      localStorage.getItem("@unactiveTemplates") || "[]"
    );
    if (localUnactiveTemplates.length) {
      fillUnactiveTemplates(localUnactiveTemplates);
    }
    const unsubUnactiveTemplates = useTemplateStore.subscribe(
      (state) => state.unactiveTemplates,
      (unactiveTemplates) => {
        localStorage.setItem(
          "@unactiveTemplates",
          JSON.stringify(unactiveTemplates)
        );
      }
    );
    return () => unsubUnactiveTemplates();
  }, []);

  useEffect(() => {
    //console.log("banner change");
    if (bannerMessage != "" && bannerType != "") {
      //console.log("banner  display", bannerMessage, bannerType);
      displayBanner(bannerMessage, bannerType);
    }
  }, [bannerMessage, bannerType]);

  const Loader = () => (
    <Rive src="/loadingwrangle.riv" className="w-screen h-screen"></Rive>
  );

  const showSideMenu = location.pathname !== "/login";

  if (isLoading) {
    return (
      <div
        className="w-screen text-left text-base text-gray-50 font-text-l-regular"
        style={{ display: "flex" }}
      >
        <SideMenuGeneral />
        <Loader />
      </div>
    );
  }

  // Check if user data is loaded
  if (!userData) {
    return (
      <div
        className="w-screen text-left text-base text-gray-50 font-text-l-regular"
        style={{ display: "flex" }}
      ></div>
    );
  }

  return (
    <div className="flex h-screen text-left text-base text-gray-50 font-text-l-regular">
      {showBanner && (
        <Banner
          startExitAnimation={startExitAnimation}
          bannerMessage={bannerMessage}
          bannerType={bannerType}
        />
      )}
      <UpgradeModal />
      <UpgradeFeatureModal />

      {isAuthenticated &&
      userData &&
      Object.keys(userData).length > 0 &&
      !userData.onboarded
        ? (() => {
            console.log("Rendering OnboardingModal with userData:", userData);
            return <OnboardingModal />;
          })()
        : null}

      {showSideMenu && (
        <div className="flex-shrink-0 h-screen bg-gray-100 overflow-y-auto">
          <SideMenuGeneral />
        </div>
      )}

      {/* Scrollable main content */}
      <div className="flex-1 overflow-y-auto">
        <ProcessingModal />
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Navigate to="/jobs" replace />} />
            <Route
              path="/login"
              element={
                isAuthenticated ? (
                  <Navigate to="/jobs" replace />
                ) : (
                  motionElement(<Login />)
                )
              }
            />
            <Route
              path="/dashboard"
              element={
                !isAuthenticated ? (
                  <Navigate to="/jobs" replace />
                ) : (
                  motionElement(<Dashboard />)
                )
              }
            />
            <Route path="/jobs" element={motionElement(<Jobs />)} />
            <Route path="/demo" element={motionElement(<Demo />)} />
            <Route path="/analytics" element={motionElement(<Analytics />)} />
            <Route path="/tracker" element={motionElement(<Tracker />)} />
            <Route
              path="/settings"
              element={
                !isAuthenticated ? (
                  <Navigate to="/login" replace />
                ) : (
                  motionElement(<Settings />)
                )
              }
            />
            <Route path="/resumes" element={motionElement(<Resumes />)} />
            <Route path="/copilot" element={motionElement(<Copilot />)} />
            <Route
              path="/resume-builder/:id"
              element={motionElement(<ResumeBuilder />)}
            />
            <Route
              path="/job/:jobGuid"
              element={motionElement(<MoreInfoPage />)}
            />
          </Routes>
        </AnimatePresence>
      </div>
    </div>
  );
}

export default App;
