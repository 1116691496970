import React, { useState, useRef, useEffect } from "react";
import { Add, ArrowDown2, Trash } from "iconsax-react";
import Collapsible from "react-collapsible";
import { Form } from ".";
import classNames from "classnames";
const ItemSection = ({
  fields = [],
  item,
  renderTitle = () => null,
  renderDescription = () => null,
  onChange = () => false,
  onRemove = () => false,
  onToggle = () => false,
  isOpen
}) => {
  return <section className="flex flex-col border border-gray-200 bg-gray-100/30 rounded border-solid ">
      <Collapsible transitionTime={200} transitionCloseTime={200} open={isOpen} onTriggerOpening={onToggle} onTriggerClosing={onToggle} trigger={<header className="flex justify-between items-center px-5 py-4 gap-10 cursor-pointer group relative">
            <button onClick={e => {
        e.stopPropagation();
        onRemove();
      }} className="bg-transparent cursor-pointer absolute right-0 p-2.5 transition translate-x-full opacity-0 group-hover:opacity-100">
              <Trash color="#5f6d7e" size={18} />
            </button>
            <div className="flex flex-col gap-1">
              <span className="font-semibold text-black text-sm group-hover:text-primary transition">
                {renderTitle(item) || "(Not specified)"}
              </span>
              {renderDescription(item) ? <span className="font-semibold text-gray-400 text-xs">
                  {renderDescription(item)}
                </span> : null}
            </div>
            <ArrowDown2 color="#9CA3AF" size={20} className={classNames("transition duration-300", {
        "rotate-180": isOpen
      })} />
          </header>}>
        <Form fields={fields} className="p-5 pt-3" onChange={(key, val) => onChange({
        ...item,
        [key]: val
      })} />
      </Collapsible>
    </section>;
};
const ListedForm = ({
  addOneName,
  fields = [],
  renderTitle = () => null,
  renderDescription = () => null,
  onChange = () => false,
  defaultData = []
}) => {
  const container = useRef(null);
  const [opened, setOpened] = useState([]);
  const [data, setData] = useState(defaultData);
  useEffect(() => {
    onChange(data);
  }, [data]);
  const handleToggleOpen = index => {
    setOpened(opened.includes(index) ? opened.filter(i => i !== index) : [...opened, index]);
  };
  const handleRemove = index => {
    setData(data.filter((__, n) => n !== index));
    setOpened(opened.filter(i => i !== index).map(i => i > index ? i - 1 : i));
  };
  const addNewItem = () => {
    setData([...data, {
      id: `ITEM_${Date.now()}`
    }]);
    setOpened([data.length]);
    setTimeout(() => {
      const {
        offsetTop
      } = container.current.children[data.length];
      if (offsetTop) window.scrollTo({
        top: offsetTop - 100,
        left: 0,
        behavior: "smooth"
      });
    }, 300);
  };
  return <div ref={container} className="flex flex-col gap-3">
      {data.map((item, index) => <ItemSection key={item.id || index} onChange={newItem => setData(data.map((i, n) => n === index ? newItem : i))} onRemove={() => handleRemove(index)} isOpen={opened.includes(index)} onToggle={() => handleToggleOpen(index)} {...{
      item,
      index,
      renderTitle,
      renderDescription,
      fields: fields.map(field => ({
        ...field,
        formValue: item[field.name]
      }))
    }} />)}
      <button onClick={addNewItem} className="mt-1 transition duration-300 bg-transparent cursor-pointer gap-1 hover:bg-primary/10 rounded px-3.5 py-2  flex-1 flex items-center">
        <Add color="rgb(92,209,147)" size={20} />
        <span className="text-primary text-sm font-semibold">
          Add {data.length ? "another " : ""} {addOneName}
        </span>
      </button>
    </div>;
};
export { ListedForm };