import React, { createContext, useContext, useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
  getDoc,
  doc,
  setDoc,
  where,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import { useUsermaven } from "./UserMaven";

const UserDataContext = createContext();

export const useUserData = () => {
  return useContext(UserDataContext);
};
let usermavenOpts = {
  key: "UMWZUKUs0g",
  tracking_host: "https://events.usermaven.com",
};
export const UserDataProvider = ({ children, user, loading }) => {
  const [userData, setUserData] = useState({});
  const { usermaven } = useUsermaven(usermavenOpts);

  useEffect(() => {
    let unsubscribe;
    if (user) {
      const userId = user.uid;
      usermaven.id({
        // Required attributes
        id: user.uid,
      });
      // usermaven change
      const docRef = doc(db, "Profiles", userId);

      // Check if user document exists
      getDoc(docRef)
        .then((docSnap) => {
          if (!docSnap.exists()) {
            console.log("yo");
            // If document does not exist, initialize it
            const initialData = {
              email: user.email,
              applied: [],
              favorites: [],
            };
            setDoc(docRef, initialData, { merge: true })
              .then(() => {
                //console.log("User data initialized!");
                setUserData(initialData); // Set the initialData in the state
              })
              .catch((error) => {
                console.error("Error initializing user data: ", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error checking user data: ", error);
        });

      unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const newData = docSnap.data();
          setUserData((prevData) => {
            // Check if the new data is different from the current data
            const isDifferent = Object.keys(newData).some(
              (key) => newData[key] !== prevData[key]
            );
            if (isDifferent) {
              window.heap.addUserProperties(newData);
              return { ...prevData, ...newData };
            }
            return prevData;
          });
        }
      });
    }
    return () => unsubscribe && unsubscribe();
  }, [user]);

  const updateJobsWithLinkedin = async () => {
    const jobsRef = collection(db, "Jobs");
    const querySnapshot = await getDocs(jobsRef);

    querySnapshot.forEach(async (docSnapshot) => {
      const job = docSnapshot.data();
      console.log(job.guid);
      if (job.guid && job.guid.includes("himalayas.app")) {
        if (!job.linkedin) {
          const jobDocRef = doc(db, "Jobs", docSnapshot.id);
          await updateDoc(jobDocRef, {
            himalayas: true,
          });
          console.log(`Added linkedin:true to job ${docSnapshot.id}`);
        } else {
          console.log(`Job ${docSnapshot.id} already has linkedin:true`);
        }
      }
    });
  };

  /*useEffect(() => {
    console.log("linnylinny");
    updateJobsWithLinkedin(); // Call the function on startup
  }, [userData]);*/

  useEffect(() => {
    if (user && userData) {
      const userId = user.uid;
      const docRef = doc(db, "Profiles", userId);
      setDoc(docRef, userData, { merge: true }).catch((error) => {
        console.error("Error updating user data: ", error);
      });
    }
  }, [userData, user]);
  const docRef = doc(db, "Admin", "admin_accounts");
  let isAdmin;
  onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
        console.log("Admin Document Found")
        const admins = docSnap.data().adminAccounts;
        // Check if the user is an admin inside the snapshot callback
        if (admins.includes(user.uid)) {
            isAdmin = true;
            console.log("User is admin:", isAdmin);
        } else {
            //console.log("User is not an admin.");
        }
    } else {
        //console.log("No such document!");
    }
  }, (error) => {
      //console.error("Error getting document:", error);
  });
  return (
    <UserDataContext.Provider
      value={{
        userData,
        setUserData,
        user,
        isAuthenticated: !!user,
        isLoading: loading,
        currentLink: "https://app.wranglejobs.com",
        currentApi: "https://app.wranglejobs.com/api",
        admin: isAdmin
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
