import React from "react";
const PanelSection = ({
  title = "",
  children,
  description
}) => {
  return <section className="flex w-full box-border flex-col bg-white py-4 px-16 border-b-2 border-gray-200">
      <h2 className="font-semibold text-xl mb-6 text-stone-900">{title}</h2>
      {description && <span className="text-xs mb-5 -mt-4 leading-normal text-gray">
          {description}
        </span>}
      {children}
    </section>;
};
export { PanelSection };