import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "./ProgressBar";
import { ChooseTemplate } from "./ChooseTemplate";
import { PanelSection } from "./PanelSection";
import { Form, ListedForm } from "../../../components/commons";
import { useThisResume } from "../../../hooks";
import { templateUtil } from "../../../utils";
import { useUserData } from "../../../UserDataContext";
const PanelSidebar_1 = _compiledBlock(_props => <div className="flex flex-1 py-8 flex-col">
      <div className="px-8 flex">
        <button className="font-text-l-regular mr-4 cursor-pointer transform-gpu transition-all duration-300 text-sm bg-base-white rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border py-2 overflow-hidden flex items-center justify-center border-[1px] border-solid hover:bg-neutral-50 border-neutral-700 hover:border-neutral-300 text-black mr-2 px-4" onClick={_props.v0}>
          Save and Exit
        </button>
        {_props.v1}
      </div>

      {_props.v2}
      {_props.v3}
      {_props.v4}
    </div>, {
  name: "PanelSidebar_1",
  portals: ["v1", "v2", "v3", "v4"]
});
const PanelSidebar = ({
  goBack
}) => {
  const navigate = useNavigate();
  const {
    userData,
    isAuthenticated
  } = useUserData();
  const data = useThisResume(state => state.data);
  const templateKey = useThisResume(state => state.template);
  const template = templateUtil.getActiveTemplate(templateKey);
  const fillData = useThisResume(state => state.fillData);
  const addDataByKey = useThisResume(state => state.addDataByKey);
  useEffect(() => {
    if (Object.keys(userData || {}).length && !Object.keys(data || {}).length) {
      const [first_name = "", last_name = ""] = (userData?.name ?? "").split(" ");
      fillData({
        first_name,
        last_name,
        email: userData.email || "",
        phone: userData.phone || ""
      });
    }
  }, [userData, data, fillData]);
  return /*@million jsx-skip*/<PanelSidebar_1 v0={() => goBack()} v1={!isAuthenticated && <div className="font-text-l-regular cursor-pointer hover:bg-green-100 w-full transform-gpu transition-all duration-300 text-sm bg-green-50 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border py-2 overflow-hidden flex items-center justify-center border-[1px] border-solid border-mediumseagreen-200 text-mediumseagreen-200" onClick={() => navigate("/login")}>
            Click here to create an account to edit resumes in the future
          </div>} v2={<ProgressBar />} v3={<ChooseTemplate />} v4={template.data.map(section => <PanelSection key={section.name} title={section.displayTitle} description={section.description}>
          {section.type === "form" ? <Form {...section} fields={section.fields.map(item => ({
      ...item,
      formValue: data[item.name],
      description: section.description
    }))} description={section.description} onChange={(key, val) => addDataByKey(key, val)} /> : <ListedForm {...section} defaultData={data[section.name]} onChange={val => addDataByKey(section.name, val)} />}
        </PanelSection>)} />;
};
export { PanelSidebar };