import React from "react";
import { useState, useEffect } from "react";
import { useJobs } from "../jobsContext";
import AutoApplyModal from "./AutoApplyModal";
import { motion, AnimatePresence } from "framer-motion";
import { useUserData } from "../UserDataContext";
import { usePayment } from "../PaymentContext";
import AccountCreateModal from "./AccountCreateModal";
import Banner from "./Banner";
import algoliasearch from "algoliasearch";
import Search from "./Search";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const JobBar = (props) => {
  const location = useLocation();
  const { userData, isAuthenticated } = useUserData();
  const { setSelectedJob } = useJobs();
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerType, setBannerType] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [startExitAnimation, setStartExitAnimation] = useState(false);
  const navigate = useNavigate();
  const [activeModeIndex, setActiveModeIndex] = useState(0);
  const [prod, setProd] = useState(false);
  const [open, setOpen] = useState(false);

  const hashCode = (s) =>
    s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

  const [modes, setModes] = useState([
    {
      title: "Search",
    },
    {
      title: "Matches",
    },
  ]);

  const searchClient = algoliasearch(
    "1AD8YCD11H",
    "72b766934c217c2c483592d02639cd46"
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get("mode");

    if (mode === "matches") {
      console.log("detected mode");

      setActiveModeIndex((prevIndex) => {
        if (prevIndex !== 1) {
          return 1;
        }
        return prevIndex;
      });

      const searchQuery =
        userData?.role !== undefined
          ? userData.role
          : userData?.keywords?.title !== undefined
          ? userData.keywords.title
          : "";

      if (searchQuery) {
        performSearch(searchQuery);
      } else {
        performSearch("");
      }
    }
  }, [activeModeIndex, userData]);

  {
    /*
  const getHighestPreferenceKeyword = () => {
    const userPreferences = userData.preferences || {};
    let highestPreference = { key: null, value: -Infinity };
    for (const [key, value] of Object.entries(userPreferences)) {
      if (value > highestPreference.value) {
        highestPreference = { key, value };
      }
    }
    return highestPreference.key;
  };
*/
  }

  const sortResults = (userData, jobs) => {
    const userPreferences = userData.preferences || {};

    // Calculate match score for each job
    const scoredJobs = jobs.map((job) => {
      let matchScore = 0;

      // Increase score for liked attributes, with null checks
      if (job.categories) {
        job.categories.forEach((category) => {
          matchScore += userPreferences[category] || 0;
        });
      }
      matchScore += userPreferences[job.type] || 0;
      if (job.skills) {
        job.skills.forEach((skill) => {
          matchScore += userPreferences[skill] || 0;
        });
      }
      matchScore += userPreferences[job.seniority] || 0;
      matchScore += userPreferences[job.title] || 0;

      // Add recency bias
      const pubDate = new Date(job.pubDate * 1000); // Convert Unix timestamp from seconds to milliseconds
      const daysAgo = (new Date() - pubDate) / (1000 * 60 * 60 * 24);
      const recencyBias = Math.max(0, 10 - daysAgo);
      matchScore += recencyBias * 100;

      return { ...job, matchScore };
    });

    // Sort jobs by match score, highest to lowest
    const sortedJobs = scoredJobs.sort((a, b) => b.matchScore - a.matchScore);

    return sortedJobs; // Fix: Return sorted jobs
  };

  const performSearch = async (query) => {
    console.log("searching", query);
    props.setResults([]);
    let filters = [];

    let indexName = "JOBS_pubDate_desc";
    const index = searchClient.initIndex(indexName);

    filters.push(`NOT linkedin:true`);

    if (userData && userData.applied && userData.applied.length > 0) {
      const appliedFilters = userData.applied.map(
        (url) => `NOT id:${hashCode(url)}`
      );
      filters.push(...appliedFilters);
    }

    const filtersString = filters.join(" AND ");

    const results = await index.search(query, {
      hitsPerPage: 250,
      filters: filtersString,
    });

    const filteredHits = results["hits"].filter((hit) => {
      if (hit.applyNowLink && typeof hit.applyNowLink === "string") {
        return hit.applyNowLink;
      }
      return true;
    });

    const sortedHits = sortResults(userData, filteredHits);

    props.setResults(sortedHits);
    setSelectedJob(sortedHits[0]);
  };

  return (
    <>
      {showBanner && (
        <Banner
          startExitAnimation={startExitAnimation}
          bannerMessage={bannerMessage}
          bannerType={bannerType}
        />
      )}
      <AnimatePresence layout="position">
        <div className="flex flex-col self-stretch items-start justify-start gap-[16px] text-9xl">
          <div className="flex flex-row self-stretch items-end justify-between">
            <div className="flex flex-col">
              <div className="relative tracking-[-0.01em] leading-[38px] font-semibold">
                Jobs
              </div>
              <div className="relative text-base tracking-[-0.1px] leading-[38px] text-gray-50 hidden xl:inline-block w-full">
                Here you’ll see the active listings and apply.
              </div>
            </div>
            <AnimatePresence>
              <motion.div
                className="flex gap-3 overflow-x-auto max-h-[200px] scrollbar-hide"
                initial={{ opacity: 0, translateX: -10 }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: -10 }}
                transition={{ duration: 0.2 }}
                layout
              >
                {modes.map((mode, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (index == 1) {
                        navigate("/jobs?mode=matches");
                        if (
                          isAuthenticated &&
                          userData?.keywords["title"] != undefined
                        ) {
                          setActiveModeIndex(index);
                        } else {
                          setOpen(true);
                        }
                      } else {
                        navigate("/jobs");
                        setActiveModeIndex(index);
                      }
                    }}
                    className={`font-text-l-regular cursor-pointer transform-gpu transition-all duration-300 hover:bg-neutral-50 ${
                      index == activeModeIndex
                        ? "text-black"
                        : "text-light-100 hover:border-neutral-300"
                    } text-sm bg-base-white rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[44px] shrink-0 overflow-hidden flex flex-row py-2 px-4 items-center justify-start border-[1px] border-solid ${
                      index == activeModeIndex
                        ? "border-mediumseagreen-200"
                        : "border-neutral-700"
                    }`}
                  >
                    {mode.title}
                  </div>
                ))}
              </motion.div>
            </AnimatePresence>
          </div>
          <AnimatePresence layout="position">
            {activeModeIndex == 0 && (
              <motion.div
                className="self-stretch flex flex-col items-start justify-start"
                initial={{ opacity: 0, translateY: -10 }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: -10 }}
                transition={{ duration: 0.3 }}
              >
                <Search {...props} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </AnimatePresence>
      <AccountCreateModal open={open} setOpen={setOpen} />
    </>
  );
};

export default JobBar;
