import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Preview, PanelSidebar } from "./components";
import { useUserData } from "../../UserDataContext";
import { useResumeStore, useThisResume } from "../../hooks";
import { useNavigate } from "react-router-dom";
const ResumeBuilder = () => {
  const navigate = useNavigate();
  const previewRef = useRef(null);
  const {
    id
  } = useParams();
  const [loading, setLoading] = useState(true);
  const {
    getResume,
    changeResume,
    resumes
  } = useResumeStore(state => ({
    getResume: state.getResume,
    changeResume: state.changeResume,
    resumes: state.resumes
  }));
  const addResume = useThisResume(state => state.addResume);
  const resume = useThisResume(state => state);
  const clearResume = useThisResume(state => state.clearResume);
  const {
    userData,
    user,
    isAuthenticated
  } = useUserData();
  useEffect(() => {
    if (user && user.uid && userData) {
      const userResumes = userData.resumeData ? [...userData.resumeData] : [];
      const userResume = userResumes.find(u => u.id === id) || {};
      const newResume = getResume(id);
      if (Object.keys(userResume).length) {
        addResume(userResume);
        setLoading(false);
      } else if (Object.keys(newResume).length) {
        addResume(newResume);
        setLoading(false);
      } else window.location.href = "/resumes";
    } else if (!isAuthenticated) {
      setLoading(false);
    }
  }, [user, userData]);
  useEffect(() => {
    // handle active resume
    const unsubThisResume = useThisResume.subscribe(state => ({
      data: state.data,
      score: state.score,
      template: state.template
    }), ({
      data,
      score,
      template
    }) => {
      localStorage.setItem("@activeResume", JSON.stringify({
        ...resume,
        template,
        score,
        data,
        updatedAt: Date.now(),
        title: data.first_name || data.last_name ? `${data.first_name} ${data.last_name}` : "Imported"
      }));
    });
    return () => {
      const activeResume = localStorage.getItem("@activeResume");
      if (activeResume) {
        changeResume(id, JSON.parse(activeResume));
        localStorage.removeItem("@activeResume");
      }
      unsubThisResume();
      clearResume();
    };
  }, []);
  const goBack = async () => {
    console.log("printing");
    if (previewRef.current) {
      console.log("printing2");
      await previewRef.current.fullSave();
    }
    navigate("/resumes");
  };
  if (loading) return <div className="flex w-full h-screen items-center justify-center">
        Loading...
      </div>;
  return <div className="flex min-h-screen w-full">
      <PanelSidebar goBack={goBack} />
      <Preview ref={previewRef} />
    </div>;
};
export default ResumeBuilder;