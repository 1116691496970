import { useEffect, useState } from "react";
import ApplicationStepSection from "./ApplicationStepSection";
export const ApplicationStep = ({
  stepData,
  onValueChange,
  value,
  inputValues,
  setInputValues,
  setJsonSuccess
}) => {
  const [stepValue, setStepValue] = useState({});
  useEffect(() => {
    if (value) setStepValue(value);
  }, [value]);
  if (!stepData || !stepData.sections) return null;
  return <div className="">
      {stepData.sections.map((section, idx) => <ApplicationStepSection setJsonSuccess={setJsonSuccess} inputValues={inputValues} setInputValues={setInputValues} sectionData={section} onValueChange={value => {
      onValueChange({
        ...stepValue,
        [stepData.id]: {
          ...stepValue[stepData.id],
          [section.id]: value
        }
      });
      setStepValue({
        ...stepValue,
        [stepData.id]: {
          ...stepValue[stepData.id],
          [section.id]: value
        }
      });
    }} key={`step-${stepData.id}-section-${idx}`} value={stepValue[stepData.id] && stepValue[stepData.id][section.id]} />)}
    </div>;
};
export default ApplicationStep;