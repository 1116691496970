class TextUtil {
  getUSNumber(str = "") {
    const USNumber = str.replace(/\D/g, "").match(/(\d{3})(\d{3})(\d{4})/);
    if (USNumber && USNumber.length)
      return "(" + USNumber[1] + ") " + USNumber[2] + "-" + USNumber[3];
    return "";
  }
}

const textUtil = new TextUtil();
export { textUtil };
