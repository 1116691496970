import {
  CERTIFICATE_ITEM,
  EDUCATION_ITEM,
  EMPLOYMENT_ITEM,
  PERSONAL_DETAILS,
  SKILL_ITEM,
  SUMMARY,
} from "./DataForms";

export const DEFAULT_CONTENT = {
  progress: {
    job_title: 10,
    first_name: 2,
    last_name: 1,
    email: 5,
    phone: 3,
    country: 2,
    city: 2,
    summary: 15,
    employments: 20,
    educations: 20,
    skills: 20,
  },
  data: [
    {
      name: "personal_details",
      displayTitle: "Personal Details",
      type: "form",
      draggable: false,
      fields: PERSONAL_DETAILS,
    },
    {
      name: "summary",
      displayTitle: "Professional Summary",
      description:
        "Write 2-4 short & energetic sentences to interest the reader! Mention your role, experience & most importantly - your biggest achievements, best qualities and skills.",
      type: "form",
      draggable: false,
      fields: SUMMARY,
    },
    {
      name: "employments",
      displayTitle: "Employment History",
      description:
        "Show your relevant experience (last 10 years). Use bullet points to note your achievements, if possible - use numbers/facts (Achieved X, measured by Y, by doing Z).",
      type: "list",
      draggable: true,
      fields: EMPLOYMENT_ITEM,
      addOneName: "employment",
      renderTitle: (item) =>
        `${item.job_title || ""}${item.employer ? " at " + item.employer : ""}`,
      renderDescription: (item) =>
        `${item.start_date || ""}${item.end_date ? " - " + item.end_date : ""}`,
    },
    {
      name: "educations",
      displayTitle: "Education",
      description:
        "A varied education on your resume sums up the value that your learnings and background will bring to job.",
      type: "list",
      draggable: true,
      fields: EDUCATION_ITEM,
      addOneName: "education",
      renderTitle: (item) =>
        `${item.degree || ""}${item.school ? " at " + item.school : ""}`,
      renderDescription: (item) =>
        `${item.start_date || ""}${item.end_date ? " - " + item.end_date : ""}`,
    },
    {
      name: "skills",
      displayTitle: "Skills",
      description:
        "Choose 5 important skills that show you fit the position. Make sure they match the key skills mentioned in the job listing (especially when applying via an online system).",
      type: "list",
      draggable: true,
      fields: SKILL_ITEM,
      addOneName: "skill",
      renderTitle: (item) => item.skill || "",
      renderDescription: (item) => item.level || "",
    },
    {
      name: "certificates",
      displayTitle: "Certifications and awards",
      type: "list",
      draggable: true,
      fields: CERTIFICATE_ITEM,
      addOneName: "certification and award",
      renderTitle: (item) => `${item.title || ""}`,
      renderDescription: (item) => `${item.date || ""}`,
    }
  ],
};
