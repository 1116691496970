import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { useState, useEffect } from "react";
import { useUserData } from "../UserDataContext";
import Rive from "rive-react";
import AddNewAccountModal from "../components/AddNewAccountModal";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import algoliasearch from "algoliasearch";
import Intercom from "@intercom/messenger-js-sdk";
const Dashboard_1 = _compiledBlock(_props => <div className="h-screen w-screen flex items-center justify-center">
        {_props.v0}
      </div>, {
  name: "Dashboard_1",
  portals: ["v0"]
});
const Dashboard_2 = _compiledBlock(_props2 => <div className="relative bg-neutral-50 w-full h-screen flex flex-col overflow-hidden text-left text-9xl text-gray-700 font-text-l-regular">
      <div className="py-8 flex flex-col flex-grow">
        {_props2.v0}

        <div className="flex self-stretch justify-between gap-4 mb-4 px-8">
          {_props2.v1}

          {_props2.v2}

          {_props2.v3}

          {_props2.v4}
        </div>
        <div className="text-lg font-semibold text-gray-600 pb-4 pt-2 px-8">
          Recently added jobs you might like
        </div>
        <div className="flex overflow-x-auto space-x-4 gap-4 pb-4 px-8">
          {_props2.v5}
        </div>
        <div className="text-lg font-semibold text-gray-600 pb-4 px-8">
          Your job search
        </div>
        <div className="flex self-stretch justify-between gap-4 flex-grow min-h-0 px-8">
          <div className="bg-white border-neutral-700 border-[1px] border-solid rounded-lg overflow-hidden flex flex-col flex-grow w-3/5 text-light-100">
            <div className="pt-6 flex-grow flex flex-col">
              <div className="text-base font-semibold text-gray-600 px-8 pb-6 border-neutral-700 border-b-[1px] border-solid">
                Getting Started
              </div>
              <div onClick={_props2.v6} className="space-y-4 hover:bg-neutral-50 duration-150 transition-all border-neutral-700 border-b-[1px] border-solid py-4 px-8">
                <div className="font-semibold text-sm text-light-100 cursor-pointer">
                  Explore our job offerings
                  {_props2.v7}
                </div>
                {_props2.v8}
              </div>
              <div onClick={_props2.v9} className="space-y-4 hover:bg-neutral-50 duration-150 transition-all border-neutral-700 border-b-[1px] border-solid py-4 px-8">
                <div className="font-semibold text-sm text-light-100 cursor-pointer">
                  Save your first job
                  {_props2.v10}
                </div>
                {_props2.v11}
              </div>
              <div onClick={_props2.v12} className="space-y-4 hover:bg-neutral-50 duration-150 transition-all border-neutral-700 border-b-[1px] border-solid py-4 px-8">
                <div className="font-semibold text-sm text-light-100 cursor-pointer">
                  Submit your first application
                  {_props2.v13}
                </div>
                {_props2.v14}
              </div>
            </div>
          </div>
          {_props2.v15}
        </div>
      </div>
      {_props2.v16}
    </div>, {
  name: "Dashboard_2",
  portals: ["v0", "v1", "v2", "v3", "v4", "v5", "v7", "v8", "v10", "v11", "v13", "v14", "v15", "v16"]
});
const Dashboard = () => {
  const navigate = useNavigate();
  const {
    userData,
    isLoading,
    isAuthenticated,
    user
  } = useUserData();
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [isJobOpen, setIsJobOpen] = useState(false);
  const [isAppOpen, setIsAppOpen] = useState(false);
  const [isSubmitOpen, setIsSubmitOpen] = useState(false);
  const [newestJobs, setNewestJobs] = useState([]);
  const hashCode = s => s.split("").reduce((a, b) => (a << 5) - a + b.charCodeAt(0) | 0, 0);
  {
    /*Intercom({
    app_id: "qgss7lxp",
    user_id: user.uid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: userData.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: userData.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: "1704067200", // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });
    */
  }
  const badgeStyle1 = {
    backgroundColor: "#E6FBF0",
    color: "#5BC68D",
    fontSize: "0.7em",
    padding: "2px 4px",
    borderRadius: "4px",
    marginLeft: "10px",
    border: "1px solid #5BC68D"
  };
  const badgeStyle2 = {
    backgroundColor: "#FFEEE7",
    color: "#FDBA9C",
    fontSize: "0.7em",
    padding: "2px 4px",
    borderRadius: "4px",
    marginLeft: "10px",
    border: "1px solid #FDBA9C"
  };
  const Loader = () => <Rive src="/loadingwrangle.riv" className="w-screen h-screen"></Rive>;
  useEffect(() => {
    const fetchNewestJobs = async () => {
      const searchClient = algoliasearch("1AD8YCD11H", "72b766934c217c2c483592d02639cd46");
      const index = searchClient.initIndex("JOBS_pubDate_desc");
      const search = userData?.role !== undefined ? userData.role : userData?.keywords?.title !== undefined ? userData.keywords.title : "";
      const results = await index.search(search, {
        hitsPerPage: 100,
        filters: ""
      });
      setNewestJobs(results.hits);
    };
    fetchNewestJobs();
  }, [userData, isAuthenticated]);
  if (isLoading) {
    return /*@million jsx-skip*/<Dashboard_1 v0={<Loader />} />;
  }
  const getSkillColors = type => {
    switch (type ? type : "") {
      case "engineering":
        return {
          textColor: "#16a34a",
          bgColor: "bg-green-50"
        };
      case "sales":
        return {
          textColor: "#2563eb",
          bgColor: "bg-blue-50"
        };
      case "marketing":
        return {
          textColor: "#9333ea",
          bgColor: "bg-purple-50"
        };
      case "product":
        return {
          textColor: "#db2777",
          bgColor: "bg-pink-50"
        };
      case "design":
        return {
          textColor: "#dc2626",
          bgColor: "bg-red-50"
        };
      case "support":
        return {
          textColor: "#ea580c",
          bgColor: "bg-orange-50"
        };
      case "operations":
        return {
          textColor: "#4f46e5",
          bgColor: "bg-indigo-50"
        };
      case "data":
        return {
          textColor: "#ca8a04",
          bgColor: "bg-yellow-50"
        };
      case "finance":
        return {
          textColor: "#0d9488",
          bgColor: "bg-teal-50"
        };
      case "management":
        return {
          textColor: "#4b5563",
          bgColor: "bg-gray-100"
        };
      default:
        return {
          textColor: "#808080",
          bgColor: "#F0F0F0"
        };
    }
  };
  const getTypeColors = type => {
    switch (type ? type : "") {
      case "Full Time":
        return {
          textColor: "#4C6EAF",
          bgColor: "#E6EEF6"
        };
      case "Part Time":
        return {
          textColor: "#6BA292",
          bgColor: "#E6F4F1"
        };
      case "Contractor":
        return {
          textColor: "#EEBB39",
          bgColor: "#FFF6E6"
        };
      case "Temporary":
        return {
          textColor: "#A267AC",
          bgColor: "#F4E6F8"
        };
      case "Intern":
        return {
          textColor: "#D2691E",
          bgColor: "#F4EAE6"
        };
      case "Volunteer":
        return {
          textColor: "#C8385A",
          bgColor: "#FCE6EF"
        };
      case "Other":
        return {
          textColor: "#8B4513",
          bgColor: "#FFF5E6"
        };
      default:
        return {
          textColor: "#808080",
          bgColor: "#F0F0F0"
        };
    }
  };
  const Card = ({
    bgColor,
    icon,
    headline,
    subline,
    onClick
  }) => {
    return <div className="bg-white border-neutral-700 hover:border-neutral-300 hover:bg-neutral-50 transition-all duration-300 border-[1px] border-solid rounded-lg overflow-hidden flex items-center w-full p-2 cursor-pointer" onClick={onClick}>
        <div className={`w-12 h-12 ${bgColor} flex items-center justify-center rounded-md mr-4`}>
          {icon}
        </div>
        <div className="flex flex-col justify-center">
          <div className="text-sm font-semibold">{headline}</div>
          <div className="text-xs text-gray-500">{subline}</div>
        </div>
      </div>;
  };
  const JobSearchInsights = () => {
    const insights = [{
      label: "Applications Sent",
      value: userData.applied?.length || 0,
      badge: userData.applied?.length ? "Updated" : "N/A"
    }, {
      label: "Jobs Tracked",
      value: (userData.tracker?.applied?.length || 0) + (userData.tracker?.saved?.length || 0) + (userData.tracker?.interviewing?.length || 0) + (userData.tracker?.awaiting?.length || 0),
      badge: userData.tracker ? "Updated" : "N/A"
    }, {
      label: "Saved Jobs",
      value: userData.saved?.length || 0,
      badge: userData.saved?.length ? "Updated" : "N/A"
    }, {
      label: "Disliked Jobs",
      value: userData.dislikes?.length || 0,
      badge: userData.dislikes?.length ? "Updated" : "N/A"
    }];
    const getBadgeColor = badge => {
      switch (badge) {
        case "Updated":
          return "bg-green-100 text-mediumseagreen-200 border-mediumseagreen-200";
        default:
          return "bg-gray-100 text-gray-400 border-gray-400";
      }
    };
    return <div className="grid grid-cols-2 gap-4 w-2/5">
        {insights.map((insight, index) => <div key={index} className="bg-white border-neutral-700 border-[1px] border-solid rounded-lg overflow-hidden flex flex-col p-4 relative">
            <span className={`px-2 py-1 text-xs font-semibold rounded absolute top-4 right-4 border-solid border-[1px] ${getBadgeColor(insight.badge)}`}>
              {insight.badge}
            </span>
            <div className="flex flex-col justify-end h-full">
              <div className="text-3xl font-semibold text-gray-700">
                {insight.value}
              </div>
              <div className="text-sm text-gray-600 mt-2">{insight.label}</div>
            </div>
          </div>)}
      </div>;
  };
  const ActionItem = ({
    headline,
    subtext,
    actionUrl,
    isOpen,
    setIsOpen,
    isComplete
  }) => {
    return <div onClick={() => setIsOpen(!isOpen)} className="space-y-4 hover:bg-neutral-50 duration-150 transition-all border-neutral-700 border-b-[1px] border-solid py-4 px-8">
        <div className="font-semibold text-sm text-light-100 cursor-pointer">
          {headline}
          <AnimatePresence mode="wait">
            <motion.span initial={{
            opacity: 0,
            x: -10
          }} animate={{
            opacity: 1,
            x: 0
          }} exit={{
            opacity: 0,
            x: -10
          }} transition={{
            duration: 0.2
          }} style={isComplete ? badgeStyle1 : badgeStyle2}>
              {isComplete ? "Completed" : "To-do"}
            </motion.span>
          </AnimatePresence>
        </div>
        <AnimatePresence mode="wait">
          {isOpen && <motion.div initial={{
          height: 0,
          opacity: 0
        }} animate={{
          height: "auto",
          opacity: 1
        }} exit={{
          height: 0,
          opacity: 0
        }} className="space-y-2">
              <div className="text-xs text-light-100 mt-2">{subtext}</div>
            </motion.div>}
        </AnimatePresence>
      </div>;
  };
  return /*@million jsx-skip*/<Dashboard_2 v0={<AnimatePresence layout>
          <motion.div initial={{
      opacity: 0,
      translateX: -10
    }} animate={{
      opacity: 1,
      translateX: 0
    }} exit={{
      opacity: 0,
      translateX: -10
    }} transition={{
      duration: 0.4
    }}>
            <div className="tracking-[-0.01em] leading-[38px] font-semibold mb-2 px-8">
              Welcome {userData.name ? userData.name.split(" ")[0] : "back"}!
            </div>
            <div className="text-base tracking-[-0.1px] leading-[24px] text-gray-50 mb-6 px-8">
              Check your matches, ask our AI any questions, or get insights on
              your search.
            </div>
          </motion.div>
        </AnimatePresence>} v1={<Card bgColor="bg-gray-100" icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M7 18C6.06812 18 5.60218 18 5.23463 17.8478C4.74458 17.6448 4.35523 17.2554 4.15224 16.7654C4 16.3978 4 15.9319 4 15C4 14.0681 4 13.6022 4.15224 13.2346C4.35523 12.7446 4.74458 12.3552 5.23463 12.1522C5.60218 12 6.06812 12 7 12C7.93188 12 8.39782 12 8.76537 12.1522C9.25542 12.3552 9.64477 12.7446 9.84776 13.2346C10 13.6022 10 14.0681 10 15C10 15.9319 10 16.3978 9.84776 16.7654C9.64477 17.2554 9.25542 17.6448 8.76537 17.8478C8.39782 18 7.93188 18 7 18Z" fill="#B9B9B9" />
                <path opacity="0.4" d="M17 12C16.0681 12 15.6022 12 15.2346 11.8478C14.7446 11.6448 14.3552 11.2554 14.1522 10.7654C14 10.3978 14 9.93188 14 9C14 8.06812 14 7.60218 14.1522 7.23463C14.3552 6.74458 14.7446 6.35523 15.2346 6.15224C15.6022 6 16.0681 6 17 6C17.9319 6 18.3978 6 18.7654 6.15224C19.2554 6.35523 19.6448 6.74458 19.8478 7.23463C20 7.60218 20 8.06812 20 9C20 9.93188 20 10.3978 19.8478 10.7654C19.6448 11.2554 19.2554 11.6448 18.7654 11.8478C18.3978 12 17.9319 12 17 12Z" fill="#B9B9B9" />
                <path d="M7 21V18" stroke="#B9B9B9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17 21V15" stroke="#B9B9B9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17 6V3" stroke="#B9B9B9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 9V3" stroke="#B9B9B9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 18C6.06812 18 5.60218 18 5.23463 17.8478C4.74458 17.6448 4.35523 17.2554 4.15224 16.7654C4 16.3978 4 15.9319 4 15C4 14.0681 4 13.6022 4.15224 13.2346C4.35523 12.7446 4.74458 12.3552 5.23463 12.1522C5.60218 12 6.06812 12 7 12C7.93188 12 8.39782 12 8.76537 12.1522C9.25542 12.3552 9.64477 12.7446 9.84776 13.2346C10 13.6022 10 14.0681 10 15C10 15.9319 10 16.3978 9.84776 16.7654C9.64477 17.2554 9.25542 17.6448 8.76537 17.8478C8.39782 18 7.93188 18 7 18Z" stroke="#B9B9B9" strokeWidth="1.5" />
                <path d="M17 12C16.0681 12 15.6022 12 15.2346 11.8478C14.7446 11.6448 14.3552 11.2554 14.1522 10.7654C14 10.3978 14 9.93188 14 9C14 8.06812 14 7.60218 14.1522 7.23463C14.3552 6.74458 14.7446 6.35523 15.2346 6.15224C15.6022 6 16.0681 6 17 6C17.9319 6 18.3978 6 18.7654 6.15224C19.2554 6.35523 19.6448 6.74458 19.8478 7.23463C20 7.60218 20 8.06812 20 9C20 9.93188 20 10.3978 19.8478 10.7654C19.6448 11.2554 19.2554 11.6448 18.7654 11.8478C18.3978 12 17.9319 12 17 12Z" stroke="#B9B9B9" strokeWidth="1.5" />
              </svg>} headline="Explore your matches" subline="Explore your recent matches" onClick={() => navigate("/jobs?mode=matches")} />} v2={<Card bgColor="bg-blue-100" icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M11.5294 22H10.4706C6.47751 22 4.48098 22 3.24049 20.8284C2 19.6569 2 17.7712 2 14V10C2 6.22876 2 4.34315 3.24049 3.17157C4.48098 2 6.47752 2 10.4706 2H15.9999C16.1596 2 16.3171 2.00936 16.4719 2.02755C13.9587 2.29136 12 4.41694 12 7C12 9.76142 14.2386 12 17 12C18.1256 12 19.1643 11.6281 19.9999 11.0004V14.4185C19.9982 17.9124 19.9532 19.701 18.7595 20.8284C17.519 22 15.5225 22 11.5294 22Z" fill="#788DD5" />
                <path d="M15 7.5C15 7.5 15.5 7.5 16 8.5C16 8.5 17.5882 6 19 5.5" stroke="#788DD5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M22 7C22 9.76142 19.7614 12 17 12C14.2386 12 12 9.76142 12 7C12 4.23858 14.2386 2 17 2C19.7614 2 22 4.23858 22 7Z" stroke="#788DD5" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M10.3995 2.02143C7.90102 1.91564 5.56417 2.20305 5.56417 2.20305C4.34532 2.2902 2.00951 2.97353 2.00953 6.96422C2.00955 10.921 1.98369 15.7989 2.00953 17.7436C2.00953 18.9316 2.74515 21.703 5.29131 21.8515C8.38615 22.032 13.9607 22.0704 16.5185 21.8515C17.2031 21.8129 19.4826 21.2754 19.7711 18.7953C20.07 16.226 20.0105 14.4403 20.0105 14.0153" stroke="#788DD5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 13H11" stroke="#788DD5" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M7 17H15" stroke="#788DD5" strokeWidth="1.5" strokeLinecap="round" />
              </svg>} headline="Manage your documents" subline="Create, update, or edit a resume" onClick={() => navigate("/resumes")} />} v3={<Card bgColor="bg-green-100" icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 15.5C2.89543 15.5 2 14.6046 2 13.5C2 12.3954 2.89543 11.5 4 11.5" stroke="#76C388" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20 15.5C21.1046 15.5 22 14.6046 22 13.5C22 12.3954 21.1046 11.5 20 11.5" stroke="#76C388" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 7V4" stroke="#76C388" strokeWidth="1.5" strokeLinejoin="round" />
                <path d="M17 7V4" stroke="#76C388" strokeWidth="1.5" strokeLinejoin="round" />
                <path d="M7 4C7.55228 4 8 3.55228 8 3C8 2.44772 7.55228 2 7 2C6.44772 2 6 2.44772 6 3C6 3.55228 6.44772 4 7 4Z" stroke="#76C388" strokeWidth="1.5" strokeLinejoin="round" />
                <path d="M17 4C17.5523 4 18 3.55228 18 3C18 2.44772 17.5523 2 17 2C16.4477 2 16 2.44772 16 3C16 3.55228 16.4477 4 17 4Z" stroke="#76C388" strokeWidth="1.5" strokeLinejoin="round" />
                <path opacity="0.4" d="M13.5 7H10.5C7.67157 7 6.25736 7 5.37868 7.90898C4.5 8.81796 4.5 10.2809 4.5 13.2069C4.5 16.1329 4.5 17.5958 5.37868 18.5048C6.25736 19.4138 7.67157 19.4138 10.5 19.4138H11.5253C12.3169 19.4138 12.5962 19.5773 13.1417 20.1713C13.745 20.8283 14.6791 21.705 15.5242 21.9091C16.7254 22.1994 16.8599 21.7979 16.5919 20.6531C16.5156 20.327 16.3252 19.8056 16.526 19.5018C16.6385 19.3316 16.8259 19.2898 17.2008 19.2061C17.7922 19.074 18.2798 18.8581 18.6213 18.5048C19.5 17.5958 19.5 16.1329 19.5 13.2069C19.5 10.2809 19.5 8.81796 18.6213 7.90898C17.7426 7 16.3284 7 13.5 7Z" fill="#76C388" />
                <path d="M13.5 7H10.5C7.67157 7 6.25736 7 5.37868 7.90898C4.5 8.81796 4.5 10.2809 4.5 13.2069C4.5 16.1329 4.5 17.5958 5.37868 18.5048C6.25736 19.4138 7.67157 19.4138 10.5 19.4138H11.5253C12.3169 19.4138 12.5962 19.5773 13.1417 20.1713C13.745 20.8283 14.6791 21.705 15.5242 21.9091C16.7254 22.1994 16.8599 21.7979 16.5919 20.6531C16.5156 20.327 16.3252 19.8056 16.526 19.5018C16.6385 19.3316 16.8259 19.2898 17.2008 19.2061C17.7922 19.074 18.2798 18.8581 18.6213 18.5048C19.5 17.5958 19.5 16.1329 19.5 13.2069C19.5 10.2809 19.5 8.81796 18.6213 7.90898C17.7426 7 16.3284 7 13.5 7Z" stroke="#76C388" strokeWidth="1.5" strokeLinejoin="round" />
                <path d="M9.5 15C10.0701 15.6072 10.9777 16 12 16C13.0223 16 13.9299 15.6072 14.5 15" stroke="#76C388" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.00896 11H9" stroke="#76C388" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.009 11H15" stroke="#76C388" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>} headline="Get advice from our AI" subline="Get instant personalized advice" onClick={() => navigate("/copilot")} />} v4={<Card bgColor="bg-red-100" icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 12C2 7.75736 2 5.63604 3.46447 4.31802C4.92893 3 7.28596 3 12 3C16.714 3 19.0711 3 20.5355 4.31802C22 5.63604 22 7.75736 22 12C22 16.2426 22 18.364 20.5355 19.682C19.0711 21 16.714 21 12 21C7.28596 21 4.92893 21 3.46447 19.682C2 18.364 2 16.2426 2 12Z" stroke="#D86C67" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M3.46447 4.31802C2 5.63604 2 7.75736 2 12C2 16.2426 2 18.364 3.46447 19.682C4.92893 21 7.28596 21 12 21C16.714 21 19.0711 21 20.5355 19.682C22 18.364 22 16.2426 22 12C22 7.75736 22 5.63604 20.5355 4.31802C19.0711 3 16.714 3 12 3C7.28596 3 4.92893 3 3.46447 4.31802ZM7.6 8H8.4C9.15425 8 9.53137 8 9.76569 8.23431C10 8.46863 10 8.84576 10 9.6V10.4C10 11.1542 10 11.5314 9.76569 11.7657C9.53137 12 9.15425 12 8.4 12H7.6C6.84575 12 6.46863 12 6.23431 11.7657C6 11.5314 6 11.1542 6 10.4V9.6C6 8.84575 6 8.46863 6.23431 8.23431C6.46863 8 6.84575 8 7.6 8Z" fill="#D86C67" />
                <path d="M8.4 8H7.6C6.84575 8 6.46863 8 6.23431 8.23431C6 8.46863 6 8.84575 6 9.6V10.4C6 11.1542 6 11.5314 6.23431 11.7657C6.46863 12 6.84575 12 7.6 12H8.4C9.15425 12 9.53137 12 9.76569 11.7657C10 11.5314 10 11.1542 10 10.4V9.6C10 8.84576 10 8.46863 9.76569 8.23431C9.53137 8 9.15425 8 8.4 8Z" stroke="#D86C67" strokeWidth="1.5" strokeLinejoin="round" />
                <path d="M6 16H10" stroke="#D86C67" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 8H18" stroke="#D86C67" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 12H18" stroke="#D86C67" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 16H18" stroke="#D86C67" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>} headline="Update your profile" subline="Keep your profile up to date" onClick={() => navigate("/settings")} />} v5={newestJobs.length > 0 ? [...new Map(newestJobs.map(job => [job.guid, job]) // Assuming `id` is the unique identifier
  ).values()].map(job => <motion.div key={job.objectID} initial={{
    opacity: 0
  }} animate={{
    opacity: 1
  }} className="mb-4 bg-base-white rounded-md transition-all duration-300 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] border-[1px] border-solid border-neutral-700 hover:border-neutral-300 flex flex-col overflow-hidden hover:scale-[0.99] flex-shrink-0">
                <div className="h-[4px] w-full rounded-t-md" style={{
      backgroundColor: "#5CD193" // Example status color
    }} />
                <div className="flex justify-between items-center px-4">
                  <h3 className="text-lg font-semibold truncate max-w-full" style={{
        maxWidth: "240px"
      }}>
                    {job.title}
                  </h3>
                </div>
                <div className="px-4 pb-2 text-sm">
                  {job.companyName && <span>{job.companyName}</span>}
                  {job.companyName && job.seniority && job.seniority[0] && <span> • </span>}
                  {job.seniority && job.seniority[0] && <span>{job.seniority[0]}</span>}
                  {job.maxSalary && <span> • ${job.maxSalary.toLocaleString()}</span>}
                </div>
                <div className="px-4 pb-4 flex gap-2">
                  {job.skills && job.skills.length > 0 && job.categories && job.categories.length > 0 && <div className={`rounded-8xs ${getSkillColors(job.categories[0]).bgColor} flex text-sm flex-row py-0.5 px-2 items-center justify-center gap-[6px]`} style={{
        color: getSkillColors(job.categories[0]).textColor
      }}>
                        {job.skills[0]}
                      </div>}
                  {job.type && <div className="rounded-8xs flex flex-row py-0.5 px-2 text-smi items-center justify-center gap-[6px]" style={{
        backgroundColor: getTypeColors(job.type).bgColor,
        color: getTypeColors(job.type).textColor
      }}>
                      {job.type}
                    </div>}
                </div>
                <div className="px-4 pb-4">
                  <button onClick={() => navigate(`/jobs?job=${job.id}`)} className="w-full bg-white border-[1px] border-solid border-neutral-700 hover:bg-neutral-50 transition-all duration-300 transform-gpu text-gray-7001 font-semibold py-3 px-4 rounded cursor-pointer">
                    View Job
                  </button>
                </div>
              </motion.div>) : <motion.div animate={{
    rotate: 360
  }} transition={{
    repeat: Infinity,
    duration: 1
  }} className="flex items-center justify-center">
              <svg className="w-4 h-4 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <circle className="opacity-25" cx="12" cy="12" r="10" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </motion.div>} v6={() => setIsJobOpen(!isJobOpen)} v7={<AnimatePresence mode="wait">
                    <motion.span initial={{
      opacity: 0,
      x: -10
    }} animate={{
      opacity: 1,
      x: 0
    }} exit={{
      opacity: 0,
      x: -10
    }} transition={{
      duration: 0.2
    }} style={badgeStyle1}>
                      {"Completed"}
                    </motion.span>
                  </AnimatePresence>} v8={<AnimatePresence>
                  {isJobOpen && <motion.div initial={{
      height: 0,
      opacity: 0
    }} animate={{
      height: "auto",
      opacity: 1
    }} exit={{
      height: 0,
      opacity: 0
    }} className="space-y-2">
                      <div className="text-xs text-light-100 mt-2">
                        Explore our offerings in the Find Jobs tab.
                      </div>
                    </motion.div>}
                </AnimatePresence>} v9={() => setIsAppOpen(!isAppOpen)} v10={<AnimatePresence mode="wait">
                    <motion.span initial={{
      opacity: 0,
      x: -10
    }} animate={{
      opacity: 1,
      x: 0
    }} exit={{
      opacity: 0,
      x: -10
    }} transition={{
      duration: 0.2
    }} style={userData?.saved?.length ? badgeStyle1 : badgeStyle2}>
                      {userData?.saved?.length ? "Completed" : "To-do"}
                    </motion.span>
                  </AnimatePresence>} v11={<AnimatePresence>
                  {isAppOpen && <motion.div initial={{
      height: 0,
      opacity: 0
    }} animate={{
      height: "auto",
      opacity: 1
    }} exit={{
      height: 0,
      opacity: 0
    }} className="space-y-2">
                      <div className="text-xs text-light-100 mt-2">
                        Save a job to personalize our recommendations.
                      </div>
                    </motion.div>}
                </AnimatePresence>} v12={() => setIsSubmitOpen(!isSubmitOpen)} v13={<AnimatePresence mode="wait">
                    <motion.span initial={{
      opacity: 0,
      x: -10
    }} animate={{
      opacity: 1,
      x: 0
    }} exit={{
      opacity: 0,
      x: -10
    }} transition={{
      duration: 0.2
    }} style={userData?.applied?.length ? badgeStyle1 : badgeStyle2}>
                      {userData?.applied?.length ? "Completed" : "To-do"}
                    </motion.span>
                  </AnimatePresence>} v14={<AnimatePresence>
                  {isSubmitOpen && <motion.div initial={{
      height: 0,
      opacity: 0
    }} animate={{
      height: "auto",
      opacity: 1
    }} exit={{
      height: 0,
      opacity: 0
    }} className="space-y-2">
                      <div className="text-xs text-light-100 mt-2">
                        Submit your first application and start your search!
                      </div>
                    </motion.div>}
                </AnimatePresence>} v15={<JobSearchInsights />} v16={<AddNewAccountModal show={showAddNewModal} onClose={() => setShowAddNewModal(false)} />} />;
};
export default Dashboard;