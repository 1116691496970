import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import JobContainer from "./JobContainer";
import { useUserData } from "../UserDataContext";
import algoliasearch from "algoliasearch";
import { useJobs } from "../jobsContext";
const JobsPreview_1 = _compiledBlock(_props => _props.v0, {
  name: "JobsPreview_1",
  portals: ["v0"]
});
const JobsPreview = ({
  step,
  searchTerm
}) => {
  const {
    userData
  } = useUserData();
  const {
    selectedJob,
    setSelectedJob
  } = useJobs();
  const [filteredJobs, setFilteredJobs] = useState([]);
  const scrollContainerRef = useRef(null);
  const bottomRef = useRef(null);
  const scrollInterval = 1;
  const formatDate = timestamp => {
    const date = new Date(timestamp * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  const searchClient = algoliasearch("1AD8YCD11H", "72b766934c217c2c483592d02639cd46");
  const index = searchClient.initIndex("JOBS_pubDate_desc");
  const getSkillColors = type => {
    switch (type ? type : "") {
      case "engineering":
        return {
          textColor: "#16a34a",
          bgColor: "bg-green-50"
        };
      case "sales":
        return {
          textColor: "#2563eb",
          bgColor: "bg-blue-50"
        };
      case "marketing":
        return {
          textColor: "#9333ea",
          bgColor: "bg-purple-50"
        };
      case "product":
        return {
          textColor: "#db2777",
          bgColor: "bg-pink-50"
        };
      case "design":
        return {
          textColor: "#dc2626",
          bgColor: "bg-red-50"
        };
      case "support":
        return {
          textColor: "#ea580c",
          bgColor: "bg-orange-50"
        };
      case "operations":
        return {
          textColor: "#4f46e5",
          bgColor: "bg-indigo-50"
        };
      case "data":
        return {
          textColor: "#ca8a04",
          bgColor: "bg-yellow-50"
        };
      case "finance":
        return {
          textColor: "#0d9488",
          bgColor: "bg-teal-50"
        };
      case "management":
        return {
          textColor: "#4b5563",
          bgColor: "bg-gray-100"
        };
      default:
        return {
          textColor: "#808080",
          bgColor: "#F0F0F0"
        };
    }
  };
  const getTypeColors = type => {
    switch (type ? type : "") {
      case "Full Time":
        return {
          textColor: "#4C6EAF",
          bgColor: "#E6EEF6"
        };
      case "Part Time":
        return {
          textColor: "#6BA292",
          bgColor: "#E6F4F1"
        };
      case "Contractor":
        return {
          textColor: "#EEBB39",
          bgColor: "#FFF6E6"
        };
      case "Temporary":
        return {
          textColor: "#A267AC",
          bgColor: "#F4E6F8"
        };
      case "Intern":
        return {
          textColor: "#D2691E",
          bgColor: "#F4EAE6"
        };
      case "Volunteer":
        return {
          textColor: "#C8385A",
          bgColor: "#FCE6EF"
        };
      case "Other":
        return {
          textColor: "#8B4513",
          bgColor: "#FFF5E6"
        };
      default:
        return {
          textColor: "#808080",
          bgColor: "#F0F0F0"
        };
    }
  };
  const generateTags = job => {
    const tags = [];
    if (job.type && job.type != "Unknown") {
      tags.push({
        text: job.type,
        ...getTypeColors(job.type),
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.57956 8.62505C2.50886 8.03528 2.47351 7.74039 2.52323 7.499C2.6651 6.81015 3.27111 6.25159 4.07871 6.06529C4.36172 6 4.717 6 5.42757 6H18.5724C19.283 6 19.6383 6 19.9213 6.06529C20.7289 6.25159 21.3349 6.81015 21.4768 7.499C21.5265 7.74039 21.4911 8.03528 21.4204 8.62505C21.2584 9.97669 20.4991 10.716 19.0512 11.1423L14.88 12.3703C13.4541 12.7901 12.7411 13 12 13C11.2589 13 10.5459 12.7901 9.11996 12.3703L4.94882 11.1423C3.50094 10.7161 2.7416 9.97669 2.57956 8.62505Z" stroke={getTypeColors(job.type)["textColor"]} strokeWidth="1.5" />
            <path d="M3.46283 10.5L3.26658 12.7757C2.91481 16.855 2.73892 18.8947 3.86734 20.1974C4.99576 21.5 6.93851 21.5 10.824 21.5H13.176C17.0615 21.5 19.0042 21.5 20.1327 20.1974C21.2611 18.8947 21.0852 16.855 20.7334 12.7757L20.5372 10.5" stroke={getTypeColors(job.type)["textColor"]} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.5 5.5L15.4227 5.23509C15.0377 3.91505 14.8452 3.25503 14.3869 2.87752C13.9286 2.5 13.3199 2.5 12.1023 2.5H11.8977C10.6801 2.5 10.0714 2.5 9.61309 2.87752C9.15478 3.25503 8.96228 3.91505 8.57727 5.23509L8.5 5.5" stroke={getTypeColors(job.type)["textColor"]} strokeWidth="1.5" />
          </svg>
      });
    }
    if (job.skills && job.categories) {
      tags.push({
        text: job.skills[0],
        ...getSkillColors(job.categories[0])
      });
    }
    if (new Date().getTime() / 1000 - job.pubDate < 7 * 24 * 60 * 60) {
      tags.push({
        text: "New",
        textColor: "#5BC68D",
        bgColor: "#E6FBF0",
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5143 2.09327C11.8265 1.96891 12.1735 1.96891 12.4857 2.09327C13.4921 2.49406 13.6887 4.03744 14.8762 4.12336C15.7124 4.18386 16.533 3.48677 17.3721 3.69574C17.7105 3.78003 18.0028 3.99579 18.186 4.29657C18.7472 5.21824 18.0229 6.57292 18.9383 7.33768C19.5743 7.86877 20.6251 7.80004 21.178 8.4511C21.4108 8.72534 21.5252 9.08303 21.4953 9.4437C21.4068 10.5166 20.0389 11.1876 20.3395 12.3439C20.5475 13.1443 21.4253 13.707 21.4953 14.5563C21.5252 14.917 21.4108 15.2747 21.178 15.5489C20.4832 16.3669 18.9808 16.0975 18.5476 17.2062C18.2434 17.9844 18.634 18.9677 18.186 19.7034C18.0028 20.0042 17.7105 20.22 17.3721 20.3043C16.3302 20.5637 15.2727 19.4445 14.2701 20.0758C13.5543 20.5264 13.2978 21.5835 12.4857 21.9067C12.1735 22.0311 11.8265 22.0311 11.5143 21.9067C10.7022 21.5835 10.4457 20.5264 9.72989 20.0758C8.73971 19.4524 7.65213 20.5593 6.62791 20.3043C6.28947 20.22 5.9972 20.0042 5.81405 19.7034C5.25286 18.7818 5.97704 17.427 5.0617 16.6623C4.42582 16.1312 3.37494 16.2 2.82204 15.5489C2.58921 15.2747 2.47484 14.917 2.50465 14.5563C2.57485 13.707 3.4524 13.1443 3.6605 12.3439C3.95808 11.1997 2.59204 10.5009 2.50465 9.4437C2.47484 9.08303 2.58921 8.72534 2.82204 8.4511C3.51676 7.63284 5.01899 7.90253 5.45238 6.79383C5.75662 6.0156 5.36608 5.03227 5.81405 4.29657C5.9972 3.99579 6.28947 3.78003 6.62791 3.69574C7.46705 3.48677 8.28757 4.18387 9.12378 4.12336C10.3113 4.03746 10.5079 2.49406 11.5143 2.09327Z" stroke="#5BC68D" strokeWidth="1.5" strokeLinejoin="round" />
            <path d="M9 13.3333C9 13.3333 9.875 13.3333 10.75 15C10.75 15 13.5294 10.8333 16 10" stroke="#5BC68D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
      });
    }
    return tags.slice(0, 3);
  };
  useEffect(() => {
    const performSearch = async () => {
      let searchValue = "";
      let filterString = "";
      switch (step) {
        case 1:
          searchValue = "";
          break;
        case 2:
          searchValue = "";
          filterString = 'locationRestrictions:"United States"';
          break;
        case 3:
          searchValue = userData.keywords ? userData.keywords["title"] : "";
          filterString = 'locationRestrictions:"United States"';
          break;
        default:
          searchValue = "";
      }
      const results = await index.search(searchValue, {
        filters: filterString,
        hitsPerPage: 100
      });
      const filteredHits = results.hits.filter(hit => {
        if (hit.applyNowLink && typeof hit.applyNowLink === "string") {
          return hit.applyNowLink;
        }
        return true;
      });
      setSelectedJob(filteredHits[0]);
      setFilteredJobs(filteredHits);
    };
    performSearch();
  }, [step, searchTerm, userData]);
  useEffect(() => {
    const scroll = () => {
      if (scrollContainerRef.current) {
        const container = scrollContainerRef.current;
        // Increment the scrollTop by a small fixed value for smooth and consistent scrolling
        container.scrollTop += 1;
      }
    };
    const intervalId = setInterval(scroll, 40); // Adjust the interval time to control the speed

    return () => clearInterval(intervalId);
  }, []);
  return /*@million jsx-skip*/<JobsPreview_1 v0={<div className="h-full overflow-y-auto w-3/4 scrollbar-hide" ref={scrollContainerRef}>
      <motion.div className="self-stretch rounded-8xs w-full w-stretch overflow-hidden shrink-0 flex space-y-2 flex-col items-start justify-start scrollbar-hide">
        <AnimatePresence>
          {filteredJobs.length > 0 && filteredJobs.map((job, index) => <motion.div key={job.guid} layout="position" initial={{
          opacity: 0
        }} animate={{
          opacity: 1,
          y: 0
        }} exit={{
          opacity: 0
        }} transition={{
          duration: 0.3
        }} className="self-stretch flex-1 relative overflow-hidden w-full max-h-full object-cover">
                <JobContainer onSelect={() => {
            setSelectedJob(job);
          }} position={job.title} pubDate={formatDate(job.pubDate)} company={job.companyName} categories={job.categories} type={job.type} skills={job.skills} seniority={job.seniority} const location={selectedJob.locationRestrictions ? Array.isArray(selectedJob.locationRestrictions) ? selectedJob.locationRestrictions.join(", ") : selectedJob.locationRestrictions : "Remote"} tags={generateTags(job)} selected={false} guid={job.guid ? job.guid : index} maxSalary={job.maxSalary ? `${job.maxSalary.toLocaleString()}` : ""} />
              </motion.div>)}
          <div ref={bottomRef}></div>
        </AnimatePresence>
      </motion.div>
    </div>} />;
};
export default JobsPreview;