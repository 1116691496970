import { useState, useEffect } from "react";
import cn from "classnames";
const Input = ({
  className,
  inputType = "text",
  onChange = () => false,
  formValue,
  ...otherProps
}) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (typeof formValue !== "string") return;
    if (formValue !== value) setValue(formValue);
  }, [formValue]);
  const changeValue = val => {
    if (inputType === "number" && Number.isNaN(Number(val))) return;
    onChange(val);
    setValue(val);
  };
  const props = {
    className: cn("font-text-l-regular w-full text-mini !outline-none autofill:shadow-[0_0_0_30px_white_inset] transition-all duration-300 focus:ring focus:ring-green-50 focus:border-mediumseagreen-100 bg-base-white self-stretch rounded-md box-border h-full shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-start border border-solid border-neutral-700", {
      "pt-2.5": inputType === "textarea"
    }),
    type: "text",
    value,
    onChange: ({
      target
    }) => changeValue(target.value),
    ...otherProps
  };
  const renderInput = () => <div className="flex relative flex-1 items-center">
      {inputType === "textarea" ? <textarea {...props} /> : <input {...props} />}
    </div>;
  return <div className={cn("group flex z-0 h-11 border-transparent relative", {
    "!h-32 !w-full": inputType === "textarea"
  }, className)}>
      {renderInput()}
    </div>;
};
export { Input };