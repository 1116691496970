import React, { useState, useEffect, useRef, useMemo } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useUserData } from "../UserDataContext";
import AccountCreateModal from "./AccountCreateModal";
import PlanStatusSection from "./PlanStatusSection";
import SideMenuItem from "./SideMenuItem";
import SignOutButton from "./SignOutButton";
import SignOut from "./SignOut";
import { usePayment } from "../PaymentContext";
import { useJobs } from "../jobsContext";
import ShareModal from "./ShareModal";
import { AnimatePresence, motion, AnimateSharedLayout } from "framer-motion";
import SideMenuDemo from "./SideMenuDemo";
import AppModal from "./AppModal";
import NotificationStack from "./NotificationStack";
const SideMenuGeneral = props => {
  const {
    setScreen,
    setShowFavoritesOnly,
    setShowRemoteOnly,
    setShowUSOnly,
    appliedJobs
  } = useJobs();
  const location = useLocation();
  const [isAppModalOpen, setIsAppModalOpen] = useState(false);
  const [htmlId, setHtmlId] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [feedbackVal, setFeedbackVal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("Dashboard");
  const [hoveredItem, setHoveredItem] = useState(null);
  const itemRefs = useRef({});
  const navigate = useNavigate();
  const {
    userData,
    currentLink,
    user,
    setUserData,
    isAuthenticated
  } = useUserData();
  const uid = user?.uid ?? null;
  const [res, setRes] = useState(false);
  const {
    openUpgrade,
    currentPlan,
    appsUsed,
    setAppsUsed,
    bonusLeft,
    pendingApps,
    paymentsEnabled
  } = usePayment();
  useEffect(() => {
    if (appliedJobs && Object.keys(appliedJobs).length > planData.used) {
      setPlanData({
        ...planData,
        used: Object.keys(appliedJobs).length
      });
      setAppsUsed(Object.values(appliedJobs).filter(job => job.status === "submitted").length);
    }
  }, [appliedJobs]);
  useEffect(() => {
    // Function to determine the active item based on the current path
    const determineActiveItem = path => {
      switch (path) {
        case "/dashboard":
          return "Dashboard";
        case "/resumes":
          return "Resume Creator";
        case "/jobs":
          return "Find Jobs";
        case "/tracker":
          return "Job Tracker";
        case "/copilot":
          return "AI Advisor";
        case "/settings":
          return "Your Account";
        default:
          return "";
      }
    };

    // Update active item based on current pathname
    setActiveItem(determineActiveItem(location.pathname));
  }, [location.pathname]);
  const [transparentStates, setTransparentStates] = useState({
    dashboard: "rgba(230, 251, 240, 0)",
    jobs: "rgba(243, 249, 254, 0)",
    favorites: "rgba(243, 249, 254, 0)",
    tracker: "rgba(243, 249, 254, 0)",
    resumes: "rgba(243, 249, 254, 0)",
    copilot: "rgba(243, 249, 254, 0)",
    interviewer: "rgba(243, 249, 254, 0)",
    demo: "rgba(243, 249, 254, 0)"
  });
  const onChange = value => {
    setFeedbackVal(value);
  };
  const handleFeedbackSubmit = feedbackVal => {
    setFeedback(feedbackVal);
    const newFeedback = [...(userData.feedback || []), feedbackVal];
    setUserData({
      ...userData,
      feedback: newFeedback
    });
    console.log(userData);
    setFeedbackVal("");
    setFeedbackOpen(false);
  };
  const [planData, setPlanData] = useState({
    used: -1,
    count: 0,
    name: "",
    barWidth: 0
  });
  const [collapsed, setCollapsed] = useState(true);
  const textVariants = {
    collapsed: {
      opacity: 0,
      x: -10
    },
    expanded: {
      opacity: 1,
      x: 0
    },
    exit: {
      opacity: 0,
      x: -10
    }
  };
  const planVariants = {
    collapsed: {
      opacity: 0,
      x: 0
    },
    expanded: {
      opacity: 1,
      x: 0
    },
    exit: {
      opacity: 0,
      x: 0
    }
  };
  const logoVariants = {
    collapsed: {
      opacity: 1,
      x: 10
    },
    expanded: {
      opacity: 1,
      x: 0
    },
    exit: {
      opacity: 1,
      x: 10
    }
  };
  const logoVariants2 = {
    collapsed: {
      opacity: 1,
      x: -10,
      y: -2
    },
    expanded: {
      opacity: 1,
      x: 0,
      y: -2
    },
    exit: {
      opacity: 1,
      x: -10,
      y: -2
    }
  };
  const fullPath = window.location.pathname;
  const pathComponents = fullPath.split("/");
  const currentPath = pathComponents.filter(Boolean)[0];
  useEffect(() => {
    updateCounter();
    updateTransparentStates();
    //console.log(currentPath);
  }, [currentPlan, appsUsed, currentPath, pendingApps]);
  const handleSignOut = async () => {
    await signOut(auth);
    window.location.href = `${currentLink}/login`;
  };
  const handleButtonClick = event => {
    event.stopPropagation();
    window.open("https://calendly.com/wranglejobs/user-interview", "_blank");
  };
  const updateCounter = () => {
    if (!userData) return;
    let count, name;
    switch (currentPlan) {
      case 2:
        count = appsUsed > 50 ? appsUsed + bonusLeft : 50 + bonusLeft;
        name = "Boosted";
        break;
      case 3:
        count = appsUsed > 250 ? appsUsed + bonusLeft : 250 + bonusLeft;
        name = "Premium";
        break;
      case 4:
        count = 250;
        name = "Admin";
        break;
      default:
        count = appsUsed > 15 ? appsUsed + bonusLeft : 15 + bonusLeft;
        name = "Free";
    }
    if (count < appsUsed + (pendingApps && pendingApps > 0 ? userData.pendingApps : 0)) {
      count = appsUsed + (pendingApps && pendingApps > 0 ? pendingApps : 0);
    }
    setPlanData(prev => ({
      ...prev,
      used: appsUsed + (pendingApps && pendingApps > 0 ? pendingApps : 0),
      count,
      name,
      barWidth: appsUsed / count * 92.5,
      pendingBarWidth: (pendingApps && pendingApps > 0 ? pendingApps : 0) / count * 95
    }));
  };
  const sideMenuVariants = {
    collapsed: {
      width: "79px"
    },
    expanded: {
      width: "290px"
    }
  };
  const updateTransparentStates = () => {
    setTransparentStates(prev => ({
      ...prev,
      dashboard: currentPath === "dashboard" ? "rgba(230, 251, 240, 1)" : "rgba(243, 249, 254, 0)",
      jobs: currentPath === "jobs" ? "rgba(230, 251, 240, 1)" : "rgba(243, 249, 254, 0)",
      tracker: currentPath === "tracker" ? "rgba(230, 251, 240, 1)" : "rgba(243, 249, 254, 0)",
      demo: currentPath === "demo" ? "rgba(230, 251, 240, 1)" : "rgba(243, 249, 254, 0)",
      resumes: currentPath === "resumes" || currentPath === "resume-builder" ? "rgba(230, 251, 240, 1)" : "rgba(243, 249, 254, 0)",
      copilot: currentPath === "copilot" ? "rgba(230, 251, 240, 1)" : "rgba(243, 249, 254, 0)",
      interviewer: currentPath === "interviewer" ? "rgba(230, 251, 240, 1)" : "rgba(243, 249, 254, 0)"
    }));
  };
  const handleNavigation = (path, label) => {
    setShowUSOnly(false);
    setShowFavoritesOnly(false);
    setShowRemoteOnly(false);
    setScreen(path);
    navigate(path);
    setActiveItem(label);
  };
  const [lastPlace, setLastPlace] = useState({
    y: 0,
    height: 0,
    width: 0,
    opacity: 0
  });
  const [shouldFadeOut, setShouldFadeOut] = useState(false);
  const [lastHoverUpdateTime, setLastHoverUpdateTime] = useState(Date.now());
  useEffect(() => {
    if (collapsed) {
      setShouldFadeOut(true);
    } else if (!hoveredItem) {
      const timeoutId = setTimeout(() => {
        const timeSinceLastUpdate = Date.now() - lastHoverUpdateTime;
        if (timeSinceLastUpdate >= 400 && !hoveredItem) {
          setShouldFadeOut(true);
        }
      }, 400);
      return () => clearTimeout(timeoutId);
    } else {
      setShouldFadeOut(false);
      setLastHoverUpdateTime(Date.now());
    }
  }, [hoveredItem, collapsed]);

  // Update lastPlace when an item is hovered
  useEffect(() => {
    if (hoveredItem && itemRefs.current[hoveredItem]) {
      const {
        offsetTop,
        offsetHeight
      } = itemRefs.current[hoveredItem];
      setLastPlace({
        y: offsetTop - offsetHeight / 3,
        height: offsetHeight - 2,
        width: collapsed ? 78 : 289,
        opacity: 1
      });
    }
  }, [hoveredItem, collapsed]);
  useEffect(() => {
    if (collapsed) {
      setHoveredItem(null);
    }
  }, [collapsed]);
  const blockStyle = useMemo(() => {
    if (hoveredItem) {
      return lastPlace;
    }
    return shouldFadeOut ? {
      ...lastPlace,
      opacity: 0
    } : lastPlace;
  }, [hoveredItem, activeItem, shouldFadeOut, lastPlace, collapsed]);
  const blockHighlightStyle = useMemo(() => {
    const item = activeItem;
    if (item && itemRefs.current[item]) {
      const {
        offsetTop,
        offsetHeight
      } = itemRefs.current[item];
      const style = {
        y: offsetTop - offsetHeight / 3,
        height: offsetHeight - 2,
        width: 4,
        opacity: 1
      };
      return style;
    }
    return {
      height: 0,
      left: -4,
      width: 0,
      opacity: 0
    };
  }, [activeItem, collapsed]);
  return <motion.div className="self-stretch h-full bg-base-white box-border shrink-0 flex flex-col pt-4 px-0 pb-4 items-center justify-start gap-[16px] text-left text-base text-gray-50 font-text-l-regular border-r-[1px] border-solid border-neutral-700 z-10" onMouseEnter={() => {
    setCollapsed(false);
    setFeedbackOpen(false);
    setFeedback(null);
  }} onMouseLeave={() => {
    setCollapsed(true);
    setFeedbackOpen(false);
  }} variants={sideMenuVariants} animate={collapsed ? "collapsed" : "expanded"} transition={{
    duration: 0.4,
    ease: [0.25, 0.1, 0.25, 1]
  }}>
      <motion.div className="absolute left-0 bg-transparent flex justify-center items-center" initial={blockStyle} style={{
      pointerEvents: "none"
    }} animate={{
      y: blockStyle.y,
      height: blockStyle.height,
      width: blockStyle.width,
      opacity: blockStyle.opacity
    }} transition={{
      duration: 0.4,
      ease: [0.25, 0.1, 0.25, 1]
    }}>
        <div className={`rounded-md transition-all duration-300 z-5 h-full w-[calc(100%-1.5rem)] ${hoveredItem == "Sign Out" ? isAuthenticated ? "bg-red-100/75" : "bg-green-100/75" : "bg-gray-neutral-100/60"}`}></div>
      </motion.div>

      <motion.div className="absolute bg-mediumseagreen-200 left-0" style={{
      pointerEvents: "none"
    }} animate={{
      y: blockHighlightStyle.y,
      height: blockHighlightStyle.height,
      width: blockHighlightStyle.width
    }} transition={{
      duration: 0.4,
      ease: [0.25, 0.1, 0.25, 1]
    }} />

      <div className={`self-stretch flex flex-col h-14 min-h-[14px] py-0 ${collapsed ? "px-6" : "px-8"} items-start justify-start`}>
        <div className={`relative h-[50px] ${collapsed ? "mt-2" : "mt-1 mr-1"}`}>
          <motion.img initial={false} variants={logoVariants2} animate={collapsed ? "collapsed" : "expanded"} transition={{
          duration: 0.3
        }} className={`absolute top-0 ${collapsed ? "hidden" : "block"} max-w-[146px] h-[46px] shrink-0`} alt="" src="/logo-text-regular.svg" />
          <motion.img initial={false} variants={logoVariants} animate={collapsed ? "expanded" : "collapsed"} transition={{
          duration: 0.3
        }} className={`absolute top-0 ${collapsed ? "block" : "hidden"} max-w-[30px] h-[35px] shrink-0`} alt="" src="/logo.svg" />
        </div>
      </div>
      <div className="w-full self-stretch flex flex-col flex-grow overflow-y-auto">
        <motion.div className="self-stretch shrink-0 flex flex-row py-2 px-7 box-border items-center justify-start mt-4" variants={textVariants} animate={collapsed ? "collapsed" : "expanded"} transition={{
        duration: 0.3
      }} exit="exit">
          <div onClick={() => {
          setUserData({
            ...userData,
            dismissed: false
          });
        }} className="tracking-[-0.1px] leading-[24px] font-medium text-mini">
            General
          </div>
        </motion.div>
        <div>
          <SideMenuItem ref={el => itemRefs.current["Dashboard"] = el} icon="/home.svg" label="Dashboard" onClick={isAuthenticated ? () => handleNavigation("/dashboard", "Dashboard") : () => setOpen(true)} isActive={currentPath === "dashboard"} backgroundColor={transparentStates.dashboard} collapsed={collapsed} tag={!isAuthenticated ? "Account required" : undefined} onHover={() => setHoveredItem("Dashboard")} onLeave={() => setHoveredItem(null)} />
        </div>
        <SideMenuItem ref={el => itemRefs.current["Resume Creator"] = el} icon="/resume.svg" label="Resume Creator" onClick={() => handleNavigation("/resumes", "Resume Creator")} isActive={currentPath === "resumes" || currentPath === "resume-builder"} backgroundColor={transparentStates.resumes} collapsed={collapsed} onHover={() => setHoveredItem("Resume Creator")} onLeave={() => setHoveredItem(null)} />
        {/* <SideMenuItem
            icon="/interview.svg"
            label="Interviewer"
            onClick={() => handleNavigation("/interviewer")}
            isActive={currentPath === "interviewer"}
            backgroundColor={transparentStates.interviewer}
            collapsed={collapsed}
            isNew={true}
          /> */}
        <motion.div className="self-stretch shrink-0 flex flex-row py-2 px-7 box-border items-center justify-start mt-4" variants={textVariants} animate={collapsed ? "collapsed" : "expanded"} transition={{
        duration: 0.3
      }} exit="exit">
          <div className="flex-1 relative tracking-[-0.1px] leading-[24px] font-medium text-mini">
            Jobs
          </div>
        </motion.div>
        <SideMenuItem ref={el => itemRefs.current["Find Jobs"] = el} icon="/earth.svg" label="Find Jobs" onClick={() => {
        handleNavigation("/jobs", "Find Jobs");
        setScreen("jobs");
      }} isActive={currentPath === "jobs"} backgroundColor={transparentStates.jobs} collapsed={collapsed} onHover={() => setHoveredItem("Find Jobs")} onLeave={() => setHoveredItem(null)} />
        <SideMenuItem ref={el => itemRefs.current["Job Tracker"] = el} icon="/check-done-02.svg" label="Job Tracker" onClick={() => {
        handleNavigation("/tracker", "Job Tracker");
        setScreen("tracker");
      }} isActive={currentPath === "tracker"} onHover={() => setHoveredItem("Job Tracker")} onLeave={() => setHoveredItem(null)} backgroundColor={transparentStates.tracker} collapsed={collapsed} />
        <SideMenuItem ref={el => itemRefs.current["AI Advisor"] = el} icon="/coach.svg" label="AI Advisor" onClick={() => handleNavigation("/copilot", "AI Advisor")} isActive={currentPath === "copilot"} backgroundColor={transparentStates.copilot} collapsed={collapsed} onHover={() => setHoveredItem("AI Advisor")} onLeave={() => setHoveredItem(null)} />
        {(uid ? uid == "5ZbUc6Gv2COwZOjvHpU1i9tpNxm2" || uid == "BTGsPigEK7cIKrHxdCuGCnJ4HSl2" : false) && <SideMenuDemo icon="/check-done-02.svg" label="Wrangle Demo" onClick={() => {
        handleNavigation("/demo");
        setScreen("demo");
      }} isActive={currentPath === "demo"} backgroundColor={transparentStates.demo} collapsed={collapsed} />}
        {(uid ? uid == "5ZbUc6Gv2COwZOjvHpU1i9tpNxm" || uid == "BTGsPigEK7cIKrHxdCuGCnJ4HSl2" : false) && <SideMenuItem icon="/workday.svg" label="Workday Demo" onClick={() => {
        const id = prompt("Enter Workday job ID");
        setHtmlId(id);
        setIsAppModalOpen(true);
      }} isActive={false} backgroundColor={transparentStates.indeed} collapsed={collapsed} />}
        {(uid ? uid == "5ZbUc6Gv2COwZOjvHpU1i9tpNxm2" || uid == "BTGsPigEK7cIKrHxdCuGCnJ4HSl2" || uid == "iBcjyPbUmscttGKlrBEwM6c11km2" : false) && <SideMenuDemo icon="/check-done-02.svg" label="Beast Analytics" onClick={() => {
        handleNavigation("/analytics");
        setScreen("analytics");
      }} isActive={currentPath === "demo"} backgroundColor={transparentStates.demo} collapsed={collapsed} />}
      </div>
      {/*
       <motion.div
        animate={collapsed ? "collapsed" : "expanded"}
        transition={{ duration: 0.3 }}
        exit="collapsed"
        className="w-full flex justify-center"
       >
        <button
          onClick={() => setIsModalOpen(true)}
          className={`cursor-pointer py-2.5 px-2.5 self-stretch rounded-lg shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex items-center justify-center ${
            collapsed ? "w-auto" : "w-[200px]"
          } bg-mediumseagreen-200 hover:bg-green-300 transition-all duration-300`}
        >
          <div className="flex items-center">
            <motion.svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="flex-shrink-0"
            >
              <path
                d="M11.1002 3C7.45057 3.00657 5.53942 3.09617 4.31806 4.31754C3 5.63559 3 7.75698 3 11.9997C3 16.2425 3 18.3639 4.31806 19.6819C5.63611 21 7.7575 21 12.0003 21C16.243 21 18.3644 21 19.6825 19.6819C20.9038 18.4606 20.9934 16.5494 21 12.8998"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.9995 6.02529L20 6.02276C16.2634 6.01331 14.3951 6.00858 13.0817 6.95265C12.6452 7.26638 12.2622 7.64844 11.9474 8.08412C11 9.39515 11 11.2634 11 15M20.9995 6.02529C21.0062 5.86266 20.9481 5.69905 20.8251 5.55333C20.0599 4.64686 18.0711 3 18.0711 3M20.9995 6.02529C20.9934 6.17111 20.9352 6.31615 20.8249 6.44681C20.0596 7.3531 18.0711 9 18.0711 9"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </motion.svg>
            {!collapsed && (
              <motion.span
                className="ml-4 font-semibold text-white"
                initial={{ opacity: 0, translateX: 0 }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: 0 }}
                transition={{ duration: 0.3 }}
              >
                Share
              </motion.span>
            )}
          </div>
        </button>
       </motion.div>
            */}
      <AnimatePresence mode="wait">
        <motion.div initial={{
        opacity: 0,
        x: -10
      }} animate={{
        opacity: 1,
        x: 0
      }} exit={{
        opacity: 0,
        x: -10
      }} transition={{
        duration: 0.4,
        ease: [0.25, 0.8, 0.25, 1]
      }} className="w-full">
          <NotificationStack collapsed={collapsed} userData={userData} setUserData={setUserData} />
        </motion.div>
      </AnimatePresence>
      <div className="h-[1px] bg-neutral-600 w-full mx-4" />
      <div className="self-stretch flex flex-col">
        <SideMenuItem ref={el => itemRefs.current["Feedback Call"] = el} layout icon="/gift.svg" label={"Feedback Call"} onClick={handleButtonClick} isActive={false} collapsed={collapsed} onHover={() => setHoveredItem("Feedback Call")} onLeave={() => setHoveredItem(null)} />
        {/*
         <SideMenuItem
          ref={(el) => (itemRefs.current["Request Feature"] = el)}
          layout
          icon="/feature.svg"
          label={feedback != null ? "Thank You!" : "Request Feature"}
          onClick={() => setFeedbackOpen(!feedbackOpen)}
          isActive={feedbackOpen}
          collapsed={collapsed}
          onHover={() => setHoveredItem("Request Feature")}
          onLeave={() => setHoveredItem(null)}
         />
         <AnimatePresence
          onExitComplete={() => setFeedbackOpen(false)}
          mode="sync"
         >
          {feedbackOpen && !collapsed && (
            <motion.div
              layout
              initial={{ opacity: 0, y: 10, height: 0 }}
              animate={{ opacity: 1, y: 0, height: "auto" }}
              exit={{ opacity: 0, y: 10, height: 0 }}
              transition={{ duration: 0.4, ease: [0.25, 0.8, 0.25, 1] }}
              className="self-stretch p-4 rounded-md z-10 space-y-2"
            >
              <div className="flex-1 flex items-start justify-start text-left text-sm text-gray-700 font-text-l-regular pb-2">
                <input
                  className="font-text-l-regular w-full text-mini hover:bg-neutral-50 focus:outline-none focus:ring focus:ring-green-50 transform-gpu transition-all duration-300 hover:border-neutral-300 focus:border-mediumseagreen-200 bg-base-white self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-box box-border border-neutral-700"
                  type="text"
                  placeholder="What feature would you like?"
                  value={feedbackVal}
                  onChange={(e) => onChange(e.target.value)}
                  required
                />
              </div>
              <div className="flex justify-between w-full mx-auto gap-4">
                <button
                  className="flex-grow py-3 transition-all duration-300 bg-white hover:bg-neutral-50 text-black font-medium rounded-md border-[1px] border-solid shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] border-neutral-700 hover:border-neutral-300"
                  onClick={() =>
                    (window.location.href = "mailto:support@wranglejobs.com")
                  }
                >
                  Contact
                </button>
                 <button
                  className="flex-grow py-3 bg-mediumseagreen-200 transition-all duration-300 hover:bg-green-400 text-white font-semibold rounded-md"
                  onClick={() => handleFeedbackSubmit(feedbackVal)}
                >
                  Submit
                </button>
              </div>
            </motion.div>
          )}
         </AnimatePresence>
         */}
        {isAuthenticated ? <SideMenuItem ref={el => itemRefs.current["Your Account"] = el} icon="/user-sharing.svg" label={userData.name !== undefined ? userData.name : "Your Account"} onClick={() => handleNavigation("/settings", "Your Account")} isActive={currentPath === "settings"} backgroundColor={transparentStates.copilot} collapsed={collapsed} tag="Get rewards" onHover={() => setHoveredItem("Your Account")} onLeave={() => setHoveredItem(null)} /> : <SignOut ref={el => itemRefs.current["Sign Out"] = el} layout icon="/user-sharing.svg" label={isAuthenticated ? "Sign Out" : "Sign In"} onClick={isAuthenticated ? () => handleSignOut() : () => handleNavigation("/login")} backgroundColor={transparentStates.tracker} collapsed={collapsed} onHover={() => setHoveredItem("Sign Out")} onLeave={() => setHoveredItem(null)} />}
      </div>

      <AppModal isOpen={isAppModalOpen} onCloseApp={() => {
      setIsAppModalOpen(false);
    }} inpLink="" company="Wrangle" guid={htmlId} companyName={"Company Name"} title={"Company Title"} htmlId={true} />
      <AccountCreateModal open={open} setOpen={setOpen} />
    </motion.div>;
};
export default SideMenuGeneral;