import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  collection,
  getDocs,
  getFirestore,
  increment,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAc4hEwMZMqJ1WTbDz_XfE6FT8UOwJxiWY",
  authDomain: "wrangle-4b551.firebaseapp.com",
  projectId: "wrangle-4b551",
  storageBucket: "wrangle-4b551.appspot.com",
  messagingSenderId: "757787724540",
  appId: "1:757787724540:web:0b8317bc8eca36a6e7e579",
  measurementId: "G-QMMB1YM7T2",
};

const app = initializeApp(firebaseConfig);
let analytics;

// Check if window is defined to confirm if the code is running in a browser environment
if (typeof window !== "undefined") {
  analytics = getAnalytics(app);
}

const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
provider.addScope("https://www.googleapis.com/auth/gmail.readonly");
provider.addScope("https://www.googleapis.com/auth/gmail.modify");

export { app, db, storage, auth, provider, increment, analytics };
