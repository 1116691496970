import {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { db, auth } from "./firebase"; // Import the db instance
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
  getDoc,
  doc,
  setDoc,
  where,
  onSnapshot,
  updateDoc,
} from "firebase/firestore"; // Import required Firestore functions
import stringSimilarity from "string-similarity";
import { useUserData } from "./UserDataContext";
import FuzzySet from "fuzzyset.js";
import { usePayment } from "./PaymentContext";

const JobsContext = createContext();

export const useJobs = () => useContext(JobsContext);
const hashCode = (s) =>
  s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
const tagMap = {
  engineering: [
    ["engineer", 2],
    ["developer", 1],
    ["programmer", 1],
    ["devops", 1],
    ["engineering", 2],
  ],
  sales: [
    ["sales", 2],
    ["business development", 1],
    ["account management", 1],
  ],
  marketing: [
    ["marketing", 2],
    ["branding", 1],
    ["promotion", 1],
  ],
  product: [
    ["product management", 1],
    ["product design", 1],
    ["ux design", 1],
    ["product", 1],
  ],
  design: [
    ["graphic design", 1],
    ["web design", 1],
    ["visual design", 1],
    ["design", 2],
    ["ui/ux", 1],
  ],
  support: [
    ["customer support", 1],
    ["technical support", 1],
    ["customer success", 1],
    ["clerk", 1],
  ],
  operations: [
    ["operations", 1],
    ["logistics", 1],
    ["supply chain", 1],
  ],
  data: [
    ["data analysis", 1],
    ["data science", 1],
    ["business intelligence", 1],
    ["writing", 2],
  ],
  finance: [
    ["finance", 1],
    ["accounting", 1],
    ["tax", 1],
    ["treasury", 1],
  ],
  management: [
    ["management", 1],
    ["leadership", 1],
    ["strategy", 1],
    ["manager", 2],
  ],
};

const scrapeApplicationLink = async (url) => {
  try {
    const response = await fetch(`${url}`);
    const html = await response.text();

    // Find job type
    const jobTypeRegex =
      /<h3 class="text-sm font-medium text-gray-500">Job type<\/h3>\s*<p class="text-gray-900">(.*?)<\/p>/;
    const jobTypeMatch = html.match(jobTypeRegex);
    const jobType = jobTypeMatch ? jobTypeMatch[1] : "Unknown";

    // Find skills under the "Skills" section
    const skills = [];
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const skillsSection = Array.from(
      doc.querySelectorAll("h3.text-sm.font-medium.text-gray-500")
    ).find((element) => element.textContent.trim() === "Skills");
    if (skillsSection && skillsSection.nextElementSibling) {
      const skillElements =
        skillsSection.nextElementSibling.querySelectorAll("a span");
      skillElements.forEach((element) => {
        skills.push(element.textContent.trim());
      });
    }

    return { type, skills };
  } catch (error) {
    console.error("Error scraping application link:", error);
    return { type: "Unknown", skills: [] };
  }
};

const findTags = (category, tagMap, threshold = 0.6) => {
  const words = category.split(/\W+/);
  const tagScores = Object.keys(tagMap).map((tag) => {
    const keywords = tagMap[tag];
    const wordScores = keywords.map(
      ([keyword, weight]) =>
        stringSimilarity.compareTwoStrings(keyword, category) * weight
    );
    const tagScore = Math.max(...wordScores);
    return { tag, score: tagScore };
  });

  const sortedTagScores = tagScores.sort((a, b) => b.score - a.score);
  const bestTags = sortedTagScores
    .filter((tagScore) => tagScore.score >= threshold)
    .map((tagScore) => tagScore.tag);

  return bestTags.slice(0, 4);
};
function extractId(text) {
  const match = text.match(/https:\/\/himalayas\.fly\.dev\/apply\/\w+/);
  if (match && match[1]) {
    return match[1];
  }
  return null;
}
function extractIdSecondTry(text) {
  const match = text.match(/https:\/\/himalayas\.app\/apply\/(\w+)/);
  if (match && match[1]) {
    return match[1];
  }
  return null;
}
async function fetchApplyNowLink(jobListingURL) {
  console.log("fetching app now link");
  console.log(jobListingURL);
  //console.log(jobListingURL);
  try {
    const corsProxy = "https://proxy.cors.sh/";
    const apiKey = "test";
    const response = await fetch(corsProxy + jobListingURL, {
      headers: {
        "x-cors-api-key": apiKey,
      },
    });
    let html = await response.text();
    //console.log(html)
    let applyNowLink;
    let id = extractId(html);
    if (id) applyNowLink = "https://himalayas.fly.dev/apply/" + id;
    id = extractIdSecondTry(html);
    if (id) applyNowLink = "https://himalayas.app/apply/" + id;
    console.log(applyNowLink);
    return applyNowLink;
  } catch (error) {
    console.error("Error fetching Apply Now link:", error);
    return null;
  }
}

const stripUrlParams = (url) => {
  const urlObj = new URL(url);
  return `${urlObj.origin}${urlObj.pathname}`;
};

const fetchFormInfo = async (appLink, guid) => {
  //console.log("hello", appLink);
  console.log("fetching form info");
  try {
    let idToken;
    if (auth.currentUser) {
      idToken = await auth.currentUser.getIdToken(true);
    } else {
      return;
    }
    const response = await fetch(`https://app.wranglejobs.com/api/get-form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ appLink: appLink, guid: guid }),
    });
    if (!response.ok) {
      return null;
    }
    const { formInfo } = await response.json();
    //console.log("check1");
    //console.log(formInfo)
    if (!formInfo) {
      //console.log("check2");
      return null;
    }
    if (formInfo.length == 0) {
      return true;
    }
    //console.log("check3");
    return formInfo;
  } catch (error) {
    //console.log("check4");
    //console.log(appLink);
    return [];
  }
};

const processJobs = async (jobs) => {
  console.log("processing jobs");
  const modifiedJobs = [];

  // Map each job to a promise that processes it
  const jobPromises = jobs.map(async (job) => {
    const categories = job.categories || job.category || [];
    if (categories.length === 0) {
      return;
    }
    const categoryTags = categories.flatMap((category) => {
      const tags = findTags(category.toLowerCase(), tagMap);
      return tags;
    });
    const uniqueTags = [...new Set(categoryTags)];
    const tags = [
      "engineering",
      "sales",
      "marketing",
      "product",
      "design",
      "support",
      "operations",
      "data",
      "finance",
      "management",
    ];
    const sortedTags = uniqueTags.sort(
      (a, b) => tags.indexOf(a) - tags.indexOf(b)
    );
    const selectedTags = sortedTags.slice(0, 10);

    const hashedId = hashCode(job.guid).toString();
    const applyNowLink = await fetchApplyNowLink(job.applicationLink);

    if (applyNowLink) {
      console.log("apply now link success " + applyNowLink);
      const cleanedApplyNowLink = stripUrlParams(applyNowLink);
      console.log(cleanedApplyNowLink);

      // Scrape job details from the application link
      const { jobType, skills } = await scrapeApplicationLink(
        job.applicationLink
      );

      const currentApi = "https://app.wranglejobs.com/api";
      const response = await fetch(`${currentApi}/get-link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ appLink: cleanedApplyNowLink }),
      });

      if (response.ok) {
        console.log("successful get link");
        const jsonResponse = await response.json();
        let { link } = jsonResponse;
        //const form = await fetchFormInfo(link);
        //if (!form || form === true || form.length == 0) return;
        //console.log(form);
        const origin = new URL(link).origin;
        console.log(link);
        if (
          link.includes("ashbyhq.com") ||
          link.includes("workable.com") ||
          link.includes("lever.co") ||
          link.includes("greenhouse.io")
        ) {
          const modifiedJob = {
            id: hashedId,
            ...job,
            categories: selectedTags.length > 0 ? selectedTags : null,
            locationRestrictions:
              job.locationRestrictions && job.locationRestrictions.length > 0
                ? job.locationRestrictions.slice(0, 2)
                : null,
            applyNowLink: link,
            //formInfo: form ? form : [],
            type: jobType, // Add scraped job type
            skills: skills, // Add scraped skills
          };
          return modifiedJob;
        }
      }
    }
  });

  // Wait for all promises to complete
  const results = await Promise.allSettled(jobPromises);
  results.forEach((result, index) => {
    if (result.status === "rejected") {
      console.error(
        `Promise at index ${index} failed with reason:`,
        result.reason
      );
    }
  });
  console.log("successfully gotten to results" + results.length);
  // Filter out undefined results (from skipped jobs)
  for (const result of results) {
    if (result.status === "fulfilled" && result.value) {
      modifiedJobs.push(result.value);
    }
  }
  console.log("Modified Jobs: " + modifiedJobs.length);

  // Write to the database
  if (modifiedJobs.length > 0) {
    await writeJobs(modifiedJobs);
  }
  return modifiedJobs;
};

const writeJobs = async (modifiedJobs) => {
  console.log("writing " + modifiedJobs.length + " jobs");
  // Map each job to a promise that writes it to the database
  for (let i = 0; i < modifiedJobs.length; i++) {
    console.log("Writing job " + modifiedJobs[i].applyNowLink);
    const jobDoc = doc(db, "Jobs", modifiedJobs[i].id);
    setDoc(jobDoc, modifiedJobs[i]);
  }
  console.log("writing complete");
};

const fetchAllJobs = async () => {
  const jobLimit = 10;

  const fetchJobsFromHimalayas = async (offset, jobLimit) => {
    console.log("fetching from himil");
    const response = await fetch(
      `https://himalayas.app/jobs/api?jobLimit=${jobLimit}&offset=${offset}`
    );
    const data = await response.json();
    console.log(data.jobs);
    return data.jobs || [];
  };

  const fetchJobsFromFirebase = async () => {
    const jobsRef = collection(db, "Jobs");
    const q = query(jobsRef, orderBy("pubDate", "desc"), limit(250));
    const querySnapshot = await getDocs(q);
    const firebaseJobs = [];
    querySnapshot.forEach((doc) => {
      firebaseJobs.push(doc.data());
    });

    return firebaseJobs;
  };

  const firebaseJobs = await fetchJobsFromFirebase();
  console.log("Gotten here");
  // restrict to owners
  let offset = 0;
  let stopFetching = false;
  while (true && !stopFetching) {
    console.log("Gotten here 2");
    const jobsChunk = await fetchJobsFromHimalayas(offset, jobLimit);
    jobsChunk.sort((a, b) => b.updated_at - a.updated_at);
    if (jobsChunk.length === 0 || jobsChunk.length < jobLimit) {
      console.log("0 jobs found");
      break;
    }
    let newJobs = [];
    for (const job of jobsChunk) {
      const existingJob = firebaseJobs.find((j) => j.guid === job.guid);
      if (!existingJob) {
        newJobs.push(job);
      } else {
        stopFetching = true;
        break;
      }
    }
    if (newJobs.length == 0) {
      console.log("fetching last 20 jobs");
      for (let i = 0; i < 20; i++) {
        newJobs.push(jobsChunk[i]);
      }
    }
    if (newJobs.length > 0) {
      console.log("gotten here 3 " + newJobs.length);
      const modifiedJobs = await processJobs(newJobs);
      if (modifiedJobs.length == 0) {
        newJobs = [];
        console.log("failure to write new jobs, writing 20");
        for (let i = 0; i < 20; i++) {
          newJobs.push(jobsChunk[i]);
        }
        await processJobs(newJobs);
      }
      //await writeJobs(modifiedJobs);
    }

    offset += jobLimit;
  }
};

async function fetchJobsFromFirebase(
  userData,
  setJobs,
  setLoading,
  lastVisibleDoc
) {
  //console.log("fetch fb");

  //console.log(userData);

  const jobsRef = collection(db, "Jobs");
  let q = query(jobsRef, orderBy("pubDate", "desc"), limit(250));

  if (lastVisibleDoc) {
    q = query(
      jobsRef,
      orderBy("pubDate", "desc"),
      startAfter(lastVisibleDoc),
      limit(5)
    );
  }

  try {
    const querySnapshot = await getDocs(q);
    //console.log(querySnapshot)
    const firebaseJobs = [];
    querySnapshot.forEach((doc) => {
      firebaseJobs.push(doc.data());
    });

    // Filter out duplicate jobs before updating the state

    setJobs((prevJobs) => {
      const uniqueJobs = firebaseJobs.filter((job) => {
        return !prevJobs.some((prevJob) => prevJob.guid === job.guid);
      });

      return [...prevJobs, ...uniqueJobs];
    });

    setLoading(false);
    return querySnapshot.docs[querySnapshot.docs.length - 1];
  } catch (error) {
    console.error("Error fetching jobs from Firebase:", error);
    setJobs([]);
  }
}

export const JobsProvider = ({ children }) => {
  const { user, userData, setUserData, isAuthenticated } = useUserData();
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const [showUSOnly, setShowUSOnly] = useState(false);
  const [showRemoteOnly, setShowRemoteOnly] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [screen, setScreen] = useState(null);
  const [fetchedInitialJobs, setFetchedInitialJobs] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState();
  const [jobslist, setJobslist] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerType, setBannerType] = useState("");
  const { setAppsUsed, fetchApplicationCount, owner } = usePayment();
  const [flag, setFlag] = useState(false);

  const grabAppliedJobs = async (bannerInp) => {
    // NEW

    let jobsDict = {};
    let jobsDictWithFailed = {};
    const appsRef = collection(db, "/Profiles/" + user.uid + "/applications/");
    const appsProfile = await getDocs(appsRef);

    if (!appsProfile.empty) {
      const applications = appsProfile.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (app) => app.createdAt && !app.url.includes("myworkdayjobs.com")
        );

      if (applications.length !== 0) {
        applications.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        for (let i = 0; i < applications.length; i++) {
          const content = applications[i].jobId;
          //if (applications[i].status === "submitted") {
          jobsDict[content] = applications[i];
          //}
          jobsDictWithFailed[content] = applications[i];
        }
      }
    }

    setAppliedJobs(jobsDict);
    const keys = Object.keys(jobsDict);
    const keysFailed = Object.keys(jobsDictWithFailed);
    const joblist = {};
    const joblistwithfailed = {};
    const promises = keys.map(async (key) => {
      const jobref = doc(db, "/Jobs/", key);
      const docSnap = await getDoc(jobref);
      joblist[key] = docSnap.data();
    });
    const promisesWithFailed = keysFailed.map(async (key) => {
      const jobref = doc(db, "/Jobs/", key);
      const docSnap = await getDoc(jobref);
      joblistwithfailed[key] = docSnap.data();
    });

    await Promise.all(promises);
    await Promise.all(promisesWithFailed);

    setJobslist(joblist);
    if (!flag) {
      setFlag(true);
    } else if (bannerInp) {
      if (jobsDictWithFailed && Object.keys(jobsDictWithFailed).length > 0) {
        setAppsUsed(Object.keys(jobsDict).length);
        // get newest job
        const currJob = Object.keys(jobsDictWithFailed).reduce((a, b) =>
          jobsDictWithFailed[a].createdAt.seconds >
          jobsDictWithFailed[b].createdAt.seconds
            ? a
            : b
        );
        const currJobRef = joblistwithfailed[currJob];
        //console.log(jobsDictWithFailed);
        if (jobsDictWithFailed[currJob].status == "submitted") {
          //console.log("here3")
          setBannerMessage(
            `Update: Your application to ${currJobRef.title} at ${currJobRef.companyName} is in!`
          );
          setBannerType("successSubmit");
        } else {
          //console.log("here4")
          setBannerMessage(
            `Update: Your application to ${
              currJobRef && currJobRef.title ? currJobRef.title : ""
            } at ${
              currJobRef && currJobRef.companyName ? currJobRef.companyName : ""
            } has failed.`
          );
          setBannerType("errorSubmit");
        }
      }
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      grabAppliedJobs(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      let isFirstSnapshot = true;
      const profileRef = collection(
        db,
        "/Profiles/" + user.uid + "/applications/"
      );
      const unsub = onSnapshot(profileRef, (snapshot) => {
        if (isFirstSnapshot) {
          isFirstSnapshot = false;
          return; // Ignore the initial snapshot
        }
        snapshot.docChanges().forEach((change) => {
          if (change.type === "modified" || change.type === "added") {
            grabAppliedJobs(true); // on doc edit
            fetchApplicationCount();
          }
        });
      });
      return () => unsub();
    }
  }, [isAuthenticated]);

  /*const filteredJobs = useMemo(() => {
    if (!jobs.length) {
      return [];
    }
  
    const filtered = jobs.filter((job) => {
      const hashCode = s => s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);
      const isFavorited = userData.favorites ? userData.favorites.includes(job.guid) : false;
      const isWithinApplied = appliedJobs ? !Object.keys(appliedJobs).includes(hashCode(job.guid).toString()) : true;
      const isApplied = userData.applied ? !userData.applied.includes(job.guid) : true;
      const isInSelectedTags = selectedTags.length ? selectedTags.every((tag) => (job.categories || []).includes(tag)) : true;
      const isUSOrRemote = showUSOnly && showRemoteOnly ?
        (job.locationRestrictions && job.locationRestrictions.includes("United States")) || !job.locationRestrictions :
        (!showUSOnly || (job.locationRestrictions && job.locationRestrictions.includes("United States"))) && (!showRemoteOnly || !job.locationRestrictions);
      return isWithinApplied && isApplied && (!showFavoritesOnly || isFavorited) && isInSelectedTags && isUSOrRemote;
    });
  
    
    if (searchQuery) {
      const fuzzySet = FuzzySet([searchQuery.toLowerCase()]);
      
      filtered.sort((a, b) => {
        const aTitle = a.title ? a.title.toLowerCase() : '';
        const bTitle = b.title ? b.title.toLowerCase() : '';
        const aCompany = a.companyName ? a.companyName.toLowerCase() : '';
        const bCompany = b.companyName ? b.companyName.toLowerCase() : '';
        
        const aRelevance = Math.max(
          (fuzzySet.get(aTitle)?.[0]?.[0]) ?? 0,
          (fuzzySet.get(aCompany)?.[0]?.[0]) ?? 0,
          a.categories ? Math.max(...a.categories.map(category => (fuzzySet.get(category.toLowerCase())?.[0]?.[0]) ?? 0)) : 0
        );
        
        const bRelevance = Math.max(
          (fuzzySet.get(bTitle)?.[0]?.[0]) ?? 0,
          (fuzzySet.get(bCompany)?.[0]?.[0]) ?? 0,
          b.categories ? Math.max(...b.categories.map(category => (fuzzySet.get(category.toLowerCase())?.[0]?.[0]) ?? 0)) : 0
        );
      
        const aExplicitMatch = aTitle.includes(searchQuery) || aCompany.includes(searchQuery);
        const bExplicitMatch = bTitle.includes(searchQuery) || bCompany.includes(searchQuery);
      
        if (aExplicitMatch && !bExplicitMatch) {
          return -1;
        }
      
        if (!aExplicitMatch && bExplicitMatch) {
          return 1;
        }
        
        // If job A is more relevant than job B, job A should come first
        if (aRelevance > bRelevance) {
          return -1;
        }
        
        // If job B is more relevant than job A, job B should come first
        if (aRelevance < bRelevance) {
          return 1;
        }
        
        // If both jobs are equally relevant, their order doesn't need to change
        return 0;
      });
    }
  
    return filtered;
  }, [showUSOnly, showRemoteOnly, showFavoritesOnly, selectedTags, jobs, screen, userData, appliedJobs, searchQuery]);*/

  const fetchNewJobsFromAPI = useCallback(async () => {
    console.log("fetchingNewJobs");
    // TODO: move the fetching of new jobs from himalayas to a serverless function on a cron schedule --
    const jobsChunk = await fetchAllJobs();
    if (!jobsChunk) {
      return;
    }
    const newJobs = jobsChunk.filter(
      (job) => !jobs.find((j) => j.guid === job.guid)
    );
    if (newJobs.length > 0) {
      const batch = writeBatch(db); // TODO: I'm getting an error that `writeBatch` is unresolved -- is it imported?
      newJobs.forEach((job) => {
        const jobDoc = doc(db, "Jobs", job.id);
        batch.set(jobDoc, job);
      });
      await batch.commit();
    }
  }, [jobs]);

  async function fetchMoreJobs() {
    const newLastVisibleDoc = await fetchJobsFromFirebase(
      setJobs,
      setLoading,
      lastVisibleDoc
    );
    setLastVisibleDoc(newLastVisibleDoc);
  }

  /*useEffect(() => {
    if (filteredJobs.length > 0) {
        setSelectedJob(filteredJobs[0]);
    }
  }, [showUSOnly, showRemoteOnly, showFavoritesOnly, selectedTags, jobs, screen, searchQuery]);*/

  useEffect(() => {
    if (!fetchedInitialJobs && userData.applied != undefined) {
      fetchJobsFromFirebase(userData, setJobs, setLoading, null).then(() => {
        /*if (owner) {
          console.log("owner");
          fetchNewJobsFromAPI();
        }*/
        setFetchedInitialJobs(true);
      });
    }
  }, [fetchedInitialJobs, userData, owner]);
  const triggerNewJobs = async () => {
    if (!owner) return;
    console.log("fetching jobs (owner)");
    fetchNewJobsFromAPI();
  };
  const remove50 = async () => {
    if (!owner) return;
    let lastDoc;
    let continueRemoving = true;
    let badjobs = 0;
    console.log("Checking for bad jobs...");
    while (continueRemoving) {
      let runningBadJobs = 0;
      const jobsRef = collection(db, "Jobs");
      const q = lastDoc
        ? query(
            jobsRef,
            orderBy("pubDate", "asc"),
            startAfter(lastDoc),
            limit(100)
          )
        : query(jobsRef, orderBy("pubDate", "asc"), limit(100));

      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        console.log("No more jobs to check.");
        break;
      }

      lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

      for (const doc of querySnapshot.docs) {
        const currJob = await (doc.data().applyNowLink, doc.id);
        if (currJob === true) {
          badjobs += 1;
          runningBadJobs += 1;
        }
      }
      console.log("Removed " + runningBadJobs + " expired jobs in batch");
      const input = prompt("Enter no to stop");
      if (input === "no") {
        continueRemoving = false;
        console.log("Removed " + badjobs + " expired jobs");
        console.log("Stopped at job " + lastDoc);
      }
    }
  };
  return (
    <JobsContext.Provider
      value={{
        triggerNewJobs,
        jobs,
        setJobs,
        loading,
        selectedJob,
        setSelectedJob,
        lastVisibleDoc,
        fetchMoreJobs,
        showFavoritesOnly,
        setShowFavoritesOnly,
        showRemoteOnly,
        setShowRemoteOnly,
        showUSOnly,
        setShowUSOnly,
        setScreen,
        screen,
        selectedTags,
        setSelectedTags,
        appliedJobs,
        jobslist,
        searchQuery,
        setSearchQuery,
        bannerMessage,
        setBannerMessage,
        bannerType,
        setBannerType,
        remove50,
      }}
    >
      {children}
    </JobsContext.Provider>
  );
};
