import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { usePayment } from "../PaymentContext";
import Rive from "rive-react";
const ProcessingModal_1 = _compiledBlock(_props => <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true"
  //onClick={() => setShowUpgradeModal(false)}
  >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="flex flex-col relative rounded-mini bg-base-white box-border shrink-0 border-[2px] border-solid border-bg-color-2 p-12 pt-16">
          <div className="flex flex-col items-center justify-start gap-[20px] text-left text-sm text-e">
            {_props.v0}
            <div className="text-mediumseagreen-200 text-medium mt-2">
              Hang on, we're processing your request...
            </div>
          </div>
        </div>
      </div>, {
  name: "ProcessingModal_1",
  portals: ["v0"]
});
const ProcessingModal = process => {
  const {
    isProcessing
  } = usePayment();
  const Processor = () => <Rive src="/liquidwrangle.riv" className="w-44 h-44"></Rive>;
  if (isProcessing) {
    return /*@million jsx-skip*/<ProcessingModal_1 v0={<Processor className="h-44" />} />;
  }
};
export default ProcessingModal;