import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useUserData } from "../UserDataContext";
import { useJobs } from "../jobsContext";
import AccountCreateModal from "./AccountCreateModal";
import WrangleTooltip from "./WrangleTooltip";

const JobContainer = ({
  onSelect,
  position,
  pubDate,
  company,
  location,
  tags,
  active,
  guid,
  categories,
  applied,
  maxSalary,
  appliedStatus,
  appliedData,
  type,
  seniority,
  skills,
  source,
}) => {
  const { userData, setUserData, isAuthenticated } = useUserData();
  const [open, setOpen] = useState(false);
  const { selectedJob = {}, setBannerMessage, setBannerType } = useJobs();
  const [isDisliked, setIsDisliked] = useState(
    userData.dislikes && userData.dislikes.includes(guid)
  );
  const [isLiked, setIsLiked] = useState(
    userData.likes && userData.likes.includes(guid)
  );

  useEffect(() => {
    setIsLiked(userData.likes && userData.likes.includes(guid));
    setIsDisliked(userData.dislikes && userData.dislikes.includes(guid));
  }, [userData.likes, userData.dislikes]);

  const updatePreferences = (jobAttributes, increment) => {
    const updatedPreferences = { ...userData.preferences };
    jobAttributes.forEach((attribute) => {
      if (Array.isArray(attribute)) {
        attribute.forEach((item) => {
          updatedPreferences[item] =
            (updatedPreferences[item] || 0) + increment;
        });
      } else {
        updatedPreferences[attribute] =
          (updatedPreferences[attribute] || 0) + increment;
      }
    });
    return updatedPreferences;
  };

  const timeSince = (date) => {
    const now = new Date();
    const publishedDate = new Date(date);
    const seconds = Math.floor((now - publishedDate) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };

  const timeSinceApplied = (date) => {
    const timestampMilliseconds = date * 1000;
    const jobdate = new Date(timestampMilliseconds);

    const now = new Date();
    const seconds = Math.floor((now - jobdate) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };

  const handleStarClick = async () => {
    setIsFavorites(!isFavorites);

    if (!isFavorites) {
      setUserData((prevUserData) => ({
        ...prevUserData,
        favorites: [...prevUserData.favorites, guid],
      }));
    } else {
      setUserData((prevUserData) => ({
        ...prevUserData,
        favorites: prevUserData.favorites.filter(
          (favorite) => favorite !== guid
        ),
      }));
    }
  };

  const handleDislike = async () => {
    setIsLiked(false);
    if (!isDisliked) {
      setBannerMessage("We'll show less jobs like this.");
      setBannerType("dislike");
      setIsDisliked(true);

      // Add to dislikes and update preferences
      setUserData((prevUserData) => ({
        ...prevUserData,
        dislikes: [...(prevUserData.dislikes || []), guid],
        preferences: updatePreferences(
          [categories, type, skills, seniority, position],
          -1
        ),
      }));
    } else {
      setIsDisliked(false);

      // Remove from dislikes and update preferences
      setUserData((prevUserData) => ({
        ...prevUserData,
        dislikes: prevUserData.dislikes.filter((dislike) => dislike !== guid),
        preferences: updatePreferences(
          [categories, type, skills, seniority, position],
          1
        ),
      }));
    }
  };

  const handleLike = async () => {
    setIsDisliked(false);
    if (!isLiked) {
      setBannerMessage("We'll recommend more jobs like this.");
      setBannerType("like");
      setIsLiked(true);

      // Add to likes and update preferences
      setUserData((prevUserData) => ({
        ...prevUserData,
        likes: [...(prevUserData.likes || []), guid],
        preferences: updatePreferences([categories, skills, seniority], 1),
      }));
    } else {
      setIsLiked(false);

      // Remove from likes and update preferences
      setUserData((prevUserData) => ({
        ...prevUserData,
        likes: prevUserData.likes.filter((like) => like !== guid),
        preferences: updatePreferences(
          [categories, type, skills, seniority, position],
          -1
        ),
      }));
    }
  };

  return (
    <AnimatePresence key={guid}>
      <motion.div
        onClick={onSelect}
        className={`self-stretch rounded-3xs ${
          active ? "bg-green-100" : null
        } hover:scale-[0.99] cursor-pointer transform-gpu transition-all duration-300`}
      >
        {active && (
          <motion.div className="px-3 pb-2.5 pt-3 font-medium text-sm text-green-500">
            Selected from previous page
          </motion.div>
        )}
        <motion.div
          onClick={onSelect}
          className={`self-stretch rounded-3xs ${
            isDisliked ? "bg-neutral-100" : "bg-base-white"
          } shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-col py-3 px-0 items-start justify-center border-box  text-left text-lg text-gray-700 font-text-l-regular border-[1px] border-solid ${
            selectedJob.guid != null
              ? selectedJob.guid === guid
                ? isDisliked
                  ? "border-neutral-700"
                  : "border-mediumseagreen-200"
                : "border-neutral-700"
              : "border-neutral-700"
          } cursor-pointer transform-gpu transition-all duration-300 ${
            selectedJob.guid != null
              ? selectedJob.guid === guid
                ? isDisliked
                  ? "border-neutral-700"
                  : "border-mediumseagreen-200"
                : "hover:border-neutral-300"
              : "hover:border-neutral-300"
          }`}
        >
          <AnimatePresence>
            <motion.div
              layout="position"
              className="self-stretch flex flex-col py-0 px-5 items-start justify-start"
            >
              <AnimatePresence>
                <motion.div
                  layout="position"
                  className="self-stretch flex flex-col items-start justify-start gap-[6px]"
                >
                  <div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
                    <div
                      className={`flex-1 relative tracking-[-0.1px] leading-[28px] ${
                        isDisliked ? "text-sm" : "font-semibold"
                      }`}
                    >
                      {isDisliked
                        ? "We've hidden this job, and adjusted our algorithm."
                        : position}
                    </div>
                    {isDisliked ? (
                      <div
                        className={`relative text-sm tracking-[-0.1px] leading-[28px] font-semibold text-mediumseagreen-200`}
                        onClick={handleDislike}
                      >
                        Undo
                      </div>
                    ) : (
                      <div
                        className={`relative text-sm tracking-[-0.1px] leading-[28px] font-semibold text-gray-50`}
                      >
                        {appliedStatus && appliedData && appliedData.createdAt
                          ? timeSinceApplied(appliedData.createdAt.seconds)
                          : timeSince(pubDate)}
                      </div>
                    )}
                  </div>
                  <AnimatePresence
                    layout
                    initial={{ opacity: 1, height: "auto" }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0, overflow: "hidden" }}
                    transition={{ duration: 0.5 }}
                  >
                    {!isDisliked && (
                      <>
                        <div className="self-stretch flex flex-row items-center justify-start gap-[16px] text-sm text-gray-50">
                          <div className="flex flex-row items-center justify-start text-gray-700">
                            <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                              {company}
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-start gap-[4px]">
                            <img
                              className="relative w-[18px] h-[18px] shrink-0"
                              alt=""
                              src="/location-pin.svg"
                            />
                            <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                              {source == "indeed" ? "Canada" : location}
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-start gap-[4px]">
                            {maxSalary ? (
                              <img
                                className="relative w-[16px] h-[16px] shrink-0"
                                alt=""
                                src="/dollar.svg"
                              />
                            ) : null}
                            <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                              {maxSalary}
                            </div>
                          </div>
                        </div>
                        <div className="self-stretch flex flex-row items-center justify-between gap-[10px]">
                          <div className="flex flex-row items-center justify-start gap-[16px] text-smi [&>*:nth-child(n+3)]:hidden [&>*:nth-child(n+3)]:lg:flex">
                            {tags &&
                              tags
                                .filter(
                                  (tag) =>
                                    tag !== undefined &&
                                    tag.text !== undefined &&
                                    tag.textColor !== undefined &&
                                    tag.bgColor !== undefined
                                )
                                .map((tag, index) => (
                                  <div
                                    key={`${guid}-${index}`} // Ensure unique keys
                                    className={`rounded-8xs ${tag.bgColor} ${
                                      tag.text == "" ? "px-1" : "py-0.5 px-2"
                                    } flex flex-row  items-center justify-center gap-[6px]`}
                                    style={{
                                      backgroundColor: tag.bgColor,
                                      color: tag.textColor,
                                    }}
                                  >
                                    {tag.icon && (
                                      <span
                                        className={`relative ml-1 flex items-center justify-center ${
                                          tag.text == ""
                                            ? "w-[40px] h-[22px]"
                                            : "w-[14px] h-[14px]"
                                        }`}
                                      >
                                        {tag.icon}
                                      </span>
                                    )}
                                    <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                                      {tag.text}
                                    </div>
                                  </div>
                                ))}
                          </div>
                          <div className="flex flex-row items-center justify-start gap-[12px]">
                            {applied ? null : (
                              <WrangleTooltip title={isLiked ? "Unlike Job" : "Like Job"}>
                              <motion.svg
                                onClick={
                                  isAuthenticated
                                    ? handleLike
                                    : () => setOpen(true)
                                }
                                className={`outline-none relative w-[24px] h-[24px] shrink-0 ${
                                  isLiked
                                    ? "text-mediumseagreen-200"
                                    : "text-gray-200"
                                }`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                whileTap={{ scale: 1.3 }}
                              >
                                <path
                                  d="M4 17.9808V9.70753C4 6.07416 4 4.25748 5.17157 3.12874C6.34315 2 8.22876 2 12 2C15.7712 2 17.6569 2 18.8284 3.12874C20 4.25748 20 6.07416 20 9.70753V17.9808C20 20.2867 20 21.4396 19.2272 21.8523C17.7305 22.6514 14.9232 19.9852 13.59 19.1824C12.8168 18.7168 12.4302 18.484 12 18.484C11.5698 18.484 11.1832 18.7168 10.41 19.1824C9.0768 19.9852 6.26947 22.6514 4.77285 21.8523C4 21.4396 4 20.2867 4 17.9808Z"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path d="M4 7H20" strokeWidth="1.5" />
                              </motion.svg>
                              </WrangleTooltip>
                            )}
                            {applied ? null : (
                              <WrangleTooltip title={"Hide Job"}>
                              <motion.svg
                                onClick={
                                  isAuthenticated
                                    ? handleDislike
                                    : () => setOpen(true)
                                }
                                className={`outline-none relative w-[24px] h-[24px] shrink-0 ${
                                  isDisliked ? "text-red-300" : "text-gray-200"
                                }`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                whileTap={{ scale: 1.3 }}
                              >
                                <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V2ZM12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84286 16.1566 4 14.1217 4 12C3.9978 10.2233 4.59302 8.49755 5.69 7.1L16.9 18.31C15.5025 19.407 13.7767 20.0022 12 20V20ZM18.31 16.9L7.1 5.69C8.49755 4.59302 10.2233 3.99779 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1572 7.84344 20 9.87827 20 12C20.0022 13.7767 19.407 15.5025 18.31 16.9Z" />
                              </motion.svg>
                              </WrangleTooltip>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </AnimatePresence>
                </motion.div>
              </AnimatePresence>
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </motion.div>
      <AccountCreateModal open={open} setOpen={setOpen} />
    </AnimatePresence>
  );
};

export default JobContainer;
