import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useUserData } from "../UserDataContext";
import { useNavigate } from "react-router-dom";

const NotificationStack = ({ collapsed, userData, setUserData }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUserData();
  const [cardData, setCardData] = useState([
    {
      id: "card1",
      title: "Getting started.",
      description:
        "Create an account to get access to all our personalized features.",
      action: "Create account",
      url: "/login",
      dismissed: "card1",
    },
    {
      id: "card2",
      title: "Not a job seeker?",
      description:
        "This is our job seeker platform. Get in touch for more info.",
      action: "Get in touch",
      url: "https://calendly.com/wranglejobs/business-solution?back=1&month=2024-05",
      dismissed: "card2",
    },
    {
      id: "card3",
      title: "Get better matches.",
      description:
        "Like or dislike some jobs to get your algorithm and matches.",
      action: "Explore jobs",
      url: "/jobs",
      dismissed: "card3",
    },
    {
      id: "card4",
      title: "Give us feedback.",
      description:
        "We'd love to hear what you think. Sign up for a quick interview!",
      action: "Book a call",
      url: "https://calendly.com/wranglejobs/user-interview?month=2024-05",
      dismissed: "card4",
    },
  ]);

  const filteredCards = cardData
    .filter((card) => !isAuthenticated || card.id !== "card1")
    .filter((card) => !(userData && userData[card.dismissed]));

  const visibleCards =
    collapsed && filteredCards.length > 0 ? [filteredCards[0]] : filteredCards;

  const handleCardClick = (cardId) => {
    // Safeguard against no visible cards
    if (visibleCards.length === 0) return;

    const index = cardData.findIndex((card) => card.id === cardId);
    if (index === -1) return; // Safeguard against not finding the card

    const updatedCardData = [
      cardData[index],
      ...cardData.slice(0, index),
      ...cardData.slice(index + 1),
    ];
    setCardData(updatedCardData);
  };

  if (visibleCards.length === 0) {
    return null; // Optionally, render a placeholder or a message here
  }

  return (
    <AnimatePresence>
      <motion.div
        layout="position"
        className="grid flex px-3 "
        style={{ position: "relative" }}
      >
        {visibleCards.map((card, index) => (
          <motion.div
            key={card.id}
            initial={{ opacity: 0, y: -10 * index, scale: 1 - index * 0.05 }}
            animate={{
              opacity: 1,
              y: -10 * index,
              scale: 1 - index * 0.05,
              zIndex: 15 - index,
            }}
            exit={{
              opacity: 0,
              y: -10 * (index - 1),
              scale: 0.95 - index * 0.05,
            }}
            transition={{
              duration: 0.5,
              ease: [0.25, 0.8, 0.25, 1],
            }}
            whileHover={index === 0 ? {} : { y: -10 * index - 30 }}
            onClick={() => handleCardClick(card.id)}
            style={{ gridArea: "1 / 1", cursor: "pointer" }}
          >
            <motion.div
              layout="position"
              className={`border-solid border-[1px] border-neutral-700 bg-neutral-50 hover:bg-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] transition-all duration-300 flex rounded-md items-center justify-center  ${
                !collapsed ? "py-2.5 " : "py-2.5"
              } `}
            >
              <motion.div
                layout="position"
                className={`items-start justify-start flex-grow self-stretch pr-2 ${
                  collapsed ? "pl-3.5 pt-1" : "pl-3"
                } h-full
                `}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.75 5.25C14.75 3.04086 16.5409 1.25 18.75 1.25C20.9591 1.25 22.75 3.04086 22.75 5.25C22.75 7.45914 20.9591 9.25 18.75 9.25C16.5409 9.25 14.75 7.45914 14.75 5.25Z"
                    fill="#7CCE98"
                  />
                  <path
                    opacity="0.4"
                    d="M21.75 12.5572C21.75 14.7479 21.75 16.4686 21.5694 17.812C21.3843 19.1886 20.9973 20.2809 20.1391 21.1391C19.2809 21.9973 18.1886 22.3843 16.812 22.5694C15.4686 22.75 13.7479 22.75 11.5572 22.75H11.4428C9.2521 22.75 7.53144 22.75 6.18802 22.5694C4.81137 22.3843 3.71911 21.9973 2.86091 21.1391C2.00272 20.2809 1.61568 19.1886 1.43059 17.812C1.24998 16.4686 1.24999 14.7479 1.25 12.5572V12.4428C1.24999 10.2521 1.24998 8.53144 1.43059 7.18802C1.61568 5.81137 2.00272 4.71911 2.86091 3.86091C3.71911 3.00272 4.81137 2.61568 6.18802 2.43059C7.53144 2.24998 9.25213 2.24999 11.4428 2.25H12.6628C13.2792 2.25 13.5874 2.25 13.7073 2.41392C13.8271 2.57784 13.7208 2.91187 13.5081 3.57993C13.3405 4.10663 13.25 4.66775 13.25 5.25C13.25 8.28757 15.7124 10.75 18.75 10.75C19.3394 10.75 19.9071 10.6573 20.4394 10.4857C21.0915 10.2754 21.4176 10.1703 21.582 10.2897C21.7464 10.409 21.7472 10.709 21.749 11.309C21.75 11.6722 21.75 12.05 21.75 12.4428V12.5572Z"
                    fill="#7CCE98"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.25 11C6.25 10.5858 6.58579 10.25 7 10.25H11C11.4142 10.25 11.75 10.5858 11.75 11C11.75 11.4142 11.4142 11.75 11 11.75H7C6.58579 11.75 6.25 11.4142 6.25 11Z"
                    fill="#7CCE98"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.25 16C6.25 15.5858 6.58579 15.25 7 15.25H15C15.4142 15.25 15.75 15.5858 15.75 16C15.75 16.4142 15.4142 16.75 15 16.75H7C6.58579 16.75 6.25 16.4142 6.25 16Z"
                    fill="#7CCE98"
                  />
                </svg>
              </motion.div>
              {!collapsed && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.4, delay: 0.3, ease: "easeOut" }}
                  className="flex-grow pr-3"
                >
                  <motion.div className="flex flex-row justify-between  mt-1">
                    <motion.div className="text-mini font-semibold pb-2 pt-1/2 shrink-0">
                      {card.title}
                    </motion.div>
                    <motion.div
                      className="cursor-pointer text-neutral-300 hover:text-gray-neutral-200 font-medium transition-all duration-300 text-smi "
                      onClick={() => {
                        setUserData({ ...userData, [card.dismissed]: true });
                      }}
                    >
                      Dismiss
                    </motion.div>
                  </motion.div>
                  <motion.div className="text-smi w-full pb-2 max-h-8">
                    {card.description}
                  </motion.div>
                  <motion.div className="flex flex-row text-smi">
                    <div
                      onClick={() => {
                        if (/^(http|https):\/\//.test(card.url)) {
                          window.open(card.url, "_blank");
                        } else {
                          navigate(card.url);
                        }
                      }}
                      className="mr-3 text-mediumseagreen-200 hover:text-green-300 font-semibold transition-all duration-300 cursor-pointer no-underline"
                    >
                      {card.action}
                    </div>
                  </motion.div>
                </motion.div>
              )}
            </motion.div>
          </motion.div>
        ))}
      </motion.div>
    </AnimatePresence>
  );
};

export default NotificationStack;
