import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const DropdownInput = ({
  titleText,
  options,
  value,
  onChange,
  required = false,
  titleClass,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(() => {
    if (options && options.length) {
      const option = options.find(({ value: optVal }) => optVal === value);
      return option?.value || null;
    }
    return null;
  });
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    const option = options.find(({ value: optVal }) => optVal === value);
    if (value && value !== selectedValue && options && option) {
      setSelectedValue(value);
      onChange(value);
    }
  }, [value, options]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!titleText || !options || options.length === 0) {
    return null;
  }

  return (
    <div className="mt-5">
      <div className="text-[14px] text-primary-600 mb-3">{titleText}</div>
      <div ref={dropdownRef} className="relative w-full">
        <div
          className={`font-text-l-regular text-mini bg-base-white text-[14px] text-black self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-between border-[1px] border-solid transform-gpu transition-all ${
            isDropdownOpen ? "border-mediumseagreen-200" : "border-neutral-700"
          } focus:outline-none cursor-pointer`}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <div>
            {selectedValue
              ? options.find(({ value: optVal }) => optVal === selectedValue)
                  ?.label
              : ""}
          </div>
          <motion.svg
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            animate={{ rotate: isDropdownOpen ? 180 : 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <path
              d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
              stroke="#7C7C7C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </motion.svg>
        </div>
        <AnimatePresence>
          {isDropdownOpen && (
            <motion.div
              className="absolute left-0 w-full top-full bg-white border-[1px] text-[14px] text-primary-600 border-solid border-neutral-700 rounded-md z-10"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.2 }}
            >
              {options.map((option, index) => (
                <div
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  key={option.value}
                  onClick={() => {
                    setIsDropdownOpen(false);
                    onChange(index);
                    setSelectedValue(option.value);
                  }}
                >
                  {option.label}
                </div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="self-stretch relative tracking-[-0.1px] leading-[20px] text-gray-50 hidden">
        Hint text
      </div>
    </div>
  );
};

export default DropdownInput;
