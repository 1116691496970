import { useState } from "react";
import FileItem from "./FileItem";
import DropdownInput from "./DropdownInput";
import CheckboxGroup from "./CheckBoxGroup"; //DONE
import FormInputCard from "./FormInputCard"; //DONE
import InputSelect from "./InputSelect";
import YesNoToggle from "./YesNoToggle"; //DONE
import RadioInputCard from "./RadioInputCard"; //DONE
import { useNavigate } from "react-router-dom";
export const ApplicationQuestion = ({
  questionData,
  onValueChange,
  value,
  inp,
  setJsonSuccess
}) => {
  const navigate = useNavigate();
  const [questionValue, setQuestionValue] = useState({});
  const titleText = questionData.required ? `${questionData.label} *` : questionData.label;
  let inputValue = inp;
  if (Array.isArray(inputValue)) {
    inputValue.forEach((val, idx) => {
      const inputOptionValue = questionData.options?.find(({
        label
      }) => label === val || label.includes(val))?.value;
      if (inputOptionValue) {
        inputValue[idx] = inputOptionValue;
      }
    });
  } else if (questionData.options && questionData.options.length) {
    const val = questionData.options.find(({
      label
    }) => label && (label === inputValue || label.includes(inputValue)))?.value;
    inputValue = val || inputValue;
  } else if (questionData.type !== "text" && questionData.type !== "textarea" && questionData.type !== "number" && questionData.tag !== "input" && questionData.tag !== "textarea" && questionData.tag !== "text" && typeof inputValue === "string" && inputValue.includes(",")) {
    inputValue = inputValue.split(",").map(item => item.trim());
  }
  switch (questionData.type || questionData.tag) {
    case "inputSelect":
      {
        return <InputSelect key={questionData.index} titleText={titleText} options={questionData.parsedOptions} value={inputValue} onChange={val => {
          /*if (!options[value]) {
          console.error("The selected option does not exist");
          return;
          }*/
          setJsonSuccess(true);
          //setInputValues({ ...inputValues, [questionData.label]: value });
          onValueChange(val);
        }} />;
      }
    case "number":
      {
        return <FormInputCard key={questionData.index} titleText={titleText} inputText={questionData.placeholder} value={/^\d*$/.test(inputValue) ? inputValue : ""} onChange={val =>
        //setInputValues({ ...inputValues, [input.label]: value })
        onValueChange(val)} isNumber={true} />;
      }
    case "toggle":
      {
        return <YesNoToggle key={questionData.index} titleText={titleText} required={questionData.required} onChange={checked => {
          //setInputValues({ ...inputValues, [input.label]: checked });
          onValueChange(checked);
        }} value={inputValue} />;
      }
    case "checkbox":
      {
        if (!Array.isArray(inputValue)) {
          inputValue = [inputValue];
        }
        //console.log("inputvals", inputValues[input.label]);
        //console.log("inputval", inputValue);
        return <CheckboxGroup key={questionData.index} label={titleText} options={questionData.options} required={questionData.required} initialChecked={inputValue} onChange={(name, checked) => {
          //console.log("inputvals", inputValues[input.label]);
          //console.log("inputval", inputValue);
          //const newInputValue = inputValues[input.label] || [];
          const newInputValue = inp || [];
          if (checked && !newInputValue.includes(name)) {
            newInputValue.push(name);
          } else if (!checked && newInputValue.includes(name)) {
            newInputValue.splice(newInputValue.indexOf(name), 1);
          }
          //setInputValues({ ...inputValues, [input.label]: newInputValue });
          onValueChange(newInputValue);
        }} />;
      }
    case "group" && !questionData.required:
      return;
    // case "file":
    //   return (
    //     <FileUpload
    //       key={inputIdx}
    //       titleText={titleText}
    //       file={inputValue}
    //       setFile={() => {}}
    //     />
    //   );
    case "radio":
      {
        return <RadioInputCard key={questionData.index} titleText={titleText} options={questionData.options} value={inputValue} onChange={checked =>
        //setInputValues({ ...inputValues, [input.label]: checked })
        onValueChange(checked)} />;
      }
    case "select":
      {
        if (!questionData) {
          console.error("input is undefined");
          return null;
        }
        if (!questionData.options) {
          console.error("options are undefined or the selected option does not exist");
          //console.log(input, options, options[inputValue])
          return null;
        }
        if (!questionData.options[inputValue]) {
          return <DropdownInput key={questionData.index} titleText={titleText} options={questionData.options} value={inputValue} onChange={val => {
            if (!questionData.options[val]) {
              console.error("The selected option does not exist");
              return;
            }

            // setInputValues({
            //   ...inputValues,
            //   [input.label]: options[value].label,
            // });
            onValueChange(questionData.options[val].value);
          }} />;
        }
        return <DropdownInput key={questionData.index} titleText={titleText} options={questionData.options} value={inputValue} onChange={val => {
          if (!questionData.options[val]) {
            console.error("The selected option does not exist");
            return;
          }

          // setInputValues({
          //   ...inputValues,
          //   [input.label]: options[value].label,
          // });
          onValueChange(questionData.options[val].value);
        }} />;
      }
    case "button":
      {
        if (questionData.options) {
          return <DropdownInput key={questionData.index} titleText={titleText} options={questionData.options} value={inputValue} onChange={val => {
            if (!questionData.options[val]) {
              console.error("The selected option does not exist");
              return;
            }
            // setInputValues({
            //   ...inputValues,
            //   [input.label]: options[value].label,
            // });
            onValueChange(questionData.options[val].value);
          }} />;
        }
        return;
      }
    case "file":
      return <>
          <div>
            {questionData.options.map((file, idx) => <FileItem filename={file.title} format={file.format} lastUsed={file.lastUsed} key={`file-${file.id}-${idx}`} id={file.index} onSelect={() => {
            //console.log(file);
            onValueChange(file.index);
            //setQuestionValue({ selectedFileId: id });
          }} selected={inp && inp === file.index} />)}
          </div>
          <div className="flex mt-2 mb-4">
            {/*<button
              className={`mr-4 py-3 px-6 rounded-md border-neutral-700 border-[1px] border-solid bg-base-white text-primary-600 font-sm`}
             >
              Upload
             </button>*/}
            <button className="py-3 px-6 rounded-md bg-mediumseagreen-200 text-white text-sm font-sm hover:bg-green-300" onClick={() => navigate("/resumes")}>
              Create
            </button>
          </div>
        </>;
    default:
      return <FormInputCard key={questionData.index} titleText={titleText} inputText={questionData.placeholder} value={inputValue} onChange={val =>
      //setInputValues({ ...inputValues, [input.label]: value })
      onValueChange(val)} />;
  }
};
export default ApplicationQuestion;