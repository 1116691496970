import PlacesAutocomplete from 'react-places-autocomplete';
const PlacesInputCard = ({
  titleText,
  inputText,
  value,
  required,
  onChange,
  maxlen,
  regex,
  onSelect
}) => {
  return <PlacesAutocomplete value={value} onChange={onChange} onSelect={onSelect}>
      {({
      getInputProps,
      suggestions,
      getSuggestionItemProps,
      loading
    }) => <div className="flex-1 flex flex-col items-start justify-start gap-[8px] text-left text-sm text-gray-700 font-text-l-regular">
          <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
            <span>{titleText + " "}</span>
            {required ? <span style={{
          color: "red"
        }}>*</span> : null}
          </div>
          <div className="relative w-full" style={{
        height: 'auto'
      }}>
          <input className="w-full font-text-l-regular text-mini focus:outline-none focus:ring focus:ring-green-50 focus:border-mediumseagreen-200 bg-base-white self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700" {...getInputProps({
          placeholder: inputText
        })} required={required} type="text" />
          {suggestions.length > 0 && <div className="absolute z-10 bg-white w-full border rounded-md shadow-md text-base overflow-auto">
              {suggestions.slice(0, 5).map((suggestion, index) => <div className="text-base truncate px-2 py-1 hover:bg-gray-200 cursor-pointer" {...getSuggestionItemProps(suggestion)} key={index}>
                {suggestion.description}
                </div>)}
            </div>}
        </div>
        </div>}
    </PlacesAutocomplete>;
};
export default PlacesInputCard;