import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useUserData } from "../UserDataContext";
import { collection, query, orderBy, limit, getDocs, startAfter, getDoc, doc, setDoc, where, onSnapshot, updateDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import SkeletonJobPanel from "../components/SkeletonPanel";
import AnalyticsContainer from "../components/AnalyticsContainer";
import { usePayment } from "../PaymentContext";
import WrangleTooltip from "../components/WrangleTooltip";
const Analytics_1 = _compiledBlock(_props => <div className="relative bg-neutral-50 w-full h-screen flex flex-row items-start justify-start text-left text-base text-gray-50 font-text-l-regular">
            <div className="m-auto items-center flex flex-col p-10 pt-4 rounded-2xl bg-base-white border border-solid border-neutral-600">
            <h1 className="w-full mb-7">404: Page not Found</h1>
            <button onClick={_props.v0} className="cursor-pointer [border:none] py-2.5 px-[18px] bg-mediumseagreen-200 self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center gap-[6px] hover:bg-green-300 transition-colors duration-300">
                <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular text-base-white text-left">
                Back to Wrangle
                </div>
            </button>
            </div>
        </div>, {
  name: "Analytics_1"
});
const Analytics_2 = _compiledBlock(_props2 => <div className="relative bg-neutral-50 w-full h-screen flex flex-row items-start justify-start text-left text-base text-gray-50 font-text-l-regular">
        <div className="relative bg-neutral-50 w-full h-screen flex flex-row items-start justify-start text-left text-base text-gray-50 font-text-l-regular">
            <div className="m-auto items-center flex flex-col p-10 pt-4 rounded-2xl bg-base-white border border-solid border-neutral-600">
                <h1 className="w-full mb-7">Big Button</h1>
                <button onClick={_props2.v0} className="cursor-pointer [border:none] py-2.5 px-[18px] bg-mediumseagreen-200 self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center gap-[6px] hover:bg-green-300 transition-colors duration-300">
                    <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular text-base-white text-left">
                    Check Queue
                    </div>
                </button>
                <button onClick={_props2.v1} className="cursor-pointer [border:none] py-2.5 px-[18px] bg-mediumseagreen-200 self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center gap-[6px] hover:bg-green-300 transition-colors duration-300">
                    <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular text-base-white text-left">
                    Toggle Failed
                    </div>
                </button>
                <button onClick={_props2.v2} className="cursor-pointer [border:none] py-2.5 px-[18px] bg-mediumseagreen-200 self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center gap-[6px] hover:bg-green-300 transition-colors duration-300">
                    {_props2.v3}
                </button>
                <div>
                    {_props2.v4}
                </div>
                <div>
                    {_props2.v5}
                </div>
            </div>
            <div className="self-stretch flex-1 overflow-hidden flex flex-row p-8 items-start justify-start gap-[32px] text-9xl text-gray-700">
                {_props2.v6}
                {_props2.v7}
            </div>
        </div>
    </div>, {
  name: "Analytics_2",
  portals: ["v3", "v4", "v5", "v6", "v7"]
});
const Analytics = () => {
  const {
    user,
    admin,
    isAuthenticated
  } = useUserData();
  const [successfulApps, setSuccessfulApps] = useState(0);
  const [pendingApps, setPendingApps] = useState(0);
  const [failedApps, setFailedApps] = useState(0);
  const [apps, setApps] = useState(0);
  const {
    setIsProcessing
  } = usePayment();
  const formatDate = timestamp => {
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  const [failed, setFailed] = useState(0);
  const [failedToggle, setFailedToggle] = useState(false);
  const requestAnalytics = async timeframe => {
    //const times = {0: "day", 1: "week", 2: "month"};
    //const timePeriod = times[timeframe];

    // if (admin) {
    try {
      const now = new Date();
      let dateFrom;
      dateFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - timeframe);
      // switch (timePeriod) {
      //     case "day":
      //         dateFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      //         break;
      //     case "week":
      //         dateFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      //         break;
      //     case "month":
      //         dateFrom = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      //         break;
      //     default:
      //         console.error("Invalid timeframe specified");
      //         return;
      // }

      // Create a reference to the collection
      const queueRef = collection(db, "queue");
      // Create a query against the collection, applying the where and orderBy filters
      const queueQuery = query(queueRef, where("createdAt", ">=", dateFrom), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(queueQuery);
      if (querySnapshot.empty) {
        console.log("No matching documents.");
        return;
      }
      let appsArr = [];
      // Processing the documents
      let success = 0;
      let failed = 0;
      let pending = 0;
      querySnapshot.forEach(doc => {
        let appData = doc.data();
        // Add the document ID as an 'id' property
        appData.id = doc.id;
        // Push the modified object into the array
        appsArr.push(appData);
        if (appData.status == "submitted") {
          success++;
        } else if (appData.status == "failed") {
          failed++;
        } else if (appData.status == "pending") {
          pending++;
        }
        console.log(doc.id, " => ", appData);
      });
      let failedApps = appsArr.filter(app => app.status === "failed");
      setFailed(failedApps);
      setSuccessfulApps(success);
      setPendingApps(pending);
      setFailedApps(failed);
      setApps(appsArr);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    // } else {
    //     console.log("Unauthorized access attempt");
    // }
  };
  const handlePayment = async () => {
    if (!isAuthenticated) {
      throw new Error("Not Authenticated");
    } else {
      setIsProcessing(true);
    }
    try {
      let idToken;
      if (auth.currentUser) {
        idToken = await auth.currentUser.getIdToken(true);
      } else {
        setIsProcessing(false);
        return;
      }
      const response = await fetch(`https://app.wranglejobs.com/api/create-checkout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify({
          item: {
            id: 1,
            quantity: 1
          }
        })
      });
      if (!response.ok) {
        const errorData = await response.json();
        setIsProcessing(false);
        throw new Error(errorData.message || "Failed to fetch the payment form URL");
      }
      const {
        url
      } = await response.json();
      setIsProcessing(false);
      window.location.href = url;
    } catch (e) {
      console.error(e.message || e);
      setIsProcessing(false);
    }
  };
  if (user.uid != "5ZbUc6Gv2COwZOjvHpU1i9tpNxm2" && user.uid != "BTGsPigEK7cIKrHxdCuGCnJ4HSl2" && user.uid != "iBcjyPbUmscttGKlrBEwM6c11km2") {
    return /*@million jsx-skip*/<Analytics_1 v0={() => {
      navigate("/jobs");
    }} />;
  }
  return /*@million jsx-skip*/<Analytics_2 v0={() => {
    const time = prompt("Enter Number of Days");
    requestAnalytics(time);
  }} v1={() => {
    setFailedToggle(!failedToggle);
  }} v2={() => {
    handlePayment();
  }} v3={<WrangleTooltip title="test">
                    <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular text-base-white text-left">
                    Test Stripe
                    </div>
                    </WrangleTooltip>} v4={"Successful: " + successfulApps + "   Pending: " + pendingApps + "   Failed: " + failedApps} v5={successfulApps + pendingApps + failedApps != 0 && "Percentage Success: " + (successfulApps / (successfulApps + pendingApps + failedApps) * 100).toFixed(2) + "%"} v6={<AnalyticsContainer formatDate={formatDate} apps={failedToggle ? failed : apps} />} v7={<SkeletonJobPanel />} />;
};
export default Analytics;