import cn from "classnames";
import { FormItem } from "./FormItem";
import { Input } from "./Input";
import { TextEditor } from "./TextEditor";
import { SlidePicker } from "./SlidePicker";
const Form = ({
  fields = [],
  className,
  onChange = () => false
}) => {
  //console.log("Fields in Form:", fields);
  const renderField = ({
    type,
    ...props
  }) => {
    if (!type) return null;
    const Component = {
      input: Input,
      editor: TextEditor,
      slidePicker: SlidePicker,
      hidden: null
    }[type];
    if (!Component) return null;
    return <Component {...props} />;
  };
  if (!FormItem) return null;
  return <div className={cn("grid gap-x-7 gap-y-4 grid-cols-4", className)}>
      {fields.filter(item => item.type !== "hidden").map(item => {
      return <FormItem key={item.name} name={item.name} label={item.label} required={item.required} description={item.description} className={cn({
        "col-span-1": item.span === 1,
        "col-span-2": item.span === 2,
        "col-span-3": item.span === 3,
        "col-span-4": !item.span || item.span === 4
      })} onChange={val => onChange(item.name, val)}>
              {renderField(item)}
            </FormItem>;
    })}
    </div>;
};
export { Form };