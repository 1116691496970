import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserData } from "../UserDataContext";
import { useIdentifyUser } from "../hooks/useIdentifyUser";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import queryString from "query-string";
import Intercom from "@intercom/messenger-js-sdk";
const Copilot = () => {
  useIdentifyUser();
  const {
    userData,
    user,
    setUserData
  } = useUserData();
  const [activeThreadIndex, setActiveThreadIndex] = useState(null);
  const [question, setQuestion] = useState("");
  const [message, setMessage] = useState("");
  const [chatMode, setChatMode] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newThreadAdded, setNewThreadAdded] = useState(false);
  {
    /*
    Intercom({
    app_id: "qgss7lxp",
    user_id: user.uid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: userData.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: userData.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: "1704067200", // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });
    */
  }
  const [threads, setThreads] = useState(userData.threads ? userData.threads : [{
    title: "How do I ace my interview?",
    messages: [{
      text: "How do I ace my interview?",
      sender: "user"
    }],
    isSuggestion: true
  }, {
    title: "What are some tips on reaching out to recruiters?",
    messages: [{
      text: "What are some tips on reaching out to recruiters?",
      sender: "user"
    }],
    isSuggestion: true
  }, {
    title: "How to Improve Public Speaking Skills?",
    messages: [{
      text: "How to improve public speaking skills?",
      sender: "user"
    }],
    isSuggestion: true
  }, {
    title: "How to Manage Work Stress Effectively?",
    messages: [{
      text: "How to manage work stress effectively?",
      sender: "user"
    }],
    isSuggestion: true
  }, {
    title: "How to Negotiate a Higher Salary?",
    messages: [{
      text: "How to negotiate a higher salary?",
      sender: "user"
    }],
    isSuggestion: true
  }, {
    title: "How to Develop Leadership Qualities?",
    messages: [{
      text: "How to develop leadership qualities?",
      sender: "user"
    }],
    isSuggestion: true
  }]);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query.thread) {
      const decodedQuestion = decodeURIComponent(query.thread);
      const existingThreadIndex = threads.findIndex(thread => thread.title === decodedQuestion);
      if (existingThreadIndex === -1 && !newThreadAdded) {
        setQuestion(decodedQuestion);
        handleQuestionSubmit(decodedQuestion);
      } else {
        setActiveThreadIndex(existingThreadIndex);
        setChatMode(true);
      }
    }
    // Reset the newThreadAdded flag after the effect runs
    setNewThreadAdded(false);
  }, [location.search, threads, newThreadAdded]);
  useEffect(() => {
    const messagesEndRef = document.querySelector("#messagesEnd");
    if (messagesEndRef) {
      messagesEndRef.scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [message, activeThreadIndex]);
  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query.thread) {
      const decodedQuestion = decodeURIComponent(query.thread);
      // Check if the thread already exists
      const existingThreadIndex = threads.findIndex(thread => thread.title === decodedQuestion);
      if (existingThreadIndex === -1) {
        // Only create a new thread if it doesn't already exist
        setQuestion(decodedQuestion);
        handleQuestionSubmit(decodedQuestion);
      } else {
        // If the thread already exists, just set it as the active thread
        setActiveThreadIndex(existingThreadIndex);
        setChatMode(true);
      }
    }
  }, [location.search, threads]);
  const handleQuestionSubmit = async (submittedQuestion = question) => {
    if (submittedQuestion.trim() !== "") {
      const newThread = {
        title: submittedQuestion,
        messages: [{
          text: submittedQuestion,
          sender: "user"
        }]
      };
      const newThreads = [...threads, newThread];
      const activeIndex = newThreads.length - 1;
      const apiQuestion = submittedQuestion;
      setActiveThreadIndex(activeIndex);
      setChatMode(true);
      newThreads[activeIndex].messages.push({
        text: "thinking",
        sender: "copilot"
      });
      setThreads(newThreads);
      setQuestion("");
      navigate(`?thread=${encodeURIComponent(submittedQuestion)}`);

      // Set the newThreadAdded flag to true
      setNewThreadAdded(true);
      try {
        const apiResponse = await sendToCopilotAPI(apiQuestion);
        newThreads[activeIndex].messages.pop();
        newThreads[activeIndex].messages.push({
          text: apiResponse,
          sender: "copilot"
        });
        setThreads(newThreads);
      } catch (error) {
        console.error("Error sending message to Copilot API:", error);
        newThreads[activeIndex].messages.pop();
        newThreads[activeIndex].messages.push({
          text: "Error: Could not load response.",
          sender: "copilot"
        });
        setThreads(newThreads);
      }
      const updatedUserData = {
        ...userData,
        threads: newThreads
      };
      console.log("updatedUserData", updatedUserData);
      setUserData(updatedUserData);
    }
  };
  const handleMessageSubmit = async () => {
    if (message.trim() !== "" && activeThreadIndex !== null) {
      const tempMessage = {
        text: "thinking",
        sender: "copilot"
      };
      const newThreads = [...threads];
      newThreads[activeThreadIndex].messages.push({
        text: message,
        sender: "user"
      }, tempMessage);
      setThreads(newThreads);
      const apiMessage = message;
      console.log(message);
      setMessage("");
      try {
        const apiResponse = await sendToCopilotAPI(apiMessage);
        newThreads[activeThreadIndex].messages.pop();
        newThreads[activeThreadIndex].messages.push({
          text: apiResponse,
          sender: "copilot"
        });
        setThreads(newThreads);
      } catch (error) {
        console.error("Error sending message to Copilot API:", error);
        newThreads[activeThreadIndex].messages.pop();
        newThreads[activeThreadIndex].messages.push({
          text: "Error: Could not load response.",
          sender: "copilot"
        });
        setThreads(newThreads);
      }
    }
  };
  const sendToCopilotAPI = async latestMessageText => {
    setIsLoading(true);
    let formattedMessages = [];
    if (threads[activeThreadIndex] && threads[activeThreadIndex].messages) {
      formattedMessages = threads[activeThreadIndex].messages.map(msg => ({
        role: msg.sender === "user" ? "user" : "assistant",
        content: msg.text
      }));
    }
    try {
      const response = await axios.post("https://app.wranglejobs.com/api/copilot", {
        messages: formattedMessages,
        latestMessage: {
          role: "user",
          content: latestMessageText
        },
        userInfo: userData.keyInfo ? userData.keyInfo : "Professional looking for a job"
      });
      const apiResponse = response.data.markdownResponse ? response.data.markdownResponse : "Error: No response from API";
      setIsLoading(false);
      return apiResponse;
    } catch (error) {
      console.error("Error sending message to Copilot API:", error);
      setIsLoading(false);
      return "Error: Could not load response.";
    }
  };
  return <div className="relative bg-neutral-50 w-full h-screen flex flex-col overflow-hidden text-left text-9xl text-gray-700 font-text-l-regular">
      <div className="p-8 flex-none">
        <AnimatePresence layout>
          <motion.div initial={{
          opacity: 0,
          translateX: -10
        }} animate={{
          opacity: 1,
          translateX: 0
        }} exit={{
          opacity: 0,
          translateX: -10
        }} transition={{
          duration: 0.4
        }}>
            <div className="tracking-[-0.01em] leading-[38px] font-semibold mb-2">
              AI Advisor
            </div>
            <div className="text-base tracking-[-0.1px] leading-[24px] text-gray-50 mb-6">
              Our AI advisor is here to help. Get started by asking it for
              advice, questions, or anything else that's on your mind.
            </div>
          </motion.div>
        </AnimatePresence>
        <div className="flex gap-4">
          <AnimatePresence>
            <motion.div className="flex gap-4" layout>
              {chatMode && <div className="flex flex-wrap gap-2">
                  <div className="flex-1 cursor-pointer flex flex-col items-start justify-start gap-[8px] text-left text-sm text-gray-700 font-text-l-regular">
                    <AnimatePresence>
                      <motion.div initial={{
                    opacity: 0,
                    translateX: -10
                  }} animate={{
                    opacity: 1,
                    translateX: 0
                  }} exit={{
                    opacity: 0,
                    translateX: -10
                  }} transition={{
                    duration: 0.4
                  }} onClick={() => {
                    setMessages([]);
                    setChatMode(false);
                  }} className="font-text-l-regular text-mini h-[44px] bg-mediumseagreen-200 self-stretch rounded-md box-border shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-start">
                        <motion.svg whileTap={{
                      scale: 1.1
                    }} className={`outline-none relative w-[18px] h-[18px] shrink-0 ${"text-gray-200"}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="transparent">
                          <path d="M21 20.9998H13M2.5 21.4998L8.04927 19.3655C8.40421 19.229 8.58168 19.1607 8.74772 19.0716C8.8952 18.9924 9.0358 18.901 9.16804 18.7984C9.31692 18.6829 9.45137 18.5484 9.72028 18.2795L21 6.99982C22.1046 5.89525 22.1046 4.10438 21 2.99981C19.8955 1.89525 18.1046 1.89524 17 2.99981L5.72028 14.2795C5.45138 14.5484 5.31692 14.6829 5.20139 14.8318C5.09877 14.964 5.0074 15.1046 4.92823 15.2521C4.83911 15.4181 4.77085 15.5956 4.63433 15.9506L2.5 21.4998ZM2.5 21.4998L4.55812 16.1488C4.7054 15.7659 4.77903 15.5744 4.90534 15.4867C5.01572 15.4101 5.1523 15.3811 5.2843 15.4063C5.43533 15.4351 5.58038 15.5802 5.87048 15.8703L8.12957 18.1294C8.41967 18.4195 8.56472 18.5645 8.59356 18.7155C8.61877 18.8475 8.58979 18.9841 8.51314 19.0945C8.42545 19.2208 8.23399 19.2944 7.85107 19.4417L2.5 21.4998Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </motion.svg>
                      </motion.div>
                    </AnimatePresence>
                  </div>
                </div>}
              <AnimatePresence>
                <motion.div className="flex gap-2 overflow-x-auto max-h-[200px] scrollbar-hide" initial={{
                opacity: 0,
                translateX: -10
              }} animate={{
                opacity: 1,
                translateX: 0
              }} exit={{
                opacity: 0,
                translateX: -10
              }} transition={{
                duration: 0.2
              }} layout>
                  {threads.slice().reverse().map((thread, index) => <div key={index} onClick={async () => {
                  const threadIndex = threads.length - 1 - index;
                  setActiveThreadIndex(threadIndex);
                  setChatMode(true);
                  if (thread.isSuggestion && thread.messages.length === 1) {
                    const firstMessage = thread.messages[0];
                    if (firstMessage.sender === "user") {
                      const newThreads = [...threads];
                      newThreads[threadIndex].messages.push({
                        text: "thinking",
                        sender: "copilot"
                      });
                      setThreads(newThreads);
                      try {
                        const apiResponse = await sendToCopilotAPI(firstMessage.text);
                        newThreads[threadIndex].messages.pop();
                        newThreads[threadIndex].messages.push({
                          text: apiResponse,
                          sender: "copilot"
                        });
                        setThreads(newThreads);
                      } catch (error) {
                        console.error("Error sending message to Copilot API:", error);
                        newThreads[threadIndex].messages.pop();
                        newThreads[threadIndex].messages.push({
                          text: "Error: Could not load response.",
                          sender: "copilot"
                        });
                        setThreads(newThreads);
                      }
                    }
                  }
                }} className={`font-text-l-regular cursor-pointer transform-gpu hover:bg-neutral-50 transition-all duration-300 text-sm bg-base-white rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[44px] shrink-0 overflow-hidden flex flex-row py-2 px-4 items-center justify-start border-[1px] border-solid ${threads.length - 1 - index == activeThreadIndex ? "border-mediumseagreen-200" : "border-neutral-700 hover:border-neutral-300"}
                        ${threads.length - 1 - index == activeThreadIndex ? "text-black" : "text-light-100"}
                        mr-2`}>
                        {thread.title}
                      </div>)}
                </motion.div>
              </AnimatePresence>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      <AnimatePresence>
        <div className={`flex flex-col pb-4 h-full ${chatMode ? "justify-end" : "justify-center"} `}>
          <div className={`flex flex-col-reverse items-center h-full ${chatMode ? "flex-grow" : "flex-grow justify-center"}`}>
            <div className={`flex flex-col w-full ${chatMode ? "justify-end" : "justify-center"} ${chatMode ? "w-[60%] max-w-[60%]" : "w-[50%] max-w-[50%]"}`}>
              {chatMode && <AnimatePresence>
                  <motion.div className="flex flex-col overflow-y-auto" style={{
                maxHeight: "65vh"
              }} layout="position">
                    {activeThreadIndex !== null && threads[activeThreadIndex] && threads[activeThreadIndex].messages.map((message, index) => <motion.div className="flex items-start mb-4" key={index} initial={{
                  opacity: 0
                }} animate={{
                  opacity: 1
                }} exit={{
                  opacity: 0
                }} transition={{
                  duration: 0.4,
                  ease: "easeIn"
                }}>
                            <div className={`flex justify-center items-center h-[46px] w-[46px] ${message.sender === "user" ? "bg-gray-200" : ""} ${message.sender === "copilot" ? "bg-copilot" : "bg-base-white"} rounded-md box-border shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] mr-4`} style={{
                    backgroundImage: message.sender === "copilot" ? "url('/copilot.png')" : "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                  }}>
                              {message.sender === "user" ? <span className="text-lg font-semibold">
                                  {userData.name ? userData.name[0].toUpperCase() : "U"}
                                </span> : ""}
                            </div>
                            <motion.div key={index} className={`font-text-l-regular box-border items-center transform-gpu transition-all min-h-[46px] flex-none duration-300 text-light-100 text-sm bg-base-white rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] px-4 ${message.sender === "copilot" ? "" : "py-2"} flex items-start justify-start border-[1px] border-solid border-neutral-700 w-auto max-w-[80%] break-words`} style={{
                    width: "fit-content"
                  }}>
                              {message.sender === "copilot" ? message.text === "thinking" ? <motion.div animate={{
                      rotate: 360
                    }} transition={{
                      repeat: Infinity,
                      duration: 1
                    }} className="flex items-center justify-center">
                                    <svg className="w-4 h-4 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <circle className="opacity-25" cx="12" cy="12" r="10" strokeWidth="4"></circle>
                                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                  </motion.div> : <ReactMarkdown className="prose">
                                    {message.text}
                                  </ReactMarkdown> : message.text}
                            </motion.div>
                          </motion.div>)}
                    <div id="messagesEnd" />
                  </motion.div>
                </AnimatePresence>}

              <motion.div className="flex-none" transition={{
              duration: 0.4,
              ease: "easeInOut",
              type: "spring",
              stiffness: 100,
              damping: 20
            }} layout>
                <motion.div className="bg-base-white flex-none flex-col items-start w-full border-[1px] box-border border-solid transform-gpu transition-all duration-300 hover:border-neutral-300 border-neutral-700 rounded-md">
                  <input className="font-text-l-regular text-mini bg-base-white py-3 px-4 w-full box-border h-[46px] rounded-md outline-none" type="text" placeholder={chatMode ? "Type your response..." : "Ask a question..."} value={chatMode ? message : question} onChange={chatMode ? e => setMessage(e.target.value) : e => setQuestion(e.target.value)} onKeyDown={event => {
                  if (event.key === "Enter") {
                    chatMode ? handleMessageSubmit() : handleQuestionSubmit();
                  }
                }} />
                  {!chatMode && <div className="w-[95%] py-3 px-4 text-center flex justify-between items-center">
                      <div className="flex gap-2">
                        <span style={{
                      backgroundColor: "#E6FBF0",
                      color: "#5BC68D",
                      fontSize: "0.75rem",
                      padding: "4px 8px",
                      borderRadius: "4px"
                    }}>
                          Copilot 1.0
                        </span>
                        <span style={{
                      backgroundColor: "#EBF2FF",
                      color: "#437EF7",
                      fontSize: "0.75rem",
                      padding: "4px 8px",
                      borderRadius: "4px"
                    }}>
                          General Assistant
                        </span>
                      </div>
                      <motion.svg whileTap={{
                    scale: 1.1
                  }} className="outline-none relative w-[24px] h-[24px] shrink-0 text-gray-200 cursor-pointer transform-gpu transition-all duration-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" onClick={() => handleQuestionSubmit()}>
                        <path d="M10.4995 13.5002L20.9995 3.00017M10.6271 13.8282L13.2552 20.5862C13.4867 21.1816 13.6025 21.4793 13.7693 21.5662C13.9139 21.6415 14.0862 21.6416 14.2308 21.5664C14.3977 21.4797 14.5139 21.1822 14.7461 20.5871L21.3364 3.69937C21.5461 3.16219 21.6509 2.8936 21.5935 2.72197C21.5437 2.57292 21.4268 2.45596 21.2777 2.40616C21.1061 2.34883 20.8375 2.45364 20.3003 2.66327L3.41258 9.25361C2.8175 9.48584 2.51997 9.60195 2.43326 9.76886C2.35809 9.91354 2.35819 10.0858 2.43353 10.2304C2.52043 10.3972 2.81811 10.513 3.41345 10.7445L10.1715 13.3726C10.2923 13.4196 10.3527 13.4431 10.4036 13.4794C10.4487 13.5115 10.4881 13.551 10.5203 13.5961C10.5566 13.647 10.5801 13.7074 10.6271 13.8282Z" stroke={question ? "#5CD193" : "#B2ABAB"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </motion.svg>
                    </div>}
                </motion.div>
                <motion.div className="text-sm text-light-50 mt-4 text-center w-full" initial={{
                opacity: 0,
                translateY: 10
              }} animate={{
                opacity: 1,
                translateY: 0
              }} exit={{
                opacity: 0,
                translateY: 10
              }} transition={{
                duration: 0.4,
                ease: "easeInOut"
              }}>
                  Our copilot will answer questions and give advice based on
                  your profile.{" "}
                  <Link to="/settings" className="text-mediumseagreen-200" style={{
                  textDecoration: "none"
                }}>
                    Update Info
                  </Link>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </AnimatePresence>
    </div>;
};
export default Copilot;