import React, { forwardRef } from "react";

import { motion } from "framer-motion";

const SideMenuItem = forwardRef(
  (
    {
      icon,
      label,
      onClick,
      isActive,
      backgroundColor,
      collapsed,
      tag,
      onHover,
      onLeave,
    },
    ref
  ) => {
    const textVariants = {
      collapsed: { opacity: 0, x: -5 },
      expanded: { opacity: 1, x: 0 },
      exit: { opacity: 0, x: -5 },
    };

    const badgeStyle = {
      backgroundColor: "#E6FBF0",
      color: "#5BC68D",
      fontSize: "0.7em",
      padding: "2px 4px",
      borderRadius: "4px",
      marginLeft: "10px",
      border: "1px solid #5BC68D",
    };

    return (
      <div
        ref={ref}
        onClick={onClick}
        onMouseEnter={() => {
          onHover();
        }}
        onMouseLeave={onLeave}
        className="cursor-pointer"
      >
        <button
          className={`px-3 w-full bg-[transparent] text-left cursor-pointer z-20 ${
            isActive
              ? "text-primary-600"
              : "transition-colors duration-300 text-gray-50 hover:text-primary-600"
          }`}
        >
          <div
            className={`rounded-md z-20 px-4 ${
              isActive
                ? "bg-neutral-50 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border shrink-0 overflow-hidden border-[1px] border-solid border-neutral-700"
                : "border-[1px] border-solid border-white"
            } self-stretch py-2.5 shrink-0 flex flex-row box-border transition-colors duration-300 items-center justify-start gap-[12px]`}
          >
            <img
              className="relative w-[20px] h-[20px] shrink-0 overflow-hidden z-[0]"
              alt=""
              src={icon}
            />

            <motion.div
              variants={textVariants}
              animate={collapsed ? "collapsed" : "expanded"}
              transition={{ duration: 0.3 }}
              exit="exit"
              className="flex-1 relative text-mini tracking-[-0.1px] leading-[22px] bg-[transparent] font-medium font-text-l-regular text-left z-[1] whitespace-nowrap"
            >
              {label}
              {tag && <span style={badgeStyle}>{tag}</span>}
            </motion.div>
          </div>
          <img
            className="relative w-6 h-6 shrink-0 overflow-hidden hidden z-[3]"
            alt=""
            src="/chevrondown.svg"
          />
        </button>
      </div>
    );
  }
);

export default SideMenuItem;
