import { CERTIFICATE_ITEM } from "../../constants/DataForms";

export const ADDITIONAL_CONTENT = [
  {
    name: "certificates",
    displayTitle: "Certificates",
    type: "list",
    draggable: true,
    fields: CERTIFICATE_ITEM,
    addOneName: "certificate",
    renderTitle: (item) => `${item.title || ""}`,
    renderDescription: (item) => `${item.date || ""}`,
  },
];
