import { useJobs } from "../jobsContext";
import { useState, useEffect } from "react";
import React from "react";
import JobsContainer from "../components/JobsContainer";
import JobPanel from "../components/JobPanel";
import { useUserData } from "../UserDataContext";
import SkeletonJobPanel from "../components/SkeletonPanel";
import { useIdentifyUser } from "../hooks/useIdentifyUser";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Intercom from "@intercom/messenger-js-sdk";
const Jobs = () => {
  useIdentifyUser();
  const {
    selectedJob
  } = useJobs();
  const location = useLocation();
  const [modeParam, setModeParam] = useState(null);
  const {
    userData,
    user,
    setUserData
  } = useUserData();
  {
    /*Intercom({
    app_id: "qgss7lxp",
    user_id: user.uid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: userData.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: userData.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: "1704067200", // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });*/
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get("mode");
    if (mode) {
      console.log(mode);
      setModeParam(mode);
    }
  }, [location.search]);
  return <div className="relative bg-neutral-50 w-full h-screen flex flex-row items-start justify-start text-left text-base text-gray-50 font-text-l-regular">
      <div className="self-stretch flex-1 overflow-hidden flex flex-row p-8 items-start justify-start gap-[32px] text-9xl text-gray-700">
        <JobsContainer mode={modeParam} />
        {selectedJob ? <JobPanel /> : <SkeletonJobPanel />}
      </div>
    </div>;
};
export default React.memo(Jobs);