import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { useEffect } from "react";
import { SKILL_OPTIONS } from "../../constants/DataForms";
import { textUtil } from "../../utils";
import "../fonts/IBMPlexSans/stylesheet.css";
import "../fonts/IBMPlexSans/IBMPlexSans";
import "../fonts/IBMPlexSans/IBMPlexSans-Medium";
import "../fonts/IBMPlexSans/IBMPlexSans-Bold";
const SectionGrop_1 = _compiledBlock(_props => <div style={_props.v0}>
      <span style={_props.v1}>
        {_props.v2}
      </span>
      <div style={_props.v3}>
        {_props.v4}
      </div>
    </div>, {
  name: "SectionGrop_1",
  portals: ["v2", "v4"]
});
const SectionGrop = ({
  title,
  children,
  style = {},
  contentStyle = {},
  showIf = false,
  titleStyle = {}
}) => {
  if (!showIf) return null;
  return /*@million jsx-skip*/<SectionGrop_1 v0={{
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: 30,
    ...style
  }} v1={{
    width: "150px",
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "1em",
    ...titleStyle
  }} v2={title} v3={{
    ...{
      display: "flex",
      flex: 1,
      flexDirection: "column"
    },
    ...contentStyle
  }} v4={children} />;
};
const SectionGrop2_1 = _compiledBlock(_props2 => <div style={_props2.v0}>
      <span style={_props2.v1}>
        {_props2.v2}
      </span>
      <div style={_props2.v3}>
        {_props2.v4}
      </div>
    </div>, {
  name: "SectionGrop2_1",
  portals: ["v2", "v4"]
});
const SectionGrop2 = ({
  title,
  children,
  contentStyle = {},
  showIf = false
}) => {
  if (!showIf) return null;
  return /*@million jsx-skip*/<SectionGrop2_1 v0={{
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: 30
  }} v1={{
    width: "100%",
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "1em"
  }} v2={title} v3={{
    ...{
      display: "flex",
      flex: 1,
      flexDirection: "column ",
      marginTop: "10px"
    },
    ...contentStyle
  }} v4={children} />;
};
const Template5_1 = _compiledBlock(_props3 => <div style={_props3.v0}>
      <div style={_props3.v1}>
        <div style={_props3.v2}>
          {/* <img
            src="/profile.png"
            style={{
              width: 115,
              height: 115,
              borderRadius: 5,
            }}
            alt={`${data.first_name} ${data.last_name}`}
           /> */}
          <div style={_props3.v3}>
            {_props3.v4}
            <div style={_props3.v5}>
              <span>
                {_props3.v6} {_props3.v7}
              </span>
            </div>
            <div style={_props3.v8}>
              <div style={_props3.v9}>
                {_props3.v10}
              </div>
              {/* <div style={{ flex: 1 }}>
                <span>paisleysloan.porfolio</span>
                <span>skype: sloanpaisley</span>
               </div> */}
            </div>
          </div>
        </div>
      </div>
      <div style={_props3.v11}>
        {_props3.v12}
      </div>
      <div style={_props3.v13}>
        <div style={_props3.v14}>
          {_props3.v15}
          {_props3.v16}
        </div>
        <div style={_props3.v17}>
          {_props3.v18}
          {_props3.v19}
        </div>
      </div>
    </div>, {
  name: "Template5_1",
  portals: ["v4", "v6", "v7", "v10", "v12", "v15", "v16", "v18", "v19"]
});
const Template5 = ({
  doc,
  data
}) => {
  const contacts = [[data.country, data.city].filter(i => i).join(", "), data.email, textUtil.getUSNumber(data.phone) || data.phone].filter(item => item);
  useEffect(() => {
    // Adding the fonts
    doc?.setFont("IBMPlexSans", "normal");
    doc?.setFont("IBMPlexSans-Medium", "normal");
    doc?.setFont("IBMPlexSans-Bold", "normal");
  }, []);
  return /*@million jsx-skip*/<Template5_1 v0={{
    fontFamily: "IBMPlexSans",
    display: "flex",
    flexDirection: "column",
    padding: "50px",
    gap: "28px",
    paddingBottom: "80px",
    WebkitFontSmoothing: "antialiased",
    color: "#212121",
    fontSize: "16px",
    width: "100%",
    boxSizing: "border-box"
  }} v1={{
    display: "flex",
    flexDirection: "column"
  }} v2={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start"
  }} v3={{
    display: "flex",
    flexDirection: "column"
    // marginLeft: 20,
  }} v4={data.job_title && <p style={{
    color: "rgba(33, 33, 33, 0.6)",
    lineHeight: 1.62,
    fontSize: "0.85em"
  }}>
                {data.job_title}
              </p>} v5={{
    width: "100%",
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "1.125em",
    lineHeight: 1.8,
    display: "flex",
    flexDirection: "column"
  }} v6={data.first_name} v7={data.last_name} v8={{
    display: "flex",
    flexDirection: "row",
    gap: 44,
    color: "rgba(33, 33, 33, 0.6)",
    lineHeight: 1.62,
    fontSize: "0.85em"
  }} v9={{
    flex: 1
  }} v10={!!contacts?.length && <div style={{
    display: "flex",
    flexDirection: "column",
    width: "fit-content"
  }}>
                    {contacts.map((item, i) => <span key={`contact${i}`}>{item}</span>)}
                  </div>} v11={{
    borderTop: "1px solid #dfdbdb",
    borderBottom: "1px solid #dfdbdb",
    borderLeftWidth: 0,
    borderRightWidth: 0,
    padding: "20px 0"
  }} v12={<SectionGrop title="Profile" style={{
    margin: 0
  }} showIf={data.summary}>
          <span style={{
      fontSize: "0.85em",
      lineHeight: 1.62,
      color: "#928585",
      marginTop: "-10px"
    }} dangerouslySetInnerHTML={{
      __html: data.summary
    }} />
        </SectionGrop>} v13={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 30
  }} v14={{
    flex: 1.85,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  }} v15={<SectionGrop2 title="Education" contentStyle={{
    gap: "20px"
  }} showIf={data.educations?.length}>
            {data.educations?.map((item, n) => <div key={item + n} style={{
      display: "flex",
      flexDirection: "column",
      gap: "4px"
    }}>
                <div style={{
        display: "flex",
        gap: "16px"
      }}>
                  <span style={{
          fontSize: "0.75em",
          marginTop: "4px",
          color: "#928585"
        }}>
                    {`${item.start_date || ""}${item.end_date ? " - " + item.end_date : ""}`}
                  </span>
                  <div style={{
          display: "flex",
          flexDirection: "column",
          flex: 1
        }}>
                    <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            fontFamily: "IBMPlexSans-Medium"
          }}>
                      {item.school}
                    </span>
                  </div>
                </div>
                <span style={{
        fontSize: "0.85em",
        lineHeight: 1.62,
        color: "#928585"
      }}>
                  {item.degree}
                </span>
              </div>)}
          </SectionGrop2>} v16={<SectionGrop2 title="Employment" contentStyle={{
    gap: "30px"
  }} showIf={data.employments?.length}>
            {data.employments?.map((item, n) => <div key={item + n} style={{
      display: "flex",
      flexDirection: "column",
      gap: "4px"
    }}>
                <div style={{
        display: "flex",
        gap: "16px"
      }}>
                  <div style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "0.75em",
          color: "#928585",
          marginRight: 15,
          marginTop: "4px"
        }}>
                    <span>{`${item.start_date || ""}`}</span>
                    <span style={{
            width: "10px",
            height: "1px",
            margin: "12px 0",
            background: "#d0cbcb"
          }} />
                    <span>{`${item.end_date || ""}`}</span>
                  </div>
                  <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
                    <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            fontFamily: "IBMPlexSans-Medium"
          }}>
                      {`${item.job_title || ""}${item.employer ? " at " + item.employer : ""}`}
                    </span>
                    <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            color: "#928585"
          }} dangerouslySetInnerHTML={{
            __html: item.description
          }} />
                  </div>
                </div>
              </div>)}
          </SectionGrop2>} v17={{
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingLeft: 20,
    borderLeft: "1px solid #dfdbdb"
  }} v18={<SectionGrop title="Skills" contentStyle={{
    width: "100%"
  }} showIf={data.skills?.length}>
            <div style={{
      display: "flex",
      flexDirection: "column"
    }}>
              {data.skills?.map((item, n) => {
        const index = SKILL_OPTIONS?.findIndex(option => option?.value === item?.level);
        const percent = (index + 1) * 100 / SKILL_OPTIONS?.length;
        return <div key={item + n} style={{
          display: "flex",
          flexDirection: "column"
        }}>
                    <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "12px"
          }}>
                      <span style={{
              fontSize: "0.85em",
              lineHeight: 1.62,
              fontFamily: "IBMPlexSans-Medium"
            }}>
                        {item.skill}
                      </span>
                      <span style={{
              fontSize: "0.85em",
              lineHeight: 1.62,
              color: "#928585"
            }}>
                        {item.level}
                      </span>
                    </div>
                    <div style={{
            width: "100%",
            height: "0.25rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "0.125rem",
            overflow: "hidden",
            margin: "6px 0"
          }}>
                      <span style={{
              background: "#3E6AF2",
              width: `${percent}%`,
              height: "100%",
              borderRadius: "0.125rem"
            }} />
                      <span style={{
              background: "#EEEEEE",
              flex: 1,
              height: "100%",
              borderRadius: "0.125rem"
            }} />
                    </div>
                  </div>;
      })}
            </div>
          </SectionGrop>} v19={<SectionGrop title="Certifications and awards" titleStyle={{
    width: "100%"
  }} contentStyle={{
    width: "100%",
    gap: "6px"
  }} showIf={data.certificates?.length}>
            {data.certificates?.map((item, n) => <div key={item + n} style={{
      display: "flex",
      flexDirection: "column"
    }}>
                <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "12px"
      }}>
                  <span style={{
          fontSize: "0.85em",
          lineHeight: 1.62,
          fontFamily: "IBMPlexSans-Medium"
        }}>
                    {item.title}
                  </span>
                  <span style={{
          fontSize: "0.85em",
          lineHeight: 1.62,
          color: "#928585"
        }}>
                    {item.date}
                  </span>
                </div>
              </div>)}
          </SectionGrop>} />;
};
export default Template5;