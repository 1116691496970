import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { useEffect } from "react";
import { textUtil } from "../../utils";
import "../fonts/IBMPlexSans/stylesheet.css";
import "../fonts/IBMPlexSans/IBMPlexSans";
import "../fonts/IBMPlexSans/IBMPlexSans-Medium";
import "../fonts/IBMPlexSans/IBMPlexSans-Bold";
const SectionGrop_1 = _compiledBlock(_props => <div style={_props.v0}>
      <span style={_props.v1}>
        {_props.v2}
      </span>
      <div style={_props.v3}>
        {_props.v4}
      </div>
    </div>, {
  name: "SectionGrop_1",
  portals: ["v2", "v4"]
});
const SectionGrop = ({
  title,
  children,
  contentStyle = {},
  showIf = false
}) => {
  if (!showIf) return null;
  return /*@million jsx-skip*/<SectionGrop_1 v0={{
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: 35
  }} v1={{
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "1.1em",
    fontWeight: 700
  }} v2={title} v3={{
    ...{
      display: "flex",
      flex: 1,
      flexDirection: "column"
    },
    ...contentStyle
  }} v4={children} />;
};
const SectionGrop2_1 = _compiledBlock(_props2 => <div style={_props2.v0}>
      <span style={_props2.v1}>
        {_props2.v2}
      </span>
      <div style={_props2.v3}>
        {_props2.v4}
      </div>
    </div>, {
  name: "SectionGrop2_1",
  portals: ["v2", "v4"]
});
const SectionGrop2 = ({
  title,
  children,
  contentStyle = {},
  showIf = false
}) => {
  if (!showIf) return null;
  return /*@million jsx-skip*/<SectionGrop2_1 v0={{
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: 35
  }} v1={{
    width: "100%",
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "1.1em"
  }} v2={title} v3={{
    ...{
      display: "flex",
      flex: 1,
      flexDirection: "column ",
      marginTop: "10px"
    },
    ...contentStyle
  }} v4={children} />;
};
const Template3_1 = _compiledBlock(_props3 => <div style={_props3.v0}>
      <div style={_props3.v1}>
        <div style={_props3.v2}>
          <div style={_props3.v3}>
            {/* <img
              src="/profile.png"
              style={{
                width: 130,
                height: 130,
                borderRadius: "50%",
              }}
              alt={`${data.first_name} ${data.last_name}`}
             /> */}
            <div style={_props3.v4}>
              <div style={_props3.v5}>
                <span>{_props3.v6}</span>
                <span>{_props3.v7}</span>
              </div>
              {_props3.v8}
            </div>
          </div>
          <div style={_props3.v9}>
            {_props3.v10}
          </div>
        </div>
      </div>
      <div style={_props3.v11}>
        <div style={_props3.v12}>
          {_props3.v13}
          {_props3.v14}
          {_props3.v15}
          {_props3.v16}
        </div>
        <div style={_props3.v17}>
          {_props3.v18}
        </div>
      </div>
    </div>, {
  name: "Template3_1",
  portals: ["v6", "v7", "v8", "v10", "v13", "v14", "v15", "v16", "v18"]
});
const Template3 = ({
  doc,
  data
}) => {
  const contacts = [[data.country, data.city].filter(i => i).join(", "), data.email, textUtil.getUSNumber(data.phone) || data.phone].filter(item => item);
  useEffect(() => {
    // Adding the fonts
    doc?.setFont("IBMPlexSans", "normal");
    doc?.setFont("IBMPlexSans-Medium", "normal");
    doc?.setFont("IBMPlexSans-Bold", "normal");
  }, []);
  return /*@million jsx-skip*/<Template3_1 v0={{
    fontFamily: "IBMPlexSans",
    display: "flex",
    flexDirection: "column",
    padding: "50px",
    gap: "40px",
    paddingBottom: "80px",
    WebkitFontSmoothing: "antialiased",
    color: "#212121",
    fontSize: "16px",
    width: "100%",
    height: "100%",
    boxSizing: "border-box"
  }} v1={{
    display: "flex",
    flexDirection: "column"
  }} v2={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #dfdbdb",
    paddingBottom: 20
  }} v3={{
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start"
  }} v4={{
    flex: 1,
    display: "flex",
    flexDirection: "column"
    // marginLeft: 20,
  }} v5={{
    width: "100%",
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "24px",
    display: "flex",
    fontWeight: 700,
    flexDirection: "column",
    marginBottom: 5
  }} v6={data.first_name} v7={data.last_name} v8={data.job_title && <p style={{
    color: "#928585",
    lineHeight: 1.62,
    fontSize: "0.85em"
  }}>
                  {data.job_title}
                </p>} v9={{
    flex: 1
  }} v10={!!contacts?.length && <div style={{
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    margin: "auto",
    color: "#928585",
    lineHeight: 1.62,
    fontSize: "0.85em"
  }}>
                {contacts.map((item, i) => <span key={`contact${i}`}>{item}</span>)}
              </div>} v11={{
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 25
  }} v12={{
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  }} v13={<SectionGrop title="Profile"
  // contentStyle={{
  //   gap: "10px",
  // }}
  showIf={data.summary}>
            <span style={{
      fontSize: "0.85em",
      lineHeight: 1.62,
      color: "#928585",
      marginTop: "-10px"
    }} dangerouslySetInnerHTML={{
      __html: data.summary
    }} />
          </SectionGrop>} v14={<SectionGrop2 title="Education" contentStyle={{
    gap: "30px"
  }} showIf={data.educations?.length}>
            {data.educations?.map((item, n) => <div key={item + n} style={{
      display: "flex",
      flexDirection: "column",
      gap: "4px"
    }}>
                <div style={{
        display: "flex",
        gap: "16px"
      }}>
                  <div style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "0.75em",
          color: "#928585",
          marginRight: 15,
          marginTop: "4px"
        }}>
                    <span>{`${item.start_date || ""}`}</span>
                    <span style={{
            width: "10px",
            height: "1px",
            margin: "12px 0",
            background: "#d0cbcb"
          }} />
                    <span>{`${item.end_date || ""}`}</span>
                  </div>
                  <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
                    <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            fontFamily: "IBMPlexSans-Medium"
          }}>
                      {item.school}
                    </span>
                    <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            color: "#928585"
          }}>
                      {item.degree}
                    </span>
                  </div>
                </div>
              </div>)}
          </SectionGrop2>} v15={<SectionGrop2 title="Certifications and awards" contentStyle={{
    gap: "30px"
  }} showIf={data.certificates?.length}>
            {data.certificates?.map((item, n) => <div key={item + n} style={{
      display: "flex",
      flexDirection: "column",
      gap: "4px"
    }}>
                <div style={{
        display: "flex",
        gap: "16px"
      }}>
                  <div style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "0.75em",
          color: "#928585",
          marginRight: 15,
          marginTop: "4px"
        }}>
                    <span>{`${item.date || ""}`}</span>
                  </div>
                  <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
                    <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            fontFamily: "IBMPlexSans-Medium"
          }}>
                      {item.title}
                    </span>
                  </div>
                </div>
              </div>)}
          </SectionGrop2>} v16={<SectionGrop title="Key Skills" contentStyle={{
    width: "100%"
  }} showIf={data.skills?.length}>
            <div style={{
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap"
    }}>
              {data.skills?.map((item, n) => <span key={item + n} style={{
        display: "flex",
        flexDirection: "column",
        fontSize: "0.75em",
        color: "#928585",
        marginTop: "4px",
        marginBottom: "4px",
        width: "50%"
      }}>
                  {item.skill}
                </span>)}
            </div>
          </SectionGrop>} v17={{
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingLeft: 25,
    borderLeft: "1px solid #dfdbdb"
  }} v18={<SectionGrop2 title="Employment" contentStyle={{
    gap: "30px"
  }} showIf={data.employments?.length}>
            {data.employments?.map((item, n) => <div key={item + n} style={{
      display: "flex",
      flexDirection: "column",
      gap: "4px"
    }}>
                <div style={{
        display: "flex",
        gap: "16px"
      }}>
                  <div style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "0.75em",
          color: "#928585",
          marginRight: 15,
          marginTop: "4px"
        }}>
                    <span>{`${item.start_date || ""}`}</span>
                    <span style={{
            width: "10px",
            height: "1px",
            margin: "12px 0",
            background: "#d0cbcb"
          }} />
                    <span>{`${item.end_date || ""}`}</span>
                  </div>
                  <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
                    <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            fontFamily: "IBMPlexSans-Medium"
          }}>
                      {`${item.job_title || ""}${item.employer ? " at " + item.employer : ""}`}
                    </span>
                    <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            color: "#928585"
          }} dangerouslySetInnerHTML={{
            __html: item.description
          }} />
                  </div>
                </div>
              </div>)}
          </SectionGrop2>} />;
};
export default Template3;