import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const Banner = ({ startExitAnimation, bannerMessage, bannerType }) => {
  const fadeInVariants = {
    hidden: { opacity: 0, y: "-10%" },
    visible: {
      opacity: 1,
      y: "0%",
      transition: {
        duration: 0.7,
        ease: [0.16, 1, 0.3, 1], // cubic-bezier curve for easeInOut
      },
    },
    exit: {
      opacity: 0,
      y: "-10%",
      transition: {
        duration: 0.7,
        ease: [0.16, 1, 0.3, 1], // cubic-bezier curve for easeInOut
      },
    },
  };

  return (
    <AnimatePresence>
      {!startExitAnimation && (
        <motion.div
          key="banner"
          className="fixed top-1/10 inset-x-0 z-10"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={fadeInVariants}
        >
          <div
            className={`relative w-full max-w-[800px] mx-auto text-left text-sm ${
              bannerType == "error" ||
              bannerType == "errorSubmit" ||
              bannerType == "dislike" ||
              bannerType == "errorRegex"
                ? "text-error-700"
                : "text-success-600"
            } font-text-sm-semibold pt-12`}
          >
            <div
              className={`rounded-lg ${
                bannerType == "error" ||
                bannerType == "dislike" ||
                bannerType == "errorSubmit" ||
                bannerType == "errorRegex"
                  ? "bg-error-25"
                  : "bg-success-50"
              } box-border w-full flex flex-row p-4 items-start justify-start gap-[12px] border-[1px] border-solid ${
                bannerType == "error" ||
                bannerType == "errorSubmit" ||
                bannerType == "dislike" ||
                bannerType == "errorRegex"
                  ? "border-error-300"
                  : "border-mediumseagreen-200"
              }`}
            >
              <div className="flex-1 flex flex-col items-start justify-start gap-[12px]">
                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                  <div className="self-stretch relative leading-[20px] font-semibold">
                    {bannerMessage}
                  </div>
                  <div className="self-stretch relative leading-[20px]">
                    {bannerType == "error"
                      ? "Whoops! Looks like something went wrong. Please try again."
                      : bannerType == "update"
                      ? "Good news! Your application has been sent – check your applied tab in a few minutes to view your responses, the new applications will be at the top!"
                      : bannerType == "successSubmit"
                      ? "Your job has been successfully submitted. Keep an eye on your applied tab!"
                      : bannerType == "errorSubmit"
                      ? "Uh oh! Something went wrong with the submission. Try again in a few minutes."
                      : bannerType == "dislike"
                      ? "Keep disliking jobs that don't fit your preference to further train and improve your algorithm!"
                      : bannerType == "like"
                      ? "Keep liking jobs that fit your preference to further train and improve your algorithm!"
                      : bannerType == "status"
                      ? "Nice job on the progress, keep going and applying to jobs!"
                      : ""}
                  </div>
                </div>
              </div>
              <div className="relative w-5 h-5">
                <div className="absolute top-[-8px] left-[-8px] rounded-lg overflow-hidden flex flex-row p-2 items-center justify-center"></div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Banner;
