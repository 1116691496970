import React, { useEffect, useState } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";

const Checkbox = ({ checked, onToggle }) => {
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0, 0.5], [0, 1]);
  const duration = 0.4;

  const boxVariants = {
    checked: { backgroundColor: "#5cd193" },
    unchecked: { backgroundColor: "#fafbfc" },
  };

  const checkVariants = {
    checked: { pathLength: 1 },
    unchecked: { pathLength: 0 },
  };

  return (
    <div className="relative flex items-center">
      <motion.div
        className={`relative h-5 w-5 cursor-pointer rounded-md border-[1px] border-solid transition-all duration-500 ${
          checked
            ? "border-mediumseagreen-200 bg-mediumseagreen-200"
            : "border-gray-neutral-200 bg-fafbfc"
        }`}
        variants={boxVariants}
        initial={false}
        animate={checked ? "checked" : "unchecked"}
        onClick={onToggle}
      >
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="3.5"
          stroke="white"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-3.5 w-3.5"
          initial={false}
          animate={checked ? "checked" : "unchecked"}
        >
          <motion.path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 12.75l6 6 9-13.5"
            variants={checkVariants}
            style={{ pathLength, opacity }}
            transition={{ duration }}
          />
        </motion.svg>
      </motion.div>
    </div>
  );
};

const CheckboxGroup = ({
  label,
  options,
  required,
  onChange,
  initialChecked,
}) => {
  const [checkedValues, setCheckedValues] = useState([]);

  useEffect(() => {
    setCheckedValues(initialChecked || []);
  }, [initialChecked]);

  const handleChange = (value, checked) => {
    if (checked) {
      setCheckedValues([...checkedValues, value]);
    } else {
      setCheckedValues(checkedValues.filter((val) => val !== value));
    }
    onChange(value, checked);
  };

  return (
    <div className="mt-5">
      <div className="text-[14px] text-primary-600 mb-3">{label}</div>
      {options.map((option) => (
        <div
          key={option.value}
          className="flex items-center space-x-4 text-[14px] text-primary-600 mb-4"
        >
          <Checkbox
            checked={checkedValues.includes(option.value)}
            onToggle={() =>
              handleChange(option.value, !checkedValues.includes(option.value))
            }
          />
          <label
            className="font-text-l-regular text-[14px] text-primary-600 cursor-pointer"
            onClick={() =>
              handleChange(option.value, !checkedValues.includes(option.value))
            }
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default CheckboxGroup;
