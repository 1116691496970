import React from "react";
import { usePayment } from "../PaymentContext";
import { motion } from "framer-motion";
const UpgradeFeatureModal = () => {
  const {upgradeFeatureModal, setUpgradeFeatureModal, openUpgrade} = usePayment();
  const handleUpgrade = async () => {
    await openUpgrade();
    setUpgradeFeatureModal(false);
  };

  return upgradeFeatureModal ? (
          <div className="flex flex-col items-center justify-start gap-[20px] text-left text-sm text-e"
        /*initial={{ opacity: 0}}
        animate={{ opacity: 1}}
        exit={{ opacity: 0}}
        transition={{ duration: 0.4, ease: "easeInOut" }}*/
      >
    <div className="fixed z-50 inset-0 overflow-y-auto flex items-center justify-center">
      <div
        className="fixed inset-0 transition-opacity"
        aria-hidden="true"
        onClick={() => setUpgradeFeatureModal(false)}
      >
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg w-[406px]">
        <div className="relative rounded-xl [background:linear-gradient(180deg,_#effaf4,_#fff)] w-full overflow-hidden flex flex-col items-center justify-center text-center text-[34px] text-f4e font-text-l-regular">
          <div className="w-[406px] h-[354px] shrink-0 flex flex-col items-start justify-start">
            <div className="self-stretch relative h-[354px] shrink-0">
              <img
                className="absolute top-[0px] left-[0px] w-[406px] h-[354px] object-cover"
                alt=""
                src="/frame-5761-1@2x.png"
              />
              <div className="absolute top-[41px] left-[19px] flex flex-col items-center justify-start gap-[24px]">
                <div className="relative leading-[120%] font-semibold inline-block w-[368px]">
                  <p className="m-0">Unlock your future.</p>
                  <p className="m-0">Faster.</p>
                </div>
                <div className="relative text-base leading-[200%] font-medium inline-block w-[333px] opacity-[0.7]">
                  <p className="m-0">Upgrading gives you access to tons of new</p>
                  <p className="m-0">features that supercharge your job-search</p>
                  <p className="m-0">{`process. `}</p>
                </div>
                <button
                  className="cursor-pointer [border:none] py-3 px-[18px] bg-mediumseagreen-200 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] w-[232px] overflow-hidden flex flex-row box-border items-center justify-center gap-[6px] hover:bg-green-300 transition-colors duration-300"
                  onClick={handleUpgrade}
                >
                  <img
                    className="relative w-5 h-5 shrink-0 overflow-hidden hidden"
                    alt=""
                    src="/placeholder.svg"
                  />
                  <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular text-base-white text-left">
                    Upgrade Now
                  </div>
                  <img
                    className="relative w-5 h-5 shrink-0 overflow-hidden hidden"
                    alt=""
                    src="/placeholder1.svg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  ) : null;
};

export default UpgradeFeatureModal;
