import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
const SkeletonPlanLoader_1 = _compiledBlock(() => <div className="mb-1 self-stretch flex flex-col py-0 items-start justify-start">
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <div className="self-stretch items-center justify-between">
              <div className="tracking-[-0.1px] leading-[28px] font-semibold bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient h-4 rounded"></div>
            </div>
            <div className="w-[150px] self-stretch flex flex-row items-center justify-start gap-[16px] text-sm text-gray-50">
                <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-[110px] h-4 rounded"></div>
            </div>
          </div>
        </div>, {
  name: "SkeletonPlanLoader_1"
});
const SkeletonPlanLoader = () => {
  return /*@million jsx-skip*/<SkeletonPlanLoader_1 />;
};
export default SkeletonPlanLoader;