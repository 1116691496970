import templates from "../templates";

class TemplateUtil {
  getActiveTemplate(id) {
    return templates[id];
  }
  getImages() {
    return Object.entries(templates)?.map(([key, template]) => ({
      key,
      image: template?.image,
    }));
  }

  getProgress(id, data) {
    const template = this.getActiveTemplate(id);
    return Object.keys(template.progress).reduce((result, key) => {
      if (data[key] && (Array.isArray(data[key]) ? data[key].length : true)) {
        return result + template.progress[key];
      }
      return result;
    }, 0);
  }
}

const templateUtil = new TemplateUtil();
export { templateUtil };
