import React, { useState, useRef, useEffect, useMemo } from "react";
import { Add, ArrowDown2, Trash, TickCircle } from "iconsax-react";
import Collapsible from "react-collapsible";
import classNames from "classnames";
import { useTemplateStore, useThisResume } from "../../../hooks";
import { templateUtil } from "../../../utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
const ChooseTemplate = () => {
  const [isOpen, setIsOpen] = useState(false);
  const sectionRef = useRef();
  const [width, setWidth] = useState(null);
  const [hoverTemplate, setHolverTemplate] = useState("");
  const templateKey = useThisResume(state => state.template);
  const changeTemplate = useThisResume(state => state.changeTemplate);
  const {
    removeTemplate,
    unactiveTemplates
  } = useTemplateStore(state => ({
    unactiveTemplates: state.unactiveTemplates,
    removeTemplate: state.removeTemplate
  }));
  const templates = useMemo(() => {
    return templateUtil.getImages().filter(image => unactiveTemplates.indexOf(image.key) < 0);
  }, [unactiveTemplates]);
  useEffect(() => {
    document.documentElement.style.setProperty("--swiper-theme-color", "#D15C5C");
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWidth(0);
      setIsOpen(false);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const onToggle = () => setIsOpen(prevState => !prevState);
  const onRemove = key => {
    removeTemplate(key);
  };
  useEffect(() => {
    const sectionRefWidth = sectionRef.current?.clientWidth;
    if (sectionRefWidth) setWidth(sectionRefWidth);
  }, [sectionRef.current, isOpen]);
  const handleHoverStart = template => {
    setHolverTemplate(template);
  };
  const handleHoverEnd = template => {
    setHolverTemplate("");
  };
  return <section ref={sectionRef} className="flex flex-col border-b border-gray-200 bg-gray-100/30 border-solid ">
      <Collapsible transitionTime={200} transitionCloseTime={200} open={isOpen} trigger={<header className="flex justify-between items-center px-5 py-4 gap-10 cursor-pointer group relative" onClick={onToggle}>
            <div className="flex flex-col gap-1">
              <span className="font-semibold text-black text-sm group-hover:text-primary transition">
                Choose Template
              </span>
            </div>
            <ArrowDown2 color="#9CA3AF" size={20} className={classNames("transition duration-300", {
        "rotate-180": isOpen
      })} />
          </header>}>
        {!!width && <div className="h-full mx-auto pb-6" style={{
        width
      }}>
            <Swiper dir="ltr" className="template-swiper" slidesPerView="auto" style={{
          "--swiper-navigation-color": "#FFF",
          "--swiper-pagination-color": "#FFF"
        }} spaceBetween={15} allowTouchMove={true} allowScrollMove={true} showsPagination={false} navigation={true} pagination={{
          clickable: false
        }} modules={[Navigation, Pagination]}>
              {templates.map(template => <SwiperSlide key={template?.key} className="!w-40">
                  {!!template.image && <div className="relative cursor-pointer flex justify-center items-center h-56 transition-transform	ease-in-out duration-300 hover:scale-105 my-4" onClick={() => changeTemplate(template?.key)} onMouseEnter={() => handleHoverStart(template?.key)} onMouseLeave={() => handleHoverEnd(template?.key)}>
                      <img src={template?.image} className="h-full w-full object-contain rounded-md shadow-sm select-none disabledrag border-[1px] border-solid hover:bg-neutral-50 border-neutral-700 hover:border-neutral-300" alt={template?.key} />
                      <div className={classNames("absolute top-0 left-0 w-full h-full opacity-0 bg-green-100/50 flex items-center justify-center transition-opacity ease-in-out duration-300", {
                "opacity-100": templateKey === template?.key
              })}>
                        <TickCircle size={40} color="#5BC68D" className="drop-shadow-lg" />
                      </div>
                      {/*
                       <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onRemove(template?.key);
                        }}
                        className={classNames(
                          "absolute top-3 right-2 cursor-pointer rounded-full w-8 h-8 bg-red-100 opacity-0 transition-transform	ease-in-out duration-300",
                          {
                            "opacity-100": hoverTemplate === template?.key,
                          }
                        )}
                       >
                        <Trash color="#FF9191" size={18} />
                       </button>
                        */}
                    </div>}
                </SwiperSlide>)}
            </Swiper>
          </div>}
      </Collapsible>
    </section>;
};
export { ChooseTemplate };