import React, { useEffect, useState } from 'react';

const RadioInputCard = ({ titleText, options, value, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const onOptionClick = (e, optionValue) => {
    e.preventDefault();
    setSelectedValue(optionValue);
    onChange(optionValue);
  };

  return (
    <div className="flex-1 flex flex-col items-start justify-start gap-[8px] text-left text-sm text-gray-700 font-text-l-regular mt-5">
      <div className="text-[14px] text-primary-600 mb-3">
        {titleText}
      </div>
      <div className="flex flex-row items-center justify-start gap-4">
        {options.map((option, idx) => (
          <label
            key={idx}
            className="flex items-center cursor-pointer"
            onClick={(e) => {
              onOptionClick(e, option.value);
             //console.log("pressed");
            }}
          >
            <input
              type="radio"
              name={titleText}
              value={option.value}
              checked={selectedValue === option.value}
              onChange={() => {}}
              className="hidden"
            />
            <span
              className={`p-2 ${
                selectedValue === option.value
                  ? 'bg-light-blue-200 text-gray-700'
                  : 'bg-base-white text-gray-700'
              } rounded-md border-[1px] border-solid ${
                selectedValue === option.value ? 'border-mediumseagreen-200' : 'border-neutral-700'
              } mr-2 text-left`}
            >
              {option.label}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioInputCard;