import { useState, useRef, useEffect, useMemo } from "react";
import { motion, useAnimation } from "framer-motion";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Trash, DocumentDownload } from "iconsax-react";
import { Document, Page } from "react-pdf";
import { useResumeStore } from "../hooks";
import { templateUtil } from "../utils";
import { useUsermaven } from "../UserMaven";
import { useUserData } from "../UserDataContext";
import { usePayment } from "../PaymentContext";
import { useIdentifyUser } from "../hooks/useIdentifyUser";
import Intercom from "@intercom/messenger-js-sdk";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { db, storage } from "../firebase";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const CreateNewResume = () => {
  let usermavenOpts = {
    key: "UMWZUKUs0g",
    tracking_host: "https://events.usermaven.com",
  };
  const { usermaven } = useUsermaven(usermavenOpts);
  const { createNew, createNewPdf } = useResumeStore((state) => ({
    createNew: state.createNew,
    createNewPdf: state.createNewPdf,
  }));
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const { userData, user, setUserData } = useUserData();
  const { appsUsed } = usePayment();

  const fileInputRef = useRef(null);

  const textVariants = {
    hidden: { opacity: 1, y: "0px" },
    visible: { opacity: 0, y: "10px", transition: { duration: 0.1 } },
    visibleWithDelay: {
      opacity: 1,
      y: "0px",
      transition: { duration: 0.1, delay: 0.1 },
    },
  };

  const optionsVariants = {
    hidden: { opacity: 0, y: "-10px" },
    visible: { opacity: 1, y: "0px", transition: { duration: 0.1 } },
  };

  const handleNewResume = () => {
    usermaven.track("Created a New Resume", {
      name: userData.name,
      email: userData.email,
      address: userData.address,
      appsUsed: userData.applied?.length,
      salary: userData.keywords?.salary,
      seniority: userData.keywords?.seniority,
      title: userData.keywords?.title,
    });
    const resume = createNew();
    navigate(`/resume-builder/${resume.id}`);
  };

  const testFileSize = async (file) => {
    const docRef = doc(db, "Profiles", user.uid);
    //0.5mb max size
    if (file.size / 1000000 > 5) {
      alert("file too big");
    } else {
      const allowedExtensions = /(\.txt|\.pdf|\.docx)$/i;
      if (!allowedExtensions.exec(file.name)) {
        alert("Invalid file type");
      } else {
        const dbRef = ref(storage, String(user.uid));
        const res = await listAll(dbRef);
        try {
          for (let item of res.items) {
            if (item.name === file.name) {
              await deleteObject(item);
            }
          }
        } catch (error) {
          return;
          // Handle the error as needed
        }
        const dbRef2 = ref(storage, String(user.uid) + "/resumes/");
        const res2 = await listAll(dbRef2);
        try {
          for (let item of res2.items) {
            if (item.name === file.name) {
              await deleteObject(item);
            }
          }
        } catch (error) {
          return;
          // Handle the error as needed
        }
        const storageRef = ref(storage, String(user.uid) + "/" + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            console.error("Upload failed", error);
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            updateDoc(
              docRef,
              { filename: file.name, allResumes: arrayUnion(file.name) },
              { merge: true }
            );
            const updatedResumes = userData.allResumes
              ? [...new Set([...userData.allResumes, file.name])]
              : [file.name];
            setUserData({
              ...userData,
              filename: file.name,
              allResumes: updatedResumes,
            });
          }
        );
        // const storageRef2 = ref(storage, user.uid + "/resumes/" + file.name);
        // const uploadTask2 = uploadBytesResumable(storageRef2, file);
        // uploadTask2.on(
        //   "state_changed",
        //   (snapshot) => {
        //   },
        //   (error) => {
        //     //console.error("Upload failed", error);
        //   },
        // );
      }
    }
  };

  const handleChooseFile = (event) => {
    const file = event.target.files[0];
    testFileSize(file);
  };

  return (
    <motion.div
      className="flex flex-col items-center justify-center w-52 h-[268px] bg-white rounded-md mb-2 border-[1px] border-dashed hover:border-solid border-neutral-700 hover:bg-green-50 hover:border-mediumseagreen-200 hover:text-mediumseagreen-200 transition-all duration-300 ease-in-out"
      initial={{ height: "268px" }}
      animate={{ height: "268px" }}
      transition={{ duration: 0.1, ease: [0.42, 0, 0.58, 1], delay: 0.1 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div
        className="flex items-center justify-center w-full h-full"
        variants={textVariants}
        initial="hidden"
        animate={isHovered ? "visible" : "visibleWithDelay"}
      >
        <p
          className={`text-center text-sm ${
            /*isHovered ? "text-mediumseagreen-200" : ""*/ ""
          }`}
        >
          Create New
        </p>
      </motion.div>
      <motion.div
        className={`flex flex-col items-center gap-3 w-52 absolute`}
        variants={optionsVariants}
        initial="hidden"
        animate={isHovered ? "visible" : "hidden"}
      >
        <button
          className="text-sm font-bold bg-primary hover:bg-green-500 duration-200 rounded-sm px-6 py-2 text-white cursor-pointer"
          onClick={handleNewResume}
        >
          New
        </button>
        <button
          className="text-sm font-bold bg-primary hover:bg-green-500 duration-200 rounded-sm px-6 py-2 text-white cursor-pointer"
          onClick={() => fileInputRef.current.click()}
        >
          Import
        </button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleChooseFile}
        />
      </motion.div>
    </motion.div>
  );
};

const ResumeItem = ({ resume }) => {
  const deleteResume = useResumeStore((state) => state.deleteResume);
  const templateContainer = useRef(null);
  const scaleContainer = useRef(null);
  const container = useRef(null);
  const controls = useAnimation();
  const navigate = useNavigate();
  const template = templateUtil.getActiveTemplate(resume.template);
  const [download, isDownload] = useState(false);
  const { user, userData, setUserData } = useUserData();

  const isPdfFile = useMemo(() => {
    return resume.pdfUrl && resume.pdfUrl !== "";
  }, [resume]);

  const downloadAsImage = () => {
    html2canvas(templateContainer.current).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = `${resume.title || "resume"}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const downloadAsPDF = () => {
    const doc = new jsPDF({
      unit: "px",
      format: "a4",
      putOnlyUsedFonts: true,
    });
    doc.html(templateContainer.current, {
      callback: function (doc) {
        doc.save(`${resume.filename || "resume"}.pdf`);
      },
      windowWidth: templateContainer.current.offsetWidth,
      width: doc.internal.pageSize.getWidth(),
    });
  };

  const handleDownload = () => {
    downloadAsPDF();
  };

  const resumeVariants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -10 },
  };

  const pdfOverlayResumeVariants = {
    visible: { opacity: 0.7, y: 0 },
    hidden: { opacity: 0, y: -10 },
  };

  const handleHoverStart = () => {
    controls.start("visible");
  };

  const handleHoverEnd = () => {
    controls.start("hidden");
  };

  const handleClick = () => {
    if (isPdfFile || download) {
      return;
    }
    navigate(`/resume-builder/${resume.id}`);
  };

  const handleRemove = (e) => {
    e.stopPropagation();
    const userResumeData = [
      ...(userData.resumeData ? userData.resumeData : []),
    ];

    const newResumeData = userResumeData.filter((r) => r.id !== resume?.id);
    console.log(newResumeData);
    console.log(resume);
    if (Object.keys(resume.data).length != 0) {
      const updatedResumes = userData.allResumes
        ? userData.allResumes.filter(
            (resumeId) => !resumeId.includes(resume.id)
          )
        : [];
      const fileRef = ref(
        storage,
        String(user.uid) + "/" + resume?.filename + ".pdf"
      );
      deleteObject(fileRef);
      console.log(resume);
      deleteResume(user.uid, resume?.id);
      setUserData({
        ...userData,
        resumeData: newResumeData,
        allResumes: updatedResumes,
      });
    } else {
      const updatedResumes = userData.allResumes
        ? userData.allResumes.filter((resumeId) => resumeId !== resume?.title)
        : [];
      const fileRef = ref(storage, String(user.uid) + "/" + resume?.title);
      deleteObject(fileRef);
      const fileRef2 = ref(
        storage,
        String(user.uid) + "/resumes/" + resume?.title
      );
      deleteObject(fileRef2);
      setUserData({
        ...userData,
        resumeData: newResumeData,
        allResumes: updatedResumes,
      });
    }
  };

  useEffect(() => {
    const paperWidth = templateContainer.current.offsetWidth;
    const realWidth = Math.min(paperWidth, container.current.offsetWidth);
    const scale = realWidth / paperWidth;
    scaleContainer.current.style.transform = `scale(${scale})`;
  }, []);

  return (
    <div
      className="relative flex flex-col items-start justify-start w-52"
      onClick={handleClick}
      onMouseEnter={handleHoverStart}
      onMouseLeave={handleHoverEnd}
    >
      <div
        ref={container}
        className={`w-full h-[268px] bg-white rounded-md overflow-hidden shadow-[0_0_0_4px_white_inset] mb-2 border-[1px] border-solid border-neutral-200 hover:border-mediumseagreen-200 transition duration-300 ease-in-out cursor-pointer`}
      >
        <div ref={scaleContainer} className="origin-top-left ">
          <div ref={templateContainer} className="inline-flex">
            <div
              className="w-[210mm] min-h-[297mm] bg-white"
              width="210mm"
              height="297mm"
              format="A4"
            >
              {isPdfFile ? (
                <Document file={resume.pdfUrl}>
                  <Page pageNumber={1} />
                </Document>
              ) : (
                <template.template data={resume.data} />
              )}
            </div>
          </div>
        </div>
      </div>
      {isPdfFile && (
        <motion.div
          className="absolute rounded-md top-0 left-0 right-0 h-[270px] opacity-70 bg-gray-100 flex cursor-auto"
          initial="hidden"
          variants={pdfOverlayResumeVariants}
          animate={controls}
        />
      )}
      <motion.button
        className="absolute top-3 right-2 rounded-full w-8 h-8 bg-red-100 cursor-pointer"
        initial="hidden"
        variants={resumeVariants}
        animate={controls}
        onClick={handleRemove}
      >
        <Trash color="#ef4444" size={20} />
      </motion.button>
      <motion.button
        className="absolute top-3 right-12 rounded-full w-8 h-8 bg-green-100 cursor-pointer"
        initial="hidden"
        variants={resumeVariants}
        animate={controls}
        onMouseEnter={() => isDownload(true)}
        onMouseLeave={() => isDownload(false)}
        onClick={handleDownload}
      >
        <DocumentDownload color="#34D399" size={20} />{" "}
      </motion.button>

      <motion.div
        className="flex flex-col items-start text-sm gap-1 px-1"
        initial="hidden"
        variants={resumeVariants}
        animate={controls}
      >
        <span className="text-base font-semibold text-gray-700">
          {resume.title}
        </span>
        <span className="text-xs font-medium text-gray-400">
          Updated {dayjs(resume.updatedAt).format("DD MMMM, H:mm")}
        </span>
      </motion.div>
    </div>
  );
};

const Resumes = () => {
  useIdentifyUser();
  const { user, userData, isAuthenticated } = useUserData();
  const { resumes, loadResumes, fillResumes } = isAuthenticated
    ? useResumeStore((state) => ({
        resumes: state.resumes,
        loadResumes: state.loadResumes,
        fillResumes: state.fillResumes,
      }))
    : { resumes: [], loadResumes: [], fillResumes: [] };
  const [isModalOpen, setIsModalOpen] = useState(false);

  {
    /*Intercom({
    app_id: "qgss7lxp",
    user_id: user.uid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: userData.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: userData.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: "1704067200", // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });*/
  }

  useEffect(() => {
    if (user && isAuthenticated && userData) {
      const userResumeData = userData.resumeData
        ? [...userData.resumeData]
        : [];
      loadResumes(
        user.uid,
        userResumeData.map((urd) => urd.id)
      ).then((resumes) => {
        const userResumes = userResumeData;
        fillResumes([...resumes, ...userResumes]);
      });
    }
  }, [user, loadResumes, userData]);

  return (
    <div className="flex flex-col justify-start items-start bg-neutral-50 w-full h-screen overflow-hidden border-r-[1px] border-solid border-neutral-600 overflow-y-auto text-9xl text-gray-700 font-text-l-regular">
      <div className="w-full h-screen p-8">
        <div className="relative font-semibold text-left text-9xl text-gray-700 font-text-l-regular">
          Resumes
        </div>
        <div className="self-stretch relative text-base tracking-[-0.1px] leading-[40px] text-gray-50">
          Here you can view, edit, and create new or existing resumes, and
          tailor them using our AI.
        </div>
        <div className="flex flex-wrap justify-start gap-6 mt-5">
          {resumes.map((resume) => (
            <ResumeItem key={resume.id} resume={resume} />
          ))}
          <CreateNewResume />
        </div>
      </div>
    </div>
  );
};

export default Resumes;
