import algoliasearch from "algoliasearch";
import { useJobs } from "../jobsContext";
import { motion, AnimatePresence } from "framer-motion";
import React, { useState, useRef, useEffect } from "react";
import { useUserData } from "../UserDataContext";
import { useLocation } from "react-router-dom";

const TrackerDropdown = ({
  titleText,
  options,
  value,
  onChange,
  sort,
  selectedSortValue,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const sortDrop = sort;

  const [selectedValues, setSelectedValues] = useState([]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!titleText || !options || options.length === 0) {
    return null;
  }

  return !sortDrop ? (
    <div className="flex flex-col items-start justify-start gap-[8px] text-left text-sm text-gray-700 font-text-l-regular pb-4">
      <div ref={dropdownRef} className="relative w-full">
        <div
          className={`font-text-l-regular text-sm bg-base-white self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[42px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-between border-[1px] border-solid transform-gpu transition-all ${
            !isDropdownOpen ? "hover:border-neutral-300" : ""
          } duration-300 ${
            isDropdownOpen ? "border-mediumseagreen-200" : "border-neutral-700"
          } focus:outline-none cursor-pointer `}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <div>
            {selectedValues.length
              ? selectedValues
                  .map(
                    (val) =>
                      options.find(({ value: optVal }) => optVal === val)?.label
                  )
                  .join(", ")
              : options[0]?.label}
          </div>
          <img
            className="relative w-5 h-5 shrink-0"
            alt=""
            src="/chevrondown2.svg"
          />
        </div>
        <AnimatePresence>
          {isDropdownOpen && (
            <motion.div
              className="box-border absolute right-0 min-w-full width-auto top-full bg-white border-[1px] border-solid border-neutral-700 rounded-md z-10 py-1"
              initial={{ opacity: 0, y: -10 }}
              animate={
                isDropdownOpen ? { opacity: 1, y: 0 } : { opacity: 0, y: -10 }
              }
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
            >
              {options.map((option) => (
                <div
                  className={`pl-4 pr-8 py-2 cursor-pointer hover:bg-gray-100 ${
                    selectedValues.includes(option.value) ||
                    (!selectedValues.length &&
                      option.value === options[0]?.value)
                      ? "text-mediumseagreen-200 bg-green-50"
                      : ""
                  }
                    ${
                      selectedValues.includes(option.value) ||
                      (!selectedValues.length &&
                        option.value === options[0]?.value)
                        ? "bg-green-50"
                        : ""
                    }`}
                  key={option.value}
                  onClick={() => {
                    setIsDropdownOpen(false);
                    const newValues = [...selectedValues];
                    if (titleText === "Dropdown 1") {
                      if (newValues.includes(option.value)) {
                        const index = newValues.indexOf(option.value);
                        newValues.splice(index, 1);
                      } else {
                        newValues.push(option.value);
                      }
                    } else {
                      newValues[0] = option.value;
                    }
                    setSelectedValues(newValues);
                    onChange(newValues);
                  }}
                >
                  {option.label}
                </div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="self-stretch relative tracking-[-0.1px] leading-[20px] text-gray-50 hidden">
        Hint text
      </div>
    </div>
  ) : (
    <div className="flex flex-col items-start justify-start gap-[8px] text-left text-sm text-gray-700 font-text-l-regular">
      <div ref={dropdownRef} className="relative w-full">
        <div
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className={`cursor-pointer rounded-8xs h-[40px] bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center gap-4 text-sm text-success-600 border-[1px] transform-gpu transition-all duration-300 border-solid px-2.5 ${
            !isDropdownOpen ? "hover:border-neutral-300" : ""
          } duration-300 ${
            isDropdownOpen ? "border-mediumseagreen-200" : "border-neutral-700"
          } focus:outline-none cursor-pointer `}
        >
          <motion.svg
            whileTap={{ scale: 1.1 }}
            className={`transition-colors duration-300 outline-none relative w-[24px] h-[18px] shrink-0 "text-gray-400"`}
            viewBox="0 0 19 15"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 12L3 2M5 11L3.35355 12.6464C3.15829 12.8417 2.84171 12.8417 2.64645 12.6464L1 11M10 5.5H16M10 13.5H12M10 1.5H18M10 9.5H14"
              stroke="#A3B2C2"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </motion.svg>
        </div>
        <AnimatePresence>
          {isDropdownOpen && (
            <motion.div
              className="box-border absolute right-0 min-w-full width-auto top-full bg-white border-[1px] border-solid border-neutral-700 rounded-md z-10 py-1"
              initial={{ opacity: 0, y: -10 }}
              animate={
                isDropdownOpen ? { opacity: 1, y: 0 } : { opacity: 0, y: -10 }
              }
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
            >
              {options.map((option) => (
                <div
                  className={`pl-4 pr-8 py-2 cursor-pointer hover:bg-gray-100 
                    ${
                      selectedSortValue
                        ? selectedSortValue === option.value
                          ? "text-mediumseagreen-200"
                          : ""
                        : ""
                    }
                    ${
                      selectedValues.includes(option.value) ||
                      (!selectedValues.length &&
                        option.value === options[0]?.value)
                        ? "bg-green-50"
                        : ""
                    }
                    `}
                  key={option.value}
                  onClick={() => {
                    setIsDropdownOpen(false);
                    setSelectedValues([option.value]); // Only select the clicked option
                    onChange(option.value); // Pass the option's value directly
                  }}
                >
                  {option.label}
                </div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="self-stretch relative tracking-[-0.1px] leading-[20px] text-gray-50 hidden">
        Hint text
      </div>
    </div>
  );
};

export default TrackerDropdown;
