import React, { useEffect } from "react";
import { useState } from "react";
import { useJobs } from "../jobsContext";
import AutoApplyModal from "./AutoApplyModal";
import { motion, AnimatePresence } from "framer-motion";
import { useUserData } from "../UserDataContext";
import { usePayment } from "../PaymentContext";
import Banner from "./Banner";
import algoliasearch from "algoliasearch";
import Search from "./Search";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  limit,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";

const DemoBar = (props) => {
  const { userData, isAuthenticated } = useUserData();
  const { setSelectedJob } = useJobs();
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerType, setBannerType] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [startExitAnimation, setStartExitAnimation] = useState(false);
  const [activeModeIndex, setActiveModeIndex] = useState(0);
  const [prod, setProd] = useState(false);
  const breezy =
    "https://otto-engineering-inc.breezy.hr/p/483f4c495bec-machine-shop-apprentice/apply";
  const [modes, setModes] = useState([
    {
      title: "Standard Fetch/Apply",
    },
    {
      title: "BreezyHR Fetch",
    },
  ]);

  useEffect(() => {
    const fetchJobs = async () => {
      const jobIds = ["513151296", "-21234707", "-478863113"];
      const indeedLinks = [
        "https://www.indeed.com/jobs?q=pathrise&l=&sort=date&vjk=3945cbed910447d8",
        "https://www.indeed.com/jobs?q=data+analyst+kraken&l=&vjk=7ae213d218f66aa8",
        "https://www.indeed.com/jobs?q=motive+regional&l=&vjk=eccf9466f558c7b3",
      ];
      const jobs = [];

      for (let i = 0; i < jobIds.length; i++) {
        const docRef = doc(db, "Jobs", jobIds[i]);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const jobData = docSnap.data();
          jobs.push(jobData);
        } else {
          console.log("No such document!");
        }
      }

      console.log(jobs);
      props.setResults(jobs);
    };

    fetchJobs();
  }, []);

  return (
    <>
      {showBanner && (
        <Banner
          startExitAnimation={startExitAnimation}
          bannerMessage={bannerMessage}
          bannerType={bannerType}
        />
      )}
      <AnimatePresence layout="position">
        <div className="flex flex-col self-stretch items-start justify-start gap-[16px] text-9xl">
          <div className="flex flex-row self-stretch items-center justify-between">
            <div className="flex flex-col">
              <div className="relative tracking-[-0.01em] leading-[38px] font-semibold">
                Wrangle Demo
              </div>
            </div>
          </div>
        </div>
      </AnimatePresence>
    </>
  );
};

export default DemoBar;
