import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { useState, useEffect, useMemo, Fragment } from "react";
import cn from "classnames";
const SlidePicker_1 = _compiledBlock(_props => <div className={_props.v0}>
      <div className="transition absolute z-0 w-full h-full top-0 left-0 opacity-10 rounded-md" style={_props.v1} />
      {_props.v2}
      <div className="absolute z-20 h-full left-0 rounded-md transition duration-300 opacity-90" style={_props.v3} />
      <p className="absolute z-20 bottom-full right-0 mb-1.5 text-xs opacity-90 font-semibold" style={_props.v4}>
        {_props.v5}
      </p>
    </div>, {
  name: "SlidePicker_1",
  portals: ["v2", "v5"]
});
const SlidePicker = ({
  className,
  options = [],
  onChange = () => false,
  formValue,
  defaultValue
}) => {
  const [selected, setSelected] = useState(defaultValue);
  const selectedItem = useMemo(() => {
    const item = options.find(item => item.value === selected) || {};
    return {
      ...item,
      index: options.indexOf(item)
    };
  }, [selected]);
  useEffect(() => {
    if (typeof formValue !== "string" || typeof formValue !== "number") return;
    if (formValue !== selected) setSelected(formValue);
  }, [formValue, selected]);
  const changeValue = val => {
    onChange(val);
    setSelected(val);
  };
  useEffect(() => {
    if (defaultValue && !formValue) {
      onChange(defaultValue);
    }
    if (!selected && options.length) {
      changeValue(options[0].value);
    }
  }, [options]);
  return /*@million jsx-skip*/<SlidePicker_1 v0={cn("flex h-11 relative rounded-md", className)} v1={{
    background: selectedItem?.color
  }} v2={options.map(item => <Fragment key={item.value}>
          <span className="flex flex-1 opacity-0 relative z-10 hover:opacity-20 transition rounded-md cursor-pointer" style={{
      background: selectedItem?.color
    }} onClick={() => changeValue(item.value)} />
          <span className="w-[1px] h-4 bg-zinc-500/30 self-center relative z-10 last-of-type:hidden" />
        </Fragment>)} v3={{
    background: selectedItem?.color,
    width: `${100 / options.length}%`,
    transform: `translateX(${100 * selectedItem?.index}%)`
  }} v4={{
    color: selectedItem?.color
  }} v5={selectedItem?.label} />;
};
export { SlidePicker };