import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { useState, useMemo } from "react";
const ConnectedAccountsContainer_1 = _compiledBlock(_props => <div className="self-stretch relative">
    <div className="flex flex-row py-4 px-0 items-center justify-between text-left text-mini text-gray-700 font-text-l-regular" style={_props.v0}>
      <div className="flex flex-row items-center justify-start gap-[24px] w-64">
        <div className="w-8 h-8 shrink-0 flex flex-row items-center justify-center relative bg-cover bg-no-repeat bg-[top]" style={_props.v1}>
        </div>
        <div className="flex flex-col items-start justify-start">
          <div className="relative tracking-[-0.1px] leading-[22px] font-medium">
            {_props.v2}
          </div>
          <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
            {_props.v3}
          </div>
        </div>
      </div>
        <button type="button" className="cursor-pointer [border:none] p-0 bg-[transparent] flex flex-row items-center justify-start" onClick={_props.v4}>
          <img className="relative w-6 h-6 shrink-0 overflow-hidden position-absolute pl-auto" alt="" src='/close-cross.svg' />
        </button>
    </div>
  </div>, {
  name: "ConnectedAccountsContainer_1",
  portals: ["v2", "v3"]
});
const ConnectedAccountsContainer = ({
  socialMediaHandle,
  acctHandle,
  propBackgroundImage,
  visible,
  setInvisible
}) => {
  const [transition, setTransition] = useState(false);
  const avatarStyle = useMemo(() => {
    return {
      backgroundImage: propBackgroundImage
    };
  }, [propBackgroundImage]);
  const handleCrossClick = () => {
    setTransition(true);
    setTimeout(() => {
      setInvisible();
    }, 500);
  };
  const contentStyle = {
    position: "relative",
    zIndex: 1,
    pointerEvents: transition ? "none" : "auto",
    transition: "opacity 0.1s ease-in-out",
    opacity: transition ? 0 : 1
  };
  return visible ? /*@million jsx-skip*/<ConnectedAccountsContainer_1 v0={contentStyle} v1={avatarStyle} v2={socialMediaHandle} v3={acctHandle} v4={handleCrossClick} /> : null;
};
export default ConnectedAccountsContainer;