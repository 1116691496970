import { DEFAULT_CONTENT } from "../../constants/ContentSections";
import Template5Image from "../images/Template5.png";
import Template5 from "./Template5";
import { ADDITIONAL_CONTENT } from "./ContentSection";

export default {
  template: Template5,
  image: Template5Image,
  ...DEFAULT_CONTENT,
  data: [...DEFAULT_CONTENT.data, ...ADDITIONAL_CONTENT],
};
