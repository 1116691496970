import React from "react";
import { useState } from "react";
import { useUserData } from "../UserDataContext";
import { db, storage } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  deleteObject,
  listAll,
} from "firebase/storage";

const FileUpload = ({ titleText, file, setFile }) => {
  const fileInputRef = React.useRef();
  const {user, userData, setUserData, isAuthenticated} = useUserData();
  const [filename, setFilename] = useState(userData.filename)
  const testFileSize = async (file) => {
    if (Math.round(file.size / 1000000) > 0.5) {
      alert("file too big");
    } else {
      const allowedExtensions = /(\.txt|\.pdf|\.docx)$/i;
      if (!allowedExtensions.exec(file.name)) {
        alert("Invalid file type");
      } else {
        const dbRef = ref(storage, String(user.uid));
        const res = await listAll(dbRef);
        try{
          for(let item of res.items){
            if (item.name === file.name) {
              await deleteObject(item)
            }
          }
      } catch (error) {
        return
        // Handle the error as needed
      }
        const storageRef = ref(storage, String(user.uid) + "/" + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            setFilename(
              "Uploading... " +
                String(
                  Math.floor(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  )
                ) +
                "%"
            );
          },
          (error) => {
            console.error("Upload failed", error);
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            const docRef = doc(db, "Profiles", isAuthenticated && user.uid);
            setFilename(file.name);
            updateDoc(docRef, { filename: file.name }, { merge: true });
            setUserData({ ...userData, filename: file.name });
          }
        );
      }
    }
  };
    const handleChooseFile = () => {
      fileInputRef.current.click();
    };
  
    return (
      <div className="flex-1 flex flex-col items-start justify-start gap-[8px] text-sm font-text-l-regular">
        <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
          <span>{titleText}</span>
        </div>
        <div className="self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-start justify-end">
          <input
            type="file"
            id="fileInput"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(e) => {
              const file = e.target.files[0];
              testFileSize(file);
            }}
          />
          <div className="self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-start justify-between flex-1">
            <input
              className="font-text-l-regular text-mini bg-base-white flex-1 rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md box-border h-[46px] overflow-hidden flex flex-row py-[11px] px-4 items-center justify-start border-[1px] border-solid border-neutral-700 select-none"
              placeholder={filename}
              value={file?.name || ''}
              readOnly
            />
            <button
              className="cursor-pointer [border:none] py-3 px-[18px] bg-neutral-100 rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none overflow-hidden flex flex-row items-center justify-between gap-[6px] border-t-[1px] border-solid border-neutral-700 border-r-[1px] border-b-[1px]"
              onClick={handleChooseFile}
            >
              <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular text-gray-700 text-left">
                Upload
              </div>
            </button>
          </div>
        </div>
        <div className="self-stretch relative tracking-[-0.1px] leading-[20px] text-gray-50 hidden">
          Hint text
        </div>
      </div>
    );
  };
  
  export default FileUpload;
  