import React from "react";
import cn from "classnames";
const FormItem = ({
  children,
  name,
  className,
  label,
  required,
  description,
  onChange = () => false
}) => {
  //console.log("Description in FormItem:", description);
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      const FieldItem = React.cloneElement(child, {
        onChange,
        name,
        required,
        description
      });
      return FieldItem;
    }
    return child;
  });
  return <div className={cn("flex flex-col", className)}>
      {label && <span className="self-stretch relative tracking-[-0.1px] text-left text-xs text-gray/90 ml-0.5 mb-1.5 font-medium">
          {label}
        </span>}
      {childrenWithProps}
    </div>;
};
export { FormItem };