import { compiledBlock as _compiledBlock } from "million/react";
const TextEditor_1 = _compiledBlock(_props => <div className={_props.v0}>
      {_props.v1}
      <div className="flex mt-2 px-0.5 gap-3 justify-between items-center">
        <span className="text-xs text-gray/90">
          Recruiter tip: write{" "}
          {_props.v2}{" "}
          characters to increase interview chances
        </span>
        <div className="text-sm text-gray/90 flex items-center whitespace-nowrap">
          <span className="text-black mr-1">{_props.v3}</span>
          {_props.v4}
          <img src={_props.v5} className="w-5 h-5 ml-1.5" alt="face" />
        </div>
      </div>
      {_props.v6}
    </div>, {
  name: "TextEditor_1",
  portals: ["v1", "v2", "v3", "v4", "v6"]
});
const TextEditor = function TextEditor({
  className,
  min,
  max,
  onChange = () => false,
  formValue,
  description
}) {
  const {
    setUpgradeFeatureModal,
    currentPlan,
    owner
  } = usePayment();
  const editor = useRef(null);
  const [open, setOpen] = useState(false);
  const firstChange = useRef(false);
  const [active, setActive] = useState(false);
  const [value, setValue] = useState("");
  const [length, setLength] = useState(0);
  const {
    user,
    userData,
    isAuthenticated
  } = useUserData();
  const [field, setField] = useState(description ? description : "");
  const [isProcessingAI, setIsProcessingAI] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const {
    messages,
    append
  } = useChat();
  const [shownMessages, setShownMessages] = useState([]);
  const handleAIClick = useCallback(async () => {
    /*if(paymentsEnabled){
      if ((currentPlan == 1 || !currentPlan) && !owner) {
        setUpgradeFeatureModal(true);
        return;
      }
    }*/

    if (!isProcessingAI && !isTyping && isAuthenticated) {
      setIsProcessingAI(true);
      const quill = editor.current.getEditor();
      quill.setText("generating...\n"); // This sets "generating..." in the editor

      await callAI();
      setIsProcessingAI(false);
    } else if (!isAuthenticated) {
      setOpen(true);
    }
  }, [isProcessingAI, isTyping]);
  useEffect(() => {
    if (description && description != null) {
      setField(description);
    }
  }, [description]);
  useEffect(() => {
    if (typeof formValue !== "string" || firstChange.current) return;
    if (formValue !== value) setValue(formValue);
    firstChange.current = true;
  }, [formValue]);
  useEffect(() => {
    const aiResponseObj = messages.reverse().find(m => m.role === "assistant");
    const aiResponse = aiResponseObj?.content;
    if (aiResponse && editor.current && !shownMessages.includes(aiResponse) && !isTyping) {
      setIsTyping(true); // Set the typing flag to true
      const cleanedAiResponse = aiResponse.replace(/(\r\n|\n|\r)/gm, "");
      const quill = editor?.current?.getEditor() || null;
      quill.setText(""); // Clear the "generating..." message

      let i = 0;
      const typingInterval = setInterval(() => {
        if (i < cleanedAiResponse.length) {
          quill.insertText(i, cleanedAiResponse[i]);
          i++;
        } else {
          clearInterval(typingInterval);
          setIsTyping(false); // Set the typing flag to false once done
        }
      }, 10);
      setShownMessages([...shownMessages, cleanedAiResponse]);
    }
  }, [messages]);
  const callAI = async () => {
    const parsedData = {
      Address: userData.address,
      Portfolio: userData.portfolio,
      Phone: userData.phone,
      Name: userData.name,
      Keyinfo: userData.keyInfo,
      Filename: userData.filename,
      Email: userData.email,
      linkedin: userData.linkedin ? "https://linkedin.com/in/" + userData.linkedin : "",
      figma: userData.figma ? "https://figma.com/@" + userData.figma : "",
      dribbble: userData.dribbble ? "https://dribbble.com/" + userData.dribbble : "",
      github: userData.github ? "https://github.com/" + userData.github : "",
      twitter: userData.twitter ? "https://twitter.com/" + userData.twitter : "",
      stackoverflow: userData.stackoverflow ? "https://stackoverflow.com/users/" + userData.stackoverflow : ""
    };
    await append({
      role: "user",
      content: `For some context, this is going on the users resume, in the professional summary section. It is going to be read by potential employers. Write a very short 100 character response to this prompt: ${field} for this user: ${parsedData}. It should be specific to the user, mentioning their name: ${parsedData.Name}, things mentioned in ${parsedData.Keyinfo}, and specific to their profile. This is for their resume. Keep it professional, no hashtags, not too casual, but still friendly. It should be from a first person perspetcive. A good example is this: "My name is John Doe and I'm a highly experienced software engineer who specializes in Kubernetes and MongoDB. I have a strong history leading development teams, and I'm eager to make an impact at my next role!`
    }, {});
  };
  useEffect(() => {
    if (editor.current) {
      const quill = editor.current.getEditor();
      const toolbar = quill.getModule("toolbar").container; // get the toolbar container directly from the quill instance

      // Commenting out the condition to check if the button already exists.
      // This will force append the button for debugging.
      // if (!existingAiButton) {
      const aiButton = document.createElement("button");
      aiButton.className = "ql-ai";
      aiButton.innerHTML = `
          <svg width="14" height="12" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.4049 0.00349431C9.13286 0.414577 4.40591 9.76099 2.9094 15.9086C2.77991 16.4406 2.76417 17.1697 3.30071 17.2788C3.64549 17.3488 4.02893 17.1319 4.18576 16.6039C5.36746 12.6249 6.50085 7.62662 11.0009 9.49992C12.4724 10.2357 14.9062 8.5655 13.11 5.66951C13.0666 5.5996 13.1173 5.50598 13.1995 5.50985C16.3065 5.65644 19.9463 5.38864 19.5092 0.0965933C19.5049 0.0437267 19.4579 0.0013731 19.4049 0.00349431Z" fill="#5BC68D"/>
            <path d="M2.11272 3.64966C1.95236 3.16858 1.57485 2.79107 1.09377 2.63071C0.60416 2.46751 0.60416 1.77497 1.09377 1.61177C1.57485 1.45141 1.95236 1.0739 2.11272 0.592819C2.27592 0.103208 2.96846 0.103208 3.13166 0.592819C3.29203 1.0739 3.66953 1.45141 4.15061 1.61177C4.64022 1.77497 4.64022 2.46751 4.15061 2.63071C3.66953 2.79107 3.29203 3.16858 3.13166 3.64966C2.96846 4.13927 2.27592 4.13927 2.11272 3.64966Z" fill="#5BC68D"/>
            <path d="M11.0937 15.6307C11.5748 15.7911 11.9523 16.1686 12.1127 16.6497C12.2759 17.1393 12.9684 17.1393 13.1316 16.6497C13.292 16.1686 13.6695 15.7911 14.1506 15.6307C14.6402 15.4675 14.6402 14.775 14.1506 14.6118C13.6695 14.4514 13.292 14.0739 13.1316 13.5928C12.9684 13.1032 12.2759 13.1032 12.1127 13.5928C11.9523 14.0739 11.5748 14.4514 11.0937 14.6118C10.6041 14.775 10.6041 15.4675 11.0937 15.6307Z" fill="#5BC68D"/>
          </svg>      
        `;
      toolbar.appendChild(aiButton);
      aiButton.addEventListener("click", handleAIClick);

      // Cleanup
      return () => {
        aiButton.removeEventListener("click", handleAIClick);
      };
      // }
    }
  }, []);

  /*useEffect(() => {
    console.log("Setting up AI button");
    if (editor.current) {
      const quill = editor.current.getEditor();
      const toolbar = quill.getModule("toolbar").container; // get the toolbar container directly from the quill instance
       let existingAiButton = toolbar.querySelector(".ql-ai");
       if (!existingAiButton) {
        const aiButton = document.createElement("button");
        aiButton.className = "ql-ai";
        console.log("Creating AI button");
         aiButton.innerHTML = `
          <svg class="w-4 h-4 text-gray-200 hover:text-blue-500 outline-none relative shrink-0 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M14 10L5 19M17 7L19 5M11 7L9 5M19 15L17 13M14 6V5M14 16V15M18 10H19M8 10H9"
              stroke="#444444" stroke-width="2" stroke-linecap="round"/>
          </svg>
        `;
         toolbar.appendChild(aiButton);
        aiButton.addEventListener("click", handleAIClick);
         console.log("AI button event listener added");
         // Cleanup
        return () => {
          aiButton.removeEventListener("click", handleAIClick);
          console.log("AI button event listener removed");
        };
      } else {
        console.log("AI button already exists.");
      }
    }
  }, [editor.current, handleAIClick]);*/

  const modules = {
    toolbar: [["bold", "italic", "underline", "strike"], [{
      list: "ordered"
    }, {
      list: "bullet"
    }], ["link", "clean"]]
  };
  const formats = ["bold", "italic", "underline", "strike", "list", "bullet", "link"];
  return /*@million jsx-skip*/<TextEditor_1 v0={classNames("flex flex-col", className)} v1={<ReactQuill ref={editor} theme="snow" value={value} onChange={(val, _, __, editor) => {
    const length = editor.getLength() - 1;
    setLength(length);
    setValue(val);
    onChange(length ? val : "");
  }} onFocus={() => setActive(true)} onBlur={() => setActive(false)} className={classNames("text-editor", {
    "ring ring-green-50 border-mediumseagreen-100": active
  })} modules={modules} formats={formats} />} v2={min && max ? `${min}-${max}` : min ? `${min}+` : `${max}-`} v3={length} v4={max && `/ ${max}`} v5={length >= (min || 0) && length <= (max || 1000) ? "/media/face-good.svg" : "/media/face-bad.svg"} v6={<AccountCreateModal open={open} setOpen={setOpen} />} />;
};
import { block as _block } from "million/react";
import classNames from "classnames";
import React, { useEffect, useRef, useState, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { motion } from "framer-motion";
import { useChat } from "ai/react";
import { useUserData } from "../../UserDataContext";
import { usePayment } from "../../PaymentContext";
import AccountCreateModal from "../AccountCreateModal";
export { TextEditor };