import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { tooltipClasses } from "@mui/material/Tooltip";
const WrangleTooltip = styled(({
  className,
  ...props
}) => <Tooltip {...props} arrow classes={{
  popper: className
}} />)(({
  theme
}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: `rgba(0, 0, 0, 0.6)`
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `rgba(0, 0, 0, 0.6)`
  }
}));
export default WrangleTooltip;