import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { useEffect } from "react";
import { Call, Location, Sms } from "iconsax-react";
import { textUtil } from "../../utils";
import "../fonts/IBMPlexSans/stylesheet.css";
import "../fonts/IBMPlexSans/IBMPlexSans";
import "../fonts/IBMPlexSans/IBMPlexSans-Medium";
import "../fonts/IBMPlexSans/IBMPlexSans-Bold";
const SectionGrop_1 = _compiledBlock(_props => <div style={_props.v0}>
      <span style={_props.v1}>
        {_props.v2}
        <br />
        {_props.v3}
      </span>
      <div style={_props.v4}>
        {_props.v5}
      </div>
    </div>, {
  name: "SectionGrop_1",
  portals: ["v2", "v3", "v5"]
});
const SectionGrop = ({
  title,
  subTitle,
  children,
  contentStyle = {},
  showIf = false
}) => {
  if (!showIf) return null;
  return /*@million jsx-skip*/<SectionGrop_1 v0={{
    display: "flex",
    gap: "16px",
    alignItems: "flex-start",
    width: "100%"
  }} v1={{
    width: "180px",
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "1.2em",
    height: "100%",
    color: "#ffffff",
    zIndex: 2
  }} v2={title} v3={subTitle && subTitle} v4={{
    ...{
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginTop: "10px"
    },
    ...contentStyle
  }} v5={children} />;
};
const SectionGrop2_1 = _compiledBlock(_props2 => <div style={_props2.v0}>
      <span style={_props2.v1}>
        {_props2.v2}
      </span>
      <div style={_props2.v3}>
        {_props2.v4}
      </div>
    </div>, {
  name: "SectionGrop2_1",
  portals: ["v2", "v4"]
});
const SectionGrop2 = ({
  title,
  children,
  contentStyle = {},
  showIf = false
}) => {
  if (!showIf) return null;
  return /*@million jsx-skip*/<SectionGrop2_1 v0={{
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "flex-start",
    width: "100%"
  }} v1={{
    width: "180px",
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "1.2em",
    color: "white",
    zIndex: 2
  }} v2={title} v3={{
    ...{
      display: "flex",
      flex: 1,
      flexDirection: "column ",
      marginTop: "10px"
    },
    ...contentStyle
  }} v4={children} />;
};
const Template4_1 = _compiledBlock(_props3 => <div style={_props3.v0}>
      <aside style={_props3.v1} />
      {_props3.v2}
      <div style={_props3.v3}>
        {_props3.v4}
      </div>
      <div style={_props3.v5}>
        {_props3.v6}
      </div>
      <div style={_props3.v7}>
        {_props3.v8}
      </div>
      <div style={_props3.v9}>
        {_props3.v10}
      </div>
      <div style={_props3.v11}>
        {_props3.v12}
      </div>
      <div style={_props3.v13}>
        {_props3.v14}
      </div>
    </div>, {
  name: "Template4_1",
  portals: ["v2", "v4", "v6", "v8", "v10", "v12", "v14"]
});
const Template4 = ({
  doc,
  data
}) => {
  const contacts = [[data.country, data.city].filter(i => i).join(", "), data.email, textUtil.getUSNumber(data.phone) || data.phone].filter(item => item);
  useEffect(() => {
    // Adding the fonts
    doc?.setFont("IBMPlexSans", "normal");
    doc?.setFont("IBMPlexSans-Medium", "normal");
    doc?.setFont("IBMPlexSans-Bold", "normal");
  }, []);
  return /*@million jsx-skip*/<Template4_1 v0={{
    fontFamily: "IBMPlexSans",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: "50px",
    gap: "28px",
    paddingBottom: "80px",
    WebkitFontSmoothing: "antialiased",
    color: "#212121",
    fontSize: "16px",
    width: "100%",
    boxSizing: "border-box"
  }} v1={{
    width: "210px",
    left: 0,
    top: 0,
    height: "297mm",
    position: "absolute",
    background: "rgb(33,33,33)",
    zIndex: 0
  }} v2={<SectionGrop title="" contentStyle={{
    gap: "5px"
  }} showIf={data.first_name || data.last_name || data.job_title}>
        <span style={{
      fontSize: "0.75em",
      marginTop: "-8px",
      color: "#928585"
    }}>
          {data.job_title}
        </span>
        <span style={{
      fontFamily: "IBMPlexSans-Bold",
      fontSize: "1.7em",
      lineHeight: 1.25
    }}>
          {data.first_name} {data.last_name}
        </span>
      </SectionGrop>} v3={{
    position: "relative",
    zIndex: 3
  }} v4={<SectionGrop title="Contacts" showIf={contacts.length}>
          <ul style={{
      paddingLeft: 0,
      margin: 0,
      gap: "8px",
      display: "flex",
      flexDirection: "column",
      zIndex: 2
    }}>
            {contacts.map((item, n) => <li key={item + n} style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
        fontSize: "0.85em",
        lineHeight: 1.5,
        color: "#928585"
      }}>
                {n === 0 && <img width={16} height={16} src="/Location-Linear-80px.png" />}
                {n === 1 && <img width={16} height={16} src="/Sms-Linear-80px.png" />}
                {n === 2 && <img width={16} height={16} src="/Call-Linear-80px.png" />}
                <span>{item}</span>
              </li>)}
          </ul>
        </SectionGrop>} v5={{
    position: "relative",
    zIndex: 3
  }} v6={<SectionGrop title="Profile" contentStyle={{
    gap: "10px"
  }} showIf={data.summary}>
          <span style={{
      fontSize: "0.85em",
      lineHeight: 1.62,
      color: "#928585",
      marginTop: "-10px"
    }} dangerouslySetInnerHTML={{
      __html: data.summary
    }} />
        </SectionGrop>} v7={{
    position: "relative",
    zIndex: 3
  }} v8={<SectionGrop2 title="Education" contentStyle={{
    gap: "20px"
  }} showIf={data.educations?.length}>
          {data.educations?.map((item, n) => <div key={item + n} style={{
      display: "flex",
      flexDirection: "column",
      gap: "4px"
    }}>
              <div style={{
        display: "flex",
        gap: "16px"
      }}>
                <span style={{
          fontSize: "0.75em",
          width: "180px",
          marginTop: "4px",
          color: "#928585",
          zIndex: 2
        }}>
                  {`${item.start_date || ""}${item.end_date ? " - " + item.end_date : ""}`}
                </span>
                <div style={{
          display: "flex",
          flexDirection: "column",
          flex: 1
        }}>
                  <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            fontFamily: "IBMPlexSans-Medium"
          }}>
                    {item.school}
                  </span>
                  <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            color: "#928585"
          }}>
                    {item.degree}
                  </span>
                </div>
              </div>
            </div>)}
        </SectionGrop2>} v9={{
    position: "relative",
    zIndex: 3
  }} v10={<SectionGrop2 title="Employment" contentStyle={{
    gap: "20px"
  }} showIf={data.employments?.length}>
          {data.employments?.map((item, n) => <div key={item + n} style={{
      display: "flex",
      flexDirection: "column",
      gap: "4px"
    }}>
              <div style={{
        display: "flex",
        gap: "16px"
      }}>
                <span style={{
          fontSize: "0.75em",
          color: "#928585",
          width: "180px",
          marginTop: "4px",
          zIndex: 2
        }}>
                  {`${item.start_date || ""}${item.end_date ? " - " + item.end_date : ""}`}
                </span>
                <div style={{
          display: "flex",
          flexDirection: "column",
          flex: 1
        }}>
                  <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            fontFamily: "IBMPlexSans-Medium"
          }}>
                    {`${item.job_title || ""}${item.employer ? " at " + item.employer : ""}`}
                  </span>
                  <span style={{
            fontSize: "0.85em",
            lineHeight: 1.62,
            color: "#928585"
          }} dangerouslySetInnerHTML={{
            __html: item.description
          }} />
                </div>
              </div>
            </div>)}
        </SectionGrop2>} v11={{
    position: "relative",
    zIndex: 3
  }} v12={<SectionGrop title="Skills" contentStyle={{
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    columnGap: "100px",
    rowGap: "10px",
    marginTop: "2px"
  }} showIf={data.skills?.length}>
          {data.skills?.map((item, n) => <div key={item + n} style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "12px"
    }}>
              <span style={{
        fontSize: "0.85em",
        lineHeight: 1.62,
        fontFamily: "IBMPlexSans-Medium"
      }}>
                {item.skill}
              </span>
              <span style={{
        fontSize: "0.85em",
        lineHeight: 1.62,
        color: "#928585"
      }}>
                {item.level}
              </span>
            </div>)}
        </SectionGrop>} v13={{
    position: "relative",
    zIndex: 3
  }} v14={<SectionGrop title={`Certifications`} subTitle="and awards" contentStyle={{
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    columnGap: "100px",
    rowGap: "10px",
    marginTop: "2px"
  }} showIf={data.certificates?.length}>
          {data.certificates?.map((item, n) => <div key={item + n} style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "12px"
    }}>
              <span style={{
        fontSize: "0.85em",
        lineHeight: 1.62,
        fontFamily: "IBMPlexSans-Medium"
      }}>
                {item.title}
              </span>
              <span style={{
        fontSize: "0.85em",
        lineHeight: 1.62,
        color: "#928585"
      }}>
                {item.date}
              </span>
            </div>)}
        </SectionGrop>} />;
};
export default Template4;