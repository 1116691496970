import React from 'react';

const SkeletonJobPanel = () => {
  return (
    <div className="self-stretch flex-1 rounded-3xs bg-base-white flex flex-row p-8 items-start justify-start border-[1px] border-solid border-neutral-600">
      <div className="self-stretch flex-1 flex flex-col items-start justify-between">
        <div className="self-stretch flex flex-col items-start justify-start gap-[32px]">
          <div className="relative tracking-[-0.02em] leading-[40px] font-semibold bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient self-stretch h-10 rounded"></div>
          <div className="self-stretch rounded-3xs bg-base-white flex flex-row py-4 px-5 items-center justify-between text-sm text-gray-50 border-[1px] border-solid border-neutral-600">
            <div className="flex flex-col items-center justify-start gap-[6px]">
              <div className="relative tracking-[-0.1px] leading-[20px] font-medium bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-28 h-4 rounded"></div>
              <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-20 h-4 rounded"></div>
            </div>
            <div className="flex flex-col items-center justify-start gap-[6px]">
              <div className="relative tracking-[-0.1px] leading-[20px] font-medium bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-28 h-4 rounded"></div>
              <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-20 h-4 rounded"></div>
            </div>
            <div className="flex flex-col items-center justify-start gap-[6px]">
              <div className="relative tracking-[-0.1px] leading-[20px] font-medium bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-28 h-4 rounded"></div>
              <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-20 h-4 rounded"></div>
            </div>
            <div className="flex flex-col items-center justify-start gap-[6px]">
              <div className="relative tracking-[-0.1px] leading-[20px] font-medium bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-28 h-4 rounded"></div>
              <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-20 h-4 rounded"></div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[24px] text-lg">
            <div className="flex-1 flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                <div className="self-stretch relative tracking-[-0.1px] leading-[28px] font-semibold bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-full h-6 rounded"></div>
                <div className="self-stretch relative text-mini tracking-[-0.1px] leading-[22px] font-medium text-gray-50">
                    <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-full h-6 rounded"></div>
                    <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-full h-6 rounded"></div>
                    <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-full h-6 rounded"></div>
                </div>
                </div>
            </div>
            <div className="w-44 shrink-0 flex flex-col items-start justify-start text-mini text-black">
                <div className="self-stretch rounded-3xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] animate-pulse flex flex-col py-4 px-5 items-start justify-start gap-[22px] border-[1px] border-solid border-neutral-600">
                <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-sm text-gray-700">
                    <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
                    <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-8 h-8 rounded-full"></div>
                    <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-20 h-6 rounded"></div>
                    </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                    <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-full h-6 rounded"></div>
                    <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-full h-6 rounded"></div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                    <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-full h-6 rounded"></div>
                    <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-full h-6 rounded"></div>
                </div>
                </div>
            </div>
        </div>
    </div>
      </div>
    </div>
)};

export default SkeletonJobPanel