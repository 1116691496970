import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { useState } from "react";
const FileItem_1 = _compiledBlock(_props => <div className={_props.v0} onClick={_props.v1}>
      <div className="flex gap-0 items-center h-full">
        <div className="pl-2 pr-2 bg-neutral-100 h-full text-[15px] flex justify-center items-center text-primary-600">
          <span>{_props.v2}</span>
        </div>
        <div className="flex flex-col gap-[5px] ml-4">
          <div className="text-[15px]">{_props.v3}</div>
          <div className="text-note text-luh-light-gray">
            {
          //Used on{" "}
        }
            {_props.v4}
          </div>
        </div>
      </div>
      <div className="m-6">
        <div className={_props.v5}>
          <div className={_props.v6}></div>
        </div>
      </div>
    </div>, {
  name: "FileItem_1",
  portals: ["v2", "v3", "v4"]
});
export const FileItem = ({
  id,
  format,
  filename,
  lastUsed,
  selected,
  onSelect
}) => {
  return /*@million jsx-skip*/<FileItem_1 v0={`flex rounded-md w-full h-[60px] gap-[0] transform-gpu transition-all duration-300 justify-between cursor-pointer border border-solid items-center overflow-hidden mt-4 mb-4 ${selected ? "border-mediumseagreen-200" : "border-neutral-700"}`} v1={() => {
    onSelect(id);
  }} v2={format} v3={filename} v4={lastUsed?.toLocaleDateString("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  })} v5={`relative rounded-[50%] w-[24px] h-[24px]  transform-gpu transition-all duration-300 border border-solid bg-white ${selected ? "border-mediumseagreen-200" : "border-neutral-700"}`} v6={`absolute w-[18px] h-[18px] transform-gpu transition-all duration-300 left-[3px] top-[3px] rounded-[50%] ${selected ? "bg-mediumseagreen-200" : "bg-neutral-700"}`} />;
};
export default FileItem;