import React, { createContext, useContext, useState, useEffect } from "react";
import { useUserData } from "./UserDataContext";
import { db, auth } from "./firebase";
import {
  doc,
  getDocs,
  onSnapshot,
  collection,
  getDoc,
} from "firebase/firestore";

const PaymentContext = createContext();

export const usePayment = () => {
  return useContext(PaymentContext);
};

export const PaymentProvider = ({ children }) => {
  const paymentsEnabled = false;
  const { userData, setUserData, user, isAuthenticated, currentApi } =
    useUserData();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeFeatureModal, setUpgradeFeatureModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(0);
  const [confirmModalOpen, setConfirmModalOpen] = useState([0, false]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [appsUsed, setAppsUsed] = useState();
  const [cutoffDate, setCutoffDate] = useState();
  const [bonus, setBonus] = useState(0);
  const [bonusLeft, setBonusLeft] = useState(0);
  const [pendingApps, setPendingApps] = useState(0);
  const [owner, setOwner] = useState(false);
  useEffect(() => {
    const loadNewData = async () => {
      await fetchSubscriptionData("2");
      //await fetchApplicationCount();
    };
    // check for flag trigger
    if (isAuthenticated && userData.customer) {
      setUserData({ ...userData, customer: false });
      loadNewData();
    }
  }, [userData.customer]);
  useEffect(() => {
    const loadNewData = async (val) => {
      await fetchSubscriptionData("2");
      //await fetchApplicationCount();
    };
    if (currentPlan == 0) {
      loadNewData();
    }
  });
  const addPromoApps = async (type) => {
    // Type guide
    // 0: Promo
    // 1: Twitter
    // 2: Facebook
    let idToken;
    if (auth.currentUser) {
      idToken = await auth.currentUser.getIdToken(true);
    } else {
      return;
    }
    fetch(`${currentApi}/add-promo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        authId: user.uid,
        type: type,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((json) => Promise.reject(json));
        }
      })
      .catch((e) => {
        console.error(e.error);
      });
    await fetchSubscriptionData("3");
  };
  const confirmPayment = async (val, monthYear) => {
    if (!isAuthenticated) throw new Error("Not Authenticated");
    else {
      if ((currentPlan == 2 && val != 2) || currentPlan == 3) {
        setConfirmModalOpen([val, monthYear]);
        return;
      } else {
        await handlePayment(val, monthYear);
      }
    }
  };
  const handlePayment = async (plan, my) => {
    if (!isAuthenticated) throw new Error("Not Authenticated");
    else {
      setIsProcessing(true);
      if (plan == 3) {
        await deleteSub();
        setIsProcessing(false);
        return;
      }
      let monthyear = "month";
      if (my) {
        monthyear = "year";
        if (plan == 1) {
          plan = 3;
        } else {
          plan = 4;
        }
      }
      let idToken;
      if (auth.currentUser) {
        idToken = await auth.currentUser.getIdToken(true);
      } else {
        return;
      }
      fetch(`${currentApi}/create-checkout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          item: { id: plan, quantity: 1 },
          authId: user.uid,
          timeframe: monthyear,
        }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            setIsProcessing(false);
            return res.json().then((json) => Promise.reject(json));
          }
        })
        .then(({ url }) => {
          window.location = url;
          setIsProcessing(false);
        })
        .catch((e) => {
          console.error(e.error);
          setIsProcessing(false);
        });
    }
  };
  const fetchApplicationCount = async () => {
    // declare returned variables
    let cutoffDate = new Date();
    cutoffDate.setMonth(cutoffDate.getMonth() - 1); // Set the date to one month ago
    let appsThisMonth = 0;
    let totalApps = 0;
    // check applications new *****
    const profileReference = collection(
      db,
      "/Profiles/" + user.uid + "/applications/"
    );
    const appsProfile = await getDocs(profileReference);
    let pendingApplications;
    if (!appsProfile.empty) {
      // grab pending apps
      pendingApplications = appsProfile.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (app) =>
            app.createdAt &&
            (app.status === "pending" || app.status === "queued")
        ).length;
      // retrieve apps
      const applications = appsProfile.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((app) => app.createdAt && app.status === "submitted");

      applications.forEach((app) => {
        app.createdAt = app.createdAt.toDate(); // Convert Firestore Timestamp to JavaScript Date
      });

      if (applications.length !== 0) {
        applications.sort((a, b) => b.createdAt - a.createdAt);
        totalApps = applications.length;
        for (let i = 0; i < applications.length; i++) {
          let dateObject = applications[i].createdAt;
          if (dateObject >= cutoffDate) {
            appsThisMonth += 1; // Increment the counter if the date is within the last month
          } else {
            break; // Exit the loop if the date is older than one month ago
          }
        }
      }
    }
    //console.log(appsThisMonth)
    //console.log(pendingApplications)
    if (currentPlan == 4) {
      setAppsUsed(totalApps);
    } else {
      setAppsUsed(appsThisMonth);
    }
    setPendingApps(pendingApplications);
    return;
  };
  const fetchSubscriptionData = async (val) => {
    //console.log(val)
    /*return new Promise(async (resolve, reject) => {
      try {
        let idToken;
        if(auth.currentUser){
           idToken = await auth.currentUser.getIdToken(true);
        }
        else{
          return;
        }
        const res = await fetch(`${currentApi}/retrieve-subscription-data`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
        });
  
        if (res.ok) {
          //const { subData, appsUsed, cutoff, bonus, remBonus, pending, owner } = await res.json();
          const { subData, cutoff, bonus, remBonus, owner } = await res.json();
          setCurrentPlan(subData);
          //setAppsUsed(appsUsed);
          setCutoffDate(new Date(cutoff));
          setBonus(bonus)
          setBonusLeft(remBonus)
          //setPendingApps(pending);
          setOwner(owner)
          //console.log([subData, cutoff, bonus, remBonus, owner]);
          resolve([subData, cutoff, bonus, remBonus, owner]);
        } else {
          const json = await res.json();
          reject(json);
        }
      } catch (e) {
        console.error(e.error);
        reject(e);
      }
    });*/
  };

  const openUpgrade = async () => {
    if (currentPlan == 0) await fetchSubscriptionData("4");
    setShowUpgradeModal(true);
  };
  const deleteSub = async () => {
    try {
      let idToken;
      if (auth.currentUser) {
        idToken = await auth.currentUser.getIdToken(true);
      } else {
        return;
      }
      const res = await fetch(`${currentApi}/delete-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          authId: user.uid,
        }),
      });

      if (res.ok) {
        await fetchSubscriptionData("5");
      } else {
        const json = await res.json();
        throw json;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  return (
    <PaymentContext.Provider
      value={{
        cutoffDate,
        appsUsed,
        setAppsUsed,
        showUpgradeModal,
        setShowUpgradeModal,
        upgradeFeatureModal,
        setUpgradeFeatureModal,
        currentPlan,
        setCurrentPlan,
        confirmModalOpen,
        setConfirmModalOpen,
        confirmPayment,
        handlePayment,
        fetchApplicationCount,
        openUpgrade,
        deleteSub,
        addPromoApps,
        bonus,
        bonusLeft,
        isProcessing,
        pendingApps,
        owner,
        paymentsEnabled,
        setIsProcessing
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
