import React, { useEffect, useState } from "react";
import { useUserData } from "../UserDataContext";
import { useNavigate } from "react-router-dom";
import { usePayment } from "../PaymentContext";
import { useJobs } from "../jobsContext";
import { auth } from "../firebase";
import ApplicationModal from "./ApplicationModal";
import { Resend } from "resend";

const SignOutButton = ({ onClick, collapsed, demo }) => {
  const { userData, user } = useUserData();
  const [prod, setProd] = useState(false);
  const { owner } = usePayment();
  const { triggerNewJobs, remove50 } = useJobs();
  const navigate = useNavigate();
  const [isAppModalOpen, setIsAppModalOpen] = useState(false);
  const [htmlId, setHtmlId] = useState(false);
  const companyName = "Walmart";
  const companyTitle = "Test Role";

  // const testStuff = async () => {
  //   if (!owner) {
  //     console.log("not owner");
  //   }
  //   console.log("TESTING");
  //   let idToken;
  //   if (auth.currentUser) {
  //     idToken = await auth.currentUser.getIdToken(true);
  //   } else {
  //     console.log("no user");
  //     return;
  //   }

  //   console.log("about to fetch");
  //   console.log(user);
  //   const res = await fetch(
  //     `https://app.wranglejobs.com/api/api-return-jobs-user`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         uid: user.uid,
  //       }),
  //     }
  //   );

  //   if (res.ok) {
  //     const responseData = await res.json();
  //     console.log(responseData);
  //   } else {
  //     console.log("Request failed");
  //   }
  // };
  const testStuff = async () => {
    if (!owner && user.uid != "BTGsPigEK7cIKrHxdCuGCnJ4HSl2") return;
    console.log("TESTING");
    let idToken;
    if (auth.currentUser) {
      idToken = await auth.currentUser.getIdToken(true);
    } else {
      console.log("no token");
      //return;
    }
    // const id = prompt("Enter Workday job ID");
    // setHtmlId(id);
    // setIsAppModalOpen(true);
    const email = prompt("Enter the email to send to");
    console.log("DONE");
    const res = await fetch(
      `https://app.wranglejobs.com/api/send-email-consumer`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          template: "test",
          data: "test",
          email: email,
        }),
      }
    );

    if (res.ok) {
      console.log("COMPLETE");
      const responseData = await res.json();
      console.log(responseData);
    } else {
      console.log("Request failed");
    } /*
    /*const res = await fetch(`https://app.wranglejobs.com/api/pull-users-applied`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer wrangle-test'
      }
    });

    if (res.ok) {
      const responseData = await res.json();
      console.log(responseData);
    } else {
      console.log("Request failed");
    }*/
    /*const res5 = await fetch(`https://app.wranglejobs.com/api/get-form-workday`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
      body: JSON.stringify({
        id: id
      })
    });

    if (res5.ok) {
      const {formInfo, link} = await res5.json();
      console.log(formInfo);
      console.log(link);
      setAppLink(link);
      setHtmlId(id);
      setIsAppModalOpen(true);
    } else {
      console.log("Request failed");
    }*/
    /*const res5 = await fetch(`https://app.wranglejobs.com/api/api-retrieve-queued-jobs`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer wrangle-test'
      },
    });

    if (res5.ok) {
      const responseData = await res5.json();
      console.log(responseData);
    } else {
      console.log("Request failed");
    }*/

    /*const res6 = await fetch(`https://app.wranglejobs.com/api/api-get-form-workday`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer wrangle-test'
      },
      body: JSON.stringify({
        id: "ICvVjG3j5DdsaiKekh7Z"
      })
    });

    if (res6.ok) {
      const {formInfo, link} = await res6.json();
      console.log(formInfo);
      console.log(link);
    } else {
      console.log("Request failed");
    }
    
    /*const res2 = await fetch(`https://app.wranglejobs.com/api/api-queue-workday-job`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer wrangle-test'
      },
      body: JSON.stringify({
        url: "test",
        id: "ICvVjG3j5DdsaiKekh7Z"
      })
    });

    if (res2.ok) {
      const id = await res2.json();
      console.log(id);
    } else {
      console.log("Request failed");
    }
    const res7 = await fetch(`https://app.wranglejobs.com/api/api-queue-workday-job`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer wrangle-test'
      },
      body: JSON.stringify({
        url: "test",
        id: "16"
      })
    });

    if (res7.ok) {
      const id = await res7.json();
      console.log(id);
    } else {
      console.log("Request failed");
    }

    
    // TEST API JOB CALL
    /*const res2 = await fetch(`https://app.wranglejobs.com/api/api-return-jobs-zip`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer wrangle-test'
      },
      body: JSON.stringify({
        search: "software",
        location: "Palo Alto",
        radius_miles: "100",
        days_ago: "",
        jobs_per_page: "100",
        page: "1",
        refine_by_salary: "false",
      })
    });

    if (res2.ok) {
      const responseData = await res2.json();
      console.log(responseData);
    } else {
      console.log("Request failed");
    }*/

    /*const res3 = await fetch(`https://app.wranglejobs.com/api/api-return-jobs`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer wrangle-test'
      },
      body: JSON.stringify({
        sorting: "Salary",
        filters: [
          "All Levels",
          "$200,000+",
          "USA Only"
        ],
        search: "engineer"
      })
    });

    if (res3.ok) {
      const responseData = await res3.json();
      console.log(responseData);
    } else {
      console.log("Request failed");
    }*/
    /*
    // SNED EMAIL
    const res2 = await fetch(`https://app.wranglejobs.com/api/send-email`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: userData.name,
        companies: [
          "https://cdn-images.himalayas.app/jtylqbcgo0y4c0lw5sgj6hopqqfp",
          "Polygon",
          "Financial Analyst (Preference to CFA)",
          "https://cdn-images.himalayas.app/6gtq4bkamlky0cuwvxi3m3t60689",
          "Netflix",
          "Senior UI Automation Engineer - L5"
        ]
      })
    });

    if (res2.ok) {
      console.log("success");
    } else {
      console.log("Request failed");
    }*/
    /*const res2 = await fetch(`https://app.wranglejobs.com/api/api-create-user`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer wrangle-test`      
      },
      body: JSON.stringify({
        userData: 
          {name: "z.string()",
          email: "z.string()",
          phone: "z.string()",
          address: "z.string()",
          portfolio: "z.string().nullish()",
          filename: "z.string()",
          resumeLink: "https://firebasestorage.googleapis.com/v0/b/wrangle-4b551.appspot.com/o/4dcmEmQB5ZWhDvcknCpRTGyieIm1%2Ftestres3.pdf?alt=media&token=487fc8e2-6e3b-4de6-90bd-5b798e8892ab&_gl=1*ieyt56*_ga*NDc1MzQyNTA2LjE2ODA3MzExMjM.*_ga_CW55HF8NVT*MTY5NjY0NDYzOS4zODEuMS4xNjk2NjQ0OTMwLjU4LjAuMA..",
          keyInfo: "z.string()",
          linkedin: "z.string().nullish()",
          github: "z.string().nullish()",
          twitter:" z.string().nullish()",
          figma: "z.string().nullish()",
          dribbble: "z.string().nullish()",
          stackoverflow: "z.string().nullish()",
          //uid: "06Z8gLDSIwg2demV4xB4"
          }
      })
    });

    if (res2.ok) {
      const responseData = await res2.json(); // Parse the JSON from the response
      console.log(responseData);
    } else {
      console.log("Request failed with status:", res2.status); // Log failure status
    }
    /*const res2 = await fetch(`https://app.wranglejobs.com/api/api-apply`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer wrangle-test`      
      },
      body: JSON.stringify({
        applications: [
          {
          address: "yo",
          jobId: '12345',
          url: 'https://example.com/job/12345',
          inputs: [
            {
              label: 'Full Name',
              tag: 'input',
              type: 'text',
              selector: '#name',
              value: 'John Doe',
            },
            {
              label: 'Email Address',
              tag: 'input',
              type: 'email',
              selector: '#email',
              value: 'john.doe@example.com',
            },
            // additional inputs as needed
          ],
          filename: 'resume.pdf',
          coverLetter: 'Dear Hiring Manager, ...',
          profileId: "4dcmEmQB5ZWhDvcknCpRTGyieIm1"
          }
        ]
      })
    });

    if (res2.ok) {
      const responseData = await res2.json(); // Parse the JSON from the response
      console.log(responseData);
    } else {
      console.log("Request failed with status:", res2.status); // Log failure status
    }*/
    /*let idToken;
    if(auth.currentUser){
       idToken = await auth.currentUser.getIdToken(true);
    }
    else{
      return;
    }*/
    // const response = await fetch(`https://app.wranglejobs.com/api/get-form`, {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}`},
    //   body: JSON.stringify({ appLink: "https://jobs.lever.co/optiv-2/3897e90a-effd-4e4b-a3df-31b259bfa5d1/apply",
    //   guid: "0" }),
    // });
    // if (!response.ok) {
    //   setErrored(true);
    //   throw new Error(`Error response from server: ${response.status}`);
    // }

    // const { formInfo } = await response.json();
    // console.log(formInfo);
    /*
    console.log(formInfo)
    if (!formInfo || formInfo.length === 0) {
      console.error('Form information is empty.');
      setErrored(true);
      return;
    }

    const prompt = formInfo
      .filter(
        ({ type, label }) =>
          type !== 'file' && !new Set().has(label)
      )
      .map(
        ({ selector, options, ...rest }) =>
          `${rest.label}|${rest.type || rest.tag}|${
            rest.required ? 'Required' : 'Not required'
          }${options ? '|' + options.map((opt) => opt.label).join(',') : ''}`
      )
      .join('\n');
    const res2 = await fetch(`https://app.wranglejobs.com/api/api-ai-request`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer wrangle-test`      
      },
      body: JSON.stringify({
        questions: prompt,
        uid = "4dcm...",
        selectedJob: {
          "title": "manager",
          "company": "Kraken"
        },
        jobIdentifier: "test"
      })
    });

    if (res2.ok) {
      const responseData = await res2.json(); // Parse the JSON from the response
      console.log(responseData);
    } else {
      console.log("Request failed with status:", res2.status); // Log failure status
    }*/
    /*const res3 = await fetch(`https://app.wranglejobs.com/api/api-retrieve-user-apps`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer wrangle-test`      
      },
      body: JSON.stringify({
        uid: "4dcmEmQB5ZWhDvcknCpRTGyieIm1"
      })
    });

    if (res3.ok) {
      const responseData = await res3.json(); // Parse the JSON from the response
      console.log(responseData);
    } else {
      console.log("Request failed with status:", res3.status); // Log failure status
    }*/
  };
  const truncatedEmail =
    userData.email && userData.email.length > 20
      ? userData.email.substring(0, 20) + "..."
      : userData.email;

  return (
    <div className="self-stretch px-8 pb-0 items-end justify-center relative">
      <div className="self-stretch flex flex-row pt-6 pb-0 items-end justify-center relative gap-12 border-t border-solid border-gray-200">
        <div className="flex-1 flex flex-row items-center justify-start gap-3">
          {collapsed && (
            <div className="rounded-full bg-gray-200 w-10 h-10 flex items-center justify-center">
              <span className="text-lg font-semibold">
                {userData.name ? userData.name[0].toUpperCase() : null}
              </span>
            </div>
          )}
          {!collapsed && (
            <div className="flex flex-col items-start justify-start">
              <div
                className="relative leading-5 font-semibold text-mini"
                onClick={
                  prod
                    ? () => navigate("/settings")
                    : () => {
                        if (!demo) {
                          console.log("TESTING");
                          testStuff();
                          console.log(user.uid);
                        }
                      }
                }
              >
                {userData.name ? userData.name : null}
              </div>
              <div
                className="relative leading-5 text-gray-600 text-smi"
                onClick={prod ? () => setTrig(true) : () => {}}
              >
                {truncatedEmail}
              </div>
            </div>
          )}
        </div>
        {!collapsed && (
          <div
            className="absolute top-7 right-0 rounded-lg overflow-hidden flex flex-row p-2 items-center justify-center z-1 cursor-pointer"
            onClick={onClick}
          >
            <img
              className="relative w-4 h-4 overflow-hidden"
              alt=""
              src="/log.svg"
            />
          </div>
        )}
      </div>
      {
        <ApplicationModal
          isOpen={isAppModalOpen}
          onCloseApp={() => {
            setIsAppModalOpen(false);
          }}
          inpLink=""
          company="Wrangle"
          guid={htmlId}
          companyName={companyName}
          title={companyTitle}
          htmlId={true}
        />
      }
    </div>
  );
};

export default SignOutButton;
