import { useState, useEffect, useRef } from "react";
import AnalyticsJobContainer from "./AnalyticsJobContainer";
import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useJobs } from "../jobsContext";
import { useUserData } from "../UserDataContext";
import {
  getFirestore,
  doc,
  query,
  updateDoc,
  getDoc,
  getDocs,
  deleteDoc,
  collection,
  setDoc,
  where,
} from "firebase/firestore";
import { db, auth } from "../firebase";

const AnalyticsContainer = (props) => {
//   const {
//     jobs,
//     loading,
//     lastVisibleDoc,
//     selectedJob,
//     setSelectedJob,
//     showFavoritesOnly,
//     setShowFavoritesOnly,
//     showUSOnly,
//     showRemoteOnly,
//     selectedTags,
//     setSelectedTags,
//   } = useJobs();
  const apps = props.apps;
  const [selectedApp, setSelectedApp] = useState(null);
  const [showTags, setShowTags] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] = useState([]);
  const [results, setResults] = useState([]);
  const prevResultsRef = useRef([]);
  useEffect(() => {
    if(!selectedApp && apps){
        setSelectedApp(apps[0])
    }
  }, [apps])
//   const hashCode = (s) =>
//     s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

  const getATS = (applyNowLink) => {
    if (applyNowLink.includes("ashbyhq.com")) {
      return "Ashby";
    } else if (applyNowLink.includes("workable.com")) {
      return "Workable";
    } else if (applyNowLink.includes("lever.co")) {
      return "Lever";
    } else if (applyNowLink.includes("greenhouse.io")) {
      return "Greenhouse";
    } else {
      return "Unknown";
    }
  };

  const [lastFilteredDoc, setLastFilteredDoc] = useState(null);
  const { userData } = useUserData();

  const getSkillColors = (type) => {
    switch (type ? type : "") {
      case "engineering":
        return { textColor: "#16a34a", bgColor: "bg-green-50" };
      case "sales":
        return { textColor: "#2563eb", bgColor: "bg-blue-50" };
      case "marketing":
        return { textColor: "#9333ea", bgColor: "bg-purple-50" };
      case "product":
        return { textColor: "#db2777", bgColor: "bg-pink-50" };
      case "design":
        return { textColor: "#dc2626", bgColor: "bg-red-50" };
      case "support":
        return { textColor: "#ea580c", bgColor: "bg-orange-50" };
      case "operations":
        return { textColor: "#4f46e5", bgColor: "bg-indigo-50" };
      case "data":
        return { textColor: "#ca8a04", bgColor: "bg-yellow-50" };
      case "finance":
        return { textColor: "#0d9488", bgColor: "bg-teal-50" };
      case "management":
        return { textColor: "#4b5563", bgColor: "bg-gray-100" };
      default:
        return { textColor: "#808080", bgColor: "#F0F0F0" };
    }
  };

  const getTypeColors = (type) => {
    switch (type ? type : "") {
      case "Full Time":
        return { textColor: "#4C6EAF", bgColor: "#E6EEF6" };
      case "Part Time":
        return { textColor: "#6BA292", bgColor: "#E6F4F1" };
      case "Contractor":
        return { textColor: "#EEBB39", bgColor: "#FFF6E6" };
      case "Temporary":
        return { textColor: "#A267AC", bgColor: "#F4E6F8" };
      case "Intern":
        return { textColor: "#D2691E", bgColor: "#F4EAE6" };
      case "Volunteer":
        return { textColor: "#C8385A", bgColor: "#FCE6EF" };
      case "Other":
        return { textColor: "#8B4513", bgColor: "#FFF5E6" };
      default:
        return { textColor: "#808080", bgColor: "#F0F0F0" };
    }
  };

  const generateTags = (job) => {
    const tags = [];
    if (job.type && job.type != "Unknown") {
      tags.push({
        text: job.type,
        ...getTypeColors(job.type),
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.57956 8.62505C2.50886 8.03528 2.47351 7.74039 2.52323 7.499C2.6651 6.81015 3.27111 6.25159 4.07871 6.06529C4.36172 6 4.717 6 5.42757 6H18.5724C19.283 6 19.6383 6 19.9213 6.06529C20.7289 6.25159 21.3349 6.81015 21.4768 7.499C21.5265 7.74039 21.4911 8.03528 21.4204 8.62505C21.2584 9.97669 20.4991 10.716 19.0512 11.1423L14.88 12.3703C13.4541 12.7901 12.7411 13 12 13C11.2589 13 10.5459 12.7901 9.11996 12.3703L4.94882 11.1423C3.50094 10.7161 2.7416 9.97669 2.57956 8.62505Z"
              stroke={getTypeColors(job.type)["textColor"]}
              strokeWidth="1.5"
            />
            <path
              d="M3.46283 10.5L3.26658 12.7757C2.91481 16.855 2.73892 18.8947 3.86734 20.1974C4.99576 21.5 6.93851 21.5 10.824 21.5H13.176C17.0615 21.5 19.0042 21.5 20.1327 20.1974C21.2611 18.8947 21.0852 16.855 20.7334 12.7757L20.5372 10.5"
              stroke={getTypeColors(job.type)["textColor"]}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.5 5.5L15.4227 5.23509C15.0377 3.91505 14.8452 3.25503 14.3869 2.87752C13.9286 2.5 13.3199 2.5 12.1023 2.5H11.8977C10.6801 2.5 10.0714 2.5 9.61309 2.87752C9.15478 3.25503 8.96228 3.91505 8.57727 5.23509L8.5 5.5"
              stroke={getTypeColors(job.type)["textColor"]}
              strokeWidth="1.5"
            />
          </svg>
        ),
      });
    }

    if (job.skills && job.categories) {
      tags.push({
        text: job.skills[0],
        ...getSkillColors(job.categories[0]),
      });
    }
    if (new Date().getTime() / 1000 - job.pubDate < 7 * 24 * 60 * 60) {
      tags.push({
        text: "New",
        textColor: "#5BC68D",
        bgColor: "#E6FBF0",
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5143 2.09327C11.8265 1.96891 12.1735 1.96891 12.4857 2.09327C13.4921 2.49406 13.6887 4.03744 14.8762 4.12336C15.7124 4.18386 16.533 3.48677 17.3721 3.69574C17.7105 3.78003 18.0028 3.99579 18.186 4.29657C18.7472 5.21824 18.0229 6.57292 18.9383 7.33768C19.5743 7.86877 20.6251 7.80004 21.178 8.4511C21.4108 8.72534 21.5252 9.08303 21.4953 9.4437C21.4068 10.5166 20.0389 11.1876 20.3395 12.3439C20.5475 13.1443 21.4253 13.707 21.4953 14.5563C21.5252 14.917 21.4108 15.2747 21.178 15.5489C20.4832 16.3669 18.9808 16.0975 18.5476 17.2062C18.2434 17.9844 18.634 18.9677 18.186 19.7034C18.0028 20.0042 17.7105 20.22 17.3721 20.3043C16.3302 20.5637 15.2727 19.4445 14.2701 20.0758C13.5543 20.5264 13.2978 21.5835 12.4857 21.9067C12.1735 22.0311 11.8265 22.0311 11.5143 21.9067C10.7022 21.5835 10.4457 20.5264 9.72989 20.0758C8.73971 19.4524 7.65213 20.5593 6.62791 20.3043C6.28947 20.22 5.9972 20.0042 5.81405 19.7034C5.25286 18.7818 5.97704 17.427 5.0617 16.6623C4.42582 16.1312 3.37494 16.2 2.82204 15.5489C2.58921 15.2747 2.47484 14.917 2.50465 14.5563C2.57485 13.707 3.4524 13.1443 3.6605 12.3439C3.95808 11.1997 2.59204 10.5009 2.50465 9.4437C2.47484 9.08303 2.58921 8.72534 2.82204 8.4511C3.51676 7.63284 5.01899 7.90253 5.45238 6.79383C5.75662 6.0156 5.36608 5.03227 5.81405 4.29657C5.9972 3.99579 6.28947 3.78003 6.62791 3.69574C7.46705 3.48677 8.28757 4.18387 9.12378 4.12336C10.3113 4.03746 10.5079 2.49406 11.5143 2.09327Z"
              stroke="#5BC68D"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M9 13.3333C9 13.3333 9.875 13.3333 10.75 15C10.75 15 13.5294 10.8333 16 10"
              stroke="#5BC68D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ),
      });
    }
    // Limit to maximum 3 tags
    return tags.slice(0, 3);
  };

  const reformatJobsInFirebase = async () => {
    const querySnapshot = await getDocs(
      query(collection(db, "Jobs"), where("linkedin", "==", true))
    );
    querySnapshot.forEach(async (doc) => {
      const job = doc.data();
      const updatedJob = {
        ...job,
        id: job.id.toString(),
        locationRestrictions:
          job.locationRestrictions !== "none" ? [job.locationRestrictions] : [],
        categories: job.categories !== "none" ? [job.categories] : [],
        seniority: job.seniority !== "none" ? [job.seniority] : [],
      };
      await updateDoc(doc.ref, updatedJob);
      console.log(`Updated job: ${job.id}`);
    });
  };

  return (
    <AnimatePresence layout="position">
      <motion.div
        className="w-1/2 shrink-0 flex flex-col items-start justify-start gap-[16px] text-left text-lg text-gray-700 font-text-l-regular overflow-y-auto h-screen"
        layout="position"
      >
        <motion.div className="self-stretch" layout="position">
          {/*<DemoBar
            openTags={() => setShowTags(true)}
            onSearch={setSearchInput}
            searchInput={searchInput}
            setFilters={setFilters}
            onTagSelect={(tag) => {
              setSearchInput("");
              setSelectedTags([...selectedTags, tag]);
            }}
            onTagRemove={(tagToRemove) => {
              setSelectedTags(
                selectedTags.filter((tag) => tag !== tagToRemove)
              );
            }}
            setResults={setResults}
            showFavoritesOnly={showFavoritesOnly}
            showUSOnly={showUSOnly}
            showRemoteOnly={showRemoteOnly}
            toggleShowFavoritesOnly={() =>
              setShowFavoritesOnly(!showFavoritesOnly)
            }
          />*/}
        </motion.div>
        <motion.div
          className="space-y-4 flex-grow overflow-y-auto w-full no-scrollbar"
          layout="position"
        >
          <AnimatePresence>
            {apps && apps
              .filter((app) => app.createdAt)
              .filter((app) =>
                [
                  "ashbyhq.com",
                  "workable.com",
                  "lever.co",
                  "greenhouse.io",
                ].some((domain) => app.url.includes(domain))
              )
              .map((app, index) => (
                <motion.div
                  key={app.id}
                  layout="position"
                  initial={{ opacity: 0, y: -5, scale: 0.9 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, y: -5, scale: 0.9 }}
                  transition={{
                    duration: 0.4,
                    ease: [0.43, 0.13, 0.23, 0.96],
                    exit: { duration: 0.2 },
                    delay: index < 10 ? index * 0.075 : 10 * 0.075,
                  }}
                >
                  <AnalyticsJobContainer
                    job={app}
                    onSelect={() => {
                      setSelectedJob(app);
                    }}
                    position={app.status}
                    pubDate={props.formatDate(app.createdAt)}
                    company={app.companyRef}
                    error={app.error}
                    //categories={job.categories}
                    //type={app.type}
                    //skills={job.skills}
                    //seniority={job.seniority}
                    /*location={
                      job.locationRestrictions
                        ? job.locationRestrictions.join(", ")
                        : "Remote"
                    }
                    tags={generateTags(job)}*/
                    selected={selectedApp && selectedApp.id === app.id}
                    id={app.id}
                    //maxSalary={
                    //  job.maxSalary ? `${job.maxSalary.toLocaleString()}` : ""
                    //}
                    ats={getATS(app.url)}
                  />
                </motion.div>
              ))}
          </AnimatePresence>
          {/*<DemoJobContainerWorkday job={results[0]} guid={"yo"} />*/}
          <div className="h-8"></div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default React.memo(AnalyticsContainer);
