import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import uniqid from "uniqid";
import { db, storage } from "../firebase";
import { collection, addDoc, updateDoc } from "firebase/firestore";
import { defaultTheme } from "../templates";
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  listAll,
  ref,
} from "firebase/storage";

const baseResume = {
  id: null,
  createdAt: null,
  updatedAt: null,
  title: "Imported",
  score: 0,
  template: defaultTheme,
  data: {},
  pdfUrl: "",
};

const useResumeStore = create(
  subscribeWithSelector((set, get) => ({
    resumes: [],
    createNew: () => {
      const newResume = {
        ...baseResume,
        id: uniqid(),
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };
      set((state) => ({ resumes: [...state.resumes, newResume] }));
      return newResume;
    },
    createNewPdf: (pdfUrl) => {
      const newResume = {
        ...baseResume,
        id: uniqid(),
        createdAt: Date.now(),
        updatedAt: Date.now(),
        pdfUrl: pdfUrl,
      };
      set((state) => ({ resumes: [...state.resumes, newResume] }));
      return newResume;
    },
    fillResumes: (resumes = []) => set(() => ({ resumes })),
    changeResume: (id, resume) =>
      set((state) => ({
        resumes: state.resumes.map((item) => (item.id === id ? resume : item)),
      })),
    getResume: (id) => get().resumes.find((res) => res.id === id) || {},

    deleteResume: async (id) => {
      const resumeRef = ref(storage, id);

      try {
        await deleteObject(resumeRef);
        console.log("File deleted successfully:", filePath);
      } catch (error) {
        console.error("Error while deleting file", error);
      }

      set((state) => ({
        resumes: state.resumes.filter((res) => res.id !== id),
      }));
    },

    saveResume: async (resume) => {
      const docRef = await addDoc(collection(db, "resumes"), resume);
      resume.id = docRef.id;
      set((state) => ({ resumes: [...state.resumes, resume] }));
      return resume;
    },
    loadResumes: async (userId, excludeIds) => {
      //const resumesDirRef = ref(storage, `${userId}/resumes/`);
      const resumesDirRef = ref(storage, `${userId}`);
      let userResumesRefs = [];
      try {
        const { items } = await listAll(resumesDirRef);

        userResumesRefs = items;
      } catch {
        set(() => ({ resumes: [] }));
        return;
      }
      const resumes = [];
      for (const resumeRef of userResumesRefs) {
        try {
          const metadata = await getMetadata(resumeRef);

          const url = await getDownloadURL(resumeRef);
          const match = metadata.name.match(/_([a-zA-Z0-9]+)\.pdf$/);
          if (match && excludeIds.includes(match[1])) continue;
          // const xId = excludeIds.find((id) => metadata.name === `${id}.pdf`);
          // if (xId) continue;
          resumes.push({
            id: metadata.fullPath,
            createdAt: new Date(metadata.timeCreated),
            updatedAt: new Date(metadata.updated),
            title: metadata.name,
            pdfUrl: url,
            score: 0,
            template: defaultTheme,
            data: {},
          });
        } catch {
          continue;
        }
      }
      set(() => ({ resumes }));
      return resumes;
    },
  }))
);

const useThisResume = create(
  subscribeWithSelector((set) => ({
    ...baseResume,
    addResume: (data = {}) => set(() => ({ ...data })),
    clearResume: () => set(() => ({ ...baseResume })),
    fillData: (data = {}) => {
      set((state) => ({ data: { ...state.data, ...data } }));
    },
    changeScore: (score = 0) => set(() => ({ score })),
    changeTemplate: (template) => set(() => ({ template })),
    addDataByKey: (key, val) =>
      set((state) => ({ data: { ...state.data, [key]: val } })),
  }))
);

const useTemplateStore = create(
  subscribeWithSelector((set, get) => ({
    unactiveTemplates: [],
    removeTemplate: (template) =>
      set(() => ({
        unactiveTemplates: [...get().unactiveTemplates, template],
      })),
    fillUnactiveTemplates: (unactiveTemplates = []) =>
      set(() => ({ unactiveTemplates })),
    clearUnactiveTemplates: () => set(() => ({ unactiveTemplates: [] })),
  }))
);

export { useResumeStore, useThisResume, useTemplateStore };
