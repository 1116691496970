import React, { forwardRef } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase"; // Import your authentication service
import { useUserData } from "../UserDataContext";

const SignOut = forwardRef(
  (
    {
      icon,
      label,
      onClick,
      isActive,
      backgroundColor,
      collapsed,
      isNew = false,
      isSignOut = false,
      onHover,
      onLeave,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const { userData, user, isAuthenticated } = useUserData();

    const handleSignOut = async () => {
      try {
        await auth.signOut(); // Replace with your sign-out method
        navigate("/login"); // Navigate to the login page
      } catch (error) {
        console.error("Error signing out:", error);
      }
    };

    const textVariants = {
      collapsed: { opacity: 0, x: -5 },
      expanded: { opacity: 1, x: 0 },
      exit: { opacity: 0, x: -5 },
    };

    const badgeStyle = {
      backgroundColor: "#E6FBF0",
      color: "#5BC68D",
      fontSize: "0.7em",
      padding: "2px 4px",
      borderRadius: "4px",
      marginLeft: "10px",
      border: "1px solid #5BC68D",
    };

    return (
      <div>
        <div
          ref={ref}
          onMouseEnter={() => {
            onHover();
          }}
          onMouseLeave={onLeave}
          className={`px-3`}
        >
          <button
            onClick={isSignOut ? handleSignOut : onClick}
            className={`cursor-pointer rounded-md [border:none] px-4 bg-[transparent] self-stretch h-[46px] shrink-0 flex flex-row box-border items-center justify-start gap-[12px]
          w-full`}
          >
            <div
              className={`cursor-pointer z-[1] [border:none] py-2.5 self-stretch shrink-0 flex transition-all duration-300 flex-row box-border items-center justify-start gap-[12px] rounded-none w-full text-gray-50 ${
                isAuthenticated ? "hover:text-red-600" : "hover:text-green-600"
              }`}
            >
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hover:stroke-red-600"
                >
                  <path
                    d="M7.78256 17.1112C6.68218 17.743 3.79706 19.0331 5.55429 20.6474C6.41269 21.436 7.36872 22 8.57068 22H15.4293C16.6313 22 17.5873 21.436 18.4457 20.6474C20.2029 19.0331 17.3178 17.743 16.2174 17.1112C13.6371 15.6296 10.3629 15.6296 7.78256 17.1112Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.5 10C15.5 11.933 13.933 13.5 12 13.5C10.067 13.5 8.5 11.933 8.5 10C8.5 8.067 10.067 6.5 12 6.5C13.933 6.5 15.5 8.067 15.5 10Z"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                  <path
                    d="M2.854 16C2.30501 14.7664 2 13.401 2 11.9646C2 6.46129 6.47715 2 12 2C17.5228 2 22 6.46129 22 11.9646C22 13.401 21.695 14.7664 21.146 16"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>

              <motion.div
                variants={textVariants}
                animate={collapsed ? "collapsed" : "expanded"}
                transition={{ duration: 0.3 }}
                exit="exit"
                className="overflow-hidden"
              >
                <div
                  className={`flex-1 relative text-mini tracking-[-0.1px] leading-[22px] bg-[transparent] font-medium font-text-l-regular text-left z-[1] whitespace-nowrap`}
                >
                  {label}
                  {isNew && <span style={badgeStyle}>New</span>}{" "}
                </div>
              </motion.div>
            </div>
          </button>
        </div>
      </div>
    );
  }
);
export default SignOut;
