import { compiledBlock as _compiledBlock } from "million/react";
const MyPageComponent_1 = _compiledBlock(_props => <div className="relative self-stretch bg-neutral-50 w-full h-screen flex flex-row items-start justify-start text-black border-r-[1px] border-solid border-neutral-600">
        <div className="relative self-stretch flex-1 bg-base-white flex flex-col items-start justify-start rounded-3xl pb-8 rounded-b-none m-6 mb-0 no-scrollbar w-full overflow-x-hidden border-[1px] border-solid border-neutral-600">
          <div className="absolute self-stretch w-full h-[10vh] bg-green-200">
            <div className="absolute top-[30px] left-[30px] cursor-pointer" onClick={_props.v0}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 9H16.5C18.9853 9 21 11.0147 21 13.5C21 15.9853 18.9853 18 16.5 18H12M3 9L7 5M3 9L7 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>, {
  name: "MyPageComponent_1"
});
const MyPageComponent_2 = _compiledBlock(_props2 => <div className="relative bg-neutral-50 w-full h-screen flex flex-row items-start justify-start text-left text-base text-gray-50 font-text-l-regular">
        <div className="m-auto items-center flex flex-col p-10 pt-4 rounded-2xl bg-base-white border border-solid border-neutral-600">
          <h1 className="w-full mb-7">404: Job not Found</h1>
          <button onClick={_props2.v0} className="cursor-pointer [border:none] py-2.5 px-[18px] bg-mediumseagreen-200 self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center gap-[6px] hover:bg-green-300 transition-colors duration-300">
            <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular text-base-white text-left">
              Back to Wrangle
            </div>
          </button>
        </div>
      </div>, {
  name: "MyPageComponent_2"
});
const MyPageComponent_3 = _compiledBlock(_props3 => <div className="relative self-stretch bg-neutral-50 w-full h-screen flex flex-row items-start justify-start text-black border-r-[1px] border-solid border-neutral-700">
      <div className="relative self-stretch flex-1 bg-base-white flex flex-col items-start justify-start rounded-md pb-8 rounded-b-none m-6 mb-0 no-scrollbar w-full overflow-x-hidden border-[1px] border-solid border-neutral-700">
        <div className="absolute self-stretch w-full h-[14vh] pt-8 pl-6 bg-green-200">
          <button className="font-text-l-regular cursor-pointer transform-gpu transition-all duration-300 text-sm bg-base-white rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border py-2 overflow-hidden flex items-center justify-start border-[2px] border-solid hover:bg-neutral-50 border-neutral-700 hover:border-neutral-300 text-black mr-2 px-4" onClick={_props3.v0}>
            Back
          </button>
        </div>
        <div className="absolute left-[2vw] top-[12vh] h-[10vh]">
          <img src={_props3.v1} className="border-[2px] border-neutral-700 border-solid rounded-lg h-[11vw] shadow-lg" alt="" />
        </div>
        <div className="mt-[18vh] flex flex-row">
          <div className="relative font-medium ml-[15vw] mt-[3vh] text-[32px] flex flex-col">
            <div className="flex flex-row justify-between">
              <div className="max-w-[40vw]">{_props3.v2}</div>
            </div>
            <div className="flex flex-row mt-4 text-sm font-normal">
              <div className="mr-2">{_props3.v3}</div>
              <img className="relative w-[18px] h-[18px] shrink-0" alt="" src="/location-pin.svg" />
              <div className="text-gray-500 ml-1 mr-3">
                {_props3.v4}
              </div>
              <img className="relative w-[18px] h-[18px] shrink-0" alt="" src="/timeloading.svg" />
              <div className="text-gray-500 ml-2">{"Full-Time"}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-[8vh] ml-8 w-[40vw]">
          <div className="text-black text-normal font-medium mb-2 text-[20px]">
            {_props3.v5}
          </div>
          <div className="text-gray-500 text-sm">{_props3.v6}</div>
        </div>
        <hr className="my-6 border-0 border-t border-[1px] border-slate-200 opacity-20 w-full max-w-[calc(100%-4rem)]" />
        <div className="relative flex flex-row ml-8">
          <div>
            <div className="font-medium mt-2">Job Description</div>
            <div className="font-light text-slate-500 text-[15px]">
              {_props3.v7}
            </div>
            {/*<div className="absolute bottom-0 right-8 text-[.1px]">
              View Listing on <a href={jobdata.guid}>Himalayas</a>
                </div>*/}
          </div>
          <div className="flex flex-row ml-[4vw]">
            <div className="flex flex-col w-[12vw]">
              <div className="font-light text-slate-500 text-[14px]">
                Location
              </div>
              <div className="font-normal text-black text-[16px] mt-2">
                {_props3.v8}
              </div>
              <div className="mt-8 font-light text-slate-500 text-[14px]">
                Pub Date
              </div>
              <div className="font-normal text-black text-[16px] mt-2">
                {_props3.v9}
              </div>
              <div className="mt-8 font-light text-slate-500 text-[14px]">
                Job Type
              </div>
              <div className="flex flex-row items-center gap-[8px] text-smi translate-y-[-2px] mt-3">
                {_props3.v10}
              </div>
            </div>
            <div className="flex flex-col w-[14vw]">
              <div className="font-light text-slate-500 text-[14px]">
                Timezone
              </div>
              <div className="font-normal text-black text-[16px] mt-2">
                {_props3.v11}
              </div>
              {_props3.v12}
              {_props3.v13}
            </div>
          </div>
        </div>
      </div>
      {_props3.v14}
    </div>, {
  name: "MyPageComponent_3",
  portals: ["v2", "v3", "v4", "v5", "v6", "v7", "v8", "v9", "v10", "v11", "v12", "v13", "v14"]
});
const MyPageComponent = function MyPageComponent() {
  useIdentifyUser();
  const [isAppModalOpen, setIsAppModalOpen] = useState(false);
  const {
    setUpgradeFeatureModal,
    appsUsed,
    currentPlan,
    bonusLeft,
    pendingApps,
    paymentsEnabled
  } = usePayment();
  const hashCode = s => s.split("").reduce((a, b) => (a << 5) - a + b.charCodeAt(0) | 0, 0);
  const {
    selectedJob,
    setBannerMessage,
    setBannerType
  } = useJobs();
  const [applied, setApplied] = useState(false);
  const navigate = useNavigate();
  const {
    user,
    isAuthenticated,
    userData,
    setUserData
  } = useUserData();
  const {
    jobGuid
  } = useParams();
  const [jobdata, setJobdata] = useState();
  const [title, setTitle] = useState();
  const [jobTitle, setJobTitle] = useState();
  const fallbackColor = {
    bgColor: "bg-gray-200",
    textColor: "text-gray-700"
  };
  let usermavenOpts = {
    key: "UMWZUKUs0g",
    tracking_host: "https://events.usermaven.com"
  };
  const {
    usermaven
  } = useUsermaven(usermavenOpts);
  const checkSubscribe = async () => {
    let nameregex = new RegExp("^[a-zA-Z]+[-' ]+[a-zA-Z]+([-' ]+[a-zA-Z]+)?$");
    let emailregex = new RegExp("(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])");
    let phoneregex = new RegExp("^(?=([+()\\d\\s-]*\\d){10,})[+\\d()\\s-]*$");
    if (userData.name == undefined || !nameregex.test(userData.name)) {
      setBannerMessage("Error: Invalid name.");
      setBannerType("error");
      return;
    } else if (userData.phone == undefined || !phoneregex.test(userData.phone)) {
      setBannerMessage("Error: Invalid phone.");
      setBannerType("error");
      return;
    } else if (userData.address == undefined || !(userData.address.length > 1)) {
      setBannerMessage("Error: Invalid address.");
      setBannerType("error");
      return;
    } else if (userData.email == undefined || !emailregex.test(userData.email)) {
      setBannerMessage("Error: Invalid email.");
      setBannerType("error");
      return;
    } else if (userData.filename == undefined || userData.filename == null) {
      setBannerMessage("Error: Please upload a resume.");
      setBannerType("error");
      return;
    } else {
      try {
        const dbRef = ref(storage, String(user.uid));
        const res = await listAll(dbRef);
        if (res.items.length === 0) {
          setBannerMessage("Error: Please reupload your resume.");
          setBannerType("errorRegex");
          return; // This will exit the function, so subsequent code will not run
        }
      } catch (error) {
        return;
        // Handle the error as needed
      }
    }
    //console.log(selectedJob.applyNowLink);
    if (!isAuthenticated) {
      setIsAppModalOpen(false);
      return;
    }
    if (paymentsEnabled) {
      let pending = pendingApps > 0 ? pendingApps : 0;
      if ((currentPlan == 1 && 15 - appsUsed - pending <= 0 || currentPlan == 2 && 50 - appsUsed - pending <= 0 || currentPlan == 3 && 250 - appsUsed - pending <= 0) && bonusLeft - pending < 1 && currentPlan != 4) {
        setIsAppModalOpen(false);
        setUpgradeFeatureModal(true);
        return;
      }
    }
    usermaven.track("Applied to job from MoreInfoPage", {
      uid: user?.uid,
      name: userData?.name,
      email: userData?.email,
      address: userData?.address,
      appsUsed: appsUsed,
      salary: userData?.keywords?.salary,
      seniority: userData?.keywords?.seniority,
      title: userData?.keywords?.title,
      jobId: jobdata?.guid
    });
    setIsAppModalOpen(true);
    const jobId = hashCode(jobGuid).toString(); // Replace with the actual job ID
    const currentUrl = window.location.href;
    const lastPart = currentUrl.split("/job/").pop();
    const separator = currentUrl.includes("?") ? "&" : "?";
    window.history.pushState({}, "", `${currentUrl}${separator}apply=${lastPart}`);
  };
  useEffect(() => {
    const getJob = async () => {
      if (isAppModalOpen) return;
      const jobRef = doc(db, "/Jobs/", jobGuid);
      const docSnap = await getDoc(jobRef);
      const data = docSnap.data();
      if (data) {
        setJobdata(data);
        setTitle(data.title);
        setJobTitle(data.title.match(/[\w\s\/.]+/)[0]);
      } else {
        setJobdata(false);
      }
    };
    getJob();
  }, []);
  const isTitleLine = element => {
    return element.type === "h3";
  };
  const truncateDescription = (desc, maxLines = 84) => {
    // Define allowed tags
    const allowedTags = sanitizeHtml.defaults.allowedTags.concat(["img"]);

    // Sanitize HTML and remove unwanted tags
    const sanitizedHtml = sanitizeHtml(desc, {
      allowedTags: allowedTags
    });

    // Parse sanitized HTML
    const parsedDescription = parse(sanitizedHtml);
    let truncatedLines = [];
    let totalLines = 0;
    for (let i = 0; i < parsedDescription.length && totalLines < maxLines; i++) {
      const element = parsedDescription[i];
      if (element.type === "p" || element.type === "ul") {
        totalLines += 1;
      } else if (isTitleLine(element)) {
        totalLines += 1;
      }
      if (totalLines <= maxLines) {
        truncatedLines.push(element);
      }
    }
    const cutLast = truncatedLines.pop();
    return truncatedLines;
  };
  const tagColors = {
    engineering: {
      bgColor: "bg-green-50",
      textColor: "text-green-600"
    },
    sales: {
      bgColor: "bg-blue-50",
      textColor: "text-blue-600"
    },
    marketing: {
      bgColor: "bg-purple-50",
      textColor: "text-purple-600"
    },
    product: {
      bgColor: "bg-pink-50",
      textColor: "text-pink-600"
    },
    design: {
      bgColor: "bg-teal-50",
      textColor: "text-teal-600"
    },
    support: {
      bgColor: "bg-red-50",
      textColor: "text-red-600"
    },
    operations: {
      bgColor: "bg-orange-50",
      textColor: "text-orange-600"
    },
    data: {
      bgColor: "bg-indigo-50",
      textColor: "text-indigo-600"
    },
    finance: {
      bgColor: "bg-yellow-50",
      textColor: "text-yellow-600"
    },
    management: {
      bgColor: "bg-gray-100",
      textColor: "text-gray-600"
    }
  };
  const renderTag = tag => {
    const normalizedTag = tag.toLowerCase();
    const color = tagColors[normalizedTag] || {
      bgColor: "bg-gray-100",
      textColor: "text-gray-600"
    };
    return <div className={`rounded-8xs ${color.bgColor} ${color.textColor} flex flex-row py-0.5 px-2 items-center justify-start gap-[4px]`}>
        <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
          {tag.charAt(0).toUpperCase() + tag.slice(1)}
        </div>
      </div>;
  };
  const formatDate = timestamp => {
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  if (jobdata == undefined) {
    return /*@million jsx-skip*/<MyPageComponent_1 v0={() => {
      navigate("/opportunities");
    }} />;
  }
  if (jobdata == false) {
    return /*@million jsx-skip*/<MyPageComponent_2 v0={() => {
      navigate("/jobs");
    }} />;
  }
  return /*@million jsx-skip*/<MyPageComponent_3 v0={() => {
    navigate("/tracker");
  }} v1={jobdata.companyLogo} v2={jobdata.title} v3={jobdata.companyName} v4={jobdata.locationRestrictions ? jobdata.locationRestrictions.join(", ") : "Remote"} v5={jobdata.companyName} v6={jobdata.excerpt} v7={truncateDescription(jobdata.description).map((element, index) => <React.Fragment key={index}>{element}</React.Fragment>)} v8={jobdata.locationRestrictions ? jobdata.locationRestrictions.join(", ") : "Remote"} v9={formatDate(jobdata.pubDate)} v10={renderTag(jobdata.categories?.[0] || "None")} v11={jobdata.timezoneRestriction || "Any"} v12={jobdata.seniority ? <>
                  <div className="mt-8 font-light text-slate-500 text-[14px]">
                    Experience
                  </div>
                  <div className="font-normal text-black text-[16px] mt-2">
                    {jobdata.seniority[0]}
                  </div>{" "}
                </> : null} v13={jobdata.minSalary ? <>
                  <div className="mt-8 font-light text-slate-500 text-[14px]">
                    Salary
                  </div>
                  <div className="font-normal text-black text-[16px] mt-2">
                    ${jobdata.minSalary.toLocaleString()}-$
                    {jobdata.maxSalary.toLocaleString()}
                  </div>{" "}
                </> : null} v14={<ApplicationModal isOpen={isAppModalOpen} onCloseApp={buttonName => {
    setIsAppModalOpen(false);
  }} inpLink={jobdata.applyNowLink} company={jobdata.company} guid={jobdata.guid} applied={applied} applyNowLink={jobdata.applyNowLink} setApplied={setApplied} companyName={jobdata ? jobdata.companyName : ""} title={jobdata ? jobdata.title : ""} htmlId={false} />} />;
};
import { block as _block } from "million/react";
import { useParams } from "react-router-dom";
import { db, storage } from "../firebase"; // Import the db instance
import { getDoc, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import sanitizeHtml from "sanitize-html";
import parse from "html-react-parser";
import React from "react";
import { useUserData } from "../UserDataContext";
import { usePayment } from "../PaymentContext";
import { useJobs } from "../jobsContext";
import ApplicationModal from "../components/ApplicationModal";
import { useNavigate } from "react-router-dom";
import { useIdentifyUser } from "../hooks/useIdentifyUser";
import { ref, uploadBytesResumable, deleteObject, listAll } from "firebase/storage";
import { useUsermaven } from "../UserMaven";
export default MyPageComponent;