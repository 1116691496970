import React from "react";
import { motion } from "framer-motion";

const SideMenuDemo = ({
  icon,
  label,
  onClick,
  isActive,
  backgroundColor,
  collapsed,
  isNew = false, // Add a prop to control the display of the 'New' badge
}) => {
  const textVariants = {
    collapsed: { opacity: 0, x: -5 },
    expanded: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -5 },
  };

  const badgeStyle = {
    backgroundColor: "#E6FBF0",
    color: "#5BC68D",
    fontSize: "0.7em", // Make the text smaller than the main text
    padding: "2px 4px",
    borderRadius: "4px",
    marginLeft: "10px",
    border: "1px solid #5BC68D",
  };

  return (
    <div>
      <div
        className={` px-4 ${
          isActive
            ? "text-primary-600"
            : "transition-colors duration-300 text-gray-50 hover:text-primary-600"
        }`}
      >
        <button
          onClick={onClick}
          className={`cursor-pointer rounded-md [border:none] py-0 px-3 bg-[transparent] self-stretch h-[46px] shrink-0 flex flexRow box-border items-center justify-start gap-[12px] ${
            isActive
              ? "text-primary-600 bg-demo-bg"
              : "transition-all duration-300 hover:bg-gray-neutral-100 text-gray-50 hover:text-demo"
          } w-full`}
        >
          <div
            className={`cursor-pointer [border:none] py-0 self-stretch h-[46px] shrink-0 flex flexRow box-border items-center justify-start gap-[12px] rounded-none ${
              isActive
                ? "text-demo"
                : "transition-colors text-gray-50 hover:text-demo"
            }
            ${isActive ? "" : ""}
             w-full`}
          >
            <div
              className={`relative w-[24px] h-[24px] shrink-0 z-[0] ${
                isActive ? "text-demo" : "transition-colors hover:text-demo"
              }`}
              alt=""
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.5429 16.5429C16.9334 16.1524 17.5666 16.1524 17.9571 16.5429L22.4571 21.0429C22.8476 21.4334 22.8476 22.0666 22.4571 22.4571C22.0666 22.8476 21.4334 22.8476 21.0429 22.4571L16.5429 17.9571C16.1524 17.5666 16.1524 16.9334 16.5429 16.5429Z"
                  fill="currentColor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.25 11C1.25 5.61522 5.61522 1.25 11 1.25C16.3848 1.25 20.75 5.61522 20.75 11C20.75 16.3848 16.3848 20.75 11 20.75C5.61522 20.75 1.25 16.3848 1.25 11ZM11 3.2C6.69218 3.2 3.2 6.69218 3.2 11C3.2 15.3078 6.69218 18.8 11 18.8C15.3078 18.8 18.8 15.3078 18.8 11C18.8 6.69218 15.3078 3.2 11 3.2Z"
                  fill="currentColor"
                />
                <path
                  d="M13.7427 8.84714C13.3821 9.26547 13.4289 9.8969 13.8472 10.2575L14.7087 11L13.8472 11.7426C13.4289 12.1031 13.3821 12.7346 13.7427 13.1529C14.1032 13.5712 14.7347 13.6181 15.153 13.2575L16.0729 12.4646L16.1048 12.4371C16.2693 12.2955 16.461 12.1305 16.6039 11.9691C16.7693 11.7821 17.0001 11.4589 17.0001 11C17.0001 10.5411 16.7693 10.2179 16.6039 10.031C16.461 9.86957 16.2693 9.70453 16.1048 9.56294L16.0729 9.53545L15.153 8.74255C14.7347 8.38198 14.1032 8.4288 13.7427 8.84714Z"
                  fill="currentColor"
                />
                <path
                  d="M8.25758 8.84714C8.61816 9.26547 8.57134 9.8969 8.153 10.2575L7.29152 11L8.153 11.7426C8.57134 12.1031 8.61816 12.7346 8.25758 13.1529C7.89701 13.5712 7.26558 13.6181 6.84724 13.2575L5.92734 12.4646L5.89542 12.4371C5.73095 12.2955 5.53925 12.1305 5.39636 11.9691C5.23092 11.7821 5.00012 11.4589 5.00012 11C5.00012 10.5411 5.23092 10.2179 5.39636 10.031C5.53925 9.86957 5.73095 9.70453 5.89542 9.56294L5.92734 9.53545L6.84724 8.74255C7.26558 8.38198 7.89701 8.4288 8.25758 8.84714Z"
                  fill="currentColor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.3715 7.57179C12.8843 7.7769 13.1337 8.35887 12.9286 8.87166L10.9286 13.8717C10.7235 14.3844 10.1415 14.6339 9.62875 14.4287C9.11597 14.2236 8.86655 13.6417 9.07167 13.1289L11.0717 8.12888C11.2768 7.61609 11.8588 7.36668 12.3715 7.57179Z"
                  fill="currentColor"
                />
              </svg>
            </div>

            <motion.div
              variants={textVariants}
              animate={collapsed ? "collapsed" : "expanded"}
              transition={{ duration: 0.3 }}
              exit="exit"
            >
              <div
                className={`flex-1 relative text-mini tracking-[-0.1px] leading-[22px] bg-[transparent] font-medium font-text-l-regular text-left z-[1] whitespace-nowrap`}
              >
                {label}
                {isNew && <span style={badgeStyle}>Beta</span>}{" "}
              </div>
            </motion.div>
          </div>
          <img
            className="relative w-6 h-6 shrink-0 overflow-hidden hidden z-[3]"
            alt=""
            src="/chevrondown.svg"
          />
        </button>
      </div>
    </div>
  );
};

export default SideMenuDemo;
