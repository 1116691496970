import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  ref,
  uploadBytesResumable,
  deleteObject,
  listAll,
} from "firebase/storage";
import { db, storage, auth } from "../firebase";
import { useEffect, useRef, useLayoutEffect } from "react";
import { useUserData } from "../UserDataContext";
import PlacesAutocomplete from "react-places-autocomplete";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import * as mammoth from "mammoth/mammoth.browser";
import { useNavigate } from "react-router-dom";
import { useUsermaven } from "../UserMaven";
import JobsPreview from "./JobsPreview";
import CreatableSelect from "react-select/creatable";
import { SlMagnifier } from "react-icons/sl";

GlobalWorkerOptions.workerSrc = pdfjsWorker;

const FileUploadBase = ({ onFileUpload, moveForward }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [uploadProgress, setUploadProgress] = useState(0);
  const { user, userData, setUserData, currentApi } = useUserData();
  const [filename, setFilename] = useState("");

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const file = e.dataTransfer.files[0];
    uploadFile(file);
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    uploadFile(file);
  };

  const uploadFile = async (file) => {
    if (Math.round(file.size / 1000000) > 10) {
      alert("file too big");
      return;
    }

    const allowedExtensions = /(\.txt|\.pdf|\.docx)$/i;
    if (!allowedExtensions.exec(file.name)) {
      alert("Invalid file type");
      return;
    }

    setUploadStatus("uploading");

    const dbRef = ref(storage, user.uid);
    listAll(dbRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          deleteObject(itemRef)
            .then(() => {})
            .catch((error) => {
              //console.log("error deleting")
            });
        });
      })
      .catch((error) => {
        //console.log("error", error)
      });

    const storageRef = ref(storage, user.uid + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setUploadStatus("uploading");
        //console.log("uploading");
        setUploadProgress(
          Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 90)
        );
      },
      (error) => {
        //console.error("Upload failed", error);
        setUploadStatus("error");
      },
      async () => {
        setFilename(file.name);
        //console.log(file.name);
        const updatedResumes = userData.allResumes
          ? [...new Set([...userData.allResumes, file.name])]
          : [file.name];
        setUserData({
          ...userData,
          filename: file.name,
          allResumes: updatedResumes,
        });

        const reader = new FileReader();
        //console.log(reader);

        let text; // declare text here

        reader.onload = async (event) => {
          //console.log("reading");
          if (file.name.endsWith(".txt")) {
            text = event.target.result;
          } else if (file.name.endsWith(".pdf")) {
            const pdf = await getDocument({ data: event.target.result })
              .promise;
            const numPages = pdf.numPages;

            text = "";

            for (let i = 1; i <= numPages; i++) {
              const page = await pdf.getPage(i);
              const content = await page.getTextContent();
              text += content.items.map((item) => item.str).join(" ");
            }
          } else if (file.name.endsWith(".docx")) {
            //console.log("dealing with docx");
            const arrayBuffer = event.target.result;
            const options = { includeDefaultStyleMap: true };
            const result = await mammoth.extractRawText(
              { arrayBuffer: arrayBuffer },
              options
            );
            text = result.value;
          }

          //console.log(text);

          // Call the API here after text is parsed
          //console.log("text", text);
          try {
            let idToken;
            if (auth.currentUser) {
              idToken = await auth.currentUser.getIdToken(true);
            } else {
              return;
            }
            const response = await fetch(
              `https://app.wranglejobs.com/api/resume-parse`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
                body: JSON.stringify({ text }),
              }
            );

            if (response.ok) {
              const data = await response.json();

              // Check if data is a string and try to parse it
              let parsedData = data;
              if (typeof data === "string") {
                try {
                  parsedData = JSON.parse(data);
                } catch (e) {
                  console.error("Error parsing data string:", e);
                }
              }

              //console.log(parsedData);
              //console.log("summary", parsedData['summary']);
              //console.log("keywords", parsedData['keywords']);

              setUserData({
                ...userData,
                keyInfo: parsedData["summary"],
                keywords: parsedData["keywords"],
              });

              onFileUpload();
              setUploadProgress(100);
              setUploadStatus("complete");
            } else {
              console.error("Error getting resume summary");
              onFileUpload();
              setUploadProgress(100);
              setUploadStatus("complete");
            }
          } catch (err) {
            console.error("Error getting resume summary with error:", err);
            onFileUpload();
            setUploadProgress(100);
            setUploadStatus("complete");
          }
        };

        if (file.name.endsWith(".txt")) {
          reader.readAsText(file);
        } else if (file.name.endsWith(".pdf") || file.name.endsWith(".docx")) {
          reader.readAsArrayBuffer(file);
        }
      }
    );
    // const storageRef2 = ref(storage, user.uid + "/resumes/" + file.name);
    // const uploadTask2 = uploadBytesResumable(storageRef2, file);
    // uploadTask2.on(
    //   "state_changed",
    //   (snapshot) => {},
    //   (error) => {
    //     //console.error("Upload failed", error);
    //     setUploadStatus("error");
    //   }
    // );
    const storageRef3 = ref(storage, user.uid + "/" + file.name);
    const uploadTask3 = uploadBytesResumable(storageRef3, file);
    uploadTask3.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        //console.error("Upload failed", error);
        setUploadStatus("error");
      }
    );
  };

  return (
    <div
      className={`relative rounded-md ${
        isDragOver ? "bg-mintcream-100" : "bg-base-white"
      } box-border w-full flex flex-col py-4 px-6 items-center justify-start text-left text-sm text-primary-700 font-text-xs-regular border-[1px] border-solid border-gray-200`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
        <img
          className="relative rounded-[28px] w-[46px] h-[46px]"
          alt=""
          src="/upload.png"
        />
        <div className="self-stretch flex flex-col items-center justify-start gap-[4px]">
          {uploadStatus != "idle" ||
          (uploadProgress < 100 && uploadProgress != 0) ? (
            <>
              {uploadStatus === "complete" ? (
                <div>Processing complete</div>
              ) : uploadProgress == 90 ? (
                <div>Generating profile. This can take up to a minute.</div>
              ) : (
                <div>Uploading file: {uploadProgress}%</div>
              )}
              <ProgressBarUpload progress={uploadProgress} />
            </>
          ) : (
            <>
              <div className="self-stretch flex flex-row items-start justify-center gap-[4px]">
                <div className="overflow-hidden flex flex-row items-center justify-center gap-[8px]">
                  <label
                    htmlFor="file-upload"
                    className="relative leading-[20px] font-semibold text-mediumseagreen-200 cursor-pointer"
                  >
                    Click to upload
                  </label>

                  <input
                    type="file"
                    id="file-upload"
                    className="hidden"
                    onChange={handleFileInput}
                  />
                </div>
                <div className="relative leading-[20px] text-gray-600">
                  or drag and drop
                </div>
              </div>
              <div className="self-stretch relative text-xs leading-[18px] text-gray-600 text-center">
                PDF, DOCX, or TXT (max. 5 MB)
              </div>
            </>
          )}
        </div>
      </div>
      <input type="file" className="hidden" onChange={handleFileInput} />
    </div>
  );
};

const Linkedin = ({ handleClose }) => {
  const [currentStep, setCurrentStep] = useState(1);

  const data = {
    1: {
      image: "linkedin1.png",
      title: "Navigate to your profile",
      description:
        'To get started exporting your resume, navigate to your LinkedIn profile and click “More”, which should open a dropdown with some options. Here, select "build resume".',
    },
    2: {
      image: "linkedin2.png",
      title: "Build your resume",
      description:
        "Once you click on build a resume, it will open a modal with two options, of which you should select “create from profile”, which will route you to the resume builder.",
    },
    3: {
      image: "linkedin3.png",
      title: "Download and submit",
      description:
        "Once your resume is customized to your liking, click the “Download as PDF” button near the top of the screen, and upload here!",
    },
  };

  useEffect(() => {
    const imageUrls = Object.values(data).map((item) => item.image);
    imageUrls.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  const handleContinue = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
      <motion.div
        className="flex flex-col items-center justify-start gap-4 text-left text-sm text-e"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
      >
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={handleClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="inline-block align-middle justify-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full relative bg-base-white shadow-md w-full overflow-hidden flex flex-col items-start justify-start text-center text-lg text-gray-900 font-text-sm-semibold">
          <div className="self-stretch bg-base-white flex flex-row pt-4 px-0 pb-0 items-center justify-center">
            <div className="self-stretch flex-1 flex flex-col px-5 pb-6 items-start justify-start gap-12">
              <div className="self-stretch flex flex-col items-center justify-center gap-8">
                <motion.div
                  className="self-stretch flex flex-col py-0 px-3 items-center justify-center gap-2"
                  key={currentStep}
                  initial={{ opacity: 0.01, x: 10 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0.01, x: -10 }}
                  transition={{ duration: 0.7, ease: "easeInOut" }}
                >
                  <div className="self-stretch flex flex-col items-center justify-center">
                    <img
                      className="relative rounded-3xs w-full h-auto py-4 object-cover"
                      src={data[currentStep].image}
                    />
                  </div>

                  <div className="self-stretch relative leading-7 font-semibold">
                    {data[currentStep].title}
                  </div>
                  <div className="self-stretch relative text-base leading-6 text-gray-600">
                    {data[currentStep].description}
                  </div>
                </motion.div>
                <div className="flex justify-center space-x-2 mb-4">
                  {Array(3)
                    .fill()
                    .map((_, i) => (
                      <div
                        key={i}
                        className={`relative rounded-xl w-2 h-2 ${
                          i < currentStep
                            ? "bg-mediumseagreen-200"
                            : "bg-gray-neutral-200"
                        }`}
                      />
                    ))}
                </div>
                <div className="self-stretch flex flex-row items-start justify-center gap-3 text-left text-sm text-gray-700">
                  <div
                    className="flex-1 rounded-lg bg-base-white shadow-sm overflow-hidden flex flex-row py-3.5 px-4 items-center justify-center border border-solid border-gray-300"
                    onClick={currentStep == 1 ? handleClose : handleBack}
                  >
                    <div className="relative leading-5 font-semibold">
                      {currentStep == 1 ? "Close" : "Back"}
                    </div>
                  </div>
                  <div
                    className="flex-1 rounded-lg bg-mediumseagreen-100 shadow-sm overflow-hidden flex flex-row py-3.5 px-4 items-center justify-center text-base-white border border-solid border-mediumseagreen-100"
                    onClick={currentStep == 3 ? handleClose : handleContinue}
                  >
                    <div className="relative leading-5 font-semibold">
                      {currentStep == 3 ? "Finish" : "Continue"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const ProgressBarUpload = ({ progress }) => {
  const previousProgress = progress - 10 > 0 ? progress - 10 : 0;

  return (
    <div className="self-stretch overflow-hidden rounded-md bg-gray-neutral-200">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: `${progress}%` }}
        transition={{
          from: `${previousProgress}%`,
          duration: 0.8,
          ease: "easeInOut",
        }}
        className="h-2 bg-mediumseagreen-200 rounded-md"
      />
    </div>
  );
};

const Step1 = ({ nextStep }) => {
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="justify-center items-center">
      <div className="self-stretch flex flex-col items-start justify-start mb-12">
        <motion.div
          className="relative tracking-[-0.01em] leading-[38px] font-semibold"
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.8, delay: 0, ease: "easeOut" }}
        >
          Welcome to Wrangle!
        </motion.div>
        <motion.div
          className="self-stretch relative text-base tracking-[-0.1px] leading-[24px] text-gray-50"
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.8, delay: 0.1, ease: "easeOut" }}
        >
          Let’s get your profile ready so we can get you hired. This will only
          take 2-3 minutes.
        </motion.div>
      </div>
      <div className="self-stretch flex flex-col items-center justify-center gap-[24px] w-full max-w-screen-md mx-auto">
        <motion.button
          onClick={nextStep}
          className="cursor-pointer py-6 px-[18px] bg-mediumseagreen-200 w-full rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center border-[1px] border-solid border-neutral-600"
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
        >
          <div className="relative text-lg leading-[22px] font-semibold font-text-sm-semibold text-base-white text-left">
            Get Started
          </div>
        </motion.button>
      </div>
    </div>
  );
};

const Step2 = ({ nextStep }) => {
  const options = [
    { value: "Engineering", label: "Engineering" },
    { value: "Sales", label: "Sales" },
    { value: "Marketing", label: "Marketing" },
    { value: "Product", label: "Product" },
    { value: "Design", label: "Design" },
    { value: "Support", label: "Support" },
    { value: "Operations", label: "Operations" },
    { value: "Data", label: "Data" },
    { value: "Finance", label: "Finance" },
    { value: "Management", label: "Management" },
  ];
  const [industryTouched, setIndustryTouched] = useState(false);
  const [industrySelection, setIndustrySelction] = useState("");
  const { userData, setUserData } = useUserData();
  const [name, setName] = useState("");
  const [nameTouched, setNameTouched] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [address, setAddress] = useState("");
  const [addressTouched, setAddressTouched] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [targetRole, setTargetRole] = useState("");
  const [targetRoleTouched, setTargetRoleTouched] = useState(false);
  const nameRegex = /^[a-zA-ZÀ-ÿ.,'-]+(\s[a-zA-ZÀ-ÿ.,'-]+)*$/;
  const phoneRegex =
    /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  const handleNameChange = (e) => {
    setName(e.target.value);
    setIsValidName(nameRegex.test(e.target.value));
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    setIsValidPhone(phoneRegex.test(e.target.value));
  };

  const handleSubmit = () => {
    if (industrySelection && targetRole) {
      setUserData({
        ...userData,
        name: name,
        phone: phone,
        address: address,
        industry: inputValue,
        role: targetRole,
      });

      nextStep();
    } else {
      setIndustryTouched(true);
      setTargetRoleTouched(true);
    }
  };

  const handleChangeIndustry = (newValue) => {
    setIndustrySelction(newValue);
    setIndustryTouched(true);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setIsDropdownOpen(true);
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleOptionClick = (option) => {
    setInputValue(option.label);
    handleChangeIndustry([option.value]);
    setIsDropdownOpen(false);
  };

  const showError = (value, touched, isValid, type) => {
    if (!touched) return "";
    switch (type) {
      case "name":
        return isValid ? "" : "Please enter your full name";
      case "phone":
        return isValid ? "" : "Please enter a valid number";
      case "address":
        return value ? "" : "Please enter your full address";
      case "industry":
        return value ? "" : "Please select an industry";
      default:
        return "";
    }
  };

  return (
    <div className="justify-center items-center">
      <motion.div
        layout
        initial={{ opacity: 0, translateY: 10 }}
        animate={{ opacity: 1, translateY: 0 }}
        exit={{ opacity: 0, translateY: 10 }}
        transition={{
          duration: 0.5,
          delay: 0,
          ease: "easeOut",
        }}
        className="self-stretch flex flex-col items-start justify-start mb-12"
      >
        <div className="relative tracking-[-0.01em] leading-[38px] font-semibold">
          Profile Information.
        </div>
        <div className="self-stretch relative text-base tracking-[-0.1px] leading-[24px] text-gray-50">
          We'll use this info to help the AI fill out your applications. We
          won't share this with anyone else, or use it to call you.
        </div>
      </motion.div>
      <div className="relative bg-base-white w-full flex flex-col items-stretch justify-center text-left text-9xl text-gray-700 font-text-sm-semibold">
        <div className="flex flex-col items-stretch justify-center gap-[16px] text-sm mb-12">
          <div className="w-full flex flex-col items-stretch justify-start">
            <div className="self-stretch flex flex-row items-stretch justify-start gap-[16px]">
              <div className="flex-1 flex flex-col items-start justify-start gap-[6px]">
                <motion.div
                  layout
                  initial={{ opacity: 0, translateY: 10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  exit={{ opacity: 0, translateY: 10 }}
                  transition={{
                    duration: 0.5,
                    delay: 0,
                    ease: "easeOut",
                  }}
                  className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium"
                >
                  Full Name <span className="text-red-600">*</span>
                </motion.div>
                <motion.div
                  layout
                  className="self-stretch flex flex-col items-start justify-start gap-[6px]"
                  initial={{ opacity: 0, translateY: 10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  exit={{ opacity: 0, translateY: 10 }}
                  transition={{ duration: 0.5, delay: 0, ease: "easeOut" }}
                >
                  <input
                    className="font-text-l-regular text-mini focus:outline-none focus:ring focus:ring-green-50 hover:border-neutral-300 transition-all duration-300 focus:border-mediumseagreen-200 bg-base-white self-stretch hover:bg-neutral-50 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700"
                    type="text"
                    placeholder="Full Name"
                    required
                    onBlur={() => setNameTouched(true)}
                    onChange={handleNameChange}
                  />
                </motion.div>
                {nameTouched && !isValidName && (
                  <motion.div
                    layout
                    className="self-stretch relative leading-[20px] text-red-600"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    {showError(name, nameTouched, isValidName, "name")}
                  </motion.div>
                )}
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[6px]">
                <motion.div
                  layout
                  initial={{ opacity: 0, translateY: 10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  exit={{ opacity: 0, translateY: 10 }}
                  transition={{
                    duration: 0.5,
                    delay: 0,
                    ease: "easeOut",
                  }}
                  className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium"
                >
                  Phone <span className="text-red-600">*</span>
                </motion.div>
                <motion.div
                  layout
                  className="self-stretch flex flex-col items-start justify-start gap-[6px]"
                  initial={{ opacity: 0, translateY: 10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  exit={{ opacity: 0, translateY: 10 }}
                  transition={{ duration: 0.5, delay: 0, ease: "easeOut" }}
                >
                  <input
                    className="font-text-l-regular text-mini focus:outline-none focus:ring focus:ring-green-50 hover:border-neutral-300 transition-all duration-300 focus:border-mediumseagreen-200 bg-base-white self-stretch hover:bg-neutral-50 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700"
                    type="tel"
                    placeholder="Phone Number"
                    required
                    onBlur={() => setPhoneTouched(true)}
                    onChange={handlePhoneChange}
                  />
                </motion.div>
                {phoneTouched && !isValidPhone && (
                  <motion.div
                    layout
                    className="self-stretch relative leading-[20px] text-red-600"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    {showError(phone, phoneTouched, isValidPhone, "phone")}
                  </motion.div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col items-center justify-center">
            <div className="self-stretch flex flex-row items-center justify-center">
              <div className="flex-1 flex flex-col items-start justify-start gap-[6px]">
                <motion.div
                  layout
                  initial={{ opacity: 0, translateY: 10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  exit={{ opacity: 0, translateY: 10 }}
                  transition={{
                    duration: 0.5,
                    delay: 0,
                    ease: "easeOut",
                  }}
                  className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium"
                >
                  Address <span className="text-red-600">*</span>
                </motion.div>
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={setAddress}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div
                      className="relative w-full flex-1 flex flex-col items-start justify-start gap-[6px] text-left text-sm text-gray-700 font-text-l-regular"
                      style={{ height: "auto" }}
                    >
                      <motion.div
                        layout
                        className="self-stretch flex flex-col items-start justify-start gap-[6px]"
                        initial={{ opacity: 0, translateY: 10 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        exit={{ opacity: 0, translateY: 10 }}
                        transition={{
                          duration: 0.5,
                          delay: 0,
                          ease: "easeOut",
                        }}
                      >
                        <input
                          {...getInputProps({
                            placeholder:
                              "Address (Must include city and state)",
                          })}
                          required
                          className="font-text-l-regular text-mini focus:outline-none focus:ring focus:ring-green-50 hover:border-neutral-300 transition-all duration-300 focus:border-mediumseagreen-200 bg-base-white self-stretch hover:bg-neutral-50 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700"
                        />
                      </motion.div>
                      {suggestions.length > 0 && (
                        <div className="absolute z-10 bg-white w-full border rounded-md shadow-md text-base overflow-auto translate-y-[50px]">
                          {suggestions.slice(0, 5).map((suggestion, index) => (
                            <div
                              className="text-base truncate px-2 py-1 hover:bg-gray-200 cursor-pointer"
                              {...getSuggestionItemProps(suggestion)}
                              key={index}
                            >
                              {suggestion.description}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            </div>
          </div>
          <motion.div
            layout
            initial={{ opacity: 0, translateY: 10 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: 10 }}
            transition={{
              duration: 0.5,
              delay: 0,
              ease: "easeOut",
            }}
            className="flex flex-col w-full"
          >
            <div className="self-stretch relative tracking-[-0.1px] leading-[15px] font-medium text-sm">
              Industry of Interest <span className="text-red-600">*</span>
            </div>
            <div className="flex flex-row justify-start items-center w-full gap-0 mt-2">
              <div
                ref={dropdownRef}
                className={`relative transition-all duration-300 hover:bg-neutral-50 w-full border border-[1px] border-solid ${
                  !isDropdownOpen
                    ? "border-neutral-700"
                    : "border-mediumseagreen-200"
                } rounded-lg`}
              >
                <div
                  className={`cursor-pointer flex-1 rounded-r-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] pr-3 overflow-hidden flex flex-row transform-gpu transition-all duration-300 border items-center gap-2 text-sm text-success-600`}
                >
                  <motion.div
                    className={`cursor-pointer ${
                      isDropdownOpen
                        ? "px-4 hover:bg-neutral-50 bg-neutral-100 border-neutral-700"
                        : "pl-3 pr-1 border-white"
                    } h-full border-r border-solid py-3.5 rounded-l-lg flex text-black items-center justify-center transition-all transform-gpu duration-300`}
                  >
                    <img
                      className="relative w-4 h-4 shrink-0 overflow-hidden"
                      alt=""
                      src="/search.svg"
                    />
                  </motion.div>
                  <motion.div
                    layout="position"
                    className="flex-grow flex items-center justify-between"
                  >
                    <input
                      ref={inputRef}
                      type="text"
                      value={inputValue}
                      onChange={handleInputChange}
                      onFocus={() => setIsDropdownOpen(true)}
                      className="outline-none bg-transparent w-full py-3 font-text-l-regular text-mini"
                      placeholder="Select or type your industry"
                    />
                    <motion.svg
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      animate={{ rotate: isDropdownOpen ? -180 : 0 }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                    >
                      <path
                        d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </motion.svg>
                  </motion.div>
                </div>
                <AnimatePresence>
                  {isDropdownOpen && (
                    <motion.div
                      className="box-border absolute right-0 mt-[1px] min-w-full width-auto top-full bg-white border border-solid border-neutral-700 rounded-md z-10 py-1 backdrop-blur-sm max-h-40 overflow-y-auto"
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      transition={{ duration: 0.2 }}
                    >
                      {filteredOptions.map((option) => (
                        <div
                          className={`pl-4 pr-8 py-2 cursor-pointer hover:bg-gray-100 ${
                            inputValue === option.label
                              ? "text-mediumseagreen-200 bg-green-50"
                              : ""
                          }`}
                          key={option.value}
                          onClick={() => handleOptionClick(option)}
                        >
                          {option.label}
                        </div>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
            {industryTouched && !industrySelection && (
              <div className="text-red-500 text-sm mt-2">
                This field is required
                {showError(industryTouched, industrySelection)}
              </div>
            )}
          </motion.div>
          <motion.div
            layout="position"
            initial={{ opacity: 0, translateY: 10 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: 10 }}
            transition={{
              duration: 0.5,
              delay: 0,
              ease: "easeOut",
            }}
            className="flex-1 flex flex-col items-start justify-start gap-[6px]"
          >
            <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
              Target or Current Role <span className="text-red-600">*</span>
            </div>
            <motion.div
              layout="position"
              className="self-stretch flex flex-col items-start justify-start gap-[6px]"
              initial={{ opacity: 0, translateY: 10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: 10 }}
              transition={{ duration: 0.5, delay: 0, ease: "easeOut" }}
            >
              <input
                className="font-text-l-regular text-mini focus:outline-none focus:ring focus:ring-green-50 hover:border-neutral-300 transition-all duration-150 focus:border-mediumseagreen-200 bg-base-white self-stretch hover:bg-neutral-50 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700"
                type="text"
                placeholder="Target or Current Role"
                required
                value={targetRole}
                onBlur={() => setTargetRoleTouched(true)}
                onChange={(e) => setTargetRole(e.target.value)}
              />
              {targetRoleTouched && !targetRole && (
                <div className="text-red-500 text-sm">
                  This field is required
                  {showError(targetRoleTouched, targetRole)}
                </div>
              )}
            </motion.div>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, translateY: 10 }}
          animate={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0, translateY: 10 }}
          transition={{
            duration: 0.5,
            delay: 0,
            ease: "easeOut",
          }}
          layout
          className="self-stretch flex flex-col items-center justify-end"
        >
          <button
            onClick={handleSubmit}
            disabled={!isValidName || !isValidPhone || !address}
            className={`cursor-pointer py-6 px-[18px] ${
              isValidName && isValidPhone && address
                ? "bg-mediumseagreen-200"
                : "bg-gray-neutral-200"
            } self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center border-[1px] border-solid border-neutral-600`}
          >
            <div className="relative text-lg leading-[22px] font-semibold font-text-sm-semibold text-base-white text-left">
              Continue to Resume
            </div>
          </button>
        </motion.div>
      </div>
    </div>
  );
};

const Step3 = ({ nextStep }) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { userData, setUserData } = useUserData();

  const handleFileUpload = () => {
    setFileUploaded(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/resumes");
    setUserData({
      ...userData,
      onboarded: true,
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, translateY: 10 }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: 10 }}
      transition={{
        duration: 0.5,
        delay: 0,
        ease: "easeOut",
      }}
      layout
      className="justify-center items-center"
    >
      <div className="self-stretch flex flex-col items-start justify-start mb-12">
        <div className="relative tracking-[-0.01em] leading-[38px] font-semibold">
          Resume Upload.
        </div>
        <div className="self-stretch relative text-base tracking-[-0.1px] leading-[24px] text-gray-50">
          If you don't have a resume, we recommend exporting from LinkedIn.
          <span
            onClick={() => setShowModal(true)}
            className="text-mediumseagreen-200 cursor-pointer relative text-base tracking-[-0.1px] leading-[24px] pl-1"
          >
            How do I do that?
          </span>
        </div>
      </div>
      {showModal && (
        <AnimatePresence>
          <Linkedin handleClose={handleClose} />
        </AnimatePresence>
      )}
      <div className="self-stretch flex flex-col items-start justify-start mb-12">
        <FileUploadBase
          onFileUpload={handleFileUpload}
          moveForward={nextStep}
        />
      </div>
      <div className="self-stretch flex flex-col items-center justify-end">
        <button
          onClick={fileUploaded ? nextStep : null}
          className={`cursor-pointer py-6 px-[18px] ${
            fileUploaded ? "bg-mediumseagreen-200" : "bg-gray-neutral-200"
          } self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center border-[1px] border-solid border-neutral-600`}
        >
          <div className="relative text-lg leading-[22px] font-semibold font-text-sm-semibold text-base-white text-left">
            Continue to Extra Info
          </div>
        </button>
      </div>
    </motion.div>
  );
};

const Step4 = () => {
  const { userData, setUserData } = useUserData();
  const [keyInfo, setKeyInfo] = useState(
    userData.keyInfo ? (userData.keyInfo != "" ? userData.keyInfo : "") : ""
  );
  const [isTouched, setIsTouched] = useState(false);
  const [isValidKeyInfo, setIsValidKeyInfo] = useState(
    userData.keyInfo ? userData.keyInfo.length > 100 : false
  );

  const handleBlur = () => {
    setIsTouched(true);
  };

  const handleChange = (e) => {
    setKeyInfo(e.target.value);
    if (e.target.value.length >= 100) {
      setIsValidKeyInfo(true);
    } else {
      setIsValidKeyInfo(false);
    }
  };

  const handleSubmit = () => {
    setUserData({
      ...userData,
      keyInfo: keyInfo != "" ? keyInfo : userData.keyInfo,
      onboarded: true,
    });
  };

  return (
    // Your Step 1 content here
    <motion.div
      initial={{ opacity: 0, translateY: 10 }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: 10 }}
      transition={{
        duration: 0.5,
        delay: 0,
        ease: "easeOut",
      }}
      layout
      className="justify-center items-center"
    >
      <div className="self-stretch flex flex-col items-start justify-start mb-12">
        <div className="relative tracking-[-0.01em] leading-[38px] font-semibold">
          Additional Information.
        </div>
        <div className="self-stretch relative text-base tracking-[-0.1px] leading-[24px] text-gray-50">
          This will be used to specialize the model to answer specifically for
          you, and increase accuracy. Don't worry, you can edit this later in
          profile.
        </div>
      </div>
      <div className="self-stretch h-[187px] flex flex-col items-start justify-start mb-12">
        <textarea
          className="bg-base-white focus:outline-none focus:ring focus:ring-green-50 focus:border-mediumseagreen-200 font-text-l-regular text-mini self-stretch flex-1 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] flex flex-row py-3 px-4 items-start justify-start border-[1px] border-solid border-neutral-700 resize-none"
          placeholder={`The more information you add, the better the responses. Include things like education, work history, or anything else you feel would benefit your applications.`}
          style={{ resize: "none" }}
          value={keyInfo}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {!isValidKeyInfo && isTouched && (
          <div className="text-red-500 text-mini pt-4">
            We need some more info! This should be atleast 100 characters.
          </div>
        )}
      </div>
      <div className="self-stretch flex flex-col items-center justify-end">
        <button
          disabled={!isValidKeyInfo}
          onClick={handleSubmit}
          className={`cursor-pointer py-6 px-[18px] ${
            isValidKeyInfo ? "bg-mediumseagreen-200" : "bg-gray-neutral-200"
          } self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center border-[1px] border-solid border-neutral-600`}
        >
          <div className="relative text-lg leading-[22px] font-semibold font-text-sm-semibold text-base-white text-left">
            Finish Profile
          </div>
        </button>
      </div>
    </motion.div>
  );
};

const ProgressBar = React.memo(({ step }) => {
  //console.log("progress");
  //console.log(step);
  const progress = (step / 3) * 100;
  const previousProgress = ((step - 1) / 3) * 100;

  return (
    <div className="self-stretch overflow-hidden rounded-md bg-gray-neutral-200">
      <motion.div
        key={step} // Here we add key prop based on the step
        initial={{ width: 0 }}
        animate={{ width: `${progress}%` }}
        transition={{
          from: `${previousProgress}%`,
          duration: 0.8,
          ease: "easeInOut",
        }}
        className="h-2 bg-mediumseagreen-200 rounded-md"
      />
    </div>
  );
});

const Onboarding = () => {
  const [step, setStep] = useState(0);
  const { userData, setUserData } = useUserData();
  let usermavenOpts = {
    key: "UMWZUKUs0g",
    tracking_host: "https://events.usermaven.com",
  };
  const { usermaven } = useUsermaven(usermavenOpts);
  const nextStep = () => {
    if (step == 0) {
      usermaven.track(`Completed onboarding step: ${step}`, {
        name: userData?.name,
        email: userData?.email,
        address: userData?.address,
        salary: userData?.keywords?.salary,
        seniority: userData?.keywords?.seniority,
        title: userData?.keywords?.title,
      });
    } else {
      usermaven.track(`Completed onboarding step: ${step}`, {
        name: userData?.name,
        email: userData?.email,
        address: userData?.address,
        salary: userData?.keywords?.salary,
        seniority: userData?.keywords?.seniority,
        title: userData?.keywords?.title,
      });
    }

    setStep(step + 1);
  };

  const variants = {
    hidden: { opacity: 0, y: 20 },
    beast: { opacity: 0.1, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const Header = () => {
    const handleClick = () => {
      if (step == 0) {
        usermaven.track(`Skipped Onboarding at Step: ${step}`, {
          name: userData?.name,
          email: userData?.email,
          address: userData?.address,
          salary: userData?.keywords?.salary,
          seniority: userData?.keywords?.seniority,
          title: userData?.keywords?.title,
        });
      } else {
        usermaven.track(`Skipped Onboarding at Step: ${step}`, {
          name: userData?.name,
          email: userData?.email,
          address: userData?.address,
          salary: userData?.keywords?.salary,
          seniority: userData?.keywords?.seniority,
          title: userData?.keywords?.title,
        });
      }
      setUserData({ ...userData });
    };

    return (
      <div className="self-stretch flex flex-row items-center justify-start">
        <div className="w-[143px] h-[34px] flex flex-col items-center justify-center">
          <img
            className="self-stretch relative max-w-full overflow-hidden h-[33px] shrink-0"
            alt=""
            src="/logo-text-regular.svg"
          />
        </div>
      </div>
    );
  };

  const StepContent = () => {
    switch (step) {
      case 0:
        return <Step1 nextStep={nextStep} />;
      case 1:
        return <Step2 nextStep={nextStep} />;
      case 2:
        return <Step3 nextStep={nextStep} />;
      case 3:
        return <Step4 />;
      default:
        return null;
    }
  };

  return (
    <div className="fixed z-40 inset-0 overflow-y-auto bg-white w-full h-screen">
      <div>
        <div className="items-start">
          <div>
            <div className="relative bg-base-white w-full h-screen flex flex-row items-start justify-start text-left text-9xl text-gray-700 font-text-sm-semibold">
              <div className="self-stretch flex-1 flex flex-row p-8 items-start justify-start gap-[48px]">
                <div className="self-stretch flex-1 flex flex-col items-start justify-between max-w-1/2">
                  <Header></Header>

                  {StepContent()}
                  <ProgressBar step={step} />
                </div>
                <div className="self-stretch rounded-8xs w-3/5 [background:linear-gradient(180deg,_#A2F4C1,_#e5ffee_97.92%)] w-stretch overflow-hidden shrink-0 flex flex-col items-center justify-center">
                  <JobsPreview
                    step={step}
                    searchTerm={userData.role?.title || ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
