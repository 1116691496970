import React, { useState, useRef, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TrackerDropdown from "../components/TrackerDropdown";
import { AnimatePresence, motion } from "framer-motion";
import { useJobs } from "../jobsContext";
import { useUserData } from "../UserDataContext";
import { useNavigate } from "react-router-dom";
import Intercom from "@intercom/messenger-js-sdk";
import {
  getFirestore,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  deleteDoc,
  collection,
  setDoc,
} from "firebase/firestore";
import { useIdentifyUser } from "../hooks/useIdentifyUser";
import { db, auth } from "../firebase";

const savedIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0546 1.25C13.8939 1.24999 15.348 1.24997 16.4854 1.39731C17.6529 1.54853 18.5995 1.86672 19.3488 2.58863C20.102 3.31431 20.4376 4.23743 20.5964 5.37525C20.75 6.4765 20.75 7.88207 20.75 9.64945V18.0458C20.75 19.1434 20.7501 20.0553 20.6447 20.7405C20.5356 21.4495 20.2814 22.1395 19.5804 22.5139C18.9553 22.8477 18.2676 22.7798 17.6846 22.6028C17.0949 22.4238 16.4955 22.0989 15.9467 21.7521C15.3926 21.402 14.7938 20.9618 14.3215 20.6127C13.8587 20.2706 13.4869 19.9958 13.2031 19.8249C12.8035 19.5843 12.5513 19.4335 12.3467 19.3371C12.158 19.2482 12.0663 19.234 12 19.234C11.9337 19.234 11.842 19.2482 11.6533 19.3371C11.4488 19.4335 11.1965 19.5843 10.7969 19.8249C10.5131 19.9958 10.1414 20.2706 9.67854 20.6127C9.2062 20.9618 8.60739 21.402 8.05335 21.7521C7.50454 22.0989 6.90514 22.4238 6.31544 22.6028C5.7324 22.7798 5.04476 22.8477 4.41958 22.5139C3.71857 22.1395 3.46438 21.4495 3.35533 20.7405C3.24993 20.0553 3.24996 19.1434 3.25 18.0459V9.64943C3.24999 7.88206 3.24997 6.4765 3.40365 5.37525C3.56243 4.23743 3.898 3.31431 4.65121 2.58863C5.40051 1.86672 6.34712 1.54853 7.51457 1.39731C8.652 1.24997 10.1061 1.24999 11.9454 1.25H12.0546Z"
      fill="#5BC68D"
    />
  </svg>
);
const appliedIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.35579 7.52874C4.32437 7.54804 4.15774 7.67722 3.96159 7.83129C3.97964 7.84416 3.99912 7.85767 4.02014 7.87183C4.36522 8.10424 4.90633 8.36743 5.73062 8.76513L8.65182 10.1745C10.5195 11.0756 11.2418 11.4048 12 11.4048C12.7582 11.4048 13.4806 11.0756 15.3482 10.1746L18.2694 8.76513C19.0937 8.36743 19.6348 8.10424 19.9799 7.87183C20.0009 7.85767 20.0204 7.84416 20.0384 7.83129C19.8423 7.67722 19.6756 7.54804 19.6442 7.52874C19.3259 7.33316 18.8178 7.10705 18.0067 6.7501L16.4948 6.0847C16.0098 5.87127 15.8063 5.33962 16.0403 4.89723C16.2743 4.45485 16.8572 4.26925 17.3422 4.48269L18.9202 5.17716C19.645 5.49607 20.2839 5.77723 20.7335 6.05341C21.1829 6.3295 21.75 6.78375 21.75 7.52563C21.75 7.55007 21.7489 7.57428 21.7468 7.59821C21.7489 7.62118 21.75 7.64445 21.75 7.66796V17.3011C21.75 18.4205 20.9311 19.1639 19.9258 19.7654C18.9075 20.3747 17.1986 21.0945 15.3055 21.8918C13.8861 22.4902 12.9716 22.8756 12 22.8756C11.0284 22.8756 10.1139 22.4902 8.69452 21.8918C6.80139 21.0945 5.0925 20.3747 4.07416 19.7654C3.06888 19.1639 2.25 18.4205 2.25 17.3011V7.66796C2.25 7.64445 2.25108 7.62118 2.2532 7.59821C2.25108 7.57428 2.25 7.55007 2.25 7.52563C2.25 6.78375 2.81708 6.3295 3.2665 6.05341C3.71606 5.77723 4.35503 5.49608 5.07981 5.17718L6.70132 4.46356C7.1863 4.25012 7.76914 4.43571 8.00313 4.8781C8.23713 5.32048 8.03366 5.85213 7.54868 6.06557L5.99328 6.7501C5.18221 7.10705 4.67415 7.33316 4.35579 7.52874ZM6.33103 12.6044C5.9593 12.4217 5.50982 12.5749 5.32709 12.9467C5.14436 13.3184 5.29758 13.7679 5.66931 13.9506L7.66931 14.9337C8.04104 15.1165 8.49051 14.9632 8.67324 14.5915C8.85597 14.2198 8.70276 13.7703 8.33103 13.5876L6.33103 12.6044Z"
      fill="#94E5FF"
    />
    <path
      d="M14.7606 3.47578C15.1192 3.89585 15.0693 4.52705 14.6492 4.8856C14.4626 5.04492 14.2343 5.1236 14.0067 5.125H13V9.125C13 9.67728 12.5523 10.125 12 10.125C11.4477 10.125 11 9.67728 11 9.125V5.125H9.99336C9.76579 5.1236 9.53745 5.04492 9.3508 4.8856C8.93073 4.52705 8.88086 3.89585 9.23941 3.47578L10.2394 2.30421L10.2721 2.26585C10.4788 2.02354 10.6945 1.77073 10.9005 1.58655C11.1317 1.37989 11.4948 1.125 12 1.125C12.5052 1.125 12.8683 1.37989 13.0995 1.58655C13.3056 1.77073 13.5212 2.02353 13.7279 2.26585L13.7606 2.30421L14.7606 3.47578Z"
      fill="#94E5FF"
    />
  </svg>
);
const interviewingIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1493 2.47427C13.3316 2.17945 13.6534 2 14 2C17.8694 2 21 5.13057 21 9C21 9.55229 20.5523 10 20 10C19.4477 10 19 9.55229 19 9C19 7.0795 17.9216 5.41457 16.3363 4.57679L15.5339 5.37922C15.3819 5.53122 15.2087 5.67508 14.9958 5.7049C14.5822 5.76285 14.1598 5.55572 13.9627 5.1615L13.1056 3.44721C12.9506 3.13723 12.9671 2.76909 13.1493 2.47427Z"
      fill="#9694FF"
    />
    <path
      d="M4 14C4.55228 14 5 14.4477 5 15C5 16.9205 6.07843 18.5854 7.66372 19.4232L8.46615 18.6208C8.61815 18.4688 8.79131 18.3249 9.00419 18.2951C9.41782 18.2372 9.84018 18.4443 10.0373 18.8385L10.8944 20.5528C11.0494 20.8628 11.0329 21.2309 10.8507 21.5257C10.6684 21.8205 10.3466 22 10 22C6.13057 22 3 18.8694 3 15C3 14.4477 3.44772 14 4 14Z"
      fill="#9694FF"
    />
    <path
      d="M14.432 18.4936C16.3136 17.5021 18.687 17.5021 20.5685 18.4936C20.6157 18.5184 20.6779 18.5494 20.7512 18.5858C21.1014 18.7601 21.7041 19.0599 22.1152 19.416C22.3671 19.6342 22.6818 19.982 22.7408 20.4594C22.8069 20.994 22.5302 21.4406 22.135 21.7738C21.5085 22.302 20.7255 22.75 19.7049 22.75H15.2957C14.275 22.75 13.492 22.302 12.8656 21.7738C12.4704 21.4406 12.1937 20.994 12.2598 20.4594C12.3187 19.982 12.6335 19.6342 12.8854 19.416C13.2965 19.0599 13.8991 18.7601 14.2494 18.5858C14.3227 18.5494 14.3849 18.5184 14.432 18.4936Z"
      fill="#9694FF"
    />
    <path
      d="M14.5 14.25C14.5 12.6002 15.8361 11.25 17.5 11.25C19.1639 11.25 20.5 12.6002 20.5 14.25C20.5 15.8998 19.1639 17.25 17.5 17.25C15.8361 17.25 14.5 15.8998 14.5 14.25Z"
      fill="#9694FF"
    />
    <path
      d="M3.43106 8.4936C5.31262 7.50213 7.686 7.50213 9.56755 8.4936C9.61467 8.51843 9.67691 8.54939 9.75019 8.58585C10.1005 8.7601 10.7031 9.05991 11.1142 9.416C11.3661 9.63423 11.6809 9.98195 11.7398 10.4594C11.8059 10.994 11.5292 11.4406 11.134 11.7738C10.5076 12.302 9.72456 12.75 8.70387 12.75H4.29474C3.27406 12.75 2.49106 12.302 1.86463 11.7738C1.46939 11.4406 1.19274 10.994 1.25878 10.4594C1.31775 9.98195 1.63248 9.63423 1.88441 9.416C2.2955 9.05991 2.89816 8.7601 3.24843 8.58585C3.32171 8.54939 3.38394 8.51843 3.43106 8.4936Z"
      fill="#9694FF"
    />
    <path
      d="M3.49902 4.25C3.49902 2.60022 4.83508 1.25 6.49902 1.25C8.16297 1.25 9.49902 2.60022 9.49902 4.25C9.49902 5.89978 8.16297 7.25 6.49902 7.25C4.83508 7.25 3.49902 5.89978 3.49902 4.25Z"
      fill="#9694FF"
    />
  </svg>
);
const awaitingIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V9.70802C10.117 10.0938 9.5 10.9748 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5C12.3224 14.5 12.6306 14.439 12.9136 14.3278L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L14.3278 12.9136C14.439 12.6306 14.5 12.3224 14.5 12C14.5 10.9748 13.883 10.0938 13 9.70802V7Z"
      fill="#F58686"
    />
  </svg>
);

const JobTracker = () => {
  useIdentifyUser();
  const [inputValue, setInputValue] = useState("");
  const [isInputFocused, setInputFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const inputRef = useRef(null);
  const { userData, setUserData, isAuthenticated, user } = useUserData();
  const [isDragging, setIsDragging] = useState(false);
  const { setBannerMessage, setBannerType } = useJobs();
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const hashCode = (s) =>
    s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

  {
    /*Intercom({
    app_id: "qgss7lxp",
    user_id: user.uid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: userData.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: userData.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: "1704067200", // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });*/
  }

  useEffect(() => {
    const fetchJobs = async () => {
      const jobPromises = userData.likes.map((guid) =>
        getDoc(doc(db, "Jobs", hashCode(guid).toString()))
      );
      const jobSnapshots = await Promise.all(jobPromises);
      const fetchedJobs = jobSnapshots.map((snapshot) => {
        const jobId = snapshot.id;
        let status = "saved"; // Default status

        // Check if the job is already being tracked in a specific status
        for (const [key, value] of Object.entries(userData.tracker || {})) {
          if (value.includes(jobId)) {
            status = key;
            break;
          }
        }

        return {
          id: jobId,
          ...snapshot.data(),
          status,
        };
      });
      setJobs(fetchedJobs);
      console.log(fetchedJobs);
    };

    if (userData.likes && userData.likes.length > 0) {
      fetchJobs();
    }
  }, [userData, isAuthenticated]);

  const dropdownOptions = [
    [
      { value: "All Levels", label: "All Levels" },
      { value: "Entry-level", label: "Entry-level" },
      { value: "Mid-level", label: "Mid-level" },
      { value: "Senior", label: "Senior" },
      { value: "Manager", label: "Manager" },
      { value: "Director", label: "Director" },
      { value: "Executive", label: "Executive" },
    ],
    [
      { value: "All Salaries", label: "All Salaries" },
      { value: "$25,000+", label: "$25,000+" },
      { value: "$50,000+", label: "$50,000+" },
      { value: "$75,000+", label: "$75,000+" },
      { value: "$100,000+", label: "$100,000+" },
      { value: "$200,000+", label: "$200,000+" },
    ],
    [
      { value: "All Locations", label: "All Locations" },
      { value: "USA Only", label: "USA Only" },
      { value: "International Only", label: "International Only" },
    ],
  ];

  const getSkillColors = (type) => {
    switch (type ? type : "") {
      case "engineering":
        return { textColor: "#16a34a", bgColor: "bg-green-50" };
      case "sales":
        return { textColor: "#2563eb", bgColor: "bg-blue-50" };
      case "marketing":
        return { textColor: "#9333ea", bgColor: "bg-purple-50" };
      case "product":
        return { textColor: "#db2777", bgColor: "bg-pink-50" };
      case "design":
        return { textColor: "#dc2626", bgColor: "bg-red-50" };
      case "support":
        return { textColor: "#ea580c", bgColor: "bg-orange-50" };
      case "operations":
        return { textColor: "#4f46e5", bgColor: "bg-indigo-50" };
      case "data":
        return { textColor: "#ca8a04", bgColor: "bg-yellow-50" };
      case "finance":
        return { textColor: "#0d9488", bgColor: "bg-teal-50" };
      case "management":
        return { textColor: "#4b5563", bgColor: "bg-gray-100" };
      default:
        return { textColor: "#808080", bgColor: "#F0F0F0" };
    }
  };

  const getTypeColors = (type) => {
    switch (type ? type : "") {
      case "Full Time":
        return { textColor: "#4C6EAF", bgColor: "#E6EEF6" };
      case "Part Time":
        return { textColor: "#6BA292", bgColor: "#E6F4F1" };
      case "Contractor":
        return { textColor: "#EEBB39", bgColor: "#FFF6E6" };
      case "Temporary":
        return { textColor: "#A267AC", bgColor: "#F4E6F8" };
      case "Intern":
        return { textColor: "#D2691E", bgColor: "#F4EAE6" };
      case "Volunteer":
        return { textColor: "#C8385A", bgColor: "#FCE6EF" };
      case "Other":
        return { textColor: "#8B4513", bgColor: "#FFF5E6" };
      default:
        return { textColor: "#808080", bgColor: "#F0F0F0" };
    }
  };

  const statuses = ["saved", "applied", "interviewing", "awaiting"];

  const statusIcons = {
    saved: savedIcon,
    applied: appliedIcon,
    interviewing: interviewingIcon,
    awaiting: awaitingIcon,
  };

  const statusColors = {
    saved: "#5CD193",
    applied: "#94E5FF",
    interviewing: "#9694FF",
    awaiting: "#F58686",
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const textVariants = {
    hidden: { opacity: 1, y: "0px" },
    visible: { opacity: 0, y: "10px", transition: { duration: 0.1 } },
    visibleWithDelay: {
      opacity: 1,
      y: "0px",
      transition: { duration: 0.1, delay: 0.1 },
    },
  };

  const optionsVariants = {
    hidden: { opacity: 0, y: "-10px" },
    visible: { opacity: 1, y: "0px", transition: { duration: 0.1 } },
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    setIsDragging(false);
    console.log("done");
    if (!destination) return;

    setBannerMessage("Job status has been updated.");
    setBannerType("status");

    let oldStatus;
    let removedJob;

    // Find the removed job and its old status
    const newJobs = jobs.map((job) => {
      if (job.id === result.draggableId) {
        removedJob = { ...job };
        oldStatus = job.status;
        return { ...job, status: destination.droppableId };
      }
      return job;
    });

    setJobs(newJobs);

    // Update Firebase
    try {
      const updatedTracker = { ...userData.tracker };
      // Remove job from old status if it exists
      if (updatedTracker[oldStatus]) {
        updatedTracker[oldStatus] = updatedTracker[oldStatus].filter(
          (id) => id !== removedJob.id
        );
      }
      // Add job to new status
      updatedTracker[destination.droppableId] = updatedTracker[
        destination.droppableId
      ]
        ? [...updatedTracker[destination.droppableId], removedJob.id]
        : [removedJob.id];

      await setUserData({ ...userData, tracker: updatedTracker });

      // Update job indices after Firebase update
      const updatedJobs = newJobs.map((job, index) => ({ ...job, index }));
      setJobs(updatedJobs);
    } catch (error) {
      console.error("Failed to update Firebase:", error);
      // Revert the local state if the update fails
      setJobs(
        jobs.map((job) =>
          job.id === removedJob.id ? { ...job, status: oldStatus } : job
        )
      );
    }
  };

  const filteredJobs = inputValue
    ? jobs.filter((job) =>
        job.title
          ? job.title.toLowerCase().includes(inputValue.toLowerCase())
          : ""
      )
    : jobs;

  console.log("filteredJobs", filteredJobs);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="relative bg-neutral-50 w-full min-h-screen flex flex-col overflow-hidden text-left text-9xl text-gray-700 font-text-l-regular">
        <div className="px-8 pt-8 flex-none">
          <div className="tracking-[-0.01em] leading-[38px] font-semibold mb-2">
            Job Tracker
          </div>
          <div className="text-base tracking-[-0.1px] leading-[24px] text-gray-50">
            Here you can track, update, and manage the status of jobs.
          </div>
        </div>
        <div
          className={`flex ${
            userData.tracker ? "flex-grow" : ""
          } overflow-auto px-6`}
        >
          {statuses.map((status, index) => (
            <Droppable key={status} droppableId={status}>
              {(provided) => (
                <motion.div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`flex flex-col w-1/4 px-2 ${
                    userData.tracker ? "h-full" : ""
                  } `}
                >
                  {index === 3 && (
                    <div className="flex flex-col mb-4">
                      <div
                        onClick={() => {
                          inputRef.current && inputRef.current.focus();
                        }}
                        className={`cursor-pointer ${
                          isInputFocused
                            ? "border-mediumseagreen-200"
                            : "border-neutral-700"
                        } rounded-8xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] pl-3 overflow-hidden flex flex-row transform-gpu transition-all duration-300 ${
                          !isInputFocused ? "hover:border-neutral-300" : ""
                        } items-center justify-start gap-2 text-sm text-success-600 border-[1px] border-solid`}
                      >
                        <AnimatePresence>
                          {!isInputFocused && (
                            <motion.img
                              initial={{ opacity: 0, translateX: -10 }}
                              animate={{ opacity: 1, translateX: 0 }}
                              exit={{ opacity: 0, translateX: -10 }}
                              transition={{ duration: 0.2 }}
                              className="relative w-4 h-4 shrink-0 overflow-hidden"
                              alt=""
                              src="/search.svg"
                            />
                          )}
                        </AnimatePresence>
                        <div className="w-full">
                          <input
                            ref={inputRef}
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            onFocus={() => setInputFocused(true)}
                            onBlur={() => setInputFocused(false)}
                            className="outline-none bg-transparent flex-grow py-3"
                            placeholder="Search through jobs..."
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {/*index === 1 && (
                    <TrackerDropdown
                      titleText={`Dropdown`}
                      options={dropdownOptions[0]}
                      onChange={{}}
                      sort={false}
                    ></TrackerDropdown>
                  )*/}
                  {index === 1 && <div className="h-[58px]" />}
                  {index === 2 && <div className="h-[58px]" />}
                  {index === 0 && <div className="h-[58px]" />}
                  <motion.div
                    className="flex flex-col flex-grow overflow-auto"
                    initial={{ opacity: 0, translateX: -10 }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: -10 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="mb-4">
                      <div className="bg-base-white rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border shrink-0 overflow-hidden border-[1px] border-solid border-neutral-700">
                        <div
                          className={`h-[4px] w-full rounded-t-md}`}
                          style={{ backgroundColor: statusColors[status] }}
                        />
                        <div
                          className="text-sm font-medium tracking-[-0.1px] leading-[24px] text-light-100 flex flex-row px-4 py-2 items-center justify-between"
                          style={{ height: "calc(100% - 4px)" }}
                        >
                          <div>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                            <span className="ml-2 text-smi bg-gray-100 text-gray-50 py-1 px-2 rounded">
                              {
                                filteredJobs.filter(
                                  (job) => job.status === status
                                ).length
                              }
                            </span>
                          </div>
                          <span className="flex items-center">
                            {statusIcons[status]}
                          </span>
                        </div>
                      </div>
                    </div>
                    {isDragging &&
                      filteredJobs.filter((job) => job.status === status)
                        .length == 0 && (
                        <motion.div
                          className={`flex flex-col items-center justify-center w-full h-[240px] rounded-md mb-2 border-[1px] border-dashed box-border hover:border-solid border-neutral-700 hover:bg-green-50 hover:border-mediumseagreen-200 hover:text-mediumseagreen-200 transition-all duration-300 ease-in-out`}
                          initial={{ height: "190px", opacity: 0 }}
                          animate={{ height: "190px", opacity: 1 }}
                          exit={{ height: "190px", opacity: 0 }}
                          transition={{
                            duration: 0.3,
                            ease: [0.42, 0, 0.58, 1],
                          }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <motion.div
                            className={`flex flex-col items-center gap-3 w-52 absolute`}
                            variants={optionsVariants}
                            initial="hidden"
                            animate={isHovered ? "visible" : "hidden"}
                          ></motion.div>
                        </motion.div>
                      )}
                    <AnimatePresence>
                      {filteredJobs.filter((job) => job.status === status)
                        .length > 0 ? (
                        filteredJobs
                          .filter((job) => job.status === status)
                          .map((job, index) => (
                            <Draggable
                              key={job.id}
                              draggableId={job.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                if (snapshot.isDragging && !isDragging) {
                                  console.log("dragging");
                                  setIsDragging(snapshot.isDragging);
                                }
                                return (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`mb-4 last:mb-0 bg-base-white rounded-md transition-all duration-300 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] border-[1px] border-solid border-neutral-700 hover:border-neutral-300 flex flex-col overflow-hidden hover:scale-[0.99] ${
                                      snapshot.isDragging ? "bg-opacity-75" : ""
                                    }`}
                                    style={{
                                      ...provided.draggableProps.style,
                                      boxShadow: snapshot.isDragging
                                        ? "0 0 0.5rem rgba(0, 0, 0, 0.2)"
                                        : "none",
                                    }}
                                    onDragStart={() => {
                                      console.log(
                                        `Starting to drag job: ${job.id}`
                                      );
                                    }}
                                    onDragEnd={() => {
                                      console.log(
                                        `Finished dragging job: ${job.id}`
                                      );
                                    }}
                                  >
                                    <div
                                      className={`h-[4px] w-full rounded-t-md}`}
                                      style={{
                                        backgroundColor: statusColors[status],
                                      }}
                                    />
                                    <div className="flex justify-between items-center px-4">
                                      <h3 className="text-lg font-semibold">
                                        {job.title}
                                      </h3>
                                    </div>
                                    <div className="px-4 pb-2 text-sm">
                                      {job.companyName && (
                                        <span>{job.companyName}</span>
                                      )}
                                      {job.companyName &&
                                        job.seniority &&
                                        job.seniority[0] && <span> • </span>}
                                      {job.seniority && job.seniority[0] && (
                                        <span>{job.seniority[0]}</span>
                                      )}
                                      {job.maxSalary && (
                                        <span>
                                          {" "}
                                          • ${job.maxSalary.toLocaleString()}
                                        </span>
                                      )}
                                    </div>
                                    <div className="px-4 pb-4 flex gap-2">
                                      {job.skills &&
                                        job.skills.length > 0 &&
                                        job.categories &&
                                        job.categories.length > 0 && (
                                          <div
                                            className={`rounded-8xs ${
                                              getSkillColors(job.categories[0])
                                                .bgColor
                                            } flex text-sm flex-row py-0.5 px-2 items-center justify-center gap-[6px]`}
                                            style={{
                                              color: getSkillColors(
                                                job.categories[0]
                                              ).textColor,
                                            }}
                                          >
                                            {job.skills[0]}
                                          </div>
                                        )}

                                      {job.type && (
                                        <div
                                          className="rounded-8xs flex flex-row py-0.5 px-2 text-smi items-center justify-center gap-[6px]"
                                          style={{
                                            backgroundColor: getTypeColors(
                                              job.type
                                            ).bgColor,
                                            color: getTypeColors(job.type)
                                              .textColor,
                                          }}
                                        >
                                          {job.type}
                                        </div>
                                      )}
                                    </div>
                                    <div className="px-4 pb-4">
                                      <a
                                        href={`/jobs?job=${hashCode(
                                          job.guid ? job.guid : "null"
                                        ).toString()}`}
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: "none" }}
                                      >
                                        <button className="w-full bg-white border-[1px] border-solid border-neutral-700 hover:bg-neutral-50 transition-all duration-300 transform-gpu text-gray-7001 font-semibold py-3 px-4 rounded cursor-pointer">
                                          View Job
                                        </button>
                                      </a>
                                    </div>
                                  </motion.div>
                                );
                              }}
                            </Draggable>
                          ))
                      ) : (
                        <div
                          className={`${
                            isAuthenticated ? " h-[200px]" : "h-[100px]"
                          } w-full`}
                        />
                      )}
                    </AnimatePresence>
                    {isAuthenticated && <div className="h-[200px] w-full" />}
                  </motion.div>
                </motion.div>
              )}
            </Droppable>
          ))}
        </div>
        {(!userData.tracker && isAuthenticated) ||
          (!isAuthenticated && (
            <div className="w-full flex flex-col items-center justify-center">
              <div
                className="h-80 w-full bg-center bg-contain"
                style={{
                  backgroundImage: "url('/emptytracker.png')",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <button
                className="bg-mediumseagreen-200 text-white text-base font-bold py-3 px-16 rounded-md hover:bg-green-400 transition-colors duration-300"
                onClick={() => navigate("/jobs")}
              >
                Explore jobs
              </button>
            </div>
          ))}
      </div>
    </DragDropContext>
  );
};

export default JobTracker;
