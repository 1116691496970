import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { auth } from "../firebase";
import { useUserData } from "../UserDataContext";
import { useJobs } from "../jobsContext";
import { CopyToClipboard } from "react-copy-to-clipboard";

const AnalyticsJobContainer = ({
  job,
  onSelect,
  position,
  pubDate,
  company,
  error,
  location,
  tags,
  id,
  categories,
  applied,
  maxSalary,
  appliedStatus,
  appliedData,
  type,
  seniority,
  skills,
  ats,
}) => {
  const { user, userData, setUserData, currentApi, isAuthenticated } =
    useUserData();
  const { selectedJob = {}, setBannerMessage, setBannerType } = useJobs();
  const [isShown, setIsShown] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [get, setGet] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequest, setIsRequest] = useState(true);


  const processFormInfo = (formInfo) => {
    return formInfo.map((item) => {
      const processedItem = {
        id: item.selector.replace(/[^a-zA-Z0-9]/g, "_"), // Remove special characters
        type: item.type || item.tag, // Use 'type' if available, otherwise 'tag'
        question: item.label,
        required: item.required,
        options: item.options
          ? item.options.map((option) => ({
              label: option.label,
              value: option.value,
            }))
          : undefined,
      };
      return processedItem;
    });
  };

  const CodeSnippetButton = ({ content }) => {
    const [copied, setCopied] = useState(false);

    // Check if content is an object or array, then format it
    const isObject = (val) =>
      typeof val === "object" && val !== null && !Array.isArray(val);

    const formattedCode =
      isObject(content) || Array.isArray(content)
        ? JSON.stringify(content, null, 2)
        : content;

    const codeLines = formattedCode.split("\n");

    const copyCodeToClipboard = () => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    };

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="relative group w-full"
      >
        <CopyToClipboard text={formattedCode} onCopy={copyCodeToClipboard}>
          <button
            className="absolute right-2 top-2 text-gray-400 opacity-0 group-hover:opacity-100 focus:opacity-100 transition"
            type="button"
          >
            {copied ? "Copied!" : "Copy"}
          </button>
        </CopyToClipboard>
        <div className="font-text-l-regular transform-gpu transition-all duration-300 text-sm bg-base-white rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border w-full overflow-auto py-2 px-4 border-[1px] border-solid border-neutral-700">
          {codeLines.map((line, index) => (
            <div key={index} className="flex my-1">
              <span className="text-gray-400 select-none">
                {`${index + 1}`.padStart(2, " ")}
              </span>
              <span className="ml-4 font-mono whitespace-pre text-demo">
                {line}
              </span>
            </div>
          ))}
        </div>
      </motion.div>
    );
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const breezy = "";

  const timeSince = (date) => {
    const now = new Date();
    const publishedDate = new Date(date);
    const seconds = Math.floor((now - publishedDate) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };

  const timeSinceApplied = (date) => {
    const timestampMilliseconds = date * 1000;
    const jobdate = new Date(timestampMilliseconds);

    const now = new Date();
    const seconds = Math.floor((now - jobdate) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };

  const handleDislike = async () => {
    if (!isShown) {
      setIsShown(true);
    } else {
      setIsShown(false);
    }
  };

  return (
    <AnimatePresence>
      <motion.div layout="position">
        <motion.div
          layout="position"
          onClick={onSelect}
          className={`self-stretch rounded-3xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-col pt-3 items-start justify-center text-left text-lg text-gray-700 font-text-l-regular border-[1px] border-solid cursor-pointer transform-gpu transition-all duration-300 space-y-3 ${
            id
              ? selectedJob && selectedJob.id != null
                ? selectedJob.id === id
                  ? "border-demo"
                  : "hover:border-neutral-300 border-neutral-700"
                : "hover:border-neutral-300 border-neutral-700"
              : "hover:border-neutral-300 border-neutral-700"
          } hover:scale-[0.99]`}
        >
          <AnimatePresence>
            <motion.div
              layout="position"
              className="self-stretch flex flex-col py-0 px-5 items-start justify-start"
            >
              <AnimatePresence>
                <motion.div
                  layout="position"
                  className="self-stretch flex flex-col items-start justify-start gap-[6px]"
                >
                  <div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
                    <div
                      className={`flex-1 relative tracking-[-0.1px] leading-[28px] font-semibold`}
                    >
                      {position}
                    </div>
                    <div
                      className={`relative text-sm tracking-[-0.1px] leading-[28px] font-semibold text-gray-50`}
                    >
                      {appliedStatus && appliedData && appliedData.createdAt
                        ? timeSinceApplied(appliedData.createdAt.seconds)
                        : timeSince(pubDate)}
                    </div>
                  </div>
                  <AnimatePresence
                    layout="position"
                    initial={{ opacity: 1, height: "auto" }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0, overflow: "hidden" }}
                    transition={{ duration: 0.5 }}
                  >
                    <>
                      <div className="self-stretch flex flex-row items-center justify-start gap-[16px] text-sm text-gray-50">
                        <div className="flex flex-row items-center justify-start text-gray-700">
                          <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                            {company}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-[4px]">
                          <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                            {id}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-[4px]">
                          {maxSalary ? (
                            <img
                              className="relative w-[16px] h-[16px] shrink-0"
                              alt=""
                              src="/dollar.svg"
                            />
                          ) : null}
                          <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                            {maxSalary}
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch flex flex-row items-center justify-start gap-[16px] text-sm text-gray-50">
                        <div className="flex flex-row items-center justify-start gap-[4px]">
                            <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                              {error}
                            </div>
                        </div>
                      </div>
                      <div className="self-stretch flex flex-row items-center justify-between gap-[10px]">
                        <div className="flex flex-row items-center justify-start gap-[16px] text-smi">
                          {tags &&
                            tags
                              .filter(
                                (tag) =>
                                  tag !== undefined &&
                                  tag.text !== undefined &&
                                  tag.textColor !== undefined &&
                                  tag.bgColor !== undefined
                              )
                              .map((tag, index) => (
                                <div
                                  key={"guid" + index}
                                  className={`rounded-8xs ${tag.bgColor} flex flex-row py-0.5 px-2 items-center justify-center gap-[6px]`}
                                  style={{
                                    backgroundColor: tag.bgColor,
                                    color: tag.textColor,
                                  }}
                                >
                                  {tag.icon && (
                                    <span className="relative flex items-center justify-center w-[14px] h-[14px]">
                                      {tag.icon}
                                    </span>
                                  )}
                                  <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                                    {tag.text}
                                  </div>
                                </div>
                              ))}
                        </div>
                        <div className="flex flex-row items-center justify-start gap-[12px]">
                          {applied ? null : (
                            <motion.svg
                              width="20"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                              onClick={() => window.open(job.indeed, "_blank")}
                              className={`outline-none duration-300 transition-all relative w-[24px] h-[24px] shrink-0 ${
                                isLiked
                                  ? "hover:text-blue-500"
                                  : "text-gray-400 hover:text-blue-400"
                              }`}
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.33759 21.9805V13.2185C8.59289 13.2416 8.8385 13.2531 9.0956 13.2531C10.3181 13.2531 11.4695 12.9325 12.4462 12.3603V21.9785C12.4462 22.8004 12.2505 23.4072 11.8705 23.8123C11.4925 24.2156 10.9897 24.4172 10.3795 24.4172C9.77879 24.4172 9.3029 24.2157 8.9115 23.799C8.5334 23.3958 8.33759 22.7889 8.33759 21.9805ZM8.36059 0.983149C10.9053 0.0902488 13.803 0.138249 15.9792 1.97015C16.3842 2.33885 16.8465 2.80355 17.0307 3.35075C17.2514 4.04205 16.2612 3.27775 16.125 3.18375C15.415 2.73055 14.7068 2.35035 13.9123 2.08915C9.63299 0.804549 5.58379 3.12625 3.06609 6.73225C2.01449 8.32615 1.32939 10.0043 0.767095 11.8496C0.705695 12.0513 0.657695 12.3143 0.546395 12.4909C0.435095 12.6945 0.498395 11.9456 0.498395 11.9207C0.582895 11.1584 0.742195 10.421 0.939795 9.68375C2.10079 5.75475 4.66849 2.48285 8.36059 0.983149ZM13.2887 8.04185C13.2887 9.71045 11.9357 11.0642 10.2682 11.0642C8.6005 11.0642 7.24959 9.71045 7.24959 8.04185C7.24959 6.37315 8.6006 5.01945 10.2682 5.01945C11.9358 5.01945 13.2887 6.37125 13.2887 8.04185Z"
                                fill="currentColor"
                              />
                            </motion.svg>
                          )}
                          {applied ? null : (
                            <motion.svg
                              onClick={() =>
                                window.open(job.applyNowLink, "_blank")
                              }
                              className={`outline-none relative w-[24px] pr-1 h-[24px] shrink-0 ${"text-gray-400 hover:text-blue-400"}`}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              whileTap={{ scale: 1.3 }}
                            >
                              <path
                                d="M14.5563 13.2183C13.514 14.2606 11.8241 14.2606 10.7817 13.2183C9.73943 12.1759 9.73943 10.486 10.7817 9.44364L13.1409 7.0845C14.1357 6.08961 15.7206 6.04433 16.7692 6.94866M16.4437 3.78175C17.486 2.73942 19.1759 2.73942 20.2183 3.78175C21.2606 4.82408 21.2606 6.51403 20.2183 7.55636L17.8591 9.9155C16.8643 10.9104 15.2794 10.9557 14.2308 10.0513"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                              <path
                                d="M21 13C21 16.7712 21 18.6569 19.8284 19.8284C18.6569 21 16.7712 21 13 21H11C7.22876 21 5.34315 21 4.17157 19.8284C3 18.6569 3 16.7712 3 13V11C3 7.22876 3 5.34315 4.17157 4.17157C5.34315 3 7.22876 3 11 3"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                            </motion.svg>
                          )}
                          {applied ? null : (
                            <motion.svg
                              onClick={handleDislike}
                              className={`outline-none relative w-[24px] h-[24px] shrink-0 ${
                                isShown
                                  ? "text-demo"
                                  : "text-gray-400 hover:text-blue-400"
                              }`}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              whileTap={{ scale: 1.3 }}
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6 12C6 11.3096 6.55964 10.75 7.25 10.75H7.25897C7.94933 10.75 8.50897 11.3096 8.50897 12C8.50897 12.6904 7.94933 13.25 7.25897 13.25H7.25C6.55964 13.25 6 12.6904 6 12ZM10.7455 12C10.7455 11.3096 11.3052 10.75 11.9955 10.75H12.0045C12.6948 10.75 13.2545 11.3096 13.2545 12C13.2545 12.6904 12.6948 13.25 12.0045 13.25H11.9955C11.3052 13.25 10.7455 12.6904 10.7455 12ZM15.491 12C15.491 11.3096 16.0507 10.75 16.741 10.75H16.75C17.4404 10.75 18 11.3096 18 12C18 12.6904 17.4404 13.25 16.75 13.25H16.741C16.0507 13.25 15.491 12.6904 15.491 12Z"
                                fill="currentColor"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M17 3C17 2.44772 17.4477 2 18 2C19.913 2 21.231 3.73702 21.231 5.57143C21.231 5.7037 21.2308 5.83139 21.2305 5.95493C21.2286 6.92941 21.2271 7.64597 21.3561 8.31956C21.4902 9.02032 21.7711 9.67768 22.4286 10.4355C23.1905 11.3136 23.1905 12.6864 22.4286 13.5645C21.7711 14.3223 21.4902 14.9797 21.3561 15.6804C21.2271 16.354 21.2286 17.0706 21.2305 18.0451C21.2308 18.1686 21.231 18.2963 21.231 18.4286C21.231 20.263 19.913 22 18 22C17.4477 22 17 21.5523 17 21C17 20.4477 17.4477 20 18 20C18.5513 20 19.231 19.4345 19.231 18.4286C19.231 18.3009 19.2306 18.1735 19.2302 18.0463C19.2271 17.1055 19.224 16.1805 19.3917 15.3045C19.5932 14.2518 20.0334 13.2733 20.9179 12.2538C21.0274 12.1277 21.0274 11.8723 20.9179 11.7462C20.0334 10.7267 19.5932 9.74823 19.3917 8.69554C19.224 7.81947 19.2271 6.89448 19.2302 5.95367C19.2306 5.82652 19.231 5.69908 19.231 5.57143C19.231 4.56552 18.5513 4 18 4C17.4477 4 17 3.55228 17 3Z"
                                fill="currentColor"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7 3C7 2.44772 6.55228 2 6 2C4.08695 2 2.76897 3.73702 2.76897 5.57143C2.76897 5.63284 2.76899 5.69342 2.76902 5.7532C2.76989 7.94318 2.77033 9.05366 1.57139 10.4355C0.809534 11.3136 0.809536 12.6864 1.5714 13.5645C2.22892 14.3223 2.50982 14.9797 2.64394 15.6804C2.77287 16.354 2.77143 17.0706 2.76947 18.0451C2.76923 18.1686 2.76897 18.2963 2.76897 18.4286C2.76897 20.263 4.08695 22 6 22C6.55228 22 7 21.5523 7 21C7 20.4477 6.55228 20 6 20C5.44872 20 4.76897 19.4345 4.76897 18.4286C4.76897 18.3009 4.76939 18.1735 4.76981 18.0463C4.77291 17.1055 4.77596 16.1805 4.60829 15.3045C4.4068 14.2518 3.96658 13.2733 3.08206 12.2538C2.97265 12.1277 2.97265 11.8723 3.08206 11.7462C4.77803 9.79147 4.77405 7.98584 4.76945 5.90134C4.76921 5.79216 4.76897 5.68221 4.76897 5.57143C4.76897 4.56552 5.44872 4 6 4C6.55228 4 7 3.55228 7 3Z"
                                fill="currentColor"
                              />
                            </motion.svg>
                          )}
                        </div>
                      </div>
                    </>
                  </AnimatePresence>
                </motion.div>
              </AnimatePresence>
            </motion.div>
          </AnimatePresence>
          <motion.div
            layout="position"
            className="py-4 px-4 w-full text-demo text-sm bg-demo-bg border-solid border-t-[1px] box-border border-box border-neutral-700"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.5 }}
          >
            <AnimatePresence>
              {!isShown ? (
                <>
                  Applicant Tracking System:{" "}
                  <span className="font-medium">{ats}</span>
                </>
              ) : (
                <motion.div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className={`font-text-l-regular transform-gpu transition-all duration-300 text-light-100
                    text-sm bg-base-white rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border shrink-0 overflow-hidden flex flex-col py-4 px-4 items-start justify-start border-[1px] border-solid border-neutral-700 space-y-4`}
                  >
                    <motion.div className="w-full flex justify-between">
                      <motion.div className="space-x-2">
                        <motion.div
                          onClick={() => {
                            setIsRequest(true);
                            setGet(true);
                            handleEndpointClick("get-form");
                          }}
                          className={`font-text-l-regular transform-gpu transition-all duration-300 ${
                            get
                              ? "text-demo bg-demo-bg"
                              : "text-gray-300 bg-gray-100"
                          }
                text-sm font-medium cursor-pointer rounded-md box-border inline-flex py-2 px-4 items-center justify-center`}
                        >
                          /get-form
                        </motion.div>
                        <motion.div
                          onClick={() => {
                            setIsRequest(true);
                            setGet(false);
                            handleEndpointClick("apply");
                          }}
                          className={`font-text-l-regular transform-gpu transition-all duration-300 ${
                            !get
                              ? "text-demo bg-demo-bg"
                              : "text-gray-300 bg-gray-100"
                          }
                          text-sm font-medium cursor-pointer rounded-md box-border inline-flex py-2 px-4 items-center justify-center`}
                        >
                          /apply
                        </motion.div>
                      </motion.div>
                      <motion.div className="space-x-2">
                        <motion.div
                          onClick={() => {
                            setIsRequest(true);
                            setApiDisplayContent(
                              JSON.stringify(currentRequestDetails, null, 2)
                            );
                          }}
                          className={`font-text-l-regular transform-gpu transition-all duration-300 ${
                            isRequest
                              ? "text-orange-300 bg-orange-100"
                              : "text-gray-300 bg-gray-100"
                          }
                text-sm font-medium cursor-pointer rounded-md box-border inline-flex py-2 px-4 items-center justify-center`}
                        >
                          Request
                        </motion.div>
                        <motion.div
                          onClick={() => {
                            setIsRequest(false);
                            // No need to set apiDisplayContent here, as it will be set in the makeApiCall function
                          }}
                          className={`font-text-l-regular transform-gpu transition-all duration-300 ${
                            !isRequest
                              ? isLoading
                                ? "text-orange-500 bg-orange-100"
                                : "text-green-500 bg-green-100"
                              : "text-gray-300 bg-gray-100"
                          }
                text-sm font-medium cursor-pointer rounded-md box-border inline-flex py-2 px-4 items-center justify-center`}
                        >
                          {isLoading && (
                            // Render a centered spinner if isLoading is true
                            <motion.div
                              animate={{ rotate: 360 }}
                              transition={{ repeat: Infinity, duration: 1 }}
                              className="flex items-center justify-center mr-4"
                            >
                              <svg
                                className="w-3 h-3"
                                fill="none"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </motion.div>
                          )}
                          Response
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    {/* <CodeSnippetButton
                      content={apiDisplayContent}
                      handleNextStep={handleNextStep}
                    /> */}
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AnalyticsJobContainer;
