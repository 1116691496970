import { useMemo } from "react";
import AppSummaryTable from "../AppSummaryTable";
import Checkbox from "../Checkbox";
import DropdownInput from "../DropdownInput";
const sampleSummaryData = [{
  caption: "General Information",
  values: ["", "", ""]
}, {
  caption: "Your documents",
  values: []
}, {
  caption: "Additional Questions",
  values: ["Unfortunately not, I will be unable to relocate until next year", "", "Yes", "Yes"]
}];
export const ApplicationSummary = ({
  steps,
  setCurrentStep
}) => {
  const applicationSummaryData = useMemo(() => {
    if (!steps || !steps.length == 3) return [];
    const summary = [];
    const stepIds = ["General Information", "Your Documents", "Additional Questions"];
    for (let i = 0; i < stepIds.length; i++) {
      const stepData = steps[i];
      const summaryStep = {
        caption: stepIds[i]
      };
      const values = [];
      for (let j = 0; j < Object.keys(stepData).length; j++) {
        values.push(stepData[Object.keys(stepData)[j]]);
        // switch (questionData.type) {
        //   case "text":
        //     values.push(questionValue.value);
        //     break;
        //   case "select":
        //     values.push(questionValue.selectedOption.value);
        //     break;
        //   case "address":
        //     values.push(
        //       `${questionValue.value.zipCode} ${
        //         questionValue.value.address
        //       }, ${
        //         questionValue.value.country
        //           ? questionValue.value.country.value
        //           : ""
        //       }`
        //     );
        //     break;
        //   case "file": {
        //     const file = questionData.options.find(
        //       (f) => f.id === questionValue.selectedFileId
        //     );
        //     if (!file) break;
        //     values.push(file.filename);
        //     break;
        //   }
        //}
      }
      summaryStep.values = values;
      summary.push(summaryStep);
    }
    return summary;
  }, [steps]);
  return <div>
      <AppSummaryTable rows={applicationSummaryData} setCurrentStep={setCurrentStep} />
      {/*
       <div className="mt-5">
        <DropdownInput
          options={[
            { value: "linkedIn", label: "Via LinkedIn" },
            { value: "Facebook", label: "Via Facebook" },
          ]}
          value="Yes"
          onChange={() => {}}
          titleText="How did you hear about us?"
          titleClass="text-primary-600"
        />
       </div>
       */}
      {/* <div className="flex items-center gap-3 mt-5">
        <Checkbox onChange={() => {}} value={false} />
        <span className="text-sm text-primary-600 font-text-l-regular">
          Send me email confirmation of my application
        </span>
       </div> */}
      <div className="text-note mt-6">
        <span className="text-luh-light-gray">
          Your answers will be saved for future applications
        </span>{" "}
      </div>
    </div>;
};
export default ApplicationSummary;